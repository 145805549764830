import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from "reactstrap"
import { updateVehicleDetail } from "store/actions";
import AddEditVehicle from "./Vehicle/AddEditVehicle";

import Vehicle from './Vehicle/Vehicle'
import ConfirmModal from '../Common/ConfirmModal'
import { deleteVehicles } from '../../store/actions'

const VehicleMgmt = () => {
    let vehicleObjectfromState = useSelector(state => state.Profile.vehicleDetail);
    const [showVehicleModal, setVehicleModelVisibility] = useState(false);
    const [showConfirmModel, setShowConfirmModel] = useState(false);
    const [currentVehicleObject, setCurrentVehicleObject] = useState();
    const dispatch = useDispatch();

    let userDetailfromState = useSelector(state => state.Profile.userProfile);
    const addUpdateVehicleDetail = (data) => {
        dispatch(updateVehicleDetail({ ...data, userID: userDetailfromState.id }));
        setVehicleModelVisibility(false);
    }

    return (
        <React.Fragment>
            <Container>
                <Row className="justify-content-center">
                    <Col md={12} lg={12} xl={12}>
                        <Container>
                            <h5 className="text-primary">Manage Vehicle</h5>
                            <hr />
                            <Row>
                                {vehicleObjectfromState && vehicleObjectfromState.map((item, index) => {
                                    return (
                                        <Vehicle data={item} key={'address_detail_' + index + item.userID}
                                            vehicleSelected={(vehicleDetail) => {
                                                setCurrentVehicleObject(vehicleDetail)
                                                setVehicleModelVisibility(true)
                                            }}
                                            deleteVehicleDetail={(vehicleDetail) => {
                                                setCurrentVehicleObject(vehicleDetail)
                                                setShowConfirmModel(true)
                                            }}
                                        />
                                    )
                                })}
                            </Row>
                            <br />
                            <AddEditVehicle
                                isOpen={showVehicleModal}
                                currentVehicleObject={currentVehicleObject}
                                onCancelClick={() => { setVehicleModelVisibility(false) }}
                                onSubmitClick={(addressDetail) => { addUpdateVehicleDetail(addressDetail) }}
                            />
                            <ConfirmModal
                                isOpen={showConfirmModel}
                                titleText={'Are you sure you want to delete this vehicle detail'}
                                onYesClick={() => {
                                    dispatch(deleteVehicles(currentVehicleObject))
                                    setShowConfirmModel(false)
                                }}
                                onNoClick={() => { setShowConfirmModel(false) }}
                            />
                            <button type="submit" className="btn btn-primary" onClick={() => {
                                setCurrentVehicleObject(null)
                                setVehicleModelVisibility(true)
                            }}>Add New</button>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default VehicleMgmt;
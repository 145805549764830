import React from "react";

import {
    Col,
    Form,
    Input,
    Label,
    Row,
    Spinner
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import PropTypes from 'prop-types';

const documentStatus = [
    {
        doc: 'Aadhar card',
        isVerified: false,
        docNo: '1234 1234 1234'
    },
    {
        doc: 'Driving Licence',
        isVerified: true,
        docNo: '1234 1234 1234'
    }
]

const KycDetail = ({
    data,
}) => {

    return (<>
        <Form>
            <Row>
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                    >
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th data-priority="1">Document</Th>
                                <Th data-priority="3">Document No.</Th>
                                <Th data-priority="1">Verification Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {documentStatus.map((el, index) => (
                                <Tr key={index}>
                                    <Th>
                                        {index + 1}
                                    </Th>
                                    <Td className="center">
                                        {el.doc}
                                    </Td>
                                    <Td className="center">
                                        {el.docNo}
                                    </Td>
                                    <Td className="center">
                                        {el.isVerified && 'Verified'}
                                        {!el.isVerified && ' Not Verified'}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            </Row>
        </Form>
    </>);
}

KycDetail.propTypes = {
    data: PropTypes.any,
    showResult: PropTypes.bool
}

export default KycDetail;
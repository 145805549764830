import { all, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  CREATE_PARTNER,
  GET_ALL_HELP_BUDDY_LIST,
  GET_ALL_PARTNER_LIST,
  GET_ALL_USER,
  GET_HEALTH_BUDDY_LIST,
  SEARCH_USER,
  UPDATE_HEALTH_BUDDY_LIST,
} from "./actionTypes"

import {
  allHelpBuddyList,
  allUser,
  allSeniorCitizen,
  allPartnerList,
  allHealthBuddyList,
  createPartnerResponse,
  createHealthBuddyResponse,
  updateHealthBuddyResponse,
  getHealthBuddyResponse,
  createPartnerError,
} from "./actions"

import {
  getAllHelpBuddyList,
  getAllUser,
  createPartner,
  updateHealthBuddy,
  getAllHealthBuddy,
  searchUser
} from "utils/Apiv2"
import { USER_TYPE } from "constants/common"
function* getAllUserSaga(data) {
  try {
    const userType = data.payload.type
    const page = data.payload.page
    const pageSize = data.payload.size
    const resp = yield call(getAllUser, {type: userType, page, size: pageSize})
    if (resp) {
      if (userType === USER_TYPE.SERVICE_PROVIDER) {
        yield put(allHelpBuddyList(resp))
      } else if (userType === USER_TYPE.SENIOR_CITIZEN) {
        yield put(allSeniorCitizen(resp))
      } else if (userType === USER_TYPE.PARTNER) {
        yield put(allPartnerList(resp))
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* searchUserSaga(data) {
  try {
    const resp = yield call(searchUser, data.payload)
    if (resp) {
      yield put(allUser(resp))
      // if (resp.length) {
        let helpBuddy = resp.filter(
          item => item.type === USER_TYPE.SERVICE_PROVIDER
        )
        let seniorCitizen = resp.filter(
          item => item.type === USER_TYPE.SENIOR_CITIZEN
        )
        let partners = resp.filter(item => item.type === USER_TYPE.PARTNER)
        yield all([
          put(allHelpBuddyList({users: helpBuddy})),
          put(allSeniorCitizen({users: seniorCitizen})),
          put(allPartnerList({users: partners})),
        ])
      // } else {
      //   console.log('Debug ::> NO USER', )
      // }
    }
  } catch (error) {
    console.error('Debug ::> ', error)
  }
}

function* getAllHealthBuddySaga(data) {
  try {
    const resp = yield call(getAllHealthBuddy, data.payload)
    if (resp) {
      yield put(getHealthBuddyResponse(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* getAllHelpBuddyListSaga() {
  try {
    const resp = yield call(getAllHelpBuddyList, null)
    if (resp) {
      yield put(allHelpBuddyList(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* savePartner(partnerData) {
  try {
    const resp = yield call(
      createPartner,
      partnerData.payload.data,
      partnerData.payload.partnerId
    )
    if (resp) {
      if (resp.type === USER_TYPE.PARTNER) {
        yield put(createPartnerResponse(resp))
        yield put(createPartnerError('success'))
      } else if (resp.type === USER_TYPE.HEALTH_BUDDY) {
        yield put(createHealthBuddyResponse(resp))
      }
    }
  } catch (error) {
    console.error(error)
    if (error?.response?.data?.message) {
      const errorMessage = error?.response?.data?.message.replace('PreconditionFailureException: ', '');
      yield put(createPartnerError(errorMessage))
    }
  }
}

function* updateHealthBuddySaga(data) {
  try {
    const resp = yield call(updateHealthBuddy, data.payload)
    if (resp) {
      yield put(updateHealthBuddyResponse(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* Users() {
  yield takeEvery(GET_ALL_HELP_BUDDY_LIST, getAllHelpBuddyListSaga)
  yield takeEvery(GET_ALL_USER, getAllUserSaga)
  yield takeEvery(GET_HEALTH_BUDDY_LIST, getAllHealthBuddySaga)
  yield takeEvery(CREATE_PARTNER, savePartner)
  yield takeEvery(UPDATE_HEALTH_BUDDY_LIST, updateHealthBuddySaga)
  yield takeEvery(SEARCH_USER, searchUserSaga)
}

export default Users

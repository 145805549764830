import React, { useEffect } from "react";

import {
    Col,
    Form,
    Input,
    Label,
    Row,
    Spinner
} from "reactstrap";
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import { updateSocialBehaviour } from "store/actions";

const SocialMedia = ({
    data,
    showResult = false
}) => {

    const dispatch = useDispatch();

    function getValue(type) {
        let socialData = data.filter(item => item.name === type);
        if (socialData && socialData.length > 0) {
            return socialData[0].socialUrl;
        }
        return "";
    }

    function valueChanged(field, value) {
        dispatch(updateSocialBehaviour({ field, value }))
    }

    return (<>
        <Form>
            <Row>
                <Col lg="8">
                    <div className="mb-3">
                        <Label for="facebook">
                            Facebook
                        </Label>
                        <div className="input-group">
                            <Input type="text" placeholder="Facebook ID" className="form-control" id="facebook" aria-describedby="inputGroupFileAddon04" aria-label="IPIN" onChange={(e) => { valueChanged('Facebook', e.target.value) }} value={getValue('Facebook')} />
                            {/* <button className="btn btn-primary" type="button" id="inputGroupFileAddon04">Verify</button> */}
                        </div>
                    </div>
                    <div className="mb-3">
                        <Label for="twitter">
                            Twitter
                        </Label>
                        <div className="input-group">
                            <Input type="text" placeholder="Twitter ID" className="form-control" id="twitter" aria-describedby="inputGroupFileAddon04" aria-label="IPIN" value={getValue('Twitter')} onChange={(e) => { valueChanged('Twitter', e.target.value) }} />
                            {/* <button className="btn btn-primary" type="button" id="inputGroupFileAddon04">Verify</button> */}
                        </div>
                    </div>
                    <div className="mb-3">
                        <Label for="website">
                            Website
                        </Label>
                        <div className="input-group">
                            <Input type="text" placeholder="Your website" className="form-control" id="website" aria-describedby="inputGroupFileAddon04" aria-label="IPIN" value={getValue('Website')} onChange={(e) => { valueChanged('Website', e.target.value) }} />
                            {/* <button className="btn btn-primary" type="button" id="inputGroupFileAddon04">Verify</button> */}
                        </div>
                    </div>
                    {/* <div className="mb-3">
                        <div className="input-group">
                            <button className="btn btn-primary pull-right" type="button" id="inputGroupFileAddon04">Analyse</button>
                        </div>
                    </div> */}
                </Col>
                {showResult && <Col lg="4" style={{ "border-left": '1px solid rgba(0, 0, 0, 0.05)' }}>
                    <div className="mb-3">
                        <Label for="basicpill-vatno-input6">
                            <p className="text-h2">Behavioral Analysis</p>
                            <small>Based on analysis from Internet and Social media platforms.</small>
                        </Label>
                        <hr />
                        <p>
                            <Spinner type="grow" className="ms-1 beep-icon" color="danger" />
                            2 post hurting religious sentiments found
                        </p>
                        <p>
                            <Spinner type="grow" className="ms-1 beep-icon" color="danger" />
                            1 anti-national post found
                        </p>
                        <p>
                            <Spinner type="grow" className="ms-1 beep-icon" color="warning" />
                            1 possible online complaint found
                        </p>
                    </div>
                </Col>}
            </Row>
        </Form>
    </>);
}

SocialMedia.propTypes = {
    data: PropTypes.any,
    showResult: PropTypes.bool
}

export default SocialMedia;
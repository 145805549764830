import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Container,
  Row,
  Nav,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Collapse,
  Button,
} from "reactstrap"
import classnames from "classnames"
import MetaTags from "react-meta-tags"
import { USER_TYPE } from "../../constants/common"
import Breadcrumb from "../../components/Common/Breadcrumb"
import ProfileMgmt from "../../components/Happy60/ProfileMgmt"
import AddressMgmt from "../../components/Happy60/AddressMgmt"
import ContactMgmt from "../../components/Happy60/ContactMgmt"
import VehicleMgmt from "../../components/Happy60/VehicleMgmt"

import { getImageForUser } from "utils/Common"
import domtoimage from "dom-to-image"
import happy60plusLogo from "../../assets/images/happy60plus/happy60plus_icon.png"
import moment from "moment"

const UserProfile = () => {
  const [col0, setcol0] = useState(true)
  const [col1, setcol1] = useState(false)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const [col4, setcol4] = useState(false)

  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1")

  const [userData, setUserData] = useState({})
  let userDetailfromState = useSelector(state => state.Profile.userProfile)
  let seniorCitizenDetailfromState = useSelector(
    state => state.Profile.seniorCitizenDetail
  )

  const menu_item = {
    profile_mgmt: {
      text: "Profile Management",
    },
    address_mgmt: {
      text: "Address Management",
    },
    vehicle_mgmt: {
      text: "Vehicle Management",
    },
    contact_mgmt: {
      text: "Contact Management",
    },
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }
  const t_col0 = () => {
    setcol0(!col0)
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(false)
  }

  const t_col1 = () => {
    setcol1(!col1)
    setcol0(false)
    setcol2(false)
    setcol3(false)
    setcol4(false)
  }

  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol0(false)
    setcol3(false)
    setcol4(false)
  }

  const t_col3 = () => {
    setcol3(!col3)
    setcol0(false)
    setcol2(false)
    setcol1(false)
    setcol4(false)
  }
  const t_col4 = () => {
    setcol4(!col4)
    setcol0(false)
    setcol2(false)
    setcol3(false)
    setcol1(false)
  }

  useEffect(() => {
    // dispatch(getProfileDetail('1abff1cc-a1ef-4973-8288-d32bea113a76'));
  }, [])

  useEffect(() => {
    if (seniorCitizenDetailfromState && seniorCitizenDetailfromState !== null) {
      setUserData({
        ...userDetailfromState,
        age: seniorCitizenDetailfromState.age,
        gender: seniorCitizenDetailfromState.gender,
        membershipID: seniorCitizenDetailfromState.membershipID,
      })
    }
  }, [seniorCitizenDetailfromState])

  const saveMembershipCard = () => {
    domtoimage
      .toJpeg(document.getElementById("MembershipCard"), {
        quality: 2,
      })
      .then(function (dataUrl) {
        let link = document.createElement("a")
        link.download = "membership-card.jpeg"
        link.href = dataUrl
        link.click()
        link.remove()
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Happy60Plus</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Home" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {/* load the accordian */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <div className="mt-4">
                            {/* <CardTitle className="h4">Flush</CardTitle>
                                                            <p className="card-title-desc">
                                                            Manage all your bookings from here
                                                            </p> */}

                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              {userData &&
                                userData.type === USER_TYPE.SENIOR_CITIZEN && (
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFlushOne1"
                                    >
                                      <button
                                        className={classnames(
                                          "accordion-button",
                                          "fw-medium",
                                          { collapsed: !col4 }
                                        )}
                                        type="button"
                                        onClick={t_col4}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Membership Card
                                      </button>
                                    </h2>
                                    <Collapse
                                      isOpen={col4}
                                      className="accordion-collapse"
                                    >
                                      <div className="accordion-body">
                                        <div>
                                          <div className="membership-card">
                                            <div
                                              className="membership-card-wrapper"
                                              id="MembershipCard"
                                            >
                                              <div className="left mt-4">
                                                <div>
                                                  {userData.profileImageURL && (
                                                    <img
                                                      alt="user"
                                                      width="140"
                                                      src={getImageForUser(
                                                        userData.id,
                                                        userData.profileImageURL
                                                      )}
                                                    />
                                                  )}
                                                  <h3 className="px-1">
                                                    <b>
                                                      {userData.firstName}{" "}
                                                      {userData.lastName}
                                                    </b>
                                                  </h3>
                                                  <div className="info_data">
                                                    <div className="data">
                                                      <h3>
                                                        {userData.mobileNumber}
                                                      </h3>
                                                      <p>
                                                        <b>Issue on: </b>{" "}
                                                        {moment(
                                                          userData.createdDate
                                                        )
                                                          .local()
                                                          .format("DD/MM/YYYY")}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="right">
                                                <div className="info">
                                                  <h2>
                                                    <b>Membership Card</b>
                                                  </h2>
                                                  <div className="info_data">
                                                    <div className="data">
                                                      <h5>
                                                        <b>Email</b>
                                                      </h5>
                                                      <h3>{userData.email}</h3>
                                                    </div>
                                                  </div>
                                                  <div className="info_data">
                                                    <div className="data">
                                                      <h5>
                                                        <b>Age</b>
                                                      </h5>
                                                      <h3 className="text-center">
                                                        {userData.age || "NA"}
                                                      </h3>
                                                    </div>
                                                    <div className="data me-4">
                                                      <h5>
                                                        <b>Gender</b>
                                                      </h5>
                                                      <h3 className="text-center">
                                                        {userData?.gender?.slice(
                                                          0,
                                                          1
                                                        ) || "NA"}
                                                      </h3>
                                                    </div>
                                                  </div>
                                                  <div className="membership-id">
                                                    <div className="info_data">
                                                      <div className="data">
                                                        <h3>
                                                          {userData.membershipID ||
                                                            "NA"}
                                                        </h3>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="mt-2">
                                              <Button
                                                style={{
                                                  margin: "20px 0",
                                                  width: 450,
                                                  marginTop: 0,
                                                  position: "relative",
                                                  zIndex: 9,
                                                }}
                                                color="primary"
                                                onClick={saveMembershipCard}
                                              >
                                                Download
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>
                                )}
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingFlushOne"
                                >
                                  <button
                                    className={classnames(
                                      "accordion-button",
                                      "fw-medium",
                                      { collapsed: !col0 }
                                    )}
                                    type="button"
                                    onClick={t_col0}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Basic
                                  </button>
                                </h2>

                                <Collapse
                                  isOpen={col0}
                                  className="accordion-collapse"
                                >
                                  <div className="accordion-body">
                                    <ProfileMgmt />
                                  </div>
                                </Collapse>
                              </div>
                              {userDetailfromState &&
                                userDetailfromState !== null &&
                                userDetailfromState.type ===
                                  USER_TYPE.SERVICE_PROVIDER && (
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFlushTwo"
                                    >
                                      <button
                                        className={classnames(
                                          "accordion-button",
                                          "fw-medium",
                                          { collapsed: !col1 }
                                        )}
                                        type="button"
                                        onClick={t_col1}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Vehicles
                                      </button>
                                    </h2>

                                    <Collapse
                                      isOpen={col1}
                                      className="accordion-collapse"
                                    >
                                      <div className="accordion-body">
                                        <VehicleMgmt />
                                      </div>
                                    </Collapse>
                                  </div>
                                )}
                              {userDetailfromState &&
                                userDetailfromState !== null &&
                                userDetailfromState.type ===
                                  USER_TYPE.SERVICE_PROVIDER && (
                                  <React.Fragment>
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="headingFlushTwo"
                                      >
                                        <button
                                          className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: !col2 }
                                          )}
                                          type="button"
                                          onClick={t_col2}
                                          style={{ cursor: "pointer" }}
                                        >
                                          Addresses
                                        </button>
                                      </h2>

                                      <Collapse
                                        isOpen={col2}
                                        className="accordion-collapse"
                                      >
                                        <div className="accordion-body">
                                          <AddressMgmt />
                                        </div>
                                      </Collapse>
                                    </div>
                                  </React.Fragment>
                                )}
                              {userDetailfromState &&
                                userDetailfromState !== null &&
                                userDetailfromState.type ===
                                  USER_TYPE.SENIOR_CITIZEN && (
                                  <React.Fragment>
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="headingFlushTwo"
                                      >
                                        <button
                                          className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: !col2 }
                                          )}
                                          type="button"
                                          onClick={t_col2}
                                          style={{ cursor: "pointer" }}
                                        >
                                          Addresses
                                        </button>
                                      </h2>

                                      <Collapse
                                        isOpen={col2}
                                        className="accordion-collapse"
                                      >
                                        <div className="accordion-body">
                                          <AddressMgmt />
                                        </div>
                                      </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="headingFlushTwo"
                                      >
                                        <button
                                          className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: !col3 }
                                          )}
                                          type="button"
                                          onClick={t_col3}
                                          style={{ cursor: "pointer" }}
                                        >
                                          Contacts
                                        </button>
                                      </h2>

                                      <Collapse
                                        isOpen={col3}
                                        className="accordion-collapse"
                                      >
                                        <div className="accordion-body">
                                          <ContactMgmt />
                                        </div>
                                      </Collapse>
                                    </div>
                                  </React.Fragment>
                                )}
                            </div>
                          </div>
                        </Col>
                        {/* <Col md="2">
                                                    <Nav pills className="flex-column">
                                                        <NavItem>
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classnames({
                                                                    "mb-2": true,
                                                                    active: verticalActiveTab === "1",
                                                                })}
                                                                onClick={() => {
                                                                    toggleVertical("1")
                                                                }}
                                                            >
                                                                {menu_item.profile_mgmt.text}
                                                            </NavLink>

                                                        </NavItem>
                                                        {userDetailfromState && (userDetailfromState.type === 'SERVICE_PROVIDER' || userDetailfromState.type === 'SENIOR_CITIZEN') && (
                                                            <NavItem>
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        "mb-2": true,
                                                                        active: verticalActiveTab === "2",
                                                                    })}
                                                                    onClick={() => {
                                                                        toggleVertical("2")
                                                                    }}
                                                                >
                                                                    {menu_item.address_mgmt.text}
                                                                </NavLink>
                                                            </NavItem>
                                                        )}
                                                        {userDetailfromState && userDetailfromState.type === 'SENIOR_CITIZEN' && (
                                                            <NavItem>
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        "mb-2": true,
                                                                        active: verticalActiveTab === "3",
                                                                    })}
                                                                    onClick={() => {
                                                                        toggleVertical("3")
                                                                    }}
                                                                >
                                                                    {menu_item.contact_mgmt.text}
                                                                </NavLink>
                                                            </NavItem>
                                                        )}
                                                        {userDetailfromState && userDetailfromState.type === 'SERVICE_PROVIDER' && (
                                                            <NavItem>
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: verticalActiveTab === "4",
                                                                    })}
                                                                    onClick={() => {
                                                                        toggleVertical("4")
                                                                    }}
                                                                >
                                                                    {menu_item.vehicle_mgmt.text}
                                                                </NavLink>
                                                            </NavItem>
                                                        )}
                                                    </Nav>
                                                </Col>


                                                <Col md="10">
                                                    <TabContent
                                                        activeTab={verticalActiveTab}
                                                        className="text-muted mt-4 mt-md-0"
                                                    >
                                                        <TabPane tabId="1">
                                                            <ProfileMgmt />
                                                        </TabPane>
                                                        <TabPane tabId="2">
                                                            <AddressMgmt />
                                                        </TabPane>
                                                        <TabPane tabId="3">
                                                            <ContactMgmt />
                                                        </TabPane>
                                                        <TabPane tabId="4">
                                                            <VehicleMgmt />
                                                        </TabPane>
                                                    </TabContent>
                                                </Col> */}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserProfile

import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Modal, Row, Col, InputGroup, InputGroupAddon } from "reactstrap"

import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"

import { getHealthBuddy, updateHealthBuddy, uploadUserDocument } from "store/actions"
import AddressMgmt from "../AddressMgmt"
import { getImageForUser } from "utils/Common"

import countryCode from "utils/countryCode"

const HealthBuddyModal = ({ t, isOpen, closeModal, data }) => {
  const dispatch = useDispatch()
  const userProfile = useSelector(state => state.Profile.userProfile)
  let uploadFileResponse = useSelector(
    state => state.CommonReducer.uploadDocResp
  )

  const [handleX, setHandleX] = useState(true)
  const [profileImageURL, updateImage] = useState(data.profileImageURL || null)

  const [selectedLanguages, setSelectedLanguages] = useState([
    ...(data.languagesKnown || []),
  ])
  const [isBackgroundVerify, setBackgroundVerify] = useState(
    data.backgroundVerification
  )
  const [gender, setGender] = useState(data.gender || "")
  const [healthBuddyType, setHealthBuddyType] = useState(data?.healthBuddyType || "")

  const [selectedCountry, setCountry] = useState("+91")

  const handleValidSubmit = (event, values) => {
    event.preventDefault()

    const healthBuddyData = {
      ...values,
      id: data.id,
      partnerOrganizationID: userProfile.id,
      languagesKnown: selectedLanguages,
      backgroundVerification: isBackgroundVerify,
      gender,
      healthBuddyType,
      profileImageURL: profileImageURL,
      type: healthBuddyType,
    }

    // healthBuddyData.mobileNumber =
    //   selectedCountry + healthBuddyData.mobileNumber
    // healthBuddyData.mobileNumber = healthBuddyData.mobileNumber.slice(1)
    healthBuddyData.countryCode = selectedCountry.slice(1)

    dispatch(updateHealthBuddy(healthBuddyData))
    setTimeout(() => {
      dispatch(getHealthBuddy(userProfile.id))
    },1000)
    closeModal()
  }

  let languageList = [
    {
      key: "hindi",
      value: "Hindi",
    },
    {
      key: "english",
      value: "English",
    },
    {
      key: "kannada",
      value: "Kannada",
    },
    {
      key: "tamil",
      value: "Tamil",
    },
    {
      key: "telugu",
      value: "Telugu",
    },
    {
      key: "malayalam",
      value: "Malayalam",
    },
  ]

  const languageSelected = e => {
    if (e.target.checked) {
      setSelectedLanguages([...selectedLanguages, e.target.value])
    } else {
      const selected = selectedLanguages.filter(item => item !== e.target.value)
      setSelectedLanguages(selected)
    }
  }
  const setBackgroundCheck = e => {
    if (e.target.checked) {
      setBackgroundVerify(true)
    } else {
      setBackgroundVerify(false)
    }
  }

  const uploadImage = data => {
    dispatch(uploadUserDocument("profileImageURL", data))
  }

  useEffect(() => {
    if (uploadFileResponse) {
      updateImage(uploadFileResponse.data)
    }
  }, [uploadFileResponse])

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      className="modal-dialog-centered modal-md"
    >
      <div
        className="modal-header"
        style={{ borderBottom: "none", paddingBottom: 0 }}
      >
        <h5 className="modal-title mt-0">Update Health Buddy</h5>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <div className="p-2">
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>First Name</b>
                </Col>
                <Col xs={8}>
                  <AvField
                    name="firstName"
                    value={data.firstName}
                    className="form-control"
                    placeholder="Enter your first name"
                    type="text"
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Last Name</b>
                </Col>
                <Col xs={8}>
                  <AvField
                    name="lastName"
                    value={data.lastName}
                    className="form-control"
                    placeholder="Enter your last name"
                    type="text"
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Email</b>
                </Col>
                <Col xs={8}>
                  <AvField
                    name="email"
                    value={data.email}
                    className="form-control"
                    placeholder="Enter your Email"
                    type="email"
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Phone Number</b>
                </Col>
                <Col xs={8}>
                  <AvGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <select
                          id="dialCode"
                          name="dialCode"
                          className={`form-control form-select`}
                          style={{
                            textAlignLast: "center",
                            width: 80,
                          }}
                          onChange={e => {
                            setCountry(e.target.value)
                          }}
                          defaultValue={selectedCountry}
                        >
                          {countryCode &&
                            countryCode.map(country => (
                              <option
                                key={country.dialCode+country.name.replace(" ","")}
                                value={data.countryCode}
                              >
                                {country.dialCode === selectedCountry
                                  ? country.dialCode
                                  : `${country.name} ${country.dialCode}`}
                              </option>
                            ))}
                        </select>
                      </InputGroupAddon>
                      <AvInput
                        name="mobileNumber"
                        id="mobileNumber"
                        placeholder="Mobile Number"
                        type="number"
                        value={data.mobileNumber}
                        required
                      />
                      <AvFeedback>This field is required.</AvFeedback>
                    </InputGroup>
                  </AvGroup>
                </Col>
              </Row>
            </div>

            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Service Price for 12 Hours</b>
                </Col>
                <Col xs={8}>
                  <AvField
                    name="servicePrice12Hr"
                    value={data.servicePrice12Hr}
                    className="form-control"
                    placeholder="Enter Price for 12 Hours"
                    type="text"
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Service Price for 24 Hours</b>
                </Col>
                <Col xs={8}>
                  <AvField
                    name="servicePrice24Hr"
                    value={data.servicePrice24Hr}
                    className="form-control"
                    placeholder="Enter Price for 24 Hours"
                    type="text"
                    required
                  />
                </Col>
              </Row>
            </div>

            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Age</b>
                </Col>
                <Col xs={8}>
                  <AvField
                    name="age"
                    value={data.age}
                    className="form-control"
                    placeholder="Enter your Age"
                    type="number"
                    required
                  />
                </Col>
              </Row>
            </div>

            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Gender</b>
                </Col>
                <Col xs={8}>
                  <select
                    className="form-control"
                    value={gender || data.gender}
                    onChange={e => {
                      setGender(e.target.value)
                    }}
                  >
                    <option value="" selected disabled>
                      Select One
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Others</option>
                  </select>
                </Col>
              </Row>
            </div>
            {profileImageURL && (
              <div className="mb-3">
                <Row>
                  <Col xs={4} style={{ alignSelf: "center" }}>
                    <b>{t("Profile Photo")}</b>
                  </Col>
                  <Col xs={8}>
                    <img
                      className="rounded-circle avatar-sm"
                      src={getImageForUser(data.id, profileImageURL)}
                      alt=""
                    />
                    <i
                      className="bx bxs-trash text-danger"
                      style={{
                        marginLeft: "20px",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        updateImage(null)
                      }}
                      id="deleteImage"
                    ></i>
                  </Col>
                </Row>
              </div>
            )}
            {(!profileImageURL || profileImageURL === null) && (
              <div className="mb-3">
                <Row>
                  <Col xs={4} style={{ alignSelf: "center" }}>
                    <b>{t("Profile Photo")}</b>
                  </Col>
                  <Col xs={8}>
                    <AvField
                      id="profileImageURL"
                      name="profileImageURL"
                      // label={t("Profile Photo")}
                      className="form-control"
                      placeholder={t("Upload Profile Photo")}
                      type="file"
                      // value={profileImageURL}
                      onChange={e => {
                        uploadImage(e.target.files[0])
                      }}
                    />
                  </Col>
                </Row>
              </div>
            )}
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Health Buddy Type</b>
                </Col>
                <Col xs={8}>
                  <select
                    className="form-control"
                    value={healthBuddyType}
                    onChange={e => {
                      setHealthBuddyType(e.target.value)
                    }}
                  >
                    <option value="" selected disabled>
                      Select One
                    </option>
                    <option
                      value="110001"
                      selected={healthBuddyType === "110001"}
                    >
                      Care Taker
                    </option>
                    <option
                      value="110002"
                      selected={healthBuddyType === "110002"}
                    >
                      Associate Nurse
                    </option>
                    <option
                      value="110003"
                      selected={healthBuddyType === "110003"}
                    >
                      Registered Nurse
                    </option>
                  </select>
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Aadhar Number</b>
                </Col>
                <Col xs={8}>
                  <AvField
                    name="aadharNumber"
                    value={data.aadharNumber}
                    className="form-control"
                    placeholder="Enter your Aadhar Number"
                    type="number"
                    validate={{
                      minLength: { value: 12 },
                      maxLength: { value: 12 },
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Experience</b>
                </Col>
                <Col xs={8}>
                  <AvField
                    name="experience"
                    value={data.experience}
                    className="form-control"
                    placeholder="Experience"
                    type="number"
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Background Verification</b>
                </Col>
                <Col xs={8}>
                  <AvCheckboxGroup
                    name="checkbox"
                    value="backgroundVerification"
                    required
                  >
                    <AvCheckbox
                      name=""
                      value={isBackgroundVerify}
                      className="form-control"
                      checked={isBackgroundVerify}
                      onChange={e => {
                        setBackgroundCheck(e)
                      }}
                    />
                  </AvCheckboxGroup>
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Education</b>
                </Col>
                <Col xs={8}>
                  <AvField
                    name="education"
                    value={data.education}
                    className="form-control"
                    placeholder="Enter your Education"
                    type="text"
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={12} style={{ alignSelf: "center" }}>
                  <AddressMgmt userProfile={data} />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Languages Known</b>
                </Col>
                <Col xs={8}>
                  <AvCheckboxGroup
                    name="checkboxExample"
                    value="allowedServices"
                    required
                  >
                    {languageList &&
                      languageList.map(item => {
                        return (
                          <AvCheckbox
                            checked={
                              selectedLanguages &&
                              selectedLanguages.includes(item.key)
                            }
                            label={item.value}
                            key={item.key}
                            value={item.key}
                            onChange={e => {
                              languageSelected(e)
                            }}
                          />
                        )
                      })}
                  </AvCheckboxGroup>
                </Col>
              </Row>
            </div>

            <Row>
              <Col xs={2}>
                <button className="btn btn-primary btn-block" type="submit">
                  Submit
                </button>
              </Col>
              <Col xs={2}>
                <button
                  className="btn btn-primary btn-block"
                  type="reset"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </div>
    </Modal>
  )
}

HealthBuddyModal.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.any,
  closeModal: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation()(HealthBuddyModal)

import React, { useState, useEffect } from "react"
import { Label } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import ServiceBookingModal from "./serviceBookingModal"
import moment from "moment"
import RateServiceModal from "./rateServiceModal"
import { PAYMENT_STATUS } from "constants/common"
import Refundmodal from "../Payment/Refundmodal"
import DataTable from "react-data-table-component"
import { getServiceType } from "store/actions"

const CompletedService = () => {
  const dispatch = useDispatch()
  const [selectedServiceBooking, setSelectedServiceBooking] = useState(null)
  const [showServiceBookingModal, setServiceBookingModal] = useState(false)
  const [ratingModal, setRatingModal] = useState(false)
  const [refundModal, setRefundModal] = useState(false)
  const completedServiceList = useSelector(
    state => state.ServiceReducer.completedServices
  )
  let serviceType = useSelector(state => state.ServiceReducer.serviceType)
  let addressList = useSelector(state => state.Profile.address)

  useEffect(() => {
    if (!serviceType?.length) {
      dispatch(getServiceType())
    }
  }, [])

  const getServiceName = serviceId => {
    let serviceObj = serviceType.find(item => item.id === serviceId)
    return serviceObj && serviceObj.serviceType ? serviceObj.serviceType : "-"
  }
  const getAddressTag = addressId => {
    let addressObj = addressList.find(item => item.id === addressId)
    return addressObj && addressObj.tags ? addressObj.tags : "-"
  }
  const getPaymentStatus = status => {
    if (status === PAYMENT_STATUS.PAID) {
      return "Paid"
    } else if (status === PAYMENT_STATUS.PENDING || status === "PENDING") {
      return "Pending"
    } else if (status === PAYMENT_STATUS.PARTIALLY_PAID) {
      return "Partially Paid"
    } else if (status === PAYMENT_STATUS.REFUND_PENDING) {
      return "Refund Pending"
    } else if (status === PAYMENT_STATUS.REFUND_INITIATED) {
      return "Refund Initiated"
    } else if (status === PAYMENT_STATUS.REFUND_COMPLATED) {
      return "Refunded"
    } else {
      return "-"
    }
  }
  let columns = [
    {
      id: 1,
      name: "Service",
      selector: item => item.serviceID,
      format: item => getServiceName(item.serviceID),
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Date",
      selector: item => item.dateTimeFrom,
      format: item =>
        moment(item.dateTimeFrom).isValid()
          ? moment.utc(item.dateTimeFrom).local().format("DD/MM/YYYY hh:mm A")
          : "-",
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Duration",
      selector: row => row.duration,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Address",
      selector: item => item.serviceAddressID,
      format: item => getAddressTag(item.serviceAddressID),
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Description",
      selector: row => row.description,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Payment Status",
      selector: item => item.paymentStatus,
      format: item => getPaymentStatus(item.paymentStatus),
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Action",
      selector: item => (
        <ul className="list-inline mb-0 font-size-20">
          <li className="list-inline-item">
            <i
              className="bx bxs-star text-success"
              onClick={() => {
                setSelectedServiceBooking(item)
                setRatingModal(true)
              }}
              id="rateService"
            ></i>
          </li>
          <li className="list-inline-item">
            <i
              className="bx bx-info-circle text-primary"
              onClick={() => {
                setSelectedServiceBooking(item)
                setServiceBookingModal(true)
              }}
            ></i>
          </li>
        </ul>
      ),
      sortable: true,
      // right: true,
      reorder: true,
    },
  ]

  return (
    <React.Fragment>
      {completedServiceList.length > 0 && (
        <DataTable
          noHeader
          // title="Senior Citizen"
          columns={columns}
          data={completedServiceList}
          defaultSortFieldId={2}
          // sortIcon={<SortIcon />}
          pagination
          highlightOnHover
          // selectableRows
        />
      )}
      {completedServiceList.length === 0 && <Label>No bookings found</Label>}
      {selectedServiceBooking && (
        <>
          <ServiceBookingModal
            isOpen={showServiceBookingModal}
            selectedService={selectedServiceBooking}
            data={selectedServiceBooking}
            closeModal={() => {
              setServiceBookingModal(false)
            }}
            onSubmitClick={() => {
              setServiceBookingModal(false)
            }}
            onCancelClick={() => {
              setServiceBookingModal(false)
            }}
          />
          <RateServiceModal
            isOpen={ratingModal}
            data={selectedServiceBooking}
            onCancelClick={() => {
              setRatingModal(false)
            }}
          ></RateServiceModal>
          <Refundmodal
            isOpen={refundModal}
            data={selectedServiceBooking}
            closeModal={() => {
              setRefundModal(false)
            }}
          ></Refundmodal>
        </>
      )}
    </React.Fragment>
  )
}

export default CompletedService

import React, { useEffect, useState } from "react"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch, useSelector } from "react-redux"
import DataTable from "react-data-table-component"
import { getAllTripList, getServiceType } from "store/actions"
import UserDetailModal from "./TripStatusModel"
import EnquiryTooltipItem from "./EnquiryTooltipItem"

import moment from "moment"

const Trip = () => {
  const dispatch = useDispatch()

  let partnerList = useSelector(state => state.Trip.trips)
  let loading = useSelector(state => state.Trip.loader)
  let page = useSelector(state => state.Trip.page)
  let services = useSelector(state => state.ServiceReducer.serviceType)

  const [perPage, setPerPage] = useState(10)
  const [selectedTrip, setSelectedTrip] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!services?.length) {
      dispatch(getServiceType())
    }
  }, [services])

  useEffect(() => {
    if ((services && services.length) || isOpen === false) {
      handlePageChange(1)
    }
  }, [services, isOpen])

  const handlePageChange = page => {
    dispatch(getAllTripList(page, perPage))
  }

  const handlePerRowsChange = newPerPage => {
    dispatch(getAllTripList(page, newPerPage))
    setPerPage(newPerPage)
  }

  const tripStatus = {
    RAISED: "RAISED",
    UNDER_PROCESS: "UNDER_PROCESS",
    COMPLETED: "COMPLETED",
    UNSERVICEABLE: "UNSERVICEABLE",
    NOT_FULFILLED: "NOT_FULFILLED",
  }

  const columns = [
    {
      id: 1,
      name: "Name",
      selector: row => row.name,
      sortable: true,
      reorder: true,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <span>{row.name}</span>
        </div>
      ),
    },
    {
      id: 2,
      name: "Contact No.",
      selector: row => row.mobileNumber,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Email",
      selector: row => row.email || "N/A",
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Created Date",
      selector: item => item.createdDate,
      format: item =>
        item.createdDate
          ? moment(item.createdDate).format("DD-MM-YYYY hh:mm A")
          : "N/A",
      // format: item =>
      //   item.createdDate
      //     ? moment(item.createdDate).isValid()
      //       ? moment.utc(item.createdDate).local().format("DD-MM-YYYY hh:mm A")
      //           .toUpperCase
      //       : moment(Number(item.createdDate)).isValid()
      //       ? moment(Number(item.createdDate))
      //           .local()
      //           .format("DD-MM-YYYY hh:mm A").toUpperCase
      //       : "-"
      //     : "-",
      sortable: true,
      sortFunction: (a, b) => {
        if (a.createdDate && b.createdDate) {
          return a.createdDate - b.createdDate
        }
        return 0
      },
      reorder: true,
    },
    {
      id: 6,
      name: "Status",
      selector: row => tripStatus[row.status] || row.status,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <span>{tripStatus[row.status] || row.status}</span>
          <EnquiryTooltipItem key={row.id} id={row.id} item={row} />
        </div>
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Action",
      selector: row => row,
      sortable: true,
      reorder: true,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <span>
            <button
              type="button"
              className={`btn btn-primary`}
              onClick={() => {
                setSelectedTrip(row)
                setIsOpen(true)
              }}
            >
              Change Status
            </button>
          </span>
        </div>
      ),
    },
  ]

  if (loading) {
    return <>Loading....</>
  }

  return (
    <React.Fragment>
      <DataTable
        noHeader
        columns={columns}
        data={partnerList}
        defaultSortFieldId={5}
        defaultSortAsc={false}
        highlightOnHover
        pagination
        paginationServer={false}
        paginationTotalRows={partnerList.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[10]}
      />

      {isOpen && (
        <UserDetailModal
          isOpen={isOpen}
          data={selectedTrip}
          closeModal={() => {
            setIsOpen(false)
            setSelectedTrip(null)
          }}
        />
      )}
    </React.Fragment>
  )
}

export default Trip

import React, { useEffect, useState, useMemo } from "react"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch, useSelector } from "react-redux"
import DataTable from "react-data-table-component"
import { allHomePageServiceList, deleteHomePageService } from "store/actions"
import HomePageServiceModel from "./HomePageServiceModel"
// import EnquiryTooltipItem from "./EnquiryTooltipItem"

// import moment from "moment"
import { CardBody, Row, Col, Card } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

const ServiceList = () => {
  const dispatch = useDispatch()

  let homePageServices = useSelector(
    state => state.HomePageServices.homePageServices
  )
  let loading = useSelector(state => state.HomePageServices.loader)

  const [selectedService, setSelectedService] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!(homePageServices && homePageServices?.length)) {
      dispatch(allHomePageServiceList())
    }
  }, [homePageServices])

  const columns = [
    {
      id: 1,
      name: "Service Name",
      selector: row => row.title,
      sortable: true,
      reorder: true,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <span>{row.title}</span>
        </div>
      ),
    },
    {
      id: 2,
      name: "Sub Title",
      selector: row => row.sub_title,
      sortable: true,
      reorder: true,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <span>{row.sub_title}</span>
        </div>
      ),
    },
    {
      id: 3,
      name: "Action",
      selector: row => row,
      sortable: true,
      reorder: true,
      cell: row => (
        <div
          className="d-flex justify-content-around w-100"
          style={{ cursor: "pointer" }}
        >
          <span>
            <button
              type="button"
              className={`btn btn-primary p-2`}
              style={{ minWidth: "100px", fontSize: 16 }}
              onClick={() => {
                console.log(
                  "APP-LOG: File: ServiceList.js Line: #72::> row",
                  row
                )
                setSelectedService(row)
                setIsOpen(true)
              }}
            >
              Edit
            </button>
          </span>
          <span>
            <button
              type="button"
              className={`btn btn-primary p-2`}
              style={{ minWidth: "100px", fontSize: 16 }}
              onClick={() => {
                dispatch(deleteHomePageService(row.id))
              }}
            >
              Delete
            </button>
          </span>
        </div>
      ),
    },
  ]

  if (loading) {
    return <>Loading....</>
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary btn-block"
                        type="button"
                        onClick={() => {
                          setSelectedService({
                            id: null,
                            title: "",
                            sub_title: "",
                            description: "",
                            image_url: "",
                            image_caption: "",
                            serviceDetails: [],
                          })
                          setIsOpen(true)
                        }}
                      >
                        Add New Service
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <DataTable
        noHeader
        columns={columns}
        pagination
        highlightOnHover
        data={homePageServices}
        defaultSortAsc={false}
      />

      {isOpen && (
        <HomePageServiceModel
          isOpen={isOpen}
          data={selectedService}
          closeModal={() => {
            setIsOpen(false)
            setSelectedService(null)
          }}
        />
      )}
    </React.Fragment>
  )
}

export default ServiceList

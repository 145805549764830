import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
import { useParams } from "react-router-dom"
import { loginWithToken } from "store/actions"

const MobileServicesRedirectionPage = () => {
  const routeParams = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const token = routeParams.token

    if (token) {
      dispatch(loginWithToken(token))
    } else {
      window.location.href = "/mobile-services"
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Services | Happy60Plus</title>
        </MetaTags>
      </div>
    </React.Fragment>
  )
}

export default MobileServicesRedirectionPage

import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, Input } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import {
  resetPassword,
  generateOtp,
  userForgetPassword,
  verifyOtp,
} from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import happy60plus from "assets/images/happy60plus/logo.png"

const ForgetPasswordPage = props => {
  const dispatch = useDispatch()

  const [showOtpBox, setShowOtpBox] = useState(false)
  const [userName, setUserName] = useState()
  const [otp, setOtp] = useState()
  const [error, setError] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  let optStatus = useSelector(state => state.Login.optVerificationStatus)
  let changePasswordResp = useSelector(
    state => state.ForgetPassword.changepasswordResp
  )

  useEffect(() => {
    if (changePasswordResp && changePasswordResp !== null) {
      if (changePasswordResp.status === "success") {
        setSuccessMessage(changePasswordResp.message)
      } else {
        setError(changePasswordResp.message)
      }
    }
    changePasswordResp && changePasswordResp !== null
      ? changePasswordResp.status === "success"
        ? setSuccessMessage(changePasswordResp.message)
        : setError(changePasswordResp.message)
      : ""
  }, [changePasswordResp])

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  function handleValidSubmit(event, values) {
    dispatch(userForgetPassword(values, props.history))
  }

  const generateOTP = e => {
    dispatch(generateOtp(userName))
  }

  const resetError = () => {
    optStatus = null
    setError(null)
  }

  const changePasswordSubmit = (e, v) => {
    if (v.password !== v.confirmPassword) {
      setError("password and confirm password should be same")
    } else {
      dispatch(resetPassword({ username: userName, password: v.password }))
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | Happy60Plus</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={happy60plus}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {successMessage ? (
                      <Alert color="success">{successMessage}</Alert>
                    ) : null}
                    {optStatus && typeof optStatus === "string" ? (
                      <Alert color="danger">{optStatus}</Alert>
                    ) : null}
                    <br />
                    {!showOtpBox &&
                      !(
                        optStatus !== null && typeof optStatus === "object"
                      ) && (
                        <>
                          <div className="mb-3">
                            <div className="input-group mb-3">
                              <Input
                                label="Mobile No/Email"
                                name="userName"
                                type="text"
                                placeholder="Registered mobile number or Email id"
                                className="form-control"
                                onChange={e => {
                                  setUserName(e.target.value)
                                  resetError()
                                }}
                              />
                            </div>
                          </div>
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={e => {
                                setShowOtpBox(true), generateOTP(e)
                              }}
                            >
                              Generate OTP
                            </button>
                          </div>
                        </>
                      )}

                    <br />
                    {showOtpBox &&
                      !(
                        optStatus !== null && typeof optStatus === "object"
                      ) && (
                        <>
                          <div className="mb-3">
                            <div className="input-group mb-3">
                              <Input
                                label="OTP"
                                name="otp"
                                type="text"
                                placeholder="12345"
                                className="form-control"
                                onChange={e => {
                                  setOtp(e.target.value)
                                  resetError()
                                }}
                              />
                            </div>
                          </div>
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={e => {
                                dispatch(
                                  verifyOtp(userName, otp, props.history, false)
                                )
                              }}
                            >
                              Verify OTP
                            </button>
                          </div>
                        </>
                      )}
                    {optStatus !== null &&
                      typeof optStatus === "object" &&
                      !(
                        changePasswordResp &&
                        changePasswordResp.status === "success"
                      ) && (
                        <>
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              changePasswordSubmit(e, v)
                            }}
                          >
                            <div className="mb-3">
                              <AvField
                                name="password"
                                label="New Password"
                                value=""
                                className="form-control"
                                placeholder="Enter new pasword"
                                type="text"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="confirmPassword"
                                label="confirm Password"
                                value=""
                                className="form-control"
                                placeholder="Confirm pasword"
                                type="text"
                                required
                              />
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Change password
                              </button>
                            </div>
                          </AvForm>
                        </>
                      )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Happy60Plus.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)

import React from "react";
import {
    Container
} from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Enquiry from "components/Happy60/Enquiry";

const EnquiryPage = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Enquiry | Happy60Plus</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumb title="Home" breadcrumbItem="Enquiry" />
                    <Enquiry></Enquiry>
                </Container>
            </div >
        </React.Fragment >
    );
}

export default EnquiryPage;
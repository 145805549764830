import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"

//import action
import {
  GetTotalServiceBookingData,
  GetRevenueData,
  GetServiceBookingByTypeData,
  GetUserRegistrationData,
  GetGeneralStats,
  GetTotalRegisteredUser,
} from "../../store/actions"

// import modalimage1 from "../../assets/images/product/img-7.png"
// import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestBookings from "./LatestBookings"
import StaticNumberWidget from "./StaticNumberWidget"
import ServiceBooked from "./ServiceBooked"
import UserRegistrationWidget from "./UserRegistrationWidget"
import RevenueWidget from "./RevenueWidget"
import ServiceBookingByType from "./ServiceBookingByType"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { currencyFormat, numberFormatter } from "../../utils/Common"
import { PERIOD, USER_TYPE } from "constants/common"

const Dashboard = props => {
  const dispatch = useDispatch()

  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)

  const generalStats = useSelector(state => state.Dashboard.generalStats)
  // const registeredUserData = useSelector(
  //   state => state.Dashboard.UserRegistrationData
  // )
  const userDetail = useSelector(state => state.Profile.userProfile)
  // const totalRegisteredUser = useSelector(state => state.Dashboard.totalRegisteredUser)

  const reports = [
    {
      title: "Total Booked Service",
      iconClass: "bx-copy-alt",
      description: numberFormatter(
        parseInt(generalStats && generalStats.totalBookings)
          ? generalStats.totalBookings
          : 0
      ),
      width: "4",
    },
    {
      title: "Total Served Hours",
      iconClass: "bx-time",
      description:
        generalStats && generalStats.totalHours
          ? numberFormatter(Math.ceil(generalStats.totalHours))
          : 0,
      width: "4",
    },
    {
      title:
        userDetail?.type === USER_TYPE.ADMIN
          ? "Total Registered Users"
          : "Total Health Buddies",
      iconClass: "bx-user",
      description:
        generalStats && generalStats.totalUserRegistered
          ? numberFormatter(Math.ceil(generalStats.totalUserRegistered))
          : 0,
      width: "4",
    },
    {
      title: "Total Revenue",
      iconClass: "bx-rupee",
      description: currencyFormat(
        generalStats && generalStats.totalRevenue
          ? generalStats.totalRevenue
          : 0
      ),
      width: "6",
    },
    {
      title: "Average Service Price",
      iconClass: "bx-purchase-tag-alt",
      description: currencyFormat(
        generalStats && generalStats.averageRevenue
          ? generalStats.averageRevenue
          : 0
      ),
      width: "6",
    },
  ]

  useEffect(() => {
    if (userDetail?.id) {
      dispatch(GetUserRegistrationData(PERIOD.MONTHLY))
      dispatch(GetTotalRegisteredUser())
      dispatch(GetServiceBookingByTypeData())
      if (userDetail?.type === USER_TYPE.PARTNER) {
        dispatch(
          GetTotalServiceBookingData({
            period: PERIOD.MONTHLY,
            id: userDetail?.id,
          })
        ) // a/p
        dispatch(GetRevenueData({ period: PERIOD.MONTHLY, id: userDetail?.id })) // a/p
        dispatch(GetGeneralStats(userDetail?.id))
      } else {
        dispatch(
          GetTotalServiceBookingData({
            period: PERIOD.MONTHLY,
          })
        ) // a/p
        dispatch(GetRevenueData({ period: PERIOD.MONTHLY })) // a/p
        dispatch(GetGeneralStats())
      }
    }
  }, [])
  // const MemoizedSoftComponent = React.memo(LatestBookings);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Happy60Plus</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          {/* <Row>
            <Col>
              <Tabs />
            </Col>
          </Row> */}
          <Row>
            <Col xl="4">
              <WelcomeComp />
              {userDetail?.type === USER_TYPE.ADMIN && (
                <ServiceBookingByType></ServiceBookingByType>
              )}
              {/* <MonthlyEarning /> */}
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <StaticNumberWidget
                    report={report}
                    key={key}
                  ></StaticNumberWidget>
                ))}
              </Row>
              {userDetail?.type === USER_TYPE.ADMIN && (
                <RevenueWidget></RevenueWidget>
              )}
            </Col>
            {/* <Col xl={6} sm={12} xs={12}> */}
            {userDetail?.type === USER_TYPE.ADMIN && (
              <Col xl={6} sm={12} xs={12}>
                <UserRegistrationWidget></UserRegistrationWidget>
              </Col>
            )}
            {userDetail?.type === USER_TYPE.ADMIN && (
              <Col xl={6} sm={12} xs={12}>
                <ServiceBooked></ServiceBooked>
              </Col>
            )}
            {userDetail?.type === USER_TYPE.PARTNER && (
              <Col xl={12} sm={12} xs={12}>
                <RevenueWidget></RevenueWidget>
              </Col>
            )}
            {userDetail?.type === USER_TYPE.PARTNER && (
              <Col xl={12} sm={12} xs={12}>
                <ServiceBooked></ServiceBooked>
              </Col>
            )}
          </Row>

          {/* <Row>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>
            <Col xl="4">
              <TopCities />
            </Col>
          </Row> */}

          {/* <Row>
            <Col lg="12">
              <LatestBookings />
            </Col>
          </Row> */}
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <ModalHeader
              toggle={() => {
                setSubscribemodal(!subscribemodal)
              }}
            ></ModalHeader>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <div className="avatar-md mx-auto mb-4">
                <div className="avatar-title bg-light  rounded-circle text-primary h1">
                  <i className="mdi mdi-email-open"></i>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <h4 className="text-primary">Subscribe !</h4>
                  <p className="text-muted font-size-14 mb-4">
                    Subscribe our newletter and get notification to stay update.
                  </p>

                  <div className="input-group rounded bg-light">
                    <Input
                      type="email"
                      className="form-control bg-transparent border-0"
                      placeholder="Enter Email address"
                    />
                    <Button color="primary" type="button" id="button-addon2">
                      <i className="bx bxs-paper-plane"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        {/* <img src={modalimage1} alt="" className="avatar-sm" /> */}
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        {/* <img src={modalimage2} alt="" className="avatar-sm" /> */}
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)

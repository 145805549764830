import React, { useCallback, useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

import {
  FormGroup,
  Modal,
  Row,
  Col,
  Label,
  Button,
  InputGroup,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap"

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import classnames from "classnames"

import {
  bookServices,
  clearPaymentStatus,
  clearServiceBookingStatus,
  generateOrder,
  loadScript,
  logPaymentDetail,
  makePayment,
  updateAddressDetail,
  validatePromoCode,
  setPromoCodeValidity,
  getHealthBuddy,
  getAddressDetail,
  generateInvoice,
} from "../../../store/actions"
import {
  PAYMENT_MODE,
  PAYMENT_STATUS,
  RZP_SCRIPT,
} from "../../../constants/common"
import moment from "moment"
import FullScreenLoader from "components/Common/FullScreenLoader"
import PricingDetailModal from "./pricingDetailModal"
import { bookActivityServices, getPricingDetail } from "store/services/actions"
import { getUserDetail } from "utils/userUtil"
import PaymentHistory from "../Payment/paymentHistory"
import PaymentDisclosure from "components/Common/paymentDisclosure"
import AddEditAddress from "../Address/AddEditAddress"

import {
  GENERAL_SERVICE_DURATIONS,
  TRANSPORTATION_SERVICE_DURATIONS,
} from "../../../constants/common"
import { getImageForUser } from "utils/Common"

import { getUrl } from "utils/Config"
import alertCall from "components/Common/CustomAlert"

const ServiceBookingModal = ({
  t,
  isOpen,
  data,
  selectedService,
  closeModal,
  onSubmitClick,
  onCancelClick,
}) => {
  const dispatch = useDispatch()
  const [error, setError] = useState({})
  const [fullScreenLoader, setFullScreenLoader] = useState(false)
  const [pricingModal, setPricingModal] = useState(false)
  const [serviceDetailObject, setServiceDetailObject] = useState(null)
  const [selectedServiceMode, setSelectedServiceMode] = useState(
    selectedService.serviceMode || "ONLINE"
  )
  const [showAddressModal, setAddressModelVisibility] = useState(false)
  const [currentAddressObject, setCurrentAddressObject] = useState()

  const [commonBookingType, setCommonBookingType] = useState()
  const [promoCode, setPromoCode] = useState("")

  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [bookingType, setBookingType] = useState("single")
  const [isRoundTrip, setIsRoundTrip] = useState("yes")
  const [healthBuddyType, setHealthBuddyType] = useState("")
  const healthBuddyList = useSelector(state => state.Users.healthBuddy)
  const [selectedHealthBuddy, setSelectedHealthBuddy] = useState(null)
  useEffect(() => {
    if (isHealthCareService) {
      // get available health buddy's list
      dispatch(getHealthBuddy())
    }
  }, [])

  const healthBType = {
    110001: "Care Taker",
    110002: "Associate Nurse",
    110003: "Registered Nurse",
  }

  if (
    selectedServiceMode !== selectedService.serviceMode &&
    selectedService.serviceMode !== "BOTH"
  ) {
    setSelectedServiceMode(selectedService.serviceMode)
  }

  let { serviceID, minDuration } = data || {}
  serviceID = serviceID
    ? serviceID
    : selectedService && selectedService.id
    ? selectedService.id
    : serviceID
  minDuration = minDuration
    ? minDuration
    : selectedService && selectedService.minDuration
    ? selectedService.minDuration
    : minDuration
  let { isTransportationType, serviceMode } = selectedService || data
  let serviceTypeList = useSelector(state => state.ServiceReducer.serviceType)
  let vehicleTypeList = useSelector(state => state.CommonReducer.vehicleType)
  let addressList = useSelector(state => state.Profile.address)
  let modeList = useSelector(state => state.CommonReducer.serviceMode)
  let userDetail = JSON.parse(getUserDetail())
  let paymentDetail = useSelector(state => state.PaymentReducer.paymentStatus)
  let orderDetail = useSelector(state => state.PaymentReducer.orderDetail)
  let pricingDetail = useSelector(state => state.ServiceReducer.pricingDetail)
  let serviceBookingStatus = useSelector(
    state => state.ServiceReducer.serviceBookingStatus
  )
  const validPromoCode = useSelector(
    state => state.ServiceReducer.validPromoCode
  )
  const selectedUser = useSelector(state => state.Profile.selectedUser)
  if (selectedUser && selectedUser.id) {
    userDetail = selectedUser
  }

  if (!addressList?.length) {
    dispatch(getAddressDetail(userDetail.id))
  }

  const [paymentMode, setPaymentMode] = useState("ONLINE")

  const isHealthCareService =
    serviceID === "420da45b-87f6-4c12-b9a7-4c2e39c02cbc" ||
    serviceID === "5de9434e-a358-428f-b3e2-766857a4c466"

  const isTransportationService =
    serviceID === "65449c7d07a94e388fa93985" ||
    serviceID === "650c246515a0137849650136"

  const isActivityService =
    serviceID === "a1e835d0-d3b7-4105-b3fb-564ff9f1957a" ||
    serviceID === "2db41585-e913-43a0-bca2-1a1c41c72d15"

  let languageList = [
    {
      key: "hindi",
      value: "Hindi",
    },
    {
      key: "english",
      value: "English",
    },
    {
      key: "kannada",
      value: "Kannada",
    },
    {
      key: "tamil",
      value: "Tamil",
    },
    {
      key: "telugu",
      value: "Telugu",
    },
    {
      key: "malayalam",
      value: "Malayalam",
    },
  ]

  let errors = {}
  let serviceObject = {}

  useEffect(() => {
    if (!promoCode) {
      setError(prevValue => ({ ...prevValue, promo: null }))
      resetPromoCodeValidity()
    } else {
      if (validPromoCode === false) {
        setError(prevValue => ({ ...prevValue, promo: "Invalid Promo Code" }))
      } else if (validPromoCode === true) {
        setError(prevValue => ({ ...prevValue, promo: null }))
      }
    }
  }, [validPromoCode, promoCode, setError])

  useEffect(() => {
    if (paymentDetail) {
      if (paymentDetail.error) {
        setError(paymentDetail)
      } else {
        // dispatch(bookServices(data));
        const paymentLog = {
          id: serviceBookingStatus.id,
          bookingID: serviceBookingStatus.orderId,
          userID: userDetail?.id || getUserDetail("id"),
          paymentMode: paymentMode,
          razorPayDetails: {
            razorPayOrderID: serviceBookingStatus.paymentInfo,
            razorPayPaymentID: paymentDetail.razorpay_payment_id,
          },
          amount: pricingDetail.finalAmount,
          isTransportationService: isTransportationService,
        }
        dispatch(logPaymentDetail(paymentLog))
        alertCall("Service booked successfully!", "success")

        onSubmitClick()
      }
      setTimeout(() => {
        dispatch(clearServiceBookingStatus())
        dispatch(clearPaymentStatus())
        setFullScreenLoader(false)
      }, 1000)
    }
  }, [paymentDetail])

  useEffect(() => {
    console.log(
      "APP-LOG: File: serviceBookingModal.js Line: #240::> serviceBookingStatus",
      serviceBookingStatus
    )
    console.log(
      "APP-LOG: File: serviceBookingModal.js Line: #241::> paymentMode === PAYMENT_MODE.ONLINE",
      paymentMode,
      paymentMode === PAYMENT_MODE.ONLINE
    )
    console.log(
      "APP-LOG: File: serviceBookingModal.js Line: #242::> data.id",
      data.id
    )
    if (
      serviceBookingStatus &&
      paymentMode === PAYMENT_MODE.ONLINE &&
      !data.id
    ) {
      console.log("Debug :::> here", serviceBookingStatus)
      payServiceFees(serviceBookingStatus)
    } else if (
      serviceBookingStatus &&
      paymentMode === PAYMENT_MODE.OFFLINE &&
      !data.id
    ) {
      setFullScreenLoader(false)
      onSubmitClick()
      alertCall("Service booked successfully!", "success")
    } else {
      setFullScreenLoader(false)
      dispatch(clearServiceBookingStatus())
      dispatch(clearPaymentStatus())
    }
  }, [serviceBookingStatus])

  const durationOptions = useMemo(() => {
    if (selectedService?.isTransportationType) {
      return TRANSPORTATION_SERVICE_DURATIONS.filter(
        ({ value }) => value >= (minDuration || 0)
      )
    }
    return GENERAL_SERVICE_DURATIONS.filter(
      ({ value }) => value >= (minDuration || 0)
    )
  }, [selectedService, minDuration])

  const handleDateChange = e => {
    if (e.target.value && e.target.value !== "") {
      data["dateTimeFrom"] = moment(e.target.value)
        .toISOString(true)
        .replace(".000+", "+")
    }
    resetPromoCodeValidity()
  }

  const handleDateEndChange = e => {
    if (e.target.value && e.target.value !== "") {
      data["dateTimeEnd"] = moment(e.target.value)
        .toISOString(true)
        .replace(".000+", "+")
    }
    resetPromoCodeValidity()
  }
  const handleDateEndChangeTransportation = (e, dateName) => {
    if (e.target.value && e.target.value !== "") {
      data[dateName] = moment(e.target.value)
        .toISOString(true)
        .replace(".000+", "+")
    }
    resetPromoCodeValidity()
  }

  const payServiceFees = paymentInfo => {
    dispatch(loadScript(RZP_SCRIPT))
    setTimeout(() => {
      dispatch(
        makePayment({ ...paymentInfo, amount: pricingDetail.finalAmount })
      )
    }, 500)
  }

  const addUpdateAddressDetail = data => {
    dispatch(updateAddressDetail({ ...data, userID: userDetail.id }))
    setAddressModelVisibility(false)
  }

  const resetPromoCodeValidity = useCallback(() => {
    if (validPromoCode === false || validPromoCode === true) {
      dispatch(setPromoCodeValidity(null))
      setError(prevState => ({ ...prevState, promo: false }))
    }
  }, [validPromoCode, setPromoCodeValidity, setError])

  const resetErrorOnInputChange = useCallback(
    errorKey => {
      setError(prevState => ({ ...prevState, [errorKey]: false }))
    },
    [setError]
  )

  const checkPromoCode = useCallback(() => {
    dispatch(
      validatePromoCode({
        serviceID,
        promo: promoCode,
      })
    )
  }, [validatePromoCode, data, promoCode])

  const resetModalData = useCallback(() => {
    resetPromoCodeValidity()
    setPromoCode("")
    setError({})
  }, [setPromoCodeValidity, setError])

  const languageSelected = e => {
    let languages
    if (e.target.checked) {
      languages = [...selectedLanguages, e.target.value]
    } else {
      const selected = selectedLanguages.filter(item => item !== e.target.value)
      languages = selected
    }
    setSelectedLanguages([...new Set(languages)])
  }

  // normal service with single not multi and not health care
  function handleValidSubmit(e, v) {
    e.preventDefault()
    let formData = { ...v }
    delete formData.dateTimeFrom
    serviceObject = { ...data, ...formData }

    let hasError = false
    errors = {}
    setPaymentMode(formData.paymentMode)
    if (serviceObject.dateTimeFrom === undefined) {
      hasError = true
      errors["dateTimeFrom"] = "Select proper date and time of service"
    }

    if (new Date(serviceObject.dateTimeFrom).getTime() < new Date().getTime()) {
      hasError = true
      errors["dateTimeFrom"] = "Service date can not be past date."
    }

    if (serviceObject.dateTimeFrom) {
      var months =
        new Date(serviceObject.dateTimeFrom).getMonth() -
        new Date().getMonth() +
        12 *
          (new Date(serviceObject.dateTimeFrom).getFullYear() -
            new Date().getFullYear())
      if (
        new Date(serviceObject.dateTimeFrom).getDate() < new Date().getDate()
      ) {
        months--
      }
      if (months !== 0) {
        hasError = true
        errors["dateTimeFrom"] =
          "Service start date should be within 1 month from today."
      }
    }

    if (serviceObject.dateTimeFrom) {
      if (
        new Date(serviceObject.dateTimeFrom).getHours() < 8 ||
        new Date(serviceObject.dateTimeFrom).getHours() > 20
      ) {
        hasError = true
        errors["dateTimeFrom"] = "Service booking time is 8am to 8pm."
      }
    }

    if (isTransportationType) {
      if (
        serviceObject.vehicleType === undefined ||
        serviceObject.vehicleType === "-1"
      ) {
        hasError = true
        errors["vehicleType"] = "Please select vehicle type"
      }
    } else {
      serviceObject = { ...serviceObject, vehicleType: null }
    }

    if (serviceObject.duration === "-1") {
      hasError = true
      errors["duration"] = "This field is invalid"
    }

    if (serviceObject.serviceAddressID === "-1") {
      hasError = true
      errors["serviceAddress"] = "This field is invalid"
    }

    if (selectedServiceMode) {
      serviceObject = { ...serviceObject, serviceMode: selectedServiceMode }
    } else {
      if (selectedService.serviceMode) {
        serviceObject = {
          ...serviceObject,
          serviceMode: selectedService.serviceMode,
        }
      } else {
        hasError = true
        errors["serviceMode"] = "Please select service mode"
      }
    }

    if (promoCode && !validPromoCode) {
      hasError = true
      errors["promo"] = "Invalid Promo Code"
    }
    let price = 0
    const hour = serviceObject.duration / 60
    console.log(
      "Debug :::> selectedService.servicePrice",
      selectedService.servicePrice
    )
    // if (selectedServiceMode === "ONLINE") {
    //   const onlinePrice = selectedService.servicePrice.onlinePrice * hour
    //   price = selectedService.servicePrice.onlineBasePrice + onlinePrice
    // } else if (selectedServiceMode === "OFFLINE") {
    //   const offlinePrice = selectedService.servicePrice.offlinePrice * hour
    //   price = selectedService.servicePrice.offlineBasePrice + offlinePrice
    // }

    const selectedDateAndTime = new Date(serviceObject.dateTimeFrom)
    const startDateAndTime = selectedDateAndTime.getTime()

    // const newDate = new Date(serviceObject.dateTimeFrom)
    // const selectedDateInMin = newDate.getMinutes()
    // const durationInMin = serviceObject.duration
    // const endDateAndTime = newDate.setMinutes(selectedDateInMin + durationInMin)

    let resData = {
      paymentMode: serviceObject.paymentMode,
      userID: userDetail.id,
      description: serviceObject.description,
      serviceID: serviceID,
      serviceAddressID:
        selectedServiceMode === "ONLINE"
          ? null
          : serviceObject.serviceAddressID,
      bookingStatus: "HELP_BUDDY_BOOKING",
      bookingMedium: "WEBSITE",
      serviceMode: selectedServiceMode,
      servicePrice: {
        vehiclePrice: 0,
        servicePriceEstimated: price * 100,
        servicePriceActual: price * 100,
        discount: 0,
        offerID: "",
        paymentStatus: "PENDING",
      },
      serviceTimeDuration: [
        {
          serviceStartTimeEstimated: startDateAndTime,
          serviceDurationEstimated: serviceObject.duration,
          serviceEndTimeEstimated: 0,
          serviceStartTimeActual: 0,
          serviceEndTimeActual: 0,
          serviceDurationActual: 0,
          serviceBookingStatus: "CREATED",
        },
      ],
      active: true,
      multipleBooking: false,
      daily: false,
      specificDay: false,
    }

    if (isTransportationService) {
      let endDateAndTime = 0
      let dateTimeEnd = 0
      if (isRoundTrip === "yes") {
        const returnStartDateTime = new Date(serviceObject.dateTimeFrom)
        const returnTime = serviceObject.returnStartDateTime.split(":")
        returnStartDateTime.setHours(returnTime[0] || 0)
        returnStartDateTime.setMinutes(returnTime[1] || 0)
        endDateAndTime = returnStartDateTime.getTime()
      }

      const serviceTimeDuration = [
        {
          serviceStartTimeEstimated: startDateAndTime,
          serviceEndTimeEstimated: dateTimeEnd,
          serviceStartTimeActual: 0,
          serviceEndTimeActual: 0,
          serviceDurationActual: 0,
          serviceDurationEstimated: serviceObject.duration,
          serviceBookingStatus: "CREATED",
        },
      ]

      if (isRoundTrip === "yes") {
        serviceTimeDuration.push({
          serviceStartTimeEstimated: endDateAndTime,
          serviceEndTimeEstimated: dateTimeEnd, //returnEndPickup,
          serviceStartTimeActual: 0,
          serviceEndTimeActual: 0,
          serviceDurationActual: 0,
          serviceDurationEstimated: serviceObject.duration,
          serviceBookingStatus: "CREATED",
        })
      }

      resData = {
        ...resData,
        roundTrip: isRoundTrip === "yes",
        isRoundTrip: true,
        bookingStatus: "TRANSPORTATION_BOOKING",
        toAddress: serviceObject.pickupAddressId,
        fromAddress: serviceObject.dropAddressId,
        serviceTimeDuration: serviceTimeDuration,
      }
    }

    setServiceDetailObject(resData)
    setError(errors)

    console.log("Debug :::> Service Object To Book Service", resData)

    if (!hasError) {
      setFullScreenLoader(true)
      // while creating there won't be id so it will be in else
      if (serviceObject.id) {
        dispatch(bookServices(serviceObject))
        onSubmitClick()
        setFullScreenLoader(false)
      } else {
        dispatch(getPricingDetail(resData))
        setPricingModal(true)
      }
    }
  }

  const handleMultipleBookingSubmit = (e, v) => {
    e.preventDefault()
    let formData = { ...v }
    delete formData.dateTimeFrom
    delete formData.dateTimeEnd
    serviceObject = { ...data, ...formData }
    if (!data.serviceStatus) {
      serviceObject = { ...serviceObject, serviceStatus: "CREATED" }
      // data.serviceStatus = "CREATED";
    }
    if (!data.serviceID) {
      serviceObject = { ...serviceObject, serviceID: serviceID }
      // data.serviceID = serviceID;
    }
    if (!data.userID) {
      serviceObject = { ...serviceObject, userID: userDetail.id }
      // data.userID = userDetail.id;;
    }
    if (!data.serviceEstimates) {
      serviceObject = { ...serviceObject, serviceEstimates: 0 }
      // data.serviceEstimates = serviceCharge;
    }
    serviceObject = { ...serviceObject, vehicleServiceEstimates: 0 }
    serviceObject = {
      ...serviceObject,
      vehicleType: vehicleTypeList.filter(item => item.label === "None")[0].id,
    }
    let hasError = false

    if (selectedServiceMode) {
      serviceObject = { ...serviceObject, serviceMode: selectedServiceMode }
    } else {
      if (selectedService.serviceMode) {
        serviceObject = {
          ...serviceObject,
          serviceMode: selectedService.serviceMode,
        }
      } else {
        hasError = true
        errors["serviceMode"] = "Please select service mode"
      }
    }

    if (!data.paymentStatus) {
      serviceObject = {
        ...serviceObject,
        paymentStatus: PAYMENT_STATUS.PENDING,
      }
    }

    if (promoCode && !validPromoCode) {
      hasError = true
      errors["promo"] = "Invalid Promo Code"
    }

    if (serviceObject.dateTimeFrom === undefined) {
      hasError = true
      errors["dateTimeFrom"] = "Select proper date and time of service"
    }

    if (new Date(serviceObject.dateTimeFrom).getTime() < new Date().getTime()) {
      hasError = true
      errors["dateTimeFrom"] = "Service date can not be past date."
    }

    if (serviceObject.dateTimeFrom) {
      var months =
        new Date(serviceObject.dateTimeFrom).getMonth() -
        new Date().getMonth() +
        12 *
          (new Date(serviceObject.dateTimeFrom).getFullYear() -
            new Date().getFullYear())
      if (
        new Date(serviceObject.dateTimeFrom).getDate() < new Date().getDate()
      ) {
        months--
      }
      if (months !== 0) {
        hasError = true
        errors["dateTimeFrom"] =
          "Service start date should be within 1 month from today."
      }
    }

    if (serviceObject.dateTimeFrom) {
      if (
        new Date(serviceObject.dateTimeFrom).getHours() < 8 ||
        new Date(serviceObject.dateTimeFrom).getHours() > 20
      ) {
        hasError = true
        errors["dateTimeFrom"] = "Service booking time is 8am to 8pm."
      }
    }

    if (
      new Date(serviceObject.dateTimeEnd) < new Date(serviceObject.dateTimeFrom)
    ) {
      hasError = true
      errors["dateTimeEnd"] = "Please Select Valid End Service Date."
    }

    setError(errors)

    let price = 0
    const hour = serviceObject.duration / 60
    console.log(
      "Debug :::> selectedService.servicePrice",
      selectedService.servicePrice
    )

    // if (selectedServiceMode === "ONLINE") {
    //   const onlinePrice = selectedService.servicePrice.onlinePrice * hour
    //   price = selectedService.servicePrice.onlineBasePrice + onlinePrice
    // } else if (selectedServiceMode === "OFFLINE") {
    //   const offlinePrice = selectedService.servicePrice.offlinePrice * hour
    //   price = selectedService.servicePrice.offlineBasePrice + offlinePrice
    // }

    if (!hasError) {
      setFullScreenLoader(true)
      if (serviceObject.id) {
        dispatch(bookServices(serviceObject))
        onSubmitClick()
        setFullScreenLoader(false)
      } else {
        let resData = {
          paymentMode: serviceObject.paymentMode,
          userID: userDetail.id,
          description: serviceObject.description,
          serviceID: serviceID,
          serviceAddressID:
            selectedServiceMode === "ONLINE"
              ? null
              : serviceObject.serviceAddressID,
          bookingStatus: "HELP_BUDDY_BOOKING",
          bookingMedium: "WEBSITE",
          serviceMode: selectedServiceMode,
          servicePrice: {
            vehiclePrice: 0,
            servicePriceEstimated: price * 100,
            servicePriceActual: price * 100,
            discount: 0,
            offerID: "",
            paymentStatus: "PENDING",
          },
          serviceTimeDuration: [
            {
              serviceStartTimeEstimated: new Date(
                serviceObject.dateTimeFrom
              ).getTime(),
              serviceDurationEstimated: serviceObject.duration,
              serviceEndTimeEstimated: new Date(
                serviceObject.dateTimeEnd
              ).getTime(),
              serviceStartTimeActual: 0,
              serviceEndTimeActual: 0,
              serviceDurationActual: 0,
              serviceBookingStatus: "CREATED",
            },
          ],
          active: true,
          multipleBooking: true,
          daily: serviceObject.commonBooking === "daily",
          isDaily: serviceObject.commonBooking === "daily",
          specificDay: serviceObject.commonBooking === "specificDay",
          daysOfWeek:
            serviceObject.commonBooking === "specificDay"
              ? serviceObject.daysOfWeek
              : [],
        }

        // if (serviceObject.commonBooking === "daily") {
        //   resData = {
        //     ...resData,
        //     isDaily: true,
        //     // duration: 24 * 60,
        //   }
        // } else if (serviceObject.commonBooking === "specificDay") {
        //   resData = {
        //     ...resData,
        //     daysOfWeek: serviceObject.daysOfWeek,
        //   }
        // }

        if (isTransportationService) {
          let endDateAndTime = 0
          let dateTimeEnd = 0
          let returnEndPickup = 0

          const startDateAndTimeDateTime = new Date(serviceObject.dateTimeFrom)
          const startDateAndTime = startDateAndTimeDateTime.getTime()

          if (isRoundTrip === "yes") {
            const returnStartDateTime = new Date(serviceObject.dateTimeFrom)
            const returnTime = serviceObject.returnStartDateTime.split(":")
            returnStartDateTime.setHours(returnTime[0] || 0)
            returnStartDateTime.setMinutes(returnTime[1] || 0)
            endDateAndTime = returnStartDateTime.getTime()
          }

          if (bookingType === "multiple") {
            const returnStartDateTime = new Date(serviceObject.dateTimeEnd)
            dateTimeEnd = returnStartDateTime.getTime()

            const returnEndPickupTime = new Date(serviceObject.returnEndPickup)
            returnEndPickup = returnEndPickupTime.getTime()
          }

          const serviceTimeDuration = [
            {
              serviceStartTimeEstimated: startDateAndTime,
              serviceEndTimeEstimated: dateTimeEnd,
              serviceStartTimeActual: 0,
              serviceEndTimeActual: 0,
              serviceDurationActual: 0,
              serviceDurationEstimated: serviceObject.duration,
              serviceBookingStatus: "CREATED",
            },
          ]

          if (isRoundTrip === "yes") {
            serviceTimeDuration.push({
              serviceStartTimeEstimated: endDateAndTime,
              serviceEndTimeEstimated: dateTimeEnd, //returnEndPickup,
              serviceStartTimeActual: 0,
              serviceEndTimeActual: 0,
              serviceDurationActual: 0,
              serviceDurationEstimated: serviceObject.duration,
              serviceBookingStatus: "CREATED",
            })
          }

          resData = {
            ...resData,
            roundTrip: isRoundTrip === "yes",
            isRoundTrip: true,
            bookingStatus: "TRANSPORTATION_BOOKING",
            toAddress: serviceObject.pickupAddressId,
            fromAddress: serviceObject.dropAddressId,
            serviceTimeDuration: serviceTimeDuration,
          }
        }

        console.log("APP-LOG: File: serviceBookingModal.js Line: #651::> 1", 1)
        setServiceDetailObject(resData)
        dispatch(getPricingDetail(resData))
        setPricingModal(true)
      }
    }
  }

  const makePaymentAndBookService = (data, costEstimates) => {
    setPricingModal(false)
    const bookingRes = { ...data }
    bookingRes.servicePrice = {
      ...bookingRes.servicePrice,
      servicePriceEstimated: pricingDetail.serviceCost,
      servicePriceActual: pricingDetail.finalAmount,
      discount: pricingDetail.totalDiscount,
    }
    dispatch(bookServices(bookingRes))
    if (data.id) {
      onSubmitClick()
    }
  }

  const handleHealthCareServiceBooking = (e, v) => {
    e.preventDefault()
    let formData = { ...v }
    delete formData.dateTimeFrom
    delete formData.dateTimeEnd
    serviceObject = { ...data, ...formData }
    if (!data.serviceStatus) {
      serviceObject = { ...serviceObject, serviceStatus: "CREATED" }
      // data.serviceStatus = "CREATED";
    }
    if (!data.serviceID) {
      serviceObject = { ...serviceObject, serviceID: serviceID }
      // data.serviceID = serviceID;
    }
    if (!data.userID) {
      serviceObject = { ...serviceObject, userID: userDetail.id }
      // data.userID = userDetail.id;;
    }
    if (!data.serviceEstimates) {
      serviceObject = { ...serviceObject, serviceEstimates: 0 }
      // data.serviceEstimates = serviceCharge;
    }
    serviceObject = { ...serviceObject, vehicleServiceEstimates: 0 }
    serviceObject = {
      ...serviceObject,
      vehicleType: vehicleTypeList.filter(item => item.label === "None")[0].id,
    }
    let hasError = false

    if (selectedServiceMode) {
      serviceObject = { ...serviceObject, serviceMode: selectedServiceMode }
    } else {
      if (selectedService.serviceMode) {
        serviceObject = {
          ...serviceObject,
          serviceMode: selectedService.serviceMode,
        }
      } else {
        hasError = true
        errors["serviceMode"] = "Please select service mode"
      }
    }

    if (!data.paymentStatus) {
      serviceObject = {
        ...serviceObject,
        paymentStatus: PAYMENT_STATUS.PENDING,
      }
    }

    if (promoCode && !validPromoCode) {
      hasError = true
      errors["promo"] = "Invalid Promo Code"
    }

    setServiceDetailObject(serviceObject)
    setError(errors)

    if (!hasError) {
      setFullScreenLoader(true)

      let resData = {
        paymentMode: serviceObject.paymentMode,
        userID: userDetail.id,
        description: serviceObject.description,
        serviceID: serviceID,
        serviceAddressID: serviceObject.serviceAddressID,
        bookingStatus: "HELP_BUDDY_BOOKING",
        bookingMedium: "WEBSITE",
        serviceBookingMode: selectedServiceMode,
        servicePrice: {
          vehiclePrice: 0,
          servicePriceEstimated: price * 100,
          servicePriceActual: price * 100,
          discount: 0,
          offerID: "",
          paymentStatus: "PENDING",
        },
        serviceTimeDuration: [
          {
            serviceStartTimeEstimated: new Date(
              serviceObject.dateTimeFrom
            ).getTime(),
            serviceDurationEstimated: serviceObject.duration,
            serviceEndTimeEstimated: new Date(
              serviceObject.dateTimeEnd
            ).getTime(),
            serviceStartTimeActual: 0,
            serviceEndTimeActual: 0,
            serviceDurationActual: 0,
            serviceBookingStatus: "CREATED",
          },
        ],
        active: true,
        multipleBooking: true,
        daily: false,
        specificDay: false,
      }

      if (serviceObject.commonBooking === "daily") {
        resData = {
          ...resData,
          isDaily: true,
          // duration: 24 * 60,
        }
      } else if (serviceObject.commonBooking === "specificDay") {
        resData = {
          ...resData,
          isSpecificDaysOfWeek: true,
          daysOfWeek: serviceObject.daysOfWeek,
        }
      }

      serviceObject = {
        ...resData,
        type: "HealthCare",
        healthBuddyType: healthBuddyType,
      }

      setServiceDetailObject(serviceObject)
      setFullScreenLoader(false)
      setSelectHealthBuddy(true)
    }
  }

  const [selectHealthBuddy, setSelectHealthBuddy] = useState(false)
  const bookHCService = () => {
    const serviceObject = {
      ...serviceDetailObject,
      selectedHealthBuddy,
    }
    dispatch(bookServices(serviceObject))
    onSubmitClick()
  }

  const [activityServiceTime, setActivityServiceTime] = useState("0")
  const bookActivityService = (e, v) => {
    const data = {
      isMorning: activityServiceTime === "0",
      userId: userDetail.id,
      serviceID: serviceID,
    }
    dispatch(bookActivityServices(data))
    closeModal()
  }

  let CONFIG
  let MORNING_ACTIVITY_URL
  let EVENING_ACTIVITY_URL

  if (isActivityService) {
    CONFIG = getUrl()
    MORNING_ACTIVITY_URL = CONFIG.URL.BOOK_SERVICE.MORNING_ACTIVITY_URL
    EVENING_ACTIVITY_URL = CONFIG.URL.BOOK_SERVICE.EVENING_ACTIVITY_URL
  }
  console.log("Debug :::> serviceTypeList", serviceTypeList)

  return (
    <>
      <Modal
        isOpen={isOpen}
        centered={!isHealthCareService}
        fullscreen
        className={isActivityService && "service-modal"}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {data.id
              ? t("Edit Service")
              : selectHealthBuddy
              ? t("Select Health Buddy")
              : t("Book a service")}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              resetModalData()
              closeModal()
            }}
          ></button>
        </div>

        {!selectHealthBuddy && !isActivityService && (
          <AvForm
            onValidSubmit={(e, v) => {
              if (bookingType === "single" && !isHealthCareService) {
                handleValidSubmit(e, v)
              } else if (bookingType === "multiple" && !isHealthCareService) {
                handleMultipleBookingSubmit(e, v)
              } else if (isHealthCareService) {
                handleHealthCareServiceBooking(e, v)
              }
            }}
          >
            <div className="modal-body">
              <Row>
                {data.id && (
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <Label> Service ID: {data.id}</Label>
                    </FormGroup>
                  </Col>
                )}
                <Col xs={12} sm={12} className="mb-4">
                  <FormGroup>
                    <AvField
                      type="select"
                      name="serviceID"
                      label="Service Type"
                      value={serviceID}
                      disabled={serviceID ? true : false}
                      required
                    >
                      {serviceTypeList &&
                        serviceTypeList.map(item => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                    </AvField>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} className="mb-4">
                  <FormGroup>
                    <Label>Booking Type </Label>
                    <AvRadioGroup
                      inline
                      name="bookingType"
                      value={bookingType}
                      onChange={e => {
                        setBookingType(e.target.value)
                      }}
                    >
                      <Row>
                        <Col md={6}>
                          <AvRadio
                            customInput
                            label={"Single"}
                            value="single"
                          />
                        </Col>
                        <Col md={6}>
                          <AvRadio
                            customInput
                            label={"Multiple"}
                            value="multiple"
                          />
                        </Col>
                      </Row>
                    </AvRadioGroup>
                  </FormGroup>
                </Col>
                {isTransportationService && (
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <Label>Is Round Trip ? </Label>
                      <AvRadioGroup
                        inline
                        name="isRoundTrip"
                        value={isRoundTrip}
                        onChange={e => {
                          setIsRoundTrip(e.target.value)
                        }}
                      >
                        <Row>
                          <Col md={6}>
                            <AvRadio customInput label={"Yes"} value="yes" />
                          </Col>
                          <Col md={6}>
                            <AvRadio customInput label={"No"} value="no" />
                          </Col>
                        </Row>
                      </AvRadioGroup>
                    </FormGroup>
                  </Col>
                )}

                {isTransportationType && (
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <AvField
                        type="select"
                        name="vehicleType"
                        label="Vehicle Type"
                        value={data.vehicleType}
                        className={`form-control ${
                          error && error["vehicleType"] && "is-invalid"
                        }`}
                        required
                      >
                        <option value={-1} key="vehicle_type_default" disabled>
                          --Select--
                        </option>
                        {vehicleTypeList &&
                          vehicleTypeList.map(item => {
                            return (
                              <option value={item.label} key={item.id}>
                                {item.label}
                              </option>
                            )
                          })}
                      </AvField>
                    </FormGroup>
                  </Col>
                )}

                {bookingType === "single" &&
                  !isHealthCareService &&
                  !isTransportationService && (
                    <Col xs={12} sm={12} className="mb-4">
                      <FormGroup>
                        <AvField
                          label="Select date and time of service"
                          type="datetime-local"
                          name="dateTimeFrom"
                          onChange={e => {
                            resetErrorOnInputChange("dateTimeFrom")
                            handleDateChange(e)
                          }}
                          className={`form-control ${
                            error && error["dateTimeFrom"] && "is-invalid"
                          }`}
                          required
                          value={
                            data.dateTimeFrom
                              ? moment
                                  .utc(data.dateTimeFrom)
                                  .local()
                                  .format("YYYY-MM-DDTHH:mm:ss")
                              : ""
                          }
                        ></AvField>
                        {error && error["dateTimeFrom"] && (
                          <label style={{ color: "red", fontWeight: "100" }}>
                            {error["dateTimeFrom"]}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                  )}

                {!!(bookingType === "multiple" || isHealthCareService) &&
                  !isTransportationService && (
                    <>
                      <Col xs={12} sm={12} className="mb-4">
                        <FormGroup>
                          <AvField
                            label="Select start date of service"
                            type={
                              isHealthCareService ? "date" : "datetime-local"
                            }
                            name="dateTimeFrom"
                            onChange={e => {
                              resetErrorOnInputChange("dateTimeFrom")
                              handleDateChange(e)
                            }}
                            className={`form-control ${
                              error && error["dateTimeFrom"] && "is-invalid"
                            }`}
                            required
                            value={
                              data.dateTimeFrom
                                ? moment
                                    .utc(data.dateTimeFrom)
                                    .local()
                                    .format(
                                      isHealthCareService
                                        ? "YYYY-MM-DD"
                                        : "YYYY-MM-DDTHH:mm:ss"
                                    )
                                : ""
                            }
                          ></AvField>
                          {error && error["dateTimeFrom"] && (
                            <label style={{ color: "red", fontWeight: "100" }}>
                              {error["dateTimeFrom"]}
                            </label>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} className="mb-4">
                        <FormGroup>
                          <AvField
                            label="Select end date of service"
                            type="date"
                            name="dateTimeEnd"
                            onChange={e => {
                              resetErrorOnInputChange("dateTimeEnd")
                              handleDateEndChange(e)
                            }}
                            className={`form-control ${
                              error && error["dateTimeEnd"] && "is-invalid"
                            }`}
                            required
                            value={
                              data.dateTimeEnd
                                ? moment
                                    .utc(data.dateTimeEnd)
                                    .local()
                                    .format("YYYY-MM-DD")
                                : ""
                            }
                          ></AvField>
                          {error && error["dateTimeEnd"] && (
                            <label style={{ color: "red", fontWeight: "100" }}>
                              {error["dateTimeEnd"]}
                            </label>
                          )}
                        </FormGroup>
                      </Col>
                    </>
                  )}
                {isTransportationService && (
                  <>
                    <Col xs={12} sm={12} className="mb-4">
                      <FormGroup>
                        <AvField
                          label="Service start date and time"
                          type="datetime-local"
                          name="dateTimeFrom"
                          onChange={e => {
                            resetErrorOnInputChange("dateTimeFrom")
                            handleDateEndChangeTransportation(e, "dateTimeFrom")
                          }}
                          className={`form-control ${
                            error && error["dateTimeFrom"] && "is-invalid"
                          }`}
                          required
                          value={
                            data.dateTimeFrom
                              ? moment
                                  .utc(data.dateTimeFrom)
                                  .local()
                                  .format("YYYY-MM-DDTHH:mm:ss")
                              : ""
                          }
                        ></AvField>
                        {error && error["dateTimeFrom"] && (
                          <label style={{ color: "red", fontWeight: "100" }}>
                            {error["dateTimeFrom"]}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                    {bookingType === "multiple" && (
                      <Col xs={12} sm={12} className="mb-4">
                        <FormGroup>
                          <AvField
                            label="Service end date"
                            type="date"
                            name="dateTimeEnd"
                            onChange={e => {
                              resetErrorOnInputChange("dateTimeEnd")
                              handleDateEndChangeTransportation(
                                e,
                                "dateTimeEnd"
                              )
                            }}
                            className={`form-control ${
                              error && error["dateTimeEnd"] && "is-invalid"
                            }`}
                            required
                            value={
                              data.dateTimeEnd
                                ? moment
                                    .utc(data.dateTimeEnd)
                                    .local()
                                    .format("YYYY-MM-DDTHH:mm:ss")
                                : ""
                            }
                          ></AvField>
                          {error && error["dateTimeEnd"] && (
                            <label style={{ color: "red", fontWeight: "100" }}>
                              {error["dateTimeEnd"]}
                            </label>
                          )}
                        </FormGroup>
                      </Col>
                    )}
                    {isRoundTrip === "yes" && (
                      <Col xs={12} sm={12} className="mb-4">
                        <FormGroup>
                          <AvField
                            label="Select time of return"
                            type="time"
                            name="returnStartDateTime"
                            onChange={e => {
                              resetErrorOnInputChange("returnStartDateTime")
                              handleDateEndChangeTransportation(
                                e,
                                "returnStartDateTime"
                              )
                            }}
                            className={`form-control ${
                              error &&
                              error["returnStartDateTime"] &&
                              "is-invalid"
                            }`}
                            required
                            value={
                              data.returnStartDateTime
                                ? moment
                                    .utc(data.returnStartDateTime)
                                    .local()
                                    .format("YYYY-MM-DDTHH:mm:ss")
                                : ""
                            }
                          ></AvField>
                          {error && error["returnStartDateTime"] && (
                            <label style={{ color: "red", fontWeight: "100" }}>
                              {error["returnStartDateTime"]}
                            </label>
                          )}
                        </FormGroup>
                      </Col>
                    )}
                  </>
                )}

                {bookingType === "multiple" && (
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <Label>Select booking type</Label>
                      <AvRadioGroup
                        inline
                        name="commonBooking"
                        required
                        onChange={e => {
                          setCommonBookingType(e.target.value)
                        }}
                      >
                        <Row>
                          <Col md={6}>
                            <AvRadio
                              customInput
                              label={"Daily"}
                              value="daily"
                            />
                          </Col>
                          <Col md={6}>
                            <AvRadio
                              customInput
                              label={"Weekly"}
                              value="specificDay"
                            />
                          </Col>
                        </Row>
                      </AvRadioGroup>
                      {commonBookingType === "specificDay" && (
                        <>
                          <Label>Select Days Of Week </Label>
                          <AvCheckboxGroup name="daysOfWeek" value="">
                            <Row>
                              <Col md={4}>
                                <AvCheckbox value="MONDAY" label="Monday" />
                              </Col>
                              <Col md={4}>
                                <AvCheckbox value="TUESDAY" label="Tuesday" />
                              </Col>
                              <Col md={4}>
                                <AvCheckbox
                                  value="WEDNESDAY"
                                  label="Wednesday"
                                />
                              </Col>
                              <Col md={4}>
                                <AvCheckbox value="THURSDAY" label="Thursday" />
                              </Col>
                              <Col md={4}>
                                <AvCheckbox value="FRIDAY" label="Friday" />
                              </Col>
                              <Col md={4}>
                                <AvCheckbox value="SATURDAY" label="Saturday" />
                              </Col>
                              <Col md={4}>
                                <AvCheckbox value="SUNDAY" label="Sunday" />
                              </Col>
                            </Row>
                          </AvCheckboxGroup>
                        </>
                      )}
                    </FormGroup>
                  </Col>
                )}

                <Col xs={12} sm={12} className="mb-4">
                  <FormGroup>
                    <AvField
                      type="select"
                      id="duration"
                      name="duration"
                      label={t("Duration of service")}
                      value={data.duration}
                      className={`form-control ${
                        error && error["duration"] && "is-invalid"
                      }`}
                      required
                      onChange={() => {
                        resetErrorOnInputChange("duration")
                        resetPromoCodeValidity()
                      }}
                    >
                      <option value={-1} key="address_default1">
                        --Select--
                      </option>
                      {isHealthCareService && (
                        <>
                          <option value={12} key="address_default12">
                            12 Hrs
                          </option>
                          <option value={24} key="address_default24">
                            24 Hrs
                          </option>
                        </>
                      )}
                      {!isHealthCareService &&
                        durationOptions &&
                        durationOptions.map(({ label, value }) => {
                          return (
                            <option value={value} key={value}>
                              {label}
                            </option>
                          )
                        })}
                    </AvField>
                    {error && error["duration"] && (
                      <label style={{ color: "red", fontWeight: "100" }}>
                        {error["duration"]}
                      </label>
                    )}
                  </FormGroup>
                </Col>
                {!isHealthCareService &&
                  serviceMode &&
                  selectedService.serviceMode === "BOTH" && (
                    <Col xs={12} sm={12} className="mb-4">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="serviceMode"
                          label="Select Mode"
                          value={data.serviceMode}
                          className={`form-control ${
                            error && error["serviceMode"] && "is-invalid"
                          }`}
                          required
                          onChange={e => setSelectedServiceMode(e.target.value)}
                        >
                          <option value={-1} key="mode_default" disabled>
                            --Select--
                          </option>
                          <option value="ONLINE">Online</option>
                          <option value="OFFLINE">Offline</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                  )}

                {!isTransportationService && (
                  <>
                    <Col
                      xs={addressList && addressList.length == 0 ? 9 : 12}
                      sm={addressList && addressList.length == 0 ? 9 : 12}
                      className="mb-4"
                    >
                      <FormGroup>
                        <AvField
                          type="select"
                          name="serviceAddressID"
                          label="Select address"
                          value={data.serviceAddressID}
                          className={`form-control ${
                            error && error["serviceAddress"] && "is-invalid"
                          }`}
                          required
                          onChange={() => {
                            resetErrorOnInputChange("serviceAddress")
                            resetPromoCodeValidity()
                          }}
                        >
                          <option value={-1} key="address_default123">
                            --Select--
                          </option>
                          {addressList &&
                            addressList.map(item => {
                              return (
                                <option value={item.id} key={item.tags}>
                                  {item.tags} - {item.address}, {item.landmark},{" "}
                                  {item.city}, {item.state}
                                </option>
                              )
                            })}
                        </AvField>
                        {error && error["serviceAddress"] && (
                          <label style={{ color: "red", fontWeight: "100" }}>
                            {error["serviceAddress"]}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                    {addressList && addressList.length === 0 && (
                      <Col xs={3} sm={3} className="mb-4">
                        <FormGroup>
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ marginTop: "26px" }}
                            onClick={() => {
                              setAddressModelVisibility(true)
                            }}
                          >
                            {t("Add New")}
                          </button>
                        </FormGroup>
                      </Col>
                    )}
                  </>
                )}

                {isTransportationService && (
                  <>
                    <Col xs={9} sm={9} className="mb-4">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="pickupAddressId"
                          label="Select pickup address"
                          value={data.pickupAddressId}
                          className={`form-control ${
                            error && error["serviceAddress"] && "is-invalid"
                          }`}
                          required
                          onChange={() => {
                            resetErrorOnInputChange("serviceAddress")
                            resetPromoCodeValidity()
                          }}
                        >
                          <option value={-1} key="address_default101">
                            --Select--
                          </option>
                          {addressList &&
                            addressList.map(item => {
                              return (
                                <option value={item.id} key={item.tags}>
                                  {item.tags} - {item.address}, {item.landmark},{" "}
                                  {item.city}, {item.state}
                                </option>
                              )
                            })}
                        </AvField>
                        {error && error["serviceAddress"] && (
                          <label style={{ color: "red", fontWeight: "100" }}>
                            {error["serviceAddress"]}
                          </label>
                        )}
                      </FormGroup>
                    </Col>

                    <Col xs={3} sm={3} className="mb-4">
                      <FormGroup>
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginTop: "26px" }}
                          onClick={() => {
                            setAddressModelVisibility(true)
                          }}
                        >
                          {t("Add New")}
                        </button>
                      </FormGroup>
                    </Col>

                    <Col xs={9} sm={9} className="mb-4">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="dropAddressId"
                          label="Select drop address"
                          value={data.dropAddressId}
                          className={`form-control ${
                            error && error["serviceAddress"] && "is-invalid"
                          }`}
                          required
                          onChange={() => {
                            resetErrorOnInputChange("serviceAddress")
                            resetPromoCodeValidity()
                          }}
                        >
                          <option value={-1} key="address_default102">
                            --Select--
                          </option>
                          {addressList &&
                            addressList.map(item => {
                              return (
                                <option value={item.id} key={item.tags}>
                                  {item.tags} - {item.address}, {item.landmark},{" "}
                                  {item.city}, {item.state}
                                </option>
                              )
                            })}
                        </AvField>
                        {error && error["serviceAddress"] && (
                          <label style={{ color: "red", fontWeight: "100" }}>
                            {error["serviceAddress"]}
                          </label>
                        )}
                      </FormGroup>
                    </Col>

                    <Col xs={3} sm={3} className="mb-4">
                      <FormGroup>
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginTop: "26px" }}
                          onClick={() => {
                            setAddressModelVisibility(true)
                          }}
                        >
                          {t("Add New")}
                        </button>
                      </FormGroup>
                    </Col>
                  </>
                )}

                {!isHealthCareService && bookingType === "single" && (
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <AvField
                        id="description"
                        name="description"
                        label={t("Description")}
                        className={`form-control ${
                          error && error["description"] && "is-invalid"
                        }`}
                        placeholder={t("Description for service")}
                        type="text"
                        value={data.description}
                      />
                    </FormGroup>
                  </Col>
                )}

                {isHealthCareService && (
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <AvField
                        id="diagnosis"
                        name="diagnosis"
                        label={"Diagnosis"}
                        className={`form-control ${
                          error && error["diagnosis"] && "is-invalid"
                        }`}
                        placeholder={"Diagnosis for service"}
                        type="text"
                        value={data.diagnosis}
                      />
                    </FormGroup>
                  </Col>
                )}
                {isHealthCareService && (
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <AvField
                        id="weight"
                        name="weight"
                        label={"Weight"}
                        className={`form-control ${
                          error && error["weight"] && "is-invalid"
                        }`}
                        placeholder={"Weight"}
                        type="number"
                        value={data.weight}
                      />
                    </FormGroup>
                  </Col>
                )}
                {isHealthCareService && (
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <Label for="healthBuddyType">Health Buddy Type</Label>
                      <select
                        id="healthBuddyType"
                        name="healthBuddyType"
                        className="form-control"
                        value={healthBuddyType}
                        onChange={e => {
                          setHealthBuddyType(e.target.value)
                        }}
                      >
                        <option value="" selected disabled>
                          Select One
                        </option>
                        <option value="110001">Care Taker</option>
                        <option value="110002">Associate Nurse</option>
                        <option value="110003">Registered Nurse</option>
                      </select>
                    </FormGroup>
                  </Col>
                )}
                {isHealthCareService && (
                  <Col xs={12} sm={12} className="mb-4">
                    <span style={{ alignSelf: "center" }}>Languages Known</span>
                    <FormGroup>
                      <AvCheckboxGroup
                        name="selectedLanguages"
                        value={selectedLanguages}
                        required
                      >
                        {languageList &&
                          languageList.map(item => {
                            return (
                              <AvCheckbox
                                checked={
                                  selectedLanguages &&
                                  selectedLanguages.includes(item.key)
                                }
                                label={item.value}
                                key={item.key}
                                value={item.key}
                                onChange={e => {
                                  languageSelected(e)
                                }}
                              />
                            )
                          })}
                      </AvCheckboxGroup>
                      {error && error["selectedLanguages"] && (
                        <label style={{ color: "red", fontWeight: "100" }}>
                          {error["selectedLanguages"]}
                        </label>
                      )}
                    </FormGroup>
                  </Col>
                )}
                {!isHealthCareService && (
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <Label
                        className={classnames({
                          "text-danger": validPromoCode === false,
                        })}
                        for="promo"
                      >
                        Coupon
                      </Label>
                      <InputGroup>
                        <Input
                          id="promo"
                          type="text"
                          value={promoCode}
                          onChange={e => {
                            resetPromoCodeValidity()
                            setPromoCode(e.target.value)
                          }}
                          invalid={validPromoCode === false}
                          placeholder="PROMO_CODE"
                          valid={validPromoCode === true}
                        />
                        <Button
                          onClick={checkPromoCode}
                          disabled={!Boolean(promoCode)}
                        >
                          Apply
                        </Button>
                        {validPromoCode === false && (
                          <FormFeedback>{error?.["promo"]}</FormFeedback>
                        )}
                        {validPromoCode === true && (
                          <FormFeedback valid>Valid Promo Code</FormFeedback>
                        )}
                      </InputGroup>
                      {promoCode && validPromoCode === null && (
                        <FormText>*Promo Code not applied</FormText>
                      )}
                    </FormGroup>
                  </Col>
                )}
                {!isHealthCareService && !data.id && (
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <Label> Service Payment</Label>
                      <AvRadioGroup
                        inline
                        name="paymentMode"
                        required
                        onChange={e => {
                          resetPromoCodeValidity()
                        }}
                      >
                        <AvRadio customInput label="Online" value="ONLINE" />
                        <AvRadio customInput label="Offline" value="OFFLINE" />
                      </AvRadioGroup>
                    </FormGroup>
                  </Col>
                )}
                {data.id && (
                  <Label>
                    Estimates Service cost: ₹{" "}
                    {parseFloat(data.serviceEstimates / 100).toFixed(2)}
                  </Label>
                )}
                <PaymentHistory data={data.servicePayments}></PaymentHistory>
                {data.id && data.paymentStatus !== PAYMENT_STATUS.PAID && (
                  <Col xs={12} sm={12} className="mb-4">
                    <Label>
                      {" "}
                      If you have not paid or partially paid for the service or
                      your payment was failed, you can pay now by{" "}
                      <span
                        style={{ color: "blue" }}
                        onClick={() => {
                          payServiceFees(data?.servicePayments[0])
                        }}
                      >
                        clicking here.
                      </span>
                    </Label>
                  </Col>
                )}
                {data.id && <PaymentDisclosure></PaymentDisclosure>}
              </Row>
            </div>
            {!(
              (selectedService &&
                selectedService.serviceStatus &&
                selectedService.serviceStatus === "COMPLETED") ||
              (selectedService &&
                selectedService.serviceStatus &&
                selectedService.serviceStatus === "STARTED")
            ) && (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    resetModalData()
                    onCancelClick()
                  }}
                >
                  {t("Cancel")}
                </button>
                <button type="submit" className="btn btn-primary">
                  {data.id
                    ? t("Update")
                    : isHealthCareService
                    ? t("Next")
                    : t("Get Estimate Price")}
                </button>
              </div>
            )}
          </AvForm>
        )}
        {selectHealthBuddy &&
          !isActivityService &&
          isHealthCareService &&
          healthBuddyList.length && (
            <>
              <Col xs={12} sm={12} className="mb-4">
                {healthBuddyList.map(healthBuddy => (
                  <>
                    <div
                      className={`card border-dark mb-3 healthBuddy ${
                        selectedHealthBuddy?.id === healthBuddy.id &&
                        "selected-healthBuddy"
                      }`}
                      onClick={() => {
                        setSelectedHealthBuddy(healthBuddy)
                      }}
                    >
                      <div className="row g-0">
                        <div className="col-md-3">
                          <img
                            src={getImageForUser(
                              healthBuddy.id,
                              healthBuddy.profileImageURL
                            )}
                            style={{
                              height: "100%",
                            }}
                            className="img-fluid rounded-start"
                            alt={`${healthBuddy.firstName} ${healthBuddy.lastName}`}
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="card-body">
                            <h5 className="card-title">{`${healthBuddy?.firstName} ${healthBuddy?.lastName}`}</h5>
                            <div className="row">
                              <div className="col-md-5">
                                <p className="card-text">
                                  {healthBuddy?.gender || "N/A"}&nbsp;
                                  {healthBuddy.age
                                    ? `${healthBuddy.age} years`
                                    : "N/A"}
                                  <br />
                                  {healthBType[healthBuddy.healthBuddyType]}
                                  <br />
                                  Experience:{" "}
                                  {healthBuddy.experience
                                    ? `${healthBuddy.experience} years`
                                    : "N/A"}
                                  <br />
                                </p>
                              </div>
                              <div className="col-md-7">
                                <p className="card-text">
                                  Education: {healthBuddy.education || "N/A"}{" "}
                                  <br />
                                  {healthBuddy.languagesKnown?.join(", ") ||
                                    "N/A"}
                                  <br />
                                  12 hour price is{" "}
                                  <b>
                                    ₹{healthBuddy.adminPrice12Hr || 200}
                                  </b>{" "}
                                  and 24 hours price is{" "}
                                  <b>₹{healthBuddy.adminPrice24Hr || 400}</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </Col>
              {selectHealthBuddy &&
                isHealthCareService &&
                healthBuddyList.length && (
                  <>
                    <Col xs={12} sm={12} className="mb-4">
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            resetModalData()
                            onCancelClick()
                          }}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={bookHCService}
                        >
                          {t("Book Service")}
                        </button>
                      </div>
                    </Col>
                  </>
                )}
            </>
          )}
        {isActivityService && (
          <>
            <AvForm
              onValidSubmit={(e, v) => {
                bookActivityService(e, v)
              }}
            >
              <Row>
                <Col xs={12} sm={12} className="p-4">
                  <FormGroup>
                    <Label for="healthBuddyType">Select Time</Label>
                    <select
                      id="healthBuddyType"
                      name="healthBuddyType"
                      className="form-control"
                      onChange={e => {
                        setActivityServiceTime(e.target.value)
                      }}
                      required
                    >
                      <option value="0" selected>
                        Morning
                      </option>
                      <option value="1">Evening</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <img
                src={
                  activityServiceTime === "0"
                    ? MORNING_ACTIVITY_URL
                    : EVENING_ACTIVITY_URL
                }
                className="w-100 rounded"
                alt="Online Activity"
              />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    resetModalData()
                    onCancelClick()
                  }}
                >
                  {t("Cancel")}
                </button>
                <button type="submit" className="btn btn-primary">
                  {t("Book Service")}
                </button>
              </div>
            </AvForm>
          </>
        )}
        <FullScreenLoader isOpen={fullScreenLoader}></FullScreenLoader>
        {pricingModal && (
          <PricingDetailModal
            isOpen={pricingModal}
            data={serviceDetailObject}
            closeModal={() => {
              setPricingModal(false)
              setFullScreenLoader(false)
            }}
            makePaymentAndBookService={(data, costEstimates) => {
              makePaymentAndBookService(data, costEstimates)
            }}
            onCancelClick={() => {
              setPricingModal(false)
              setFullScreenLoader(false)
            }}
          ></PricingDetailModal>
        )}
        <AddEditAddress
          isOpen={showAddressModal}
          currentAddressObject={null}
          updateData={setCurrentAddressObject}
          onCancelClick={() => {
            setAddressModelVisibility(false)
          }}
          onSubmitClick={addressDetail => {
            addUpdateAddressDetail(addressDetail)
          }}
        />
      </Modal>
    </>
  )
}

ServiceBookingModal.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.bool,
  data: PropTypes.any,
  selectedService: PropTypes.any,
  closeModal: PropTypes.any,
  onSubmitClick: PropTypes.func,
  onCancelClick: PropTypes.func,
}

export default withTranslation()(ServiceBookingModal)

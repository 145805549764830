import {
    BOOKING_DETAIL, BOOK_SERVICE, GET_SERVICE_TYPE, SERVICE_TYPE, GET_ALL_SERVICE_BOOKINGS, CANCEL_SERIVCE_BOOKING, SERVICE_BOOKING_STATUS, CANCEL_SERVICE_RESPONSE, CLEAR_SERVICE_BOOKING_RESPOSE, CREATE_SERVICE_TYPE, UPDATE_SERVICE_TYPE, UPDATE_SERVICE_RESPONSE, CREATE_SERVICE_RESPONSE, DELETE_SERVICE_TYPE, DELETE_SERVICE_TYPE_RESPONSE, GET_PRICING_DETAIL, PRICING_DETAIL, RATE_SERVICE,
    BOOK_WEBINAR, WEBINAR_BOOKING_STATUS, CLEAR_WEBINAR_BOOKING_STATUS,
    VALIDATE_PROMO_CODE, SET_PROMO_CODE_VALIDITY_STATUS, GET_ALL_HEALTH_CARE_BOOKINGS, GET_ALL_HEALTH_CARE_BOOKINGS_RESPONSE, UPDATE_HEALTH_CARE_ASSIGNED, BOOK_ACTIVITY_SERVICE, CHECK_BOOK_ACTIVITY_SERVICE, ACTIVITY_SERVICE_STATUS, BOOK_ANNUAL_SUBSCRIPTION, BOOK_ANNUAL_SUBSCRIPTION_RESP
} from "./actionTypes"

export const getServiceType = () => {
    return {
        type: GET_SERVICE_TYPE,
        payload: null
    }
}
export const createServiceType = (data) => {
    return {
        type: CREATE_SERVICE_TYPE,
        payload: data
    }
}

export const createServiceTypeResponse = (data) => {
    return {
        type: CREATE_SERVICE_RESPONSE,
        payload: data
    }
}

export const updateServiceType = (data) => {
    return {
        type: UPDATE_SERVICE_TYPE,
        payload: data
    }
}
export const updateAssignHCService = (data) => {
    return {
        type: UPDATE_HEALTH_CARE_ASSIGNED,
        payload: data
    }
}

export const updateServiceResponse = (data) => {
    return {
        type: UPDATE_SERVICE_RESPONSE,
        payload: data
    }
}

export const deleteServiceType = (data) => {
    return {
        type: DELETE_SERVICE_TYPE,
        payload: data
    }
}

export const deleteServiceTypeResponse = (data) => {
    return {
        type: DELETE_SERVICE_TYPE_RESPONSE,
        payload: data
    }
}

export const serviceType = (serviceType) => {
    return {
        type: SERVICE_TYPE,
        payload: serviceType
    }
}

export const bookServices = (serviceInfo) => {
    return {
        type: BOOK_SERVICE,
        payload: serviceInfo
    }
}

export const bookActivityServices = (serviceInfo) => {
    return {
        type: BOOK_ACTIVITY_SERVICE,
        payload: serviceInfo
    }
}

export const checkBookActivityServices = (serviceInfo) => {
    return {
        type: CHECK_BOOK_ACTIVITY_SERVICE,
        payload: serviceInfo
    }
}

export const bookActivityServicesStatus = (serviceInfo) => {
    return {
        type: ACTIVITY_SERVICE_STATUS,
        payload: serviceInfo
    }
}

export const serviceBookingStatus = (resp) => {
    return {
        type: SERVICE_BOOKING_STATUS,
        payload: resp
    }
}

export const clearServiceBookingStatus = () => {
    return {
        type: CLEAR_SERVICE_BOOKING_RESPOSE,
        payload: null
    }
}

export const getAllServiceBookingDetail = (id) => {
    return {
        type: GET_ALL_SERVICE_BOOKINGS,
        payload: id
    }
}
export const getAllHealthCareBookingDetail = (data) => {
    return {
        type: GET_ALL_HEALTH_CARE_BOOKINGS,
        payload: data
    }
}

export const getAllHealthCareBookingDetailResponse = (data) => {
    return {
        type: GET_ALL_HEALTH_CARE_BOOKINGS_RESPONSE,
        payload: data
    }
}

export const bookingDetail = (bookingInfo) => {
    return {
        type: BOOKING_DETAIL,
        payload: bookingInfo
    }
}

export const cancelServiceBookings = (serviceInfo) => {
    return {
        type: CANCEL_SERIVCE_BOOKING,
        payload: serviceInfo
    }
}

export const cancelServiceResponse = (serviceInfo) => {
    return {
        type: CANCEL_SERVICE_RESPONSE,
        payload: serviceInfo
    }
}

export const getPricingDetail = (serviceInfo) => {
    return {
        type: GET_PRICING_DETAIL,
        payload: serviceInfo
    }
}

export const pricingDetail = (pricingInfo) => {
    return {
        type: PRICING_DETAIL,
        payload: pricingInfo
    }
}

export const rateService = (data) => {
    return {
        type: RATE_SERVICE,
        payload: data
    }
}

export const bookWebinar = (data) => {
    return {
        type: BOOK_WEBINAR,
        payload: data
    }
}

export const webinarBookingStatus = (resp) => {
    return {
        type: WEBINAR_BOOKING_STATUS,
        payload: resp
    }
}

export const clearWebinarBookingStatus = () => {
    return {
        type: CLEAR_WEBINAR_BOOKING_STATUS
    }
}

export const validatePromoCode = (resp) => {
    return {
        type: VALIDATE_PROMO_CODE,
        payload: resp
    }
}

export const setPromoCodeValidity = (resp) => {
    return {
        type: SET_PROMO_CODE_VALIDITY_STATUS,
        payload: resp
    }
}

export const bookAnnualSubscription = (bookingInfo) => {
    return {
        type: BOOK_ANNUAL_SUBSCRIPTION,
        payload: bookingInfo
    }
}

export const bookAnnualSubscriptionResp = (bookingInfo) => {
    return {
        type: BOOK_ANNUAL_SUBSCRIPTION_RESP,
        payload: bookingInfo
    }
}
import { ALL_ASSIGNMENTS, ACCEPT_SERVICE, GET_ALL_ASSIGNMENTS, COMPLETE_SERVICE, START_SERVICE, REJECT_SERVICE, GET_COMPLETED_ASSIGNMENT, COMPLETED_ASSIGNMENT_RESP, GET_ASSIGNED_ASSIGNMENTS, ASSIGNED_ASSIGNMENT_RESP, GET_ONGOING_ASSIGNMENTS, ONGOING_ASSIGNMENTS, ONGOING_ASSIGNMENTS_RESP, START_SERVICE_RESPONSE, COMPLETE_SERVICE_RESPONSE, ACCEPT_SERVICE_RESPONSE, GET_INCOMPLETE_ASSIGNMENT, INCOMPLETE_ASSIGNMENT_RESP, HEALTH_CARE_PAYMENT_DETAIL } from "./actionTypes"

export const getAllAssignments = (data) => {
    return {
        type: GET_ALL_ASSIGNMENTS,
        payload: data
    }
}

export const allAssignmentsResp = (assignmentList) => {
    return {
        type: ALL_ASSIGNMENTS,
        payload: assignmentList
    }
}

export const getOngoingAssignments = (id = null, page, perPage) => {
    return {
        type: GET_ONGOING_ASSIGNMENTS,
        payload: {id, page, perPage}
    }
}

export const ongoingAssignmentsResp = (assignmentList) => {
    return {
        type: ONGOING_ASSIGNMENTS_RESP,
        payload: assignmentList
    }
}

export const getAllAssignedAssignemnts = (id = null, page, perPage) => {
    return {
        type: GET_ASSIGNED_ASSIGNMENTS,
        payload: {id, page, perPage}
    }
}

export const allAssignedAssignmentResp = (assignmentList) => {
    return {
        type: ASSIGNED_ASSIGNMENT_RESP,
        payload: assignmentList
    }
}

export const getAllCompletedAssignments = (id = null, page, perPage) => {
    return {
        type: GET_COMPLETED_ASSIGNMENT,
        payload: {id, page, perPage}
    }
}

export const completedAssignmentResp = (assignmentList) => {
    return {
        type: COMPLETED_ASSIGNMENT_RESP,
        payload: assignmentList
    }
}

export const getAllIncompletedAssignments = () => {
    return {
        type: GET_INCOMPLETE_ASSIGNMENT,
        payload: null
    }
}

export const incompeletedAssignments = (assignmentList) => {
    return {
        type: INCOMPLETE_ASSIGNMENT_RESP,
        payload: assignmentList
    }
}

export const assignService = (serviceDetail) => {

}

export const acceptService = (serviceDetail) => {
    return {
        type: ACCEPT_SERVICE,
        payload: serviceDetail
    }
}

export const savePaymentDetail = (paymentDetail) => {
    return {
        type: HEALTH_CARE_PAYMENT_DETAIL,
        payload: paymentDetail
    }
}

export const acceptServiceResponse = (resp) => {
    return {
        type: ACCEPT_SERVICE_RESPONSE,
        payload: resp
    }
}

export const startService = (serviceDetail) => {
    return {
        type: START_SERVICE,
        payload: serviceDetail
    }
}

export const startServiceResponse = (resp) => {
    return {
        type: START_SERVICE_RESPONSE,
        payload: resp
    }
}

export const completeService = (serviceDetail) => {
    return {
        type: COMPLETE_SERVICE,
        payload: serviceDetail
    }
}

export const completeServiceResponse = (resp) => {
    return {
        type: COMPLETE_SERVICE_RESPONSE,
        payload: resp
    }
}

export const rejectService = (serviceDetail) => {
    return {
        type: REJECT_SERVICE,
        payload: serviceDetail
    }
}
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import countryCode from "utils/countryCode"

import { Modal } from "reactstrap"

const URL = {
  PROD: "https://server.happy60plus.com",
  TEST: "https://test.server.happy60plus.com",
}

const TripAndToursEnquiryModal = ({ enquiryData, isOpen, toggleModal }) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [selectedCountry, setCountry] = useState("+91")
  const [errors, setError] = useState({
    name: false,
    mobileNumber: false,
    serviceID: false,
  })
  let timeout

  useEffect(() => {
    if (!isOpen) {
      return () => {
        clearTimeout(timeout)
        timeout = undefined
      }
    }
  }, [])

  function isValid(formSubmitted = false) {
    if (!formSubmitted && !isFormSubmitted) {
      return
    }
    const form = document.getElementById("EnquiryForm")
    const formData = new FormData(form)
    const data = Object.fromEntries(formData.entries())
    let isValid = true
    const error = {
      name: false,
      mobileNumber: false,
      //   serviceID: showService && !data.serviceID,
    }
    for (const [key, value] of Object.entries(data)) {
      if (!value || value.trim() === "") {
        if (key !== "email") {
          error[key] = true
          isValid = false
        }
        if (key === "pincode") {
          error[key] = false
          isValid = true
        }
      } else if (key === "mobileNumber") {
        const regex = /^[0-9 ~@#$^*()_+=[\]{}|\\,.?: -]{10,15}$/
        const isValidPhone = regex.test(value)
        if (!isValidPhone) {
          error[key] = true
          isValid = false
        }
      }
    }
    setError(error)
    return isValid
  }

  const handleSubmit = event => {
    event.preventDefault()
    setFormSubmitted(true)
    const formData = new FormData(event.target)
    const formJSON = Object.fromEntries(formData.entries())
    if (isValid(true)) {
      // formJSON.mobileNumber = formJSON.dialCode + formJSON.mobileNumber
      // formJSON.mobileNumber = formJSON.mobileNumber.slice(1)
      formJSON.countryCode = formJSON.dialCode.slice(1)
      formJSON.enquiryStatus = "RAISED"
      formJSON.enquiryType = "TOUR_AND_TRIP_ENQUIRY"
      formJSON.serviceID = enquiryData.id
      delete formJSON.dialCode
      // dispatch(createTripStatus(formJSON))
      let url = URL.PROD
      if (
        window.location.hostname.indexOf("test") !== -1 ||
        window.location.hostname == "localhost"
      ) {
        url = URL.TEST
      }
      const serviceListUrl = `${url}/data-service/api/enquiry/save`
      fetch(serviceListUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formJSON),
      })
        .then(response => {
          response.json()
        })
        .then(() => {
          setShowSuccess(true)
          try {
            event?.target?.reset()
          } catch (error) {
            console.log(error)
          }
          // addData(true);
          timeout = setTimeout(() => {
            toggleModal()
            setShowSuccess(false)
          }, 3000)
        })
        .catch(error => {
          setShowError(true)
          timeout = setTimeout(() => {
            setShowError(false)
          }, 3000)
        })
    }
    return false
  }

  return (
    <>
      <Modal
        id="YogaModal"
        isOpen={isOpen}
        centered={true}
        className="modal-dialog-centered"
      >
        <div className="modal-header" style={{ borderBottom: "none" }}>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => toggleModal()}
          ></button>
        </div>

        <div className="faq-contact triangle-yoga">
          <div className="enquiry-wrapper">
            <h3
              className="text-uppercase text-white text-center p-3"
              style={{
                fontSize: "1.8rem",
                marginBottom: "0.5rem",
              }}
            >
              {/* Itinerary for Assam & Nagaland (7N/8D) */}
              {enquiryData.title}
            </h3>
            {/* <h5
              className="text-uppercase text-white text-center"
              style={{
                fontSize: "1rem",
              }}
            >
              INCLUSIONS
            </h5> */}
            {/* <ul
              style={{
                margin: "0 auto",
                width: 400,
                textAlign: "start",
              }}
            >
              <li>Premium accommodation on double occupancy basis</li>
              <li>Breakfast on all days at the property</li>
              <li>SUV on all days</li>
              <li>One Elephant Safari at Kaziranga National Park</li>
              <li>One Elephant Safari at Kaziranga National Park</li>
              <li>One Jeep Safari at Kaziranga National Park</li>
              <li>Inner line permit for Nagaland</li>
              <li>Anvayins representative in Nagaland</li>
              <li>One Ethnic Lunch at Khonoma on 4 December 2023</li>
              <li>All toll and taxes</li>
            </ul> */}
            <form
              id="EnquiryForm"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="row">
                <div className="col-lg-12 col-md-12 p-3 px-5">
                  <div className="form-group" style={{ marginBottom: "-18px" }}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      className={`form-control ${errors.name && "is-invalid"}`}
                      style={{
                        fontSize: "1.2rem",
                      }}
                      onChange={() => isValid()}
                    />
                    <div className="invalid-feedback">
                      Your name is required.
                    </div>
                  </div>
                </div>

                <div className={`col-lg-12 col-md-12 p-3 px-5`}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-4 pe-0 border-end">
                        <select
                          id="dialCode"
                          name="dialCode"
                          className={`form-control form-select`}
                          style={{
                            textAlignLast: "center",
                            fontSize: "1.2rem",
                            borderBottomRightRadius: "unset",
                            borderTopRightRadius: "unset",
                          }}
                          onChange={e => {
                            setCountry(e.target.value)
                          }}
                          defaultValue={selectedCountry}
                        >
                          {countryCode &&
                            countryCode.map(country => (
                              <option
                                key={`k-${country.name}`}
                                value={country.dialCode}
                              >
                                {country.dialCode === selectedCountry
                                  ? country.dialCode
                                  : `${country.name} ${country.dialCode}`}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-8 ps-0">
                        <input
                          type="tel"
                          // pattern="^\d{10}$"
                          name="mobileNumber"
                          placeholder="Phone Number"
                          className={`form-control ${
                            errors.mobileNumber && "is-invalid"
                          }`}
                          onChange={() => isValid()}
                          style={{
                            fontSize: "1.2rem",
                            borderBottomLeftRadius: "unset",
                            borderTopLeftRadius: "unset",
                          }}
                        />
                      </div>
                    </div>

                    <div className="invalid-feedback">
                      Please provide valid phone number.
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-8 d-grid gap-2 mx-auto">
                  <button
                    className="btn btn-gradient services-button enquiry-button"
                    type="submit"
                  >
                    Submit Now
                  </button>
                </div>
                {showSuccess && (
                  <div className="col-lg-12 col-md-12 d-grid gap-2 mx-auto p-2">
                    <div className="alert alert-success" role="alert">
                      Thank you for booking
                    </div>
                  </div>
                )}
                {showError && (
                  <div className="col-lg-12 col-md-12 d-grid gap-2 mx-auto p-2">
                    <div className="alert alert-danger" role="alert">
                      Something Went wrong, Please Try again!
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}

TripAndToursEnquiryModal.propTypes = {
  enquiryData: PropTypes.any,
  isOpen: PropTypes.any,
  toggleModal: PropTypes.any,
}

export default TripAndToursEnquiryModal

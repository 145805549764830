import { all, call, put, takeEvery } from "redux-saga/effects"

import {
  GET_HOME_PAGE_SERVICES,
  CREATE_HOME_PAGE_SERVICES,
  UPDATE_HOME_PAGE_SERVICES,
  DELETE_HOME_PAGE_SERVICES,
} from "./actionTypes"
import { updateListHomePageService, setHomePageLoading } from "./actions"

import {
  createHomePageService,
  deleteHomePageServices,
  getHomePageServices,
  updateHomePageService,
} from "utils/Apiv2"

function* getAllEnquiryListSaga() {
  try {
    yield put(setHomePageLoading(true))
    const resp = yield call(getHomePageServices)
    if (resp) {
      yield put(updateListHomePageService(resp))
      yield put(setHomePageLoading(false))
    }
  } catch (error) {
    console.error(error)
  }
}

function* deleteHomePageSaga(data) {
  try {
    yield put(setHomePageLoading(true))
    const resp = yield call(deleteHomePageServices, data.payload)
    if (resp) {
      const resp1 = yield call(getHomePageServices)
      if (resp1) {
        yield put(updateListHomePageService(resp1))
        yield put(setHomePageLoading(false))
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* updateHomePageServiceSaga(data) {
  try {
    yield put(setHomePageLoading(true))
    const resp = yield call(updateHomePageService, data.payload)
    if (resp) {
      const resp1 = yield call(getHomePageServices)
      if (resp1) {
        yield put(updateListHomePageService(resp1))
        yield put(setHomePageLoading(false))
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* createHomePageServiceSaga(data) {
  try {
    yield put(setHomePageLoading(true))
    const resp = yield call(createHomePageService, data.payload)
    if (resp) {
      const resp1 = yield call(getHomePageServices)
      if (resp1) {
        yield put(updateListHomePageService(resp1))
        yield put(setHomePageLoading(false))
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* HomePageServicesSaga() {
  yield takeEvery(GET_HOME_PAGE_SERVICES, getAllEnquiryListSaga)
  yield takeEvery(CREATE_HOME_PAGE_SERVICES, createHomePageServiceSaga)
  yield takeEvery(UPDATE_HOME_PAGE_SERVICES, updateHomePageServiceSaga)
  yield takeEvery(DELETE_HOME_PAGE_SERVICES, deleteHomePageSaga)
}

export default HomePageServicesSaga

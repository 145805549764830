import { ENV, LOCAL_STORAGE_KEY } from "constants/common"
import { getServerDetail } from "./serverDetailUtil"

const getUserDetailFromLocalStorage = () => {
  if (getServerDetail()?.env === ENV.PROD) {
    let prodUserDetail = localStorage.getItem(LOCAL_STORAGE_KEY.PROD_USER_DATA)
    if (prodUserDetail) {
      return prodUserDetail
    }
  } else {
    let testUserDetail = localStorage.getItem(LOCAL_STORAGE_KEY.TEST_USER_DATA)
    if (testUserDetail) {
      return testUserDetail
    }
  }
  return null
}

const setUserDetail = data => {
  if (getServerDetail().env === ENV.PROD) {
    localStorage.setItem(LOCAL_STORAGE_KEY.PROD_USER_DATA, JSON.stringify(data))
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEY.TEST_USER_DATA, JSON.stringify(data))
  }
}

const removeUserDetail = () => {
  if (getServerDetail().env === ENV.PROD) {
    localStorage.removeItem(LOCAL_STORAGE_KEY.PROD_USER_DATA)
  } else {
    console.log(localStorage.getItem(LOCAL_STORAGE_KEY.TEST_USER_DATA))
    localStorage.removeItem(LOCAL_STORAGE_KEY.TEST_USER_DATA)
    console.log(localStorage.getItem(LOCAL_STORAGE_KEY.TEST_USER_DATA))
  }
}

const getUserDetail = (field = null) => {
  let userDetail = getUserDetailFromLocalStorage()
  if (field === null) {
    return userDetail
  } else {
    return JSON.parse(userDetail)[field]
  }
}

export { getUserDetail, setUserDetail, removeUserDetail }

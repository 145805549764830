import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  GET_ADDRESS_DETAIL,
  GET_PROFILE,
  UPDATE_PROFILE,
  GET_SENIOR_CITIZEN_DETAIL,
  GET_VEHICLE_DETAIL,
  GET_CONTACT_DETAIL,
  GET_HELP_BUDDY_DETAIL,
  UPDATE_SENIOR_CITIZEN_DETAIL,
  UPDATE_HELP_BUDDY_DETAIL,
  UPDATE_ADDRESS,
  UPDATE_VEHICLE_DETAIL,
  UPDATE_CONTACT_DETAIL,
  DELETE_CONTACT,
  DELETE_VEHICLE,
  DELETE_ADDRESS,
  GET_NON_APPROVED_HELP_BUDDY_DETAIL,
  GET_APPROVED_HELP_BUDDY,
  CHANGE_PASSWORD,
  VERIFY_SUBSCRIPTION,
} from "./actionTypes"

import {
  profileDetail,
  profileError,
  seniorCitizenDetail,
  helpBuddyDetail,
  addressDetail,
  getAddressDetail,
  vehicleDetail,
  getVehicleDetail,
  contactDetail,
  getContactDetail,
  nonApprovedHelpBuddyDetail,
  ApprovedHelpBuddyDetail,
  changePasswordResponse,
  updateProfileResponseStatus,
  verifySubscriptionResponse,
} from "./actions"

import {
  getValueHelps,
  getServiceType,
  getAllServiceBookingDetail,
} from "../../actions"
import { setUserDetail } from "utils/userUtil"
import {
  updateProfileDetails,
  getSeniorCitizen,
  updateSeniorCitiznDetails,
  getAddressDetails,
  updateAddress,
  deleteAddress,
  getVehicleDetails,
  updateVehicleDetails,
  deleteVehicle,
  getContactDetails,
  addUpdateContactDetail,
  deleteContact,
  getHelpBuddy,
  updateHelpBuddyDetails,
  getFilteredHelpBuddy,
  changePassword,
  verifySubscription,
} from "../../../utils/Apiv2"
import { getUser } from "../../../utils/Apiv2"
import { HELP_BUDDY_APP_STATUS, USER_TYPE } from "constants/common"

function* getProfile(data) {
  try {
    const resp = yield call(getUser, data.payload)
    if (resp && resp !== null) {
      // setUserDetail(resp)
      yield all([
        put(profileDetail(resp)),
        // put(getValueHelps()),
        put(getServiceType()),
        // put(getAddressDetail(resp.id)),
        // put(getAllServiceBookingDetail(resp.id)),
      ])
      yield
    } else {
      yield put(profileError(resp))
    }
  } catch (error) {
    console.log("getProfile error", error)
    yield put(profileError(error))
  }
}

function* updateProfile(data) {
  try {
    const resp = yield call(updateProfileDetails, data.payload)
    if (data.payload.type === USER_TYPE.SERVICE_PROVIDER) {
      yield put(helpBuddyDetail(resp))
    }
    yield put(updateProfileResponseStatus(true))
  } catch (error) {
    console.log("updateProfile error", error)
    yield put(profileError(error))
    yield put(updateProfileResponseStatus(false))
  }
}

function* getSeniorCitizenDetail(data) {
  try {
    const resp = yield call(getSeniorCitizen, data.payload)
    if (resp && resp !== null) {
      yield put(seniorCitizenDetail(resp))
    }
  } catch (error) {
    console.log("Debug ::> Err getSeniorCitizenDetail error", error)
    yield put(profileError(error))
  }
}

function* updateSeniorCitizenDetail(data) {
  try {
    yield call(updateSeniorCitiznDetails, data.payload)
    yield put(updateProfileResponseStatus(true))
  } catch (error) {
    console.log("updateSeniorCitizenDetail error", error)
    yield put(updateProfileResponseStatus(false))
  }
}

function* getHelpBuddyDetail(data) {
  try {
    const resp = yield call(getHelpBuddy, data.payload)
    if (resp && resp !== null) {
      yield put(helpBuddyDetail(resp))
    }
  } catch (error) {
    console.log("getHelpBuddyDetail error", error)
  }
}

function* updateHelpBuddyDetail(data) {
  try {
    yield call(updateHelpBuddyDetails, data.payload)
    yield put(updateProfileResponseStatus(true))
  } catch (error) {
    console.log("updateHelpBuddyDetail error", error)
    yield put(updateProfileResponseStatus(false))
  }
}

function* getNonApprovedHelpBuddyDetail() {
  try {
    const resp = yield call(getFilteredHelpBuddy, {
      status: HELP_BUDDY_APP_STATUS.COMPLETED,
      verified: false,
    })
    if (resp) {
      yield put(nonApprovedHelpBuddyDetail(resp))
    }
  } catch (error) {
    console.log("getNonApprovedHelpBuddyDetail error", error)
  }
}

function* getApprovedHelpBuddyDetailSaga() {
  try {
    const resp = yield call(getFilteredHelpBuddy, {
      status: HELP_BUDDY_APP_STATUS.COMPLETED,
      verified: true,
    })
    if (resp) {
      yield put(ApprovedHelpBuddyDetail(resp))
    }
  } catch (error) {
    console.log("getApprovedHelpBuddyDetailSaga error", error)
  }
}

function* getAddress(data) {
  try {
    const resp = yield call(getAddressDetails, data.payload)
    if (resp && resp !== null) {
      yield put(addressDetail(resp))
    }
  } catch (error) {
    console.log("getAddress error", error)
    // yield put(profileError(error))
  }
}

function* updateAddressDetail(data) {
  try {
    yield call(updateAddress, data.payload)
    yield put(getAddressDetail(data.payload.userID))
  } catch (error) {
    console.log("updateAddressDetail error", error)
  }
}

function* deleteAddressDetail(data) {
  try {
    yield call(deleteAddress, data.payload)
    yield put(getAddressDetail(data.payload.userID))
  } catch (error) {
    console.log("deleteAddressDetail error", error)
  }
}

function* getVehicle(data) {
  try {
    const resp = yield call(getVehicleDetails, data.payload)
    if (resp && resp !== null) {
      yield put(vehicleDetail(resp._embedded["vehicle-details"]))
    }
  } catch (error) {
    console.log("getVehicle error", error)
    // yield put(profileError(error))
  }
}

function* updateVehicleDetail(data) {
  try {
    yield call(updateVehicleDetails, data.payload)
    yield put(getVehicleDetail(data.payload.userID))
  } catch (error) {
    console.log("updateVehicleDetail error", error)
  }
}

function* deleteVehicleDetail(data) {
  try {
    yield call(deleteVehicle, data.payload)
    yield put(getVehicleDetail(data.payload.userID))
  } catch (error) {
    console.log("deleteVehicleDetail error", error)
  }
}

function* getContact(data) {
  try {
    const resp = yield call(getContactDetails, data.payload)
    if (resp && resp !== null) {
      yield put(contactDetail(resp.data["contact-details"]))
    }
  } catch (error) {
    console.log("getContact error", error)
    // yield put(profileError(error))
  }
}

function* updateContactDetail(data) {
  try {
    yield call(addUpdateContactDetail, data.payload)
    yield put(getContactDetail(data.payload.userID))
  } catch (error) {
    console.log("updateContactDetail error", error)
  }
}

function* deleteContactDetail(data) {
  try {
    yield call(deleteContact, data.payload)
    yield put(getContactDetail(data.payload.userID))
  } catch (error) {
    console.log("deleteContactDetail error", error)
  }
}

function* changePasswordSaga(data) {
  try {
    const resp = yield call(changePassword, data.payload)
    if (resp) {
      yield put(changePasswordResponse(resp))
    }
  } catch (error) {
    console.log("changePasswordSaga error", error)
  }
}

function* verifySubscriptionSaga(data) {
  try {
    const resp = yield call(verifySubscription, data.payload)
    if (resp) {
      yield put(verifySubscriptionResponse(resp))
    }
  } catch (error) {
    yield put(verifySubscriptionResponse(error))
    console.log("Debug ::> error", error)
  }
}

function* ProfileSaga() {
  yield takeEvery(GET_PROFILE, getProfile)
  yield takeEvery(UPDATE_PROFILE, updateProfile)

  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga)

  yield takeEvery(GET_SENIOR_CITIZEN_DETAIL, getSeniorCitizenDetail)
  yield takeEvery(UPDATE_SENIOR_CITIZEN_DETAIL, updateSeniorCitizenDetail)

  yield takeEvery(GET_HELP_BUDDY_DETAIL, getHelpBuddyDetail)
  yield takeEvery(UPDATE_HELP_BUDDY_DETAIL, updateHelpBuddyDetail)
  yield takeEvery(
    GET_NON_APPROVED_HELP_BUDDY_DETAIL,
    getNonApprovedHelpBuddyDetail
  )
  yield takeEvery(GET_APPROVED_HELP_BUDDY, getApprovedHelpBuddyDetailSaga)

  yield takeEvery(GET_ADDRESS_DETAIL, getAddress)
  yield takeEvery(UPDATE_ADDRESS, updateAddressDetail)
  yield takeEvery(DELETE_ADDRESS, deleteAddressDetail)

  yield takeEvery(GET_VEHICLE_DETAIL, getVehicle)
  yield takeEvery(UPDATE_VEHICLE_DETAIL, updateVehicleDetail)
  yield takeEvery(DELETE_VEHICLE, deleteVehicleDetail)

  yield takeEvery(GET_CONTACT_DETAIL, getContact)
  yield takeEvery(UPDATE_CONTACT_DETAIL, updateContactDetail)
  yield takeEvery(DELETE_CONTACT, deleteContactDetail)

  yield takeEvery(VERIFY_SUBSCRIPTION, verifySubscriptionSaga)
}

export default ProfileSaga

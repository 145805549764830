export const GET_PROFILE = "GET_PROFILE"
export const PROFILE_DETAIL = "PROFILE_DETAIL"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_STATUS = "UPDATE_PROFILE_STATUS";
export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";

export const GET_SENIOR_CITIZEN_DETAIL = "GET_SENIOR_CITIZEN_DETAIL";
export const SENIOR_CITIZEN_DETAIL = "SENIOR_CITIZEN_DETAIL";
export const UPDATE_SENIOR_CITIZEN_DETAIL = "UPDATE_SENIOR_CITIZEN_DETAIL";

export const GET_HELP_BUDDY_DETAIL = "GET_HELP_BUDDY_DETAIL";
export const HELP_BUDDY_DETAIL = "HELP_BUDDY_DETAIL";
export const GET_NON_APPROVED_HELP_BUDDY_DETAIL = "GET_NON_APPROVED_HELP_BUDDY_DETAIL";
export const GET_APPROVED_HELP_BUDDY = "GET_APPROVED_HELP_BUDDY";
export const APPROVED_HELP_BUDDY = "APPROVED_HELP_BUDDY";
export const NON_APPROVED_HELP_BUDDY_DETAIL = "NON_APPROVED_HELP_BUDDY_DETAIL";
export const UPDATE_HELP_BUDDY_DETAIL = "UPDATE_HELP_BUDDY_DETAIL";

export const GET_ADDRESS_DETAIL = "GET_ADDRESS_DETAIL";
export const ADDRESS_DETAIL = "ADDRESS_DETAIL";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";

export const GET_CONTACT_DETAIL = "GET_CONTACT_DETAIL";
export const CONTACT_DETAIL = "CONTACT_DETAIL";
export const UPDATE_CONTACT_DETAIL = "UPDATE_CONTACT_DETAIL";
export const DELETE_CONTACT = "DELETE_CONTACT";

export const GET_VEHICLE_DETAIL = "GET_VEHICLE_DETAIL";
export const VEHICLE_DETAIL = "VEHICLE_DETAIL";
export const UPDATE_VEHICLE_DETAIL = "UPDATE_VEHICLE_DETAIL";
export const DELETE_VEHICLE = "DELETE_VEHICLE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_RESPONSE = "CHANGE_PASSWORD_RESPONSE"


export const SELECTED_USER = "SELECTED_USER"

export const VERIFY_SUBSCRIPTION = "VERIFY_SUBSCRIPTION"
export const VERIFY_SUBSCRIPTION_RESPONSE = "VERIFY_SUBSCRIPTION_RESPONSE"


import { call, put, takeEvery } from "@redux-saga/core/effects"
import {
  BOOK_SERVICE,
  CANCEL_SERIVCE_BOOKING,
  CREATE_SERVICE_TYPE,
  DELETE_SERVICE_TYPE,
  GET_ALL_SERVICE_BOOKINGS,
  GET_PRICING_DETAIL,
  GET_SERVICE_TYPE,
  RATE_SERVICE,
  UPDATE_SERVICE_TYPE,
  BOOK_WEBINAR,
  VALIDATE_PROMO_CODE,
  GET_ALL_HEALTH_CARE_BOOKINGS,
  BOOK_ACTIVITY_SERVICE,
  CHECK_BOOK_ACTIVITY_SERVICE,
  BOOK_ANNUAL_SUBSCRIPTION,
} from "./actionTypes"
import {
  serviceType,
  serviceBookingStatus,
  bookingDetail,
  cancelServiceResponse,
  updateServiceResponse,
  createServiceTypeResponse,
  deleteServiceTypeResponse,
  getPricingDetail,
  pricingDetail,
  webinarBookingStatus,
  setPromoCodeValidity,
  getAllHealthCareBookingDetailResponse,
  bookActivityServicesStatus,
  bookAnnualSubscriptionResp,
} from "./actions"
import {
  bookServices,
  bookMultiServices,
  getServiceBookings,
  cancelBookedService,
  createServiceType,
  updateServiceType,
  getPrigincDetail,
  rateService,
  bookWebinar,
  checkPromoCodeValidity,
  createHealthCareServices,
  getHealthCareServices,
  bookActivityService,
  checkBookActivityService,
  bookAnnualSubscription,
} from "../../utils/Apiv2"
import { getServiceType } from "../../utils/Apiv2"

import { sortServicesByDate } from "utils/sortUtil"
import { USER_TYPE } from "constants/common"
import alertCall from "components/Common/CustomAlert"

function* getServiceTypes() {
  try {
    const response = yield call(getServiceType, {})
    yield put(serviceType(response))
  } catch (error) {
    console.error(error)
  }
}

function* createServiceTypeSaga(data) {
  try {
    const resp = yield call(createServiceType, data.payload)
    yield put(createServiceTypeResponse(resp))
  } catch (error) {
    console.error(error)
  }
}

function* updateServiceTypeSaga(data) {
  try {
    const resp = yield call(updateServiceType, data.payload)
    yield put(updateServiceResponse(resp))
  } catch (error) {
    console.error(error)
  }
}

function* deleteServiceTypeSaga(data) {
  try {
    const resp = yield call(updateServiceType, data.payload)
    yield put(deleteServiceTypeResponse(resp))
  } catch (error) {
    console.error(error)
  }
}

function* getPricingDetailSaga(data) {
  try {
    const resp = yield call(getPrigincDetail, data.payload)
    if (resp) {
      yield put(pricingDetail(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* bookService(serviceInfo) {
  try {
    // if (
    //   serviceInfo.payload.isDaily ||
    //   serviceInfo.payload.isSpecificDaysOfWeek
    // ) {
    //   // this is for multi service booking
    //   const resp = yield call(bookMultiServices, serviceInfo.payload)
    //   if (resp) {
    //     yield put(serviceBookingStatus(resp))
    //   }
    // } else
    if (serviceInfo.payload.type !== "HealthCare") {
      // this is for single service booking
      const resp = yield call(bookServices, serviceInfo.payload)
      if (resp) {
        console.log(resp)
        yield put(serviceBookingStatus(resp))
      }
    } else {
      // this is for health care service booking
      const resp = yield call(createHealthCareServices, serviceInfo.payload)
      if (resp) {
        console.log("Debug ::> resp", resp)
        // yield put(serviceBookingStatus(resp))
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* bookActivityServices(data) {
  try {
    const resp = yield call(bookActivityService, data.payload)
    yield put(bookActivityServicesStatus(resp))
  } catch (error) {
    console.error(error)
  }
}

function* checkBookActivityServices(data) {
  try {
    const resp = yield call(checkBookActivityService, data.payload)
    yield put(bookActivityServicesStatus(resp))
  } catch (error) {
    console.error(error)
  }
}

function* getServiceBooking(data) {
  try {
    const resp = yield call(getServiceBookings, data.payload)
    let responsePayload = {
      ongoing: [],
      upcoming: [],
      completed: [],
    }
    if (resp) {
      responsePayload.ongoing = resp.filter(
        item => item.serviceStatus === "STARTED"
      )
      responsePayload.upcoming = resp.filter(
        item =>
          item.serviceStatus === "CREATED" || item.serviceStatus === "ASSIGNED"
      )
      responsePayload.completed = sortServicesByDate(
        resp.filter(item => item.serviceStatus === "COMPLETED"),
        true
      )
    }
    yield put(bookingDetail(responsePayload))
  } catch (error) {
    console.error(error)
  }
}
function* getHealthCareServiceBooking(data) {
  try {
    const resp = yield call(getHealthCareServices)
    if (resp) {
      let services = resp.filter(item => item.commonBookingID !== null)
      if (data.payload.userRole !== USER_TYPE.PARTNER) {
        services = resp.filter(
          item => item.bookingDetail?.userID === data.payload.id
        )
      }
      const dataRes = {
        createdHealthCareService: services?.filter(
          item => item.bookingStatus === "Created"
        ),
        healthBuddyAssignedHealthCareService: services?.filter(
          item => item.bookingStatus === "HelpBuddyAssigned"
        ),
        selectedHealthBuddyHealthCareService: services?.filter(
          item => item.bookingStatus === "HelpBuddySelected"
        ),
      }
      yield put(getAllHealthCareBookingDetailResponse(dataRes))
    }
  } catch (error) {
    console.error(error)
  }
}

function* cancelServiceBooking(data) {
  try {
    const resp = yield call(cancelBookedService, data.payload)
    if (resp) {
      yield put(cancelServiceResponse(resp))
    }
  } catch (error) {
    console.error(first)
  }
}

function* rateServiceSaga(data) {
  try {
    const resp = yield call(rateService, data.payload)
  } catch (error) {
    console.error(error)
  }
}

function* bookWebinarSaga(data) {
  try {
    const resp = yield call(bookWebinar, data.payload)
    const { status, errorMessage, jsonData } = resp
    if (status) {
      yield put(webinarBookingStatus({ id: data.payload.id, status: true }))
    } else {
      alertCall(errorMessage || jsonData, "danger")
      yield put(webinarBookingStatus({ id: data.payload.id, status: false }))
    }
  } catch (error) {
    console.error(error)
    alertCall(error, "danger")
    yield put(webinarBookingStatus({ id: data.payload.id, status: false }))
  }
}

function* checkPromoCode(data) {
  try {
    const resp = yield call(checkPromoCodeValidity, data.payload)

    if (resp) {
      console.log(resp)
      yield put(setPromoCodeValidity(resp?.isValid || false))
    }
  } catch (e) {
    //
  }
}

function* bookAnnualSubscriptionSaga(data) {
  try {
    console.log("Debug ::> data.payload", data.payload)
    const resp = yield call(bookAnnualSubscription, data.payload)
    if (resp) {
      console.log("Debug ::> resp", resp)
      yield put(bookAnnualSubscriptionResp(resp))
    }
  } catch (e) {
    //
    yield put(bookAnnualSubscriptionResp(e))
    console.log("Debug ::> e101", e)
  }
}

function* ServiceSaga() {
  yield takeEvery(GET_SERVICE_TYPE, getServiceTypes)
  yield takeEvery(CREATE_SERVICE_TYPE, createServiceTypeSaga)
  yield takeEvery(UPDATE_SERVICE_TYPE, updateServiceTypeSaga)
  yield takeEvery(DELETE_SERVICE_TYPE, deleteServiceTypeSaga)
  yield takeEvery(GET_PRICING_DETAIL, getPricingDetailSaga)
  yield takeEvery(BOOK_SERVICE, bookService)
  yield takeEvery(GET_ALL_SERVICE_BOOKINGS, getServiceBooking)
  yield takeEvery(GET_ALL_HEALTH_CARE_BOOKINGS, getHealthCareServiceBooking)
  yield takeEvery(CANCEL_SERIVCE_BOOKING, cancelServiceBooking)
  yield takeEvery(RATE_SERVICE, rateServiceSaga)
  yield takeEvery(BOOK_WEBINAR, bookWebinarSaga)
  yield takeEvery(VALIDATE_PROMO_CODE, checkPromoCode)
  yield takeEvery(BOOK_ACTIVITY_SERVICE, bookActivityServices)
  yield takeEvery(CHECK_BOOK_ACTIVITY_SERVICE, checkBookActivityServices)
  yield takeEvery(BOOK_ANNUAL_SUBSCRIPTION, bookAnnualSubscriptionSaga)
}

export default ServiceSaga

import axios from "axios"
import { getRefreshToken } from "./Apiv2"
import cloneDeep from "lodash/cloneDeep"

let refreshTokenPromise // placeholder promise reference for any in-progress token refresh requests

export const requestInterceptor = async config => {
  return config
}

export const successInterceptor = response => {
  return response
}

export const errorInterceptor = async error => {
  if (
    error.response?.status === 401 ||
    error.response?.data?.errorMessage === "Token Expired"
  ) {
    console.log("called refresh token....")
    try {
      if (!refreshTokenPromise) {
        refreshTokenPromise = getRefreshToken().then(refreshToken => {
          localStorage.setItem("token", refreshToken)
          return refreshToken
        })
      }

      if (refreshTokenPromise) {
        return refreshTokenPromise.then(token => {
          const newRequestConfigs = cloneDeep(error.config)
          newRequestConfigs.headers["Authorization"] = `Bearer ${token}`

          return axios.request(newRequestConfigs)
        })
      }

      refreshToken = null
      return axios.request(error.config)
    } catch (e) {
      console.log("catch called after")
      return Promise.reject(e.response?.data)
    }
  } else {
    if (error.response) {
      console.error({
        status: error.response.status,
        data: error.response.data,
      })
    } else if (error.request) {
      console.error(error.request)
    } else {
      console.error("Error", error.message)
    }
    return Promise.reject(error.response?.data)
  }
}

import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next"
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { FormGroup, Modal, Row, Col, Button, Input } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"

import { GENERAL_SERVICE_DURATIONS, TRANSPORTATION_SERVICE_DURATIONS } from 'constants/common';

import { updateAddressDetail, bookServiceFromPackage, bookServiceFromPackageResponse, getPackageBookingsByUserId } from "store/actions";

import FullScreenLoader from "components/Common/FullScreenLoader";
import AddEditAddress from "../Address/AddEditAddress";

const PackageServiceBookingModal = ({
    t,
    isOpen,
    data,
    userId,
    closeModal,
    emptyPackageServiceDetails
}) => {
    const dispatch = useDispatch();

    const [showAddressModal, setAddressModelVisibility] = useState(false);
    const [packageServiceDetails, setPackageServiceDetails] = useState(cloneDeep(emptyPackageServiceDetails))
    const [error, setError] = useState({})
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    const {
        serviceType, isTransportationType, vehicleType, dateTimeFrom, availableHours, duration, description,
        serviceAddressID, minDurationHours, price, discount
    } = useMemo(() => packageServiceDetails, [packageServiceDetails])

    const addressList = useSelector(state => state.Profile.address);
    const vehicleTypeList = useSelector(state => state.CommonReducer.vehicleType);
    const packageServiceBookingStatus = useSelector(state => state.PackagesReducer.packageServiceBookingStatus);

    useEffect(() => {
        return () => {
            setError({})
        }
    }, [])

    useEffect(() => {
        if (data) {
            setPackageServiceDetails(cloneDeep(data))
        } else {
            setPackageServiceDetails(cloneDeep(emptyPackageServiceDetails))
        }
    }, [data])

    useEffect(() => {
        switch (packageServiceBookingStatus) {
            case false:
                setFullScreenLoader(false);
                break;
            default:
                if (packageServiceBookingStatus && !packageServiceBookingStatus.error) {
                    dispatch(getPackageBookingsByUserId())
                    setFullScreenLoader(false);
                    closeModal();
                    bookServiceFromPackageResponse(null)
                }
                break;
        }

    }, [packageServiceBookingStatus, setFullScreenLoader, getPackageBookingsByUserId, closeModal, bookServiceFromPackageResponse])

    const durationOptions = useMemo(() => {
        if (isTransportationType) {
            return TRANSPORTATION_SERVICE_DURATIONS.map(({ label, value }) => ({ label, value: value / 60 })).filter(({ value }) => value <= availableHours && (!minDurationHours || value % minDurationHours === 0))
        }
        return GENERAL_SERVICE_DURATIONS.map(({ label, value }) => ({ label, value: value / 60 })).filter(({ value }) => value <= availableHours && (!minDurationHours || value % minDurationHours === 0))
    }, [isTransportationType, availableHours])

    const resetErrorOnInputChange = useCallback((errorKey) => {
        setError((prevState) => ({ ...prevState, [errorKey]: false }))
    }, [setError])

    const handleDateChange = (e) => {
        if (e.target.value && e.target.value !== "") {
            setPackageServiceDetails((prevState) => ({ ...prevState, dateTimeFrom: moment(e.target.value).toISOString(true).replace('.000+', "+") }))
        }
    }

    const addUpdateAddressDetail = (addressDetails) => {
        dispatch(updateAddressDetail({ ...addressDetails, userID: userId }));
        setAddressModelVisibility(false);
    }

    const handleValidSubmit = (e, v) => {
        e.preventDefault();
        const formData = { ...v }
        delete formData.dateTimeFrom

        const packageServiceBookingData = cloneDeep({ ...packageServiceDetails, ...formData });

        const { dateTimeFrom, serviceAddressID } = packageServiceBookingData

        const errors = cloneDeep(error)
        let hasError = false;

        if (!dateTimeFrom) {
            hasError = true;
            errors['dateTimeFrom'] = "Select proper date and time of service";
        } else {
            const dateTimeFromDate = new Date(dateTimeFrom)

            if (dateTimeFromDate.getTime() < new Date().getTime()) {
                hasError = true;
                errors['dateTimeFrom'] = "Service date can not be past date.";
            } else if (dateTimeFromDate.getHours() < 8 || dateTimeFromDate.getHours() > 20) {
                hasError = true;
                errors['dateTimeFrom'] = "Service booking time is 8am to 8pm.";
            } else {
                var months = dateTimeFromDate.getMonth() - new Date().getMonth()
                    + (12 * (dateTimeFromDate.getFullYear() - new Date().getFullYear()));
                if (dateTimeFromDate.getDate() < new Date().getDate()) {
                    months--;
                }
                if (months !== 0) {
                    hasError = true;
                    errors['dateTimeFrom'] = "Service start date should be within 1 month from today.";
                }
            }
        }

        if (serviceAddressID === "-1") {
            hasError = true
            errors["serviceAddressID"] = "This field is invalid"
        }

        setError(errors)

        if (!hasError) {
            const {
                userID, description, serviceID, serviceStatus, vehicleType, serviceMode,
                vehicleServiceEstimates, paymentMode, duration, packageBookingId
            } = packageServiceBookingData

            const total = price * duration
            const serviceEstimates = total - (total * ((discount || 0) / 100))

            const packageServiceBookingDataObject = {
                userID, serviceAddressID, description, serviceID, serviceStatus,
                vehicleType, serviceMode, serviceEstimates, vehicleServiceEstimates, dateTimeFrom,
                paymentMode,
                // send duration in minutes
                duration: duration * 60, 
                // packageBookingId,
                bookingCreatedBy: "PACKAGE",
                bookingID: packageBookingId
            }

            setFullScreenLoader(true);
            dispatch(bookServiceFromPackage(packageServiceBookingDataObject))
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            centered={true}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                    Package Service Booking
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                >
                </button>
            </div>

            <AvForm onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
            }}>
                <div className="modal-body">
                    <Row>
                        <Col xs={12} sm={12} className="mb-4">
                            <FormGroup>
                                <Input
                                    id="name"
                                    name="name"
                                    label={t("Name")}
                                    type="text"
                                    value={serviceType}
                                    disabled
                                />
                            </FormGroup>
                        </Col>

                        {isTransportationType && (
                            <Col xs={12} sm={12} className="mb-4">
                                <FormGroup>
                                    <AvField type="select" name="vehicleType" id="vehicleType" label="Vehicle Type" value={vehicleType}
                                        className={`form-control ${error && error["vehicleType"] &&
                                            "is-invalid"}`}
                                        required
                                    >
                                        <option value={-1} key="vehicle_type_default" disabled>--Select--</option>
                                        {vehicleTypeList && vehicleTypeList.map(item => {
                                            return (
                                                <option value={item.label} key={item.id}>{item.label}</option>
                                            )
                                        })}
                                    </AvField>
                                </FormGroup>
                            </Col>
                        )}

                        <Col xs={12} sm={12} className="mb-4">
                            <FormGroup>
                                <AvField label="Select date and time of service" type="datetime-local" name="dateTimeFrom" id="dateTimeFrom"
                                    onChange={(e) => {
                                        resetErrorOnInputChange("dateTimeFrom")
                                        handleDateChange(e)
                                    }}
                                    className={`form-control ${error && error["dateTimeFrom"] &&
                                        "is-invalid"}`} required
                                    value={dateTimeFrom ? moment.utc(dateTimeFrom).local().format('YYYY-MM-DDTHH:mm:ss') : ''}
                                ></AvField>
                                {error && error["dateTimeFrom"] && (
                                    <label style={{ "color": "red", "fontWeight": "100" }}>{error["dateTimeFrom"]}</label>
                                )}
                            </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} className="mb-4">
                            <FormGroup>
                                <AvField
                                    type="select"
                                    id="duration"
                                    name="duration"
                                    label={t("Duration of service")}
                                    value={duration}
                                    required
                                >
                                    <option value="-1" key="duration_default" disabled>Select</option>
                                    {durationOptions && durationOptions.map(({ label, value }) => <option value={value} key={value}>{label}</option>)}
                                </AvField>
                            </FormGroup>
                        </Col>

                        <Col xs={addressList && addressList.length == 0 ? 9 : 12} sm={addressList && addressList.length == 0 ? 9 : 12} className="mb-4">
                            <FormGroup>
                                <AvField
                                    type="select"
                                    name="serviceAddressID"
                                    id="serviceAddressID"
                                    label="Select address"
                                    value={serviceAddressID}
                                    className={`form-control ${error && error["serviceAddressID"] &&
                                        "is-invalid"}`}
                                    required
                                    onChange={() => {
                                        resetErrorOnInputChange("serviceAddressID")
                                    }}
                                >
                                    <option value="-1" key="address_default" disabled>--Select--</option>
                                    {addressList && addressList.map(({ id, tags, address, landmark, city, state }) => <option value={id} key={id}>{tags} - {address}, {landmark}, {city}, {state}</option>)}
                                </AvField>
                                {error && error["serviceAddressID"] && (
                                    <label style={{ "color": "red", "fontWeight": "100" }}>{error["serviceAddressID"]}</label>
                                )}
                            </FormGroup>
                        </Col>

                        {addressList && addressList.length === 0 && (
                            <Col xs={3} sm={3} className="mb-4">
                                <FormGroup>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        style={{ "marginTop": "26px" }}
                                        onClick={() => { setAddressModelVisibility(true) }}
                                    >
                                        {t("Add New")}
                                    </button>
                                </FormGroup>
                            </Col>
                        )}

                        <Col xs={12} sm={12} className="mb-4">
                            <FormGroup>
                                <AvField
                                    id="description"
                                    name="description"
                                    label={t("Description")}
                                    className={`form-control ${error && error["description"] &&
                                        "is-invalid"}`}
                                    placeholder={t("Description for service")}
                                    type="text"
                                    value={description}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="submit"
                        className="btn btn-primary px-4"
                    >
                        Book Service
                    </button>
                </div>
            </AvForm>
            <FullScreenLoader
                isOpen={fullScreenLoader}
            >
            </FullScreenLoader>

            <AddEditAddress
                isOpen={showAddressModal}
                currentAddressObject={null}
                onCancelClick={() => { setAddressModelVisibility(false) }}
                onSubmitClick={(addressDetail) => { addUpdateAddressDetail(addressDetail) }}
            />
        </Modal>
    )
}


PackageServiceBookingModal.propTypes = {
    t: PropTypes.any,
    isOpen: PropTypes.any,
    data: PropTypes.any,
    userId: PropTypes.any,
    closeModal: PropTypes.any,
    emptyPackageServiceDetails: PropTypes.any
}

export default withTranslation()(PackageServiceBookingModal);

import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types'

const DurationResponse = ({
    durationSelectorsRef,
    title,
    options,
    updateUserMessage,
    setUserInputBtnDisabled,
    widgetStyle
}) => {
    const {
        primaryBGC,
        primaryTextColor,
        botIcon
    } = useMemo(() => widgetStyle, [widgetStyle])

    const [dropdownValue, setDropdownValue] = useState("")

    const durationChangeHandle = useCallback((value) => {
        const valueNew = value.target.value
        setDropdownValue(valueNew)
        updateUserMessage(valueNew)
    }, [setDropdownValue, updateUserMessage])

    useEffect(() => {
        setUserInputBtnDisabled(true)

        return (() => {
            setUserInputBtnDisabled(false)
            setDropdownValue("")
        })
    }, [setUserInputBtnDisabled])

    return (
        <>
            <div className="bot-response">
                <div>
                    <img src={botIcon} className="bot-profile-photo" alt="elie" />
                </div>

                <div className="bot-msg-div">
                    <div className="bot-msg" style={{ background: primaryBGC, color: primaryTextColor }}>
                        {/* <p style={{ color: primaryTextColor }}>
              {title}
            </p> */}

                        <div dangerouslySetInnerHTML={{ __html: title }} style={{ color: primaryTextColor }}>
                            {/* {title} */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="durationSelectorsRef" ref={durationSelectorsRef}>
                <div className="selection-response">
                    <select className="form-select form-select-sm" aria-label="Default select example" value={dropdownValue} onChange={durationChangeHandle}>
                        {options.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
                    </select>
                </div>
            </div>
        </>
    )
}

DurationResponse.propTypes = {
    durationSelectorsRef: PropTypes.any,
    title: PropTypes.string,
    options: PropTypes.any,
    updateUserMessage: PropTypes.func,
    setUserInputBtnDisabled: PropTypes.func,
    widgetStyle: PropTypes.any
}

export default DurationResponse

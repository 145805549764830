import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  CHANGE_PASSWORD_RESPONSE,
  RESET_PROFILE_FLAG,
  PROFILE_DETAIL,
  ADDRESS_DETAIL,
  CONTACT_DETAIL,
  VEHICLE_DETAIL,
  SENIOR_CITIZEN_DETAIL,
  HELP_BUDDY_DETAIL,
  NON_APPROVED_HELP_BUDDY_DETAIL,
  APPROVED_HELP_BUDDY,
  SELECTED_USER,
  UPDATE_PROFILE_STATUS,
  VERIFY_SUBSCRIPTION_RESPONSE,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  userProfile: null,
  address: [],
  contactDetail: [],
  vehicleDetail: [],
  nonApprovedHelpBuddy: [],
  approvedHelpBuddy: [],
  seniorCitizenDetail: null,
  helpBuddyDetail: null,
  changePasswordResponse: null,
  selectedUser: null,
  updateProfileStatus: null,
  goldSubVerification: null,
}

const Profile = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case PROFILE_DETAIL:
      state = { ...state, userProfile: payload.user }
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    case SENIOR_CITIZEN_DETAIL:
      state = { ...state, seniorCitizenDetail: payload }
      break
    case HELP_BUDDY_DETAIL:
      state = { ...state, helpBuddyDetail: payload }
      break
    case NON_APPROVED_HELP_BUDDY_DETAIL:
      state = { ...state, nonApprovedHelpBuddy: payload }
      break
    case APPROVED_HELP_BUDDY:
      state = { ...state, approvedHelpBuddy: payload }
      break
    case ADDRESS_DETAIL:
      state = { ...state, address: payload }
      break
    case CONTACT_DETAIL:
      state = { ...state, contactDetail: payload }
      break
    case VEHICLE_DETAIL:
      state = { ...state, vehicleDetail: payload }
      break
    case UPDATE_PROFILE_STATUS:
      state = { ...state, updateProfileStatus: payload }
      break
    case CHANGE_PASSWORD_RESPONSE:
      state = { ...state, changePasswordResponse: payload }
    case SELECTED_USER:
      state = { ...state, selectedUser: payload }
    case VERIFY_SUBSCRIPTION_RESPONSE:
      state = { ...state, goldSubVerification: payload }
    default:
      state = { ...state }
      break
  }
  return state
}

export default Profile

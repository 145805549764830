export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"

export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const GENERATE_OTP = "GENERATE_OTP"
export const VERIFY_OTP = "VERIFY_OTP"
export const OTP_VERIFICATION_STATUS = "OTP_VERIFICATION_STATUS"
export const VERIFY_MOBILE_NUMBER = "VERIFY_MOBILE_NUMBER"
export const MOBILE_NUMBER_VERIFICATION_STATUS =
  "MOBILE_NUMBER_VERIFICATION_STATUS"

export const LOGIN_WITH_TOKEN = "LOGIN_WITH_TOKEN"

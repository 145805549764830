import React from "react";
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import {
    Modal
} from "reactstrap";

const ConfirmModal = ({
    t,
    isOpen,
    titleText,
    bodyText,
    onNoClick,
    onYesClick,
    data
}) => {
    return (
        <Modal
            isOpen={isOpen}
            centered={true}
        >
            {
                titleText && (
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            {titleText}
                        </h5>
                    </div>
                )
            }

            {
                bodyText && (
                    <div className="modal-body">
                        {bodyText}
                    </div>
                )
            }

            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        onNoClick();
                    }}
                    className="btn btn-secondary px-4"
                    data-dismiss="modal"
                >
                    {t("No")}
                </button>

                <button
                    type="button"
                    onClick={() => {
                        onYesClick()
                    }}
                    className="btn btn-primary px-4"
                >
                    {t("Yes")}
                </button>
            </div>
        </Modal>
    )
}

ConfirmModal.propTypes = {
    t: PropTypes.any,
    isOpen: PropTypes.bool,
    titleText: PropTypes.string,
    bodyText: PropTypes.string,
    onNoClick: PropTypes.func,
    onYesClick: PropTypes.func,
    data: PropTypes.any
}

export default withTranslation()(ConfirmModal)

import React, { useState } from "react";
import {
    Container
} from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Booking from '../../components/Happy60/bookings'

const Registeration = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Bookings | Happy60Plus</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Home" breadcrumbItem="Bookings" />

                    <Booking></Booking>

                </Container>
            </div >
        </React.Fragment >
    );
}

export default Registeration;
const getDistance = (maps, origin, desination) => {
    if (window.google) {
        return new Promise((res, rej) => {
            var origin = origin || { lat: 12.9716, lng: 77.5946 };//bangalore
            var destination = desination || { lat: 17.3850, lng: 78.4867 };//hyderabad

            var service = new maps.DistanceMatrixService;
            service.getDistanceMatrix({
                origins: [origin],
                destinations: [destination],
                travelMode: 'DRIVING',
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false
            }, function (response, status) {
                console.log(response);
                if (status !== 'OK') {
                    alert('Error was: ' + status);
                    rej();
                } else {
                    if (response?.rows[0]?.elements[0].status == "ZERO_RESULTS") {
                        res(null);
                    }
                    try {
                        const distance = response?.rows[0]?.elements[0].distance.text;
                        res(distance);
                    } catch (e) {
                        rej(e)
                    }

                }
            });
        })

    }

}

export default getDistance;
import { all, fork } from "redux-saga/effects"

//public
import RegisterSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import AssignmentSaga from "./assignments/saga"
import ServiceSaga from "./services/saga"
import CommonSaga from "./common/saga"
import LayoutSaga from "./layout/saga"
import Users from "./users/saga"
import dashboardSaga from "./dashboard/saga"
import PaymentSaga from "./payment/saga"
import OnboardingSaga from "./onboarding/saga"
import PackagesSaga from "./packages/saga"
import EnquirySaga from "./enquiry/saga"
import HomePageServicesSaga from "./home-page-service/saga"
import TripSaga from "./trip/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(RegisterSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(AssignmentSaga),
    fork(ServiceSaga),
    fork(CommonSaga),
    fork(Users),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(PaymentSaga),
    fork(OnboardingSaga),
    fork(PackagesSaga),
    fork(EnquirySaga),
    fork(HomePageServicesSaga),
    fork(TripSaga),
  ])
}

import React, { useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Modal
} from "reactstrap"
import GenericModal from "./GenericModal";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import SocialMedia from '../Onboarding-Forms/SocialMedia'
import KycDetail from '../Onboarding-Forms/KycDetail';
import EQTest from "../Onboarding-Forms/EQTest";
import { useDispatch, useSelector } from "react-redux";
import { getBehaviouralAnalysisByUserId, getEmotionalQuotientByUserId, updateProfileDetail, getNonApprovedHelpBuddyDetail, getApprovedHelpBuddyDetail, ApprovedHelpBuddyDetail } from 'store/actions';
import { USER_TYPE } from 'constants/common';

const PendingApproval = () => {
    const pendingApprovalHelpBuddy = useSelector(state => state.Profile.nonApprovedHelpBuddy);
    const socialBehaviour = useSelector(state => state.OnboardReducer.behaviouranalysisByUserId);
    const emotionalQuotientByUserId = useSelector(state => state.OnboardReducer.emotionalQuotientByUserId);
    const approvedHelpBuddyDetail = useSelector(state => state.Profile.approvedHelpBuddy);
    const dispatch = useDispatch();

    const onAccept = (el) => {
        dispatch(updateProfileDetail({ ...el.helpBuddy, verified: true, type: USER_TYPE.SERVICE_PROVIDER }));
        setTimeout(() => {
            dispatch(getNonApprovedHelpBuddyDetail());
            // dispatch(getApprovedHelpBuddyDetail());
            dispatch(ApprovedHelpBuddyDetail([...approvedHelpBuddyDetail, { ...el, serviceCompleted: "0", revenueGenerated: "0", rating: "0" }]))
        }, 1000);
    }

    const onReject = (buddyDetail) => {
        dispatch(updateProfileDetail({ ...buddyDetail, verified: false, type: USER_TYPE.SERVICE_PROVIDER }))
    }

    function getBehaviourAnalysis() {
        let number = Math.random();
        if (Math.floor(number * 4 + 1) < 2) {
            return 'Bad';
        }
        else if (Math.floor(number * 4 + 1) == 2) {
            return 'Moderate';
        }
        else if (Math.floor(number * 4 + 1) === 3) {
            return 'Good';
        }
        else if (Math.floor(number * 4 + 1) > 3) {
            return 'very Good'
        }
    }
    return (
        <>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <div className="table-responsive">
                                <h4 className="card-title">Help Buddies</h4>
                                <p className="card-title-desc">
                                    Here is the list of <code>Help buddies</code> who have requested for
                                    onboarding.
                                </p>

                                <div className="table-responsive">
                                    <Table className="table table-striped">
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th>Name</Th>
                                                <Th>Contact Number</Th>
                                                <Th>KYC Verification</Th>
                                                <Th>Behavioral Analysis</Th>
                                                <Th>EQ Test</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {pendingApprovalHelpBuddy.map((el, index) => (
                                                <Tr key={el.id} className={`table-${el.color}`} onClick={() => {
                                                    dispatch(getBehaviouralAnalysisByUserId(el.id))
                                                    dispatch(getEmotionalQuotientByUserId(el.id))
                                                }}>
                                                    <Th scope="row">{index + 1}</Th>
                                                    <Td className="center">{el.user.firstName} {el.user.lastName}</Td>
                                                    <Td className="center">{el.user.mobileNumber}</Td>
                                                    <Td className="center">
                                                        {el?.verified ? "Verified" : "Pending"}
                                                        <GenericModal title='KYC Detail' btn={<i className="bx bxs-message-square-detail"></i>}>
                                                            <KycDetail data={[]}></KycDetail>
                                                        </GenericModal>
                                                    </Td>
                                                    <Td className="center">
                                                        {getBehaviourAnalysis()}
                                                        <GenericModal title='Behavioral Analysis' btn={<i className="bx bxs-message-square-detail"></i>}>
                                                            <SocialMedia showResult={true} data={socialBehaviour || []}></SocialMedia>
                                                        </GenericModal>
                                                    </Td>
                                                    <Td className="center">{Math.floor(Math.random() * 31 + 60)}/100
                                                        <GenericModal title='EQ Test Scores' btn={<i className="bx bxs-message-square-detail"></i>}>
                                                            <EQTest data={emotionalQuotientByUserId}></EQTest>
                                                        </GenericModal>
                                                    </Td>
                                                    <Td className="center">
                                                        <ul className="list-inline mb-0 font-size-20">
                                                            <li className="list-inline-item">
                                                                <i className="bx bx-check text-success" onClick={() => onAccept(el)}></i>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <i className="bx bx-x text-danger" onClick={() => onReject(el.helpBuddy)}></i>
                                                            </li>
                                                        </ul>
                                                        {/* <button onClick={() => onAccept(el)} type="button" className="btn btn-success btn-sm"><i className="bx bx-check"></i></button>
                                                        &nbsp; <button onClick={() => onReject(el.helpBuddy)} type="button" className="btn btn-danger btn-sm"><i className="bx bx-x"></i></button> */}
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </>
    )
}

export default PendingApproval;
import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Collapse } from "reactstrap"
import classnames from "classnames"
import CompletedAssignments from "./Assignment/CompletedAssignments"
import OngoingAssignment from "./Assignment/OngoingAssignments"
import UpcomingAssignment from "./Assignment/UpcomingAssignments"
import AllAssignments from "./Assignment/AllAssignments"
import IncompleteAssignments from "./Assignment/IncompletedAssignments"
import { useDispatch } from "react-redux"
import { getUserDetail } from "utils/userUtil"
import { getAllUser } from "store/users/actions"
import { getApprovedHelpBuddyDetail } from "store/actions"

const AssignmentMgmt = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    // dispatch(getAllUser());
    dispatch(getApprovedHelpBuddyDetail())
  }, [dispatch])
  // dispatch(getAllAssignments());
  // dispatch(getAllAssignedAssignemnts());
  // dispatch(getOngoingAssignments());
  // dispatch(getAllCompletedAssignments());
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const [col4, setcol4] = useState(false)
  const [col5, setcol5] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
    setcol4(false)
    setcol5(false)
  }
  const t_col2 = () => {
    setcol1(false)
    setcol2(!col2)
    setcol3(false)
    setcol4(false)
    setcol5(false)
  }

  const t_col3 = () => {
    setcol1(false)
    setcol2(false)
    setcol3(!col3)
    setcol4(false)
    setcol5(false)
  }

  const t_col4 = () => {
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(!col4)
    setcol5(false)
  }

  const t_col5 = () => {
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(false)
    setcol5(!col5)
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <div className="mt-4">
                    {/* <CardTitle className="h4">Flush</CardTitle>
                            <p className="card-title-desc">
                               Manage all your bookings from here
                            </p> */}

                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col1 }
                            )}
                            type="button"
                            onClick={t_col1}
                            style={{ cursor: "pointer" }}
                          >
                            All
                          </button>
                        </h2>

                        <Collapse isOpen={col1} className="accordion-collapse">
                          <div
                            className="accordion-body"
                            style={{ overflow: "auto" }}
                          >
                            <AllAssignments userRole={getUserDetail("type")} />
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col2 }
                            )}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: "pointer" }}
                          >
                            Ongoing
                          </button>
                        </h2>

                        <Collapse isOpen={col2} className="accordion-collapse">
                          <div
                            className="accordion-body"
                            style={{ overflow: "auto" }}
                          >
                            <OngoingAssignment
                              userRole={getUserDetail("type")}
                            />
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushTwo">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col3 }
                            )}
                            type="button"
                            onClick={t_col3}
                            style={{ cursor: "pointer" }}
                          >
                            Upcoming
                          </button>
                        </h2>

                        <Collapse isOpen={col3} className="accordion-collapse">
                          <div
                            className="accordion-body"
                            style={{ overflow: "auto" }}
                          >
                            <UpcomingAssignment
                              userRole={getUserDetail("type")}
                            />
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushThree">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col4 }
                            )}
                            type="button"
                            onClick={t_col4}
                            style={{ cursor: "pointer" }}
                          >
                            Completed
                          </button>
                        </h2>

                        <Collapse isOpen={col4} className="accordion-collapse">
                          <div
                            className="accordion-body"
                            style={{ overflow: "auto" }}
                          >
                            <CompletedAssignments
                              userRole={getUserDetail("type")}
                            />
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushThree">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col5 }
                            )}
                            type="button"
                            onClick={t_col5}
                            style={{ cursor: "pointer" }}
                          >
                            InCompleted/Unattended
                          </button>
                        </h2>

                        <Collapse isOpen={col5} className="accordion-collapse">
                          <div
                            className="accordion-body"
                            style={{ overflow: "auto" }}
                          >
                            <IncompleteAssignments
                              userRole={getUserDetail("type")}
                            />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AssignmentMgmt

import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Card, CardHeader, CardBody, CardText, Col, Row } from "reactstrap"

const TripAndToursCard = ({
  title,
  startDate,
  endDate,
  imageUrl,
  id,
  key,
  pdfUrl,
  openKnowMore,
  openEnquiryModal,
}) => {
  return (
    <Col lg={4} sm={6} xs={12} className="mb-3">
      <Card
        key={key}
        outline
        className="border height-adusted-card"
        color="primary"
      >
        <CardHeader className="bg-transparent">
          <h5 className="my-0 text-primary">
            <i className="mdi mdi-bullseye-arrow me-3" />
            {title}
          </h5>
        </CardHeader>
        <CardBody className="pt-2 d-flex flex-column justify-content-between">
          <CardText>
            <Row>
              <Col xs={12} sm={12} className="mb-1">
                <img src={imageUrl} className="border rounded w-100" />
              </Col>
            </Row>
          </CardText>

          <Row>
            <Col>
              <div className="btn-group btn-group-example" role="group">
                <button
                  type="button"
                  className="btn btn-outline-primary w-sm"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openKnowMore({ pdfUrl })
                  }}
                >
                  Know More
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary w-sm"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openEnquiryModal({ id, title })
                  }}
                >
                  Enquiry
                </button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

TripAndToursCard.propTypes = {
  title: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  imageUrl: PropTypes.any,
  id: PropTypes.any,
  key: PropTypes.any,
  pdfUrl: PropTypes.any,
  openKnowMore: PropTypes.any,
  openEnquiryModal: PropTypes.any,
}

export default withTranslation()(TripAndToursCard)

import React from "react";
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import { Modal, Spinner } from 'reactstrap';

const FullScreenLoader = ({
    t,
    isOpen
}) => {
    return (
        <Modal
            isOpen={isOpen}
            centered={true}
        >
            <div className="modal-body" style={{"display":"flex", "justifyContent":"center", "alignItems":"center","minHeight":"200px"}}>
                <Spinner className="ms-12" color="primary" />
            </div>
            <div className="modal-footer" style={{'justifyContent':'center'}}>
                {t('Please wait while we are booking service')}
            </div>
        </Modal>
    )
}

FullScreenLoader.propTypes = {
    t: PropTypes.any,
    isOpen: PropTypes.bool
}

export default withTranslation()(FullScreenLoader);
import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink, TabContent, TabPane, Modal } from 'reactstrap'

// ** Icons Imports
// import { User, Lock, Bookmark, Bell, Link } from 'react-feather'
import PropTypes from 'prop-types';
import { USER_TYPE } from "constants/common";
import { useDispatch } from "react-redux";
import { getAllAssignedAssignemnts, getAllCompletedAssignments, getAllHealthCareBookingDetail, getAllIncompletedAssignments, getAllServiceBookingDetail, getOngoingAssignments, getProfileDetail, selectProfile } from "store/actions";
import ServiceBooking from "./modalComponent/serviceBooking";
import UserDetail from "./modalComponent/userDetail";
import Payment from "./modalComponent/payment";
import Passbook from "./modalComponent/passbook";
import Reviews from "./modalComponent/review";
import Assignments from "./modalComponent/assignment";

const UserDetailModal = ({
    t,
    isOpen,
    data,
    closeModal
}) => {

    const dispatch = useDispatch();

    const [active, toggleTab] = useState('1');


    useEffect(() => {
        if (data.type === USER_TYPE.SENIOR_CITIZEN) {
            // toggleTab('2');
            dispatch(selectProfile(data));
            dispatch(getAllServiceBookingDetail(data.id))
            dispatch(getAllHealthCareBookingDetail(data.id))
        } else if (data.type === USER_TYPE.SERVICE_PROVIDER) {
            // toggleTab('6');
            dispatch(getAllCompletedAssignments(data.id));
            dispatch(getOngoingAssignments(data.id))
            dispatch(getAllAssignedAssignemnts(data.id));
            dispatch(getAllIncompletedAssignments());
        }
    }, [data])

    return (
        <Modal
            isOpen={isOpen}
            centered={true}
            className="modal-dialog-centered modal-xl">
            <div className="modal-header" style={{ "borderBottom": "none" }}>
                {/* <h5 className="modal-title mt-0">
                    {t('User Detail')}
                </h5> */}
                <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                >
                </button>
            </div>
            <Fragment>
                <Nav tabs className='mb-2'>
                    <NavItem>
                        <NavLink active={active === '1'} onClick={() => toggleTab('1')}>
                            <span>User Detail</span>
                        </NavLink>
                    </NavItem>
                    {data.type === USER_TYPE.SENIOR_CITIZEN && (
                        <>
                            <NavItem>
                                <NavLink active={active === '2'} onClick={() => toggleTab('2')}>
                                    {/* <Lock className='font-medium-3 me-50' /> */}
                                    <span>Service Booking</span>
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink active={active === '3'} onClick={() => toggleTab('3')}>
                                    <span>Payments</span>
                                </NavLink>
                            </NavItem> */}
                        </>
                    )}
                    {data.type === USER_TYPE.SERVICE_PROVIDER && (
                        <>
                            <NavItem>
                                <NavLink active={active === '6'} onClick={() => toggleTab('6')}>
                                    {/* <Lock className='font-medium-3 me-50' /> */}
                                    <span>Assignments</span>
                                </NavLink>
                            </NavItem>
                        </>
                    )}

                    {/* <NavItem>
                        <NavLink active={active === '4'} onClick={() => toggleTab('4')}>
                            <span>Passbook</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={active === '5'} onClick={() => toggleTab('5')}>
                            <span>Review</span>
                        </NavLink>
                    </NavItem> */}
                </Nav>
                <TabContent activeTab={active}>
                    <TabPane tabId='1'>
                        <UserDetail profile={data}/>
                    </TabPane>
                    <TabPane tabId='2'>
                        <ServiceBooking />
                    </TabPane>
                    <TabPane tabId='3'>
                        <Payment />
                        {/* <BillingPlanTab /> */}
                    </TabPane>
                    <TabPane tabId='4'>
                        <Passbook />
                        {/* <Notifications /> */}
                    </TabPane>
                    <TabPane tabId='5'>
                        <Reviews />
                        {/* <Connections /> */}
                    </TabPane>
                    <TabPane tabId='6'>
                        <Assignments />
                        {/* <Connections /> */}
                    </TabPane>
                </TabContent>
            </Fragment>

        </Modal >
    )
}

UserDetailModal.propTypes = {
    t: PropTypes.any,
    isOpen: PropTypes.any,
    data: PropTypes.any,
    closeModal: PropTypes.any
}

export default withTranslation()(UserDetailModal);
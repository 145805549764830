import React from "react"
import PropTypes from 'prop-types'

const ImageResponse = ({ image }) => {
  return (
    <div className="bot-response">
      <div className="bot-msg-div">
        <img src={image} alt="img" className="bot-msg-img w-100 rounded" />
      </div>
    </div>
  )
}

ImageResponse.propTypes = {
  image: PropTypes.any
}

export default ImageResponse

import { CLEAR_PAYMENT_STATUS, MAKE_PAYMENT, PAYMENT_STATUS, PAYMENT_INFO_BY_SERVICE_ID, LOG_PAYMENT_DETAIL_RESPONSE, WALLET_BALANCE_BY_USER_ID, ALL_WALLET_BALANCE, UPDATE_WALLET_BALANCE_RESP, GET_PAYMENT_DETAIL_RESP } from './actionTypes';

const initialState = {
    paymentStatus: null,
    orderDetail: null,
    paymentDetailByServiceBookingId: null,
    logPaymentDetailResp: null,
    walletBalance: null,
    allWalletBalance: null,
    updateWalletBalanceResp: null,
    annualSubPaymentDetail: null
}

const PaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_STATUS:
            state = { ...state, paymentStatus: action.payload }
            break;
        case MAKE_PAYMENT:
            state = { ...state, orderDetail: action.payload }
            break;
        case CLEAR_PAYMENT_STATUS:
            state = { ...state, paymentStatus: null, logPaymentDetailResp: null }
            break;
        case PAYMENT_INFO_BY_SERVICE_ID:
            state = { ...state, paymentDetailByServiceBookingId: action.payload }
            break;
        case LOG_PAYMENT_DETAIL_RESPONSE:
            state = { ...state, logPaymentDetailResp: action.payload }
            break;
        case WALLET_BALANCE_BY_USER_ID:
            state = { ...state, walletBalance: action.payload }
            break;
        case GET_PAYMENT_DETAIL_RESP:
            state = { ...state, annualSubPaymentDetail: action.payload }
            break;
        case ALL_WALLET_BALANCE:
            state = { ...state, allWalletBalance: action.payload }
            break;
        case UPDATE_WALLET_BALANCE_RESP:
            state = { ...state, updateWalletBalanceResp: action.payload }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;

}

export default PaymentReducer;
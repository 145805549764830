import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Collapse } from "reactstrap"
import classnames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import CreatedServices from "../../components/Happy60/services/health-care/CreatedServices"
import { getAllHealthCareBookingDetail } from "store/actions"
import AssignedHealthBuddy from "./services/health-care/AssignedHealthBuddy"
import SelectedHealthBuddy from "./services/health-care/SelectedHealthBuddy"
import { USER_TYPE } from "constants/common"

const HealthCareBooking = () => {
  const dispatch = useDispatch()
  const userDetail = useSelector(state => state.Profile.userProfile)
  const [col1, setcol1] = useState(false)
  const [col2, setcol2] = useState(true)
  const [col3, setcol3] = useState(false)

  useEffect(() => {
    if (userDetail?.id) {
      dispatch(
        getAllHealthCareBookingDetail({
          id: userDetail.id,
          userRole: userDetail.type,
        })
      )
    }
  }, [userDetail])

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
  }

  const t_col2 = () => {
    setcol1(false)
    setcol2(!col2)
    setcol3(false)
  }

  const t_col3 = () => {
    setcol1(false)
    setcol3(!col3)
    setcol2(false)
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">Health Care Booking</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <div className="mt-4">
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      {/* {userDetail.type === USER_TYPE.SENIOR_CITIZEN && (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFlushOne">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium",
                                { collapsed: !col1 }
                              )}
                              type="button"
                              onClick={t_col1}
                              style={{ cursor: "pointer" }}
                            >
                              Created
                            </button>
                          </h2>

                          <Collapse
                            isOpen={col1}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <CreatedServices />
                            </div>
                          </Collapse>
                        </div>
                      )} */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushTwo">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col2 }
                            )}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: "pointer" }}
                          >
                            {userDetail.type === USER_TYPE.SENIOR_CITIZEN
                              ? "Created"
                              : "New"}
                          </button>
                        </h2>

                        <Collapse isOpen={col2} className="accordion-collapse">
                          <div
                            className="accordion-body"
                            style={{ overflow: "auto" }}
                          >
                            <AssignedHealthBuddy />
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushThree">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col3 }
                            )}
                            type="button"
                            onClick={t_col3}
                            style={{ cursor: "pointer" }}
                          >
                            Health Buddy Selected
                          </button>
                        </h2>

                        <Collapse isOpen={col3} className="accordion-collapse">
                          <div className="accordion-body">
                            <SelectedHealthBuddy />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default HealthCareBooking

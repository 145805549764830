import React, { useEffect, useState } from "react"
import { Tooltip } from "reactstrap"
import PropTypes from "prop-types"
import moment from "moment"

const EnquiryTooltipItem = ({ id, item }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const [itemData, setItemData] = useState(item)

  useEffect(() => {
    setItemData({
      ...item,
    })
  }, [item])

  const toggle = () => {
    setTooltipOpen(!tooltipOpen)
  }

  return (
    <span>
      &nbsp;
      <i className="bx bx-info-circle text-primary" id={"Tooltip-" + id}></i>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={"Tooltip-" + id}
        toggle={toggle}
      >
        {!itemData.comment || itemData.comment === ""
          ? "N/A"
          : itemData.comment}
        <br />
        {itemData.updatedDate
          ? moment(itemData.updatedDate).format("DD-MM-YYYY hh:mm A")
          : "N/A"}
      </Tooltip>
    </span>
  )
}

EnquiryTooltipItem.propTypes = {
  id: PropTypes.any,
  item: PropTypes.any,
}

export default EnquiryTooltipItem

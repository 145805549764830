import React from "react"
import PropTypes from "prop-types"

import "./OverlayLoader.scss"

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const OverlayLoader = ({ isLoading }) => {
    if (isLoading) {
        return <div className="overlay-loader-wrapper"><LoadingSpinner /></div>
    }
    return null
}

OverlayLoader.propTypes = {
    isLoading: PropTypes.bool
}

export default OverlayLoader

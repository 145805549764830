import React from 'react';
import { useDispatch } from 'react-redux';
import { getApprovedHelpBuddyDetail, getNonApprovedHelpBuddyDetail } from 'store/actions';
import ApprovedHelpBuddies from './Approval/ApprovedHelpBuddies';
import PendingApproval from './Approval/PendingApproval';

const Approvals = () => {
    const dispatch = useDispatch();
    dispatch(getNonApprovedHelpBuddyDetail());
    dispatch(getApprovedHelpBuddyDetail());

    return (
        <>
            <PendingApproval></PendingApproval>
            <ApprovedHelpBuddies></ApprovedHelpBuddies>
        </>
    )
}

export default Approvals;
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  Modal,
  Row,
  Col,
  Alert,
  Label,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"

import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"

import countryCode from "utils/countryCode"

import { USER_TYPE } from "constants/common"
import { createPartner } from "store/actions"

const CreateUserModal = ({ t, isOpen, closeModal, userType }) => {
  const dispatch = useDispatch()
  const userProfile = useSelector(state => state.Profile.userProfile)
  const errorMessage = useSelector(state => state.Users.partnerError)

  const [selectedCountry, setCountry] = useState("+91")

  useEffect(() => {
    if (errorMessage && errorMessage === "success") {
      closeModal()
    }
  }, [errorMessage])

  const handleValidSubmit = (event, values) => {
    const partnerData = {
      ...values,
      type: userType,
      password: values.mobileNumber,
    }
    // partnerData.mobileNumber = selectedCountry + partnerData.mobileNumber
    // partnerData.mobileNumber = partnerData.mobileNumber.slice(1)
    partnerData.countryCode = selectedCountry.slice(1)
    if (userType === USER_TYPE.PARTNER) {
      dispatch(createPartner(partnerData))
    } else if (userType === USER_TYPE.HEALTH_BUDDY) {
      dispatch(createPartner(partnerData, userProfile.id))
      closeModal()
    }
  }

  const userProfileType = userProfile.type

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      className="modal-dialog-centered modal-md"
    >
      <div
        className="modal-header"
        style={{ borderBottom: "none", paddingBottom: 0 }}
      >
        {userType === USER_TYPE.PARTNER && (
          <h5 className="modal-title mt-0">Add Partner</h5>
        )}
        {userType === USER_TYPE.HEALTH_BUDDY && (
          <h5 className="modal-title mt-0">Add Health Buddy</h5>
        )}
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <div className="p-2">
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>First Name</b>
                </Col>
                <Col xs={8}>
                  <AvField
                    name="firstName"
                    value=""
                    className="form-control"
                    placeholder="Enter your first name"
                    type="text"
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Last Name</b>
                </Col>
                <Col xs={8}>
                  {userProfileType !== USER_TYPE.PARTNER && (
                    <AvField
                      name="lastName"
                      value=""
                      className="form-control"
                      placeholder="Enter your last name"
                      type="text"
                      required
                    />
                  )}
                  {userProfileType === USER_TYPE.PARTNER && (
                    <AvField
                      name="lastName"
                      value=""
                      className="form-control"
                      placeholder="Enter your last name"
                      type="text"
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Email</b>
                </Col>
                <Col xs={8}>
                  {userProfileType !== USER_TYPE.PARTNER && (
                    <AvField
                      name="email"
                      value=""
                      className="form-control"
                      placeholder="Enter your Email"
                      type="email"
                      required
                    />
                  )}
                  {userProfileType === USER_TYPE.PARTNER && (
                    <AvField
                      name="email"
                      value=""
                      className="form-control"
                      placeholder="Enter your Email"
                      type="email"
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col xs={4} style={{ alignSelf: "center" }}>
                  <b>Phone Number</b>
                </Col>
                <Col xs={8}>
                  <AvGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <select
                          id="dialCode"
                          name="dialCode"
                          className={`form-control form-select`}
                          style={{
                            textAlignLast: "center",
                            width: 80,
                          }}
                          onChange={e => {
                            setCountry(e.target.value)
                          }}
                          defaultValue={selectedCountry}
                        >
                          {countryCode &&
                            countryCode.map(country => (
                              <option
                                key={
                                  country.dialCode +
                                  country.name.replace(" ", "")
                                }
                                value={country.dialCode}
                              >
                                {country.dialCode === selectedCountry
                                  ? country.dialCode
                                  : `${country.name} ${country.dialCode}`}
                              </option>
                            ))}
                        </select>
                      </InputGroupAddon>
                      <AvInput
                        name="mobileNumber"
                        id="mobileNumber"
                        placeholder="Mobile Number"
                        type="number"
                        required
                      />
                      <AvFeedback>This field is required.</AvFeedback>
                    </InputGroup>
                  </AvGroup>
                </Col>
              </Row>
            </div>
            {userType === USER_TYPE.HEALTH_BUDDY && (
              <>
                <div className="mb-3">
                  <Row>
                    <Col xs={4} style={{ alignSelf: "center" }}>
                      <b>Service Price for 12 Hours</b>
                    </Col>
                    <Col xs={8}>
                      <AvField
                        name="servicePrice12Hr"
                        value=""
                        className="form-control"
                        placeholder="Enter Price for 12 Hours"
                        type="text"
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    <Col xs={4} style={{ alignSelf: "center" }}>
                      <b>Service Price for 24 Hours</b>
                    </Col>
                    <Col xs={8}>
                      <AvField
                        name="servicePrice24Hr"
                        value=""
                        className="form-control"
                        placeholder="Enter Price for 24 Hours"
                        type="text"
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </>
            )}
            {errorMessage && (
              <div className="mb-3">
                <Row>
                  <Col xs={12} style={{ alignSelf: "center" }}>
                    <Alert color="danger">{errorMessage}</Alert>
                  </Col>
                </Row>
              </div>
            )}
            <Row>
              <Col xs={2}>
                <button className="btn btn-primary btn-block" type="submit">
                  Submit
                </button>
              </Col>
              <Col xs={2}>
                <button
                  className="btn btn-primary btn-block"
                  type="reset"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </div>
    </Modal>
  )
}

CreateUserModal.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.any,
  closeModal: PropTypes.any,
  userType: PropTypes.any,
}

export default withTranslation()(CreateUserModal)

import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  LOGIN_FAILURE,
  GENERATE_OTP,
  VERIFY_OTP,
  OTP_VERIFICATION_STATUS,
  VERIFY_MOBILE_NUMBER,
  MOBILE_NUMBER_VERIFICATION_STATUS,
  LOGIN_WITH_TOKEN,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const loginWithToken = token => {
  return {
    type: LOGIN_WITH_TOKEN,
    payload: token,
  }
}

export const loginFailure = data => {
  return {
    type: LOGIN_FAILURE,
    payload: data,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const generateOtp = userName => {
  return {
    type: GENERATE_OTP,
    payload: {
      userName,
    },
  }
}

export const verifyOtp = (username, otp, history, redirection = true) => {
  return {
    type: VERIFY_OTP,
    payload: {
      username,
      otp,
      history,
      redirection,
    },
  }
}

export const otpVerificationStatus = data => {
  return {
    type: OTP_VERIFICATION_STATUS,
    payload: data,
  }
}

export const verifyMobileNumber = ({ countryCode, mobileNumber }) => {
  return {
    type: VERIFY_MOBILE_NUMBER,
    payload: { countryCode, mobileNumber },
  }
}

export const mobileNumberVerificationStatus = (resp, data) => {
  console.log("Debug ::> resp, data", resp, data)
  return {
    type: MOBILE_NUMBER_VERIFICATION_STATUS,
    payload: { resp, data },
  }
}

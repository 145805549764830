import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Col,
  Row,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import { USER_TYPE } from "../../../constants/common"
import { useSelector } from "react-redux"
import { getUrl } from "utils/Config"

const ServiceDetail = ({
  t,
  data,
  key,
  userDetail,
  bookService,
  knowMore,
  editServiceDetail,
  deleteService,
}) => {
  const selectedUser = useSelector(state => state.Profile.selectedUser)
  const onlineActivityServiceStatus = useSelector(
    state => state.ServiceReducer.onlineActivityServiceStatus
  )

  const isHealthCareService =
    data.id === "64fefa4e389d0722fab0e166" ||
    data.id === "5de9434e-a358-428f-b3e2-766857a4c466"

  const isDailyActivityService =
    data.id === "a1e835d0-d3b7-4105-b3fb-564ff9f1957a" ||
    data.id === "2db41585-e913-43a0-bca2-1a1c41c72d15"

  let CONFIG
  let CSV_URL

  if (isDailyActivityService) {
    CONFIG = getUrl()
    CSV_URL = CONFIG.URL.BOOK_SERVICE.DOWNLOAD_CSV + data.id
  }
  if (isDailyActivityService) {
    return null
  }
  return (
    <Col lg={4} sm={6} xs={12} className="mb-3">
      <Card
        key={key}
        outline
        className="border height-adusted-card"
        color="primary"
        style={{
          backgroundColor: `${
            isDailyActivityService ? "#556ee51a" : "#FFFFFF"
          }`,
        }}
      >
        <CardHeader className="bg-transparent">
          <h5 className="my-0 text-primary">
            <i className="mdi mdi-bullseye-arrow me-3" />
            {t(data.name)}
          </h5>
        </CardHeader>
        <CardBody className="pt-0 d-flex flex-column justify-content-between">
          <CardText>
            <Row>
              <Col xs={12} sm={12}>
                Description: {data.description}
              </Col>
            </Row>
            <br />
            {!isHealthCareService && (
              <Row>
                <Col xs={12} sm={12}>
                  Service Charge(in ₹):
                  {(data.serviceMode === "ONLINE" ||
                    data.serviceMode === "BOTH") && (
                    <Row>
                      <Col xs={3} sm={3}>
                        Online{!isDailyActivityService && `:`}{" "}
                      </Col>
                      {!isDailyActivityService && (
                        <Col xs={9} sm={9}>
                          {data.servicePrice.onlineBasePrice}
                          <span style={{ fontSize: "10px" }}>
                            (Base)
                          </span> + {data.servicePrice.onlinePrice}
                          <span style={{ fontSize: "10px" }}>(Hourly)</span>
                        </Col>
                      )}
                    </Row>
                  )}
                  {(data.serviceMode === "OFFLINE" ||
                    data.serviceMode === "BOTH") && (
                    <Row>
                      <Col xs={3} sm={3}>
                        Offline:{" "}
                      </Col>
                      <Col xs={9} sm={9}>
                        {data.servicePrice.offlineBasePrice}
                        <span style={{ fontSize: "10px" }}>(Base)</span> +{" "}
                        {data.servicePrice.offlinePrice}
                        <span style={{ fontSize: "10px" }}>(Hourly)</span>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            )}
          </CardText>
          {!!(
            userDetail?.type !== USER_TYPE.ADMIN || selectedUser !== null
          ) && (
            <Row>
              <Col>
                <p className="mb-1">{t("Click on Book Now to register")}</p>
                <div className="btn-group btn-group-example" role="group">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-sm"
                    onClick={() => {
                      bookService(data)
                    }}
                    style={{ cursor: "pointer", backgroundColor: "#FFFFFF" }}
                    disabled={
                      isDailyActivityService &&
                      onlineActivityServiceStatus.isCurrentMonthBooking
                    }
                  >
                    Book Now
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary w-sm"
                    onClick={() => {
                      knowMore(data)
                    }}
                    style={{ cursor: "pointer", backgroundColor: "#FFFFFF" }}
                  >
                    Know More
                  </button>
                </div>
              </Col>
            </Row>
          )}

          {!!(userDetail.type === USER_TYPE.ADMIN && selectedUser === null) && (
            <Row>
              <Col>
                <div className="btn-group btn-group-example" role="group">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-sm"
                    onClick={() => {
                      editServiceDetail(data)
                    }}
                    style={{ cursor: "pointer", backgroundColor: "#FFFFFF" }}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger w-sm"
                    onClick={() => {
                      deleteService(data)
                    }}
                    style={{ cursor: "pointer", backgroundColor: "#FFFFFF" }}
                  >
                    Delete
                  </button>
                </div>
              </Col>
            </Row>
          )}
          {isDailyActivityService && userDetail.type === USER_TYPE.ADMIN && (
            <div className="mt-3">
              <a href={CSV_URL} download>
                <button className="btn btn-primary">
                  Download Subscribed User
                </button>
              </a>
            </div>
          )}
          {isDailyActivityService &&
            onlineActivityServiceStatus?.isCurrentMonthBooking && (
              <div className="mt-3">
                <span>
                  You are already subscribed, your subscription is valid till{" "}
                  <b>
                    {moment(
                      new Date(onlineActivityServiceStatus?.monthLastDate)
                    ).format("Do MMMM YYYY")}
                  </b>
                </span>
              </div>
            )}
        </CardBody>
      </Card>
    </Col>
  )
}

ServiceDetail.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  key: PropTypes.any,
  userDetail: PropTypes.any,
  bookService: PropTypes.any,
  knowMore: PropTypes.any,
  editServiceDetail: PropTypes.any,
  deleteService: PropTypes.any,
}

export default withTranslation()(ServiceDetail)

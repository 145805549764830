import React, { useState } from "react"

import {
    Card,
    CardBody,
    Col,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";
import SocialMedia from "./Onboarding-Forms/SocialMedia"
import EQTest from "./Onboarding-Forms/EQTest"
import Training from "./Onboarding-Forms/Training"
import { useDispatch, useSelector } from "react-redux";
import { saveEmotionalQuotient, saveSocialBehaviour, sendWhatsappMessage, updateHelpBuddyDetail } from "store/actions";
import ApplicationProgress from "./Onboarding-Forms/ApplicationProgress";
import { HELP_BUDDY_APP_STATUS } from "constants/common";

const OnBoard = () => {
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [applicationProgressModal, setApplicationProgressModal] = useState(false);

    const dispatch = useDispatch();
    let socialBehaviour = useSelector(state => state.OnboardReducer.socialBehaviour);
    const emotionalQuotient = useSelector(state => state.OnboardReducer.emotionalQuotient);
    let userDetailfromState = useSelector(state => state.Profile.userProfile);
    let helpBuddyDetailfromState = useSelector(state => state.Profile.helpBuddyDetail);
    // dispatch(getEmotionalQuotient());
    // dispatch(getSocialBehaviour());

    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
        if (activeTab === 1) {
            dispatch(saveEmotionalQuotient(emotionalQuotient));
        }
        else if (activeTab === 2) {
            socialBehaviour && socialBehaviour.length > 0 ? dispatch(saveSocialBehaviour(socialBehaviour)) : null;
        }
        else if (activeTab === 4 && tab !== 3) {
            setApplicationProgressModal(true);
        }
        // dispatch(saveOnboardStatus(onboardStatus));
    }
    return (
        <>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                            <div className="wizard clearfix">
                                <div className="steps clearfix">
                                    <ul>
                                        <NavItem
                                            className={classnames({ current: activeTab === 1 })}
                                        >
                                            <NavLink
                                                className={classnames({ active: activeTab === 1 })}
                                                onClick={() => {
                                                    setactiveTab(1)
                                                }}
                                                disabled={!(passedSteps || []).includes(1)}
                                            >
                                                <span className="number">01</span> EQ Test
                                            </NavLink>
                                        </NavItem>
                                        <NavItem
                                            className={classnames({ current: activeTab === 2 })}
                                        >
                                            <NavLink
                                                className={classnames({ active: activeTab === 2 })}
                                                onClick={() => {
                                                    setactiveTab(2)
                                                }}
                                                disabled={!(passedSteps || []).includes(2)}
                                            >
                                                <span className="number ms-2">02</span> Behavioral Verification
                                            </NavLink>
                                        </NavItem>

                                        <NavItem
                                            className={classnames({ current: activeTab === 3 })}
                                        >
                                            <NavLink
                                                className={classnames({ active: activeTab === 3 })}
                                                onClick={() => {
                                                    setactiveTab(3)
                                                }}
                                                disabled={!(passedSteps || []).includes(3)}
                                            >
                                                <span className="number">03</span> Training
                                            </NavLink>
                                        </NavItem>
                                        <NavItem
                                            className={classnames({ current: activeTab === 4 })}
                                        >
                                            <NavLink
                                                className={classnames({ active: activeTab === 4 })}
                                                onClick={() => {
                                                    setactiveTab(4)
                                                }}
                                                disabled={!(passedSteps || []).includes(4)}
                                            >
                                                <span className="number">04</span> Finish
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                </div>
                                <div className="content clearfix mt-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={1}>
                                            <div>
                                                <EQTest data={emotionalQuotient} />
                                            </div>
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            <div>
                                                <SocialMedia data={socialBehaviour} />
                                            </div>
                                        </TabPane>
                                        <TabPane tabId={3}>
                                            <div>
                                                <Training />
                                            </div>
                                        </TabPane>
                                        <TabPane tabId={4}>
                                            <div className="row justify-content-center">
                                                <Col lg="6">
                                                    <div className="text-center">
                                                        <div className="mb-4">
                                                            <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                        </div>
                                                        <div>
                                                            <h5>Confirm Detail</h5>
                                                            <p className="text-muted">
                                                                By submitting this form, you agree with our Terms and conditions.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </div>
                                <div className="actions clearfix">
                                    <ul>
                                        <li
                                            className={
                                                activeTab === 1 ? "previous disabled" : "previous"
                                            }
                                        >
                                            <Link
                                                to="#"
                                                onClick={() => {
                                                    toggleTab(activeTab - 1)
                                                }}
                                            >
                                                Previous
                                            </Link>
                                        </li>
                                        <li
                                            className={"next"}
                                        >
                                            <Link
                                                to="#"
                                                onClick={() => {
                                                    toggleTab(activeTab + 1)
                                                }}
                                            >
                                                {activeTab === 4 ? 'Submit' : 'Next'}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ApplicationProgress
                                isOpen={applicationProgressModal}
                                onClose={() => {
                                    setApplicationProgressModal(false)
                                    dispatch(updateHelpBuddyDetail({ ...helpBuddyDetailfromState, verificationStatus: HELP_BUDDY_APP_STATUS.COMPLETED }))
                                    // dispatch(updateProfileDetail({ ...userDetailfromState, verificationStatus: HELP_BUDDY_APP_STATUS.COMPLETED }))
                                    setTimeout(() => {
                                        dispatch(sendWhatsappMessage(userDetailfromState.id))
                                    }, 1000)
                                }}
                            ></ApplicationProgress>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default OnBoard;

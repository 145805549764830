import AssignmentMgmt from "components/Happy60/AssignmentMgmt"
import React from "react"

const Assignments = () => {
    return (
        <>
            <AssignmentMgmt />
        </>
    )
}

export default Assignments
import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap";
import { Col, Media } from 'reactstrap';

const StaticNumberWidget = ({
    report, key
}) => {
    return (
        <Col md={report.width} key={"_col_" + key}>
            <Card className="mini-stats-wid">
                <CardBody>
                    <Media>
                        <Media body>
                            <p className="text-muted fw-medium">
                                {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                        </Media>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                                <i
                                    className={
                                        "bx " + report.iconClass + " font-size-24"
                                    }
                                ></i>
                            </span>
                        </div>
                    </Media>
                </CardBody>
            </Card>
        </Col>
    )
}

StaticNumberWidget.propTypes = {
    report: PropTypes.any,
    key: PropTypes.any,
}

export default withRouter(StaticNumberWidget)

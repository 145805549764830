import React, { useMemo } from "react";
import PropTypes from 'prop-types'

const UserResponse = ({ messages, widgetStyle }) => {
  const {
    secondaryBGC,
    secondaryTextColor,
    userIcon
  } = useMemo(() => widgetStyle, [widgetStyle])

  return (
    <div className="user-response">
      <div>
        <img src={userIcon} className="user-profile-photo" alt="elie" />
      </div>

      <div className="user-msg-div">
        <p className="user-msg" style={{ background: secondaryBGC, color: secondaryTextColor }}>
          {messages}
        </p>
      </div>
    </div>
  )
}

UserResponse.propTypes = {
  messages: PropTypes.any,
  widgetStyle: PropTypes.any
}

export default UserResponse

import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next"
import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames'

import {
    Row, Col, Table, Modal,
    Label, FormGroup
} from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"

import {
    addPackage, updatePackage, addPackageResponse, updatePackageResponse
} from "store/actions";

import SelectionList from "./SelectionList/SelectionList";
import OverlayLoader from "components/Common/OverlayLoader/OverlayLoader";

const VALIDITY_OPTIONS = [
    { name: "7 Days", id: 7 },
    { name: "15 Days", id: 15 },
    { name: "30 Days", id: 30 }
]

const ADDITIONAL_BENEFITS_OPTIONS = [
    { name: "Dedicated Care coordinator", id: "Dedicated Care coordinator" },
    { name: "Weekly calls", id: "Weekly calls" },
    { name: "Free webinars", id: "Free webinars" },
    { name: "Free activity participation", id: "Free activity participation" },
]

const PackageDetailsModal = ({
    t,
    isOpen,
    data,
    readOnlyDetails,
    userDetail,
    isAdminUser,
    closeModal,
    emptyPackageDetails,
    getDefaultDiscount
}) => {
    const dispatch = useDispatch();
    const [error, setError] = useState({})

    // add/update package statuses
    const addPackageStatus = useSelector(state => state.PackagesReducer.addPackageStatus)
    const updatePackageStatus = useSelector(state => state.PackagesReducer.updatePackageStatus)

    // services
    const allServices = useSelector(state => state.ServiceReducer.serviceType);

    // online services only
    const allOnlineServices = useMemo(() => allServices, [allServices])

    const [packageDetails, setPackageDetails] = useState(cloneDeep(emptyPackageDetails));
    const { id, name, totalPackagePrice, validity, description, additionalBenefits, packageDetails: services } = useMemo(() => packageDetails || {}, [packageDetails])
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    useEffect(() => {
        if (addPackageStatus) {
            addPackageResponse(false)
            setFullScreenLoader(false)
            closeModal();
        }

        if (updatePackageStatus) {
            updatePackageResponse(false)
            setFullScreenLoader(false)
            closeModal();
        }
    }, [addPackageStatus, updatePackageStatus])

    useEffect(() => {
        if (data.id) {
            setPackageDetails(cloneDeep(data))
        } else {
            setPackageDetails(cloneDeep(emptyPackageDetails))
        }
    }, [data])

    useEffect(() => {
        return () => {
            setError({})
        }
    }, [])

    const updateSelectedServices = useCallback((newList) => {
        setPackageDetails((prevState) => ({ ...prevState, packageDetails: newList, totalPackagePrice: newList.reduce((a, b) => a + b.packagePrice, 0) }))
        resetErrorOnInputChange("packageDetails")
    }, [setPackageDetails])

    const updatePackageService = useCallback((targetId, targetKey, targetValue) => {
        setPackageDetails((prevState) => {
            const packageDetailsNew = prevState.packageDetails.map((item) => {
                if (item.serviceId === targetId) {
                    switch (targetKey) {
                        case "discount":
                        case "hours": {
                            const newData = { ...item, [targetKey]: parseInt(targetValue) }

                            const { hours, price, discount } = newData
                            const total = price * hours
                            const packagePrice = total - (total * ((discount || 0) / 100))

                            return ({ ...newData, total, packagePrice })
                        }
                        default:
                            return ({ ...item, [targetKey]: targetValue })
                    }
                }
                return item
            })
            const totalPackagePriceNew = packageDetailsNew.reduce((a, b) => a + b.packagePrice, 0)

            return {
                ...prevState,
                packageDetails: packageDetailsNew,
                totalPackagePrice: totalPackagePriceNew
            }
        })
    }, [setPackageDetails])

    const resetErrorOnInputChange = useCallback((errorKey) => {
        setError((prevState) => ({ ...prevState, [errorKey]: false }))
    }, [setError])

    const handleValidSubmit = (e, v) => {
        e.preventDefault();
        const packageData = cloneDeep({ ...data, ...packageDetails, ...v });
        const {
            id, name, description, validity, packageDetails: packageServicesDetails, totalPackagePrice, additionalBenefits,
            createdByAdmin, userId, createdTime, active
        } = packageData

        const errors = cloneDeep(error)
        let hasError = false;

        if (validity === "-1") {
            hasError = true
            errors["validity"] = "This field is invalid"
        }

        if (!packageServicesDetails?.length) {
            hasError = true
            errors["packageDetails"] = "Please select at least one service"
        }

        setError(errors)

        if (!hasError) {
            setFullScreenLoader(true)
            if (packageData.id) {
                const formattedPackageData = {
                    id,
                    name,
                    description,
                    validity: parseInt(validity),
                    packageDetails: packageServicesDetails.map(({ id: packageDetailId, serviceId, price, hours, total, discount, packagePrice }) => ({
                        id: packageDetailId || null, serviceId, price, hours, total, discount, packagePrice
                    })),
                    totalPackagePrice,
                    additionalBenefits,
                    createdByAdmin,
                    userId,
                    createdTime,
                    active
                }
                // console.log("formattedPackageData", formattedPackageData)

                // update plan
                dispatch(updatePackage(formattedPackageData))
            }
            else {
                const formattedPackageData = {
                    id: null,
                    name,
                    description,
                    validity: parseInt(validity),
                    packageDetails: packageServicesDetails.map(({ id: packageDetailId, serviceId, price, hours, total, discount, packagePrice }) => ({
                        id: packageDetailId || null, serviceId, price, hours, total, discount, packagePrice
                    })),
                    totalPackagePrice,
                    additionalBenefits,
                    createdByAdmin: isAdminUser ? true : false,
                    userId: userDetail.id,
                    // createdTime,
                    // active
                }
                // console.log("formattedPackageData", formattedPackageData)

                // add plan
                dispatch(addPackage(formattedPackageData))
            }
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            centered={true}
            size="xl"
        >
            <OverlayLoader isLoading={fullScreenLoader} />

            <div className="modal-header">
                <h5 className="modal-title mt-0">
                    Package Details
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                >
                </button>
            </div>

            {readOnlyDetails ? (
                <>
                    <div className="modal-body">
                        <div className="mb-1">
                            <span>
                                Name: {name}
                            </span>
                        </div>

                        <div className="mb-1">
                            <span>
                                Price: {totalPackagePrice ? "₹ " + totalPackagePrice : ""}
                            </span>
                        </div>

                        <div className="mb-1">
                            <span>
                                Validity: {validity ? validity + " Days" : ""}
                            </span>
                        </div>

                        <div className="mb-1">
                            <span>
                                Description: {description}
                            </span>
                        </div>

                        <div className="mb-1">
                            <span>
                                Additional Benefits: {(additionalBenefits || []).join(", ")}
                            </span>
                        </div>

                        <div>
                            <span>
                                Services:
                            </span>
                            {
                                services?.length ? (
                                    <>
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Price</th>
                                                    <th>Hours</th>
                                                    <th>Total</th>
                                                    <th>Discount %</th>
                                                    <th>Package Price</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    services.map(({ serviceId: packageServiceId, price, hours, total, discount, packagePrice }) => {
                                                        const { serviceType } = allOnlineServices.find(({ id }) => id === packageServiceId) || {}

                                                        return (
                                                            <tr key={packageServiceId}>
                                                                <td>{serviceType}</td>
                                                                <td>{price}</td>
                                                                <td>{hours}</td>
                                                                <td>{total}</td>
                                                                <td>{discount}</td>
                                                                <td>{packagePrice}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </>
                                ) : "-"
                            }
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <AvForm onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                    }}>
                        <div className="modal-body">
                            <Row>
                                <Col xs={12} md={6}>
                                    <Row>
                                        <Col xs={12} sm={12} className="mb-4">
                                            <FormGroup>
                                                <AvField
                                                    id="name"
                                                    name="name"
                                                    label={t("Package Name")}
                                                    className={`form-control ${error && error["name"] &&
                                                        "is-invalid"}`}
                                                    placeholder={t("Package Name")}
                                                    type="text"
                                                    value={name}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={12} sm={12} className="mb-4">
                                            <FormGroup>
                                                <AvField
                                                    id="description"
                                                    name="description"
                                                    label={t("Package Description")}
                                                    className={`form-control ${error && error["descripton"] &&
                                                        "is-invalid"}`}
                                                    placeholder={t("Package Description")}
                                                    type="text"
                                                    value={description}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={12} sm={12} className="mb-4">
                                            <FormGroup>
                                                <AvField
                                                    type="select"
                                                    id="validity"
                                                    name="validity"
                                                    className={`form-control ${error && error["validity"] &&
                                                        "is-invalid"}`}
                                                    label={t("Validity")}
                                                    value={validity}
                                                    onChange={() => { resetErrorOnInputChange("validity") }}
                                                    required
                                                >
                                                    <option value="-1" key="validity_default" disabled>Select</option>
                                                    {VALIDITY_OPTIONS.map((item) => {
                                                        return (
                                                            <option value={item.id} key={item.id}>{item.name}</option>
                                                        )
                                                    })}
                                                </AvField>
                                                {error && error["validity"] && (
                                                    <label style={{ "color": "red", "fontWeight": "100" }}>{error["validity"]}</label>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12} md={6}>
                                    <Row>
                                        <Col xs={12} sm={12} className="mb-4">
                                            <FormGroup>
                                                <AvField
                                                    type="select"
                                                    id="additionalBenefits"
                                                    name="additionalBenefits"
                                                    className={`form-control ${error && error["additionalBenefits"] &&
                                                        "is-invalid"}`}
                                                    label={t("Additional Benefits")}
                                                    value={additionalBenefits}
                                                    multiple
                                                >
                                                    {ADDITIONAL_BENEFITS_OPTIONS.map((item) => {
                                                        return (
                                                            <option value={item.id} key={item.id}>{item.name}</option>
                                                        )
                                                    })}
                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12}>
                                    <Label className={classnames('', {
                                        'text-danger': error && error["packageDetails"]
                                    })}>Services</Label>
                                    {error && error["packageDetails"] && (
                                        <>
                                            <br />
                                            <label style={{ "color": "red", "fontWeight": "100" }}>{error["packageDetails"]}</label>
                                        </>
                                    )}
                                    <SelectionList
                                        allData={allOnlineServices}
                                        alreadySelectedFields={services}
                                        updateSelectedFields={updateSelectedServices}
                                        updatePackageService={updatePackageService}
                                        totalPackagePrice={totalPackagePrice}
                                        isAdminUser={isAdminUser}
                                        getDefaultDiscount={getDefaultDiscount}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="submit"
                                className="btn btn-primary px-4"
                            >
                                {id ? t("Update") : t('Create')}
                            </button>
                        </div>
                    </AvForm>
                </>
            )}
        </Modal>
    )
}


PackageDetailsModal.propTypes = {
    t: PropTypes.any,
    isOpen: PropTypes.any,
    data: PropTypes.any,
    readOnlyDetails: PropTypes.bool,
    userDetail: PropTypes.any,
    isAdminUser: PropTypes.bool,
    isSeniorCitizenUser: PropTypes.bool,
    closeModal: PropTypes.any,
    emptyPackageDetails: PropTypes.any,
    getDefaultDiscount: PropTypes.func
}

export default withTranslation()(PackageDetailsModal);

export const UPLOAD_KYC_DOC = "UPLOAD_KYC_DOC";
export const SAVE_ONBOARD_STATUS = "SAVE_ONBOARD_STATUS";

export const GET_EMOTIAL_QUOTIENTS = "GET_EMOTIAL_QUOTIENTS";
export const EMOTIONAL_QUOTIENTS = "EMOTIONAL_QUOTIENTS";
export const UPDATE_EMOTIONAL_QUOTIENT = "UPDATE_EMOTIONAL_QUOTIENT";
export const SAVE_EMOTIONAL_QUOTIENT = "SAVE_EMOTIONAL_QUOTIENT";
export const GET_BEHAVIOURAL_ANALYSIS_BY_USER_ID = "GET_BEHAVIOURAL_ANALYSIS_BY_USER_ID";
export const BEHAVIOURAL_ANALYSIS_BY_USER_ID = "BEHAVIOURAL_ANALYSIS_BY_USER_ID";

export const GET_SOCIAL_BEHAVIOUR = "GET_SOCIAL_BEHAVIOUR";
export const SOCIAL_BEHAVIOUR = "SOCIAL_BEHAVIOUR";
export const UPDATE_SOCIAL_BEHAVIOUR = "UPDATE_SOCIAL_BEHAVIOUR";
export const SAVE_SOCIAL_BEHAVIOUR = "SAVE_SOCIAL_BEHAVIOUR";
export const GET_EMOTIONAL_QUOTIENT_BY_USER_ID = "GET_EMOTIONAL_QUOTIENT_BY_USER_ID";
export const EMOTIONAL_QUOTIENT_BY_USER_ID = "EMOTIONAL_QUOTIENT_BY_USER_ID";

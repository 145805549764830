import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

import { Modal, Row, Col, FormGroup, Label } from "reactstrap"

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import GoogleMaps from "../GoogleMap/GoogleMap"

const defaultLocation = {
  latitude: null,
  longitude: null,
}

const AddEditAddress = ({
  t,
  isOpen,
  currentAddressObject,
  onSubmitClick,
  onCancelClick,
}) => {
  const userDetail = useSelector(state => state.Profile.userProfile)
  const { id: userId } = useMemo(() => userDetail || {}, [userDetail])

  const {
    id,
    address,
    landmark,
    city,
    state,
    pinCode,
    tags,
    location,
    active = true,
    primary = true,
  } = useMemo(() => currentAddressObject || {}, [currentAddressObject])

  const [locationDetails, setLocationDetails] = useState(
    location || defaultLocation
  )
  useEffect(() => {
    setLocationDetails(location || defaultLocation)
  }, [location])
  const updateLocationDetails = useCallback(({ lat, lng }) => {
    setLocationDetails({ latitude: lat, longitude: lng })
  }, [])
  const { latitude, longitude } = useMemo(
    () => locationDetails,
    [locationDetails]
  )

  const handleValidSubmit = (e, v) => {
    e.preventDefault()
    const newData = {
      ...currentAddressObject,
      ...v,
      latitude,
      longitude,
      userID: userId,
    }
    onSubmitClick(newData)
  }

  return (
    <Modal isOpen={isOpen} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {id ? t("Update Address") : t("Add Address")}
        </h5>
      </div>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v)
        }}
      >
        <div className="modal-body">
          <Row>
            <Col xs={12} className="mb-4">
              <FormGroup>
                <AvField
                  name="address"
                  label={t("Address")}
                  value={address}
                  className="form-control"
                  placeholder={t("Enter Address")}
                  type="text"
                  required
                />
              </FormGroup>
            </Col>

            <Col xs={12} className="mb-4">
              <FormGroup>
                <AvField
                  name="landmark"
                  label={t("Landmark")}
                  value={landmark}
                  className="form-control"
                  placeholder={t("Enter Landmark")}
                  type="text"
                  required
                />
              </FormGroup>
            </Col>

            <Col xs={12} className="mb-4">
              <FormGroup>
                <AvField
                  name="city"
                  label={t("City")}
                  value={city}
                  className="form-control"
                  placeholder={t("Enter City")}
                  type="text"
                  required
                />
              </FormGroup>
            </Col>

            <Col xs={12} className="mb-4">
              <FormGroup>
                <AvField
                  name="state"
                  label={t("State")}
                  value={state}
                  className="form-control"
                  placeholder={t("Enter State")}
                  type="text"
                  required
                />
              </FormGroup>
            </Col>

            <Col xs={12} className="mb-4">
              <FormGroup>
                <AvField
                  name="pinCode"
                  label={t("Pincode")}
                  value={pinCode}
                  className="form-control"
                  placeholder={t("Enter Pincode")}
                  type="number"
                  required
                />
              </FormGroup>
            </Col>

            <Col xs={12} className="mb-4">
              <FormGroup>
                <AvField
                  name="tags"
                  label={t("Tags")}
                  value={tags}
                  className="form-control"
                  placeholder={t("Enter Tags")}
                  type="text"
                />
              </FormGroup>
            </Col>

            <Col xs={6} className="mb-4">
              <AvGroup check>
                <Label check>
                  <AvInput type="checkbox" name="active" value={active} />
                  {t("Active")}
                </Label>
              </AvGroup>
            </Col>

            <Col xs={6} className="mb-4">
              <AvGroup check>
                <Label check>
                  <AvInput type="checkbox" name="primary" value={primary} />
                  {t("Primary")}
                </Label>
              </AvGroup>
            </Col>

            <Col xs={12} className="mb-4">
              <label>Location</label>
              <GoogleMaps
                lat={latitude}
                lng={longitude}
                callback={updateLocationDetails}
              />
            </Col>

            {/* <Col xs={6}>
              <FormGroup>
                <AvField
                  name="location.latitude"
                  label={t("Latitude")}
                  value={latitude}
                  className="form-control"
                  placeholder={t("0")}
                  type="text"
                />
              </FormGroup>
            </Col>

            <Col xs={6}>
              <FormGroup>
                <AvField
                  name="location.longitude"
                  label={t("Longitude")}
                  value={longitude}
                  className="form-control"
                  placeholder={t("0")}
                  type="text"
                />
              </FormGroup>
            </Col> */}
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              onCancelClick()
            }}
            className="btn btn-secondary px-4"
            data-dismiss="modal"
          >
            {t("Cancel")}
          </button>

          <button type="submit" className="btn btn-primary px-4">
            {currentAddressObject?.id ? t("Update") : t("Add")}
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

AddEditAddress.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.bool,
  currentAddressObject: PropTypes.any,
  onSubmitClick: PropTypes.func,
  onCancelClick: PropTypes.func,
}

export default withTranslation()(AddEditAddress)

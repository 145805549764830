import React, { useMemo } from "react"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import {
  Col,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Button
} from "reactstrap"

const Contact = ({
  t,
  data,
  key,
  contactSelected,
  deleteContactDetail
}) => {
  const { firstName, lastName, mobileNumber } = useMemo(() => data || {}, [data])

  return (
    <Col lg={4} sm={6} xs={12} key={key}>
      <Card outline color="primary" className="border">
        <CardHeader className="bg-transparent">
          <h5 className="my-0 text-primary">
            <i className="mdi mdi-bullseye-arrow me-3" />{firstName} {lastName}
          </h5>
        </CardHeader>
        <CardBody>
          <CardText>
            Phone. {mobileNumber}
          </CardText>
          <div
            className="btn-group btn-group-example mb-3"
            role="group"
          >
            <button
              type="button"
              className="btn btn-outline-primary w-sm"
              onClick={() => { contactSelected(data) }}
            >
              Edit
            </button>
            <button
              type="button"
              className="btn btn-outline-danger w-sm"
              onClick={() => { deleteContactDetail(data) }}
            >
              Delete
            </button>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

Contact.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  key: PropTypes.any,
  contactSelected: PropTypes.func,
  deleteContactDetail: PropTypes.func
}

export default withTranslation()(Contact)

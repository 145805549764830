import {
  GET_ALL_TRIP_LIST,
  ALL_TRIP_LIST,
  UPDATE_TRIP,
  CREATE_TRIP,
  DONE_PROCESS,
} from "./actionTypes"

export const getAllTripList = (page, perPage) => {
  return {
    type: GET_ALL_TRIP_LIST,
    payload: { page, perPage },
  }
}

export const allTripList = data => {
  return {
    type: ALL_TRIP_LIST,
    payload: data,
  }
}

export const updateTripStatus = data => {
  return {
    type: UPDATE_TRIP,
    payload: data,
  }
}

export const createTripStatus = data => {
  return {
    type: CREATE_TRIP,
    payload: data,
  }
}
export const stopLoading = () => {
  return {
    type: DONE_PROCESS,
  }
}

import React, { useMemo } from "react"
import PropTypes from 'prop-types'

import { Paperclip } from "react-feather"

const ButtonsResponse = ({
  buttons,
  onUserMessageSubmit,
  buttonsResponseRef,
  handleFileUploadChange,
  handleFileUploadSubmit,
  fileToBeUploaded,
  widgetStyle
}) => {
  const filename = useMemo(() => fileToBeUploaded?.name, [fileToBeUploaded])
  const {
    secondaryBGC,
    secondaryTextColor
  } = useMemo(() => widgetStyle, [widgetStyle])

  return (
    <div className="bot-response">
      <div className="bot-msg-div buttonsResponseRef" ref={buttonsResponseRef}>
        <div className="bot-msg btns-div">
          {
            buttons.map(({ payload, title, isFileSubmitButton, isFileUploadButton }, index) => {

              if (isFileUploadButton) {
                return (
                  <div key={`btn-${title}-${index}`}>
                    <input className="d-none" id="chatbot-upload-input" type="file" onChange={handleFileUploadChange} />
                    <p
                      className="bot-msg-btn"
                      style={{ background: secondaryBGC, color: secondaryTextColor }}
                      onClick={() => {
                        document.getElementById("chatbot-upload-input").click()
                      }}
                      dangerouslySetInnerHTML={{ __html: title }}
                    >
                      {/* {title} */}
                    </p>

                    {
                      filename ? (
                        <div className="selected-filename">
                          <span className="d-table-cell">
                            <Paperclip size={12} color="#040474" />
                          </span>

                          <span className="d-table-cell">
                            {filename}
                          </span>
                        </div>
                      ) : null
                    }

                  </div>
                )
              }

              if (isFileSubmitButton) {
                return (
                  <div key={`btn-${title}-${index}`}>
                    <p
                      className="bot-msg-btn"
                      style={{ background: secondaryBGC, color: secondaryTextColor }}
                      onClick={handleFileUploadSubmit}
                      disabled={!Boolean(filename)}
                      dangerouslySetInnerHTML={{ __html: title }}
                    >
                      {/* {title} */}
                    </p>
                  </div>
                )
              }

              return (
                <div key={`btn-${title}-${index}`}>
                  <p
                    className="bot-msg-btn"
                    style={{ background: secondaryBGC, color: secondaryTextColor }}
                    onClick={() => {
                      onUserMessageSubmit(payload, title)
                      if (buttonsResponseRef?.current) buttonsResponseRef.current.innerHTML = ""
                    }}
                    dangerouslySetInnerHTML={{ __html: title }}
                  >
                    {/* {title} */}
                  </p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

ButtonsResponse.propTypes = {
  buttons: PropTypes.any,
  onUserMessageSubmit: PropTypes.func,
  buttonsResponseRef: PropTypes.any,
  handleFileUploadChange: PropTypes.func,
  handleFileUploadSubmit: PropTypes.func,
  fileToBeUploaded: PropTypes.any,
  widgetStyle: PropTypes.any,
}

export default ButtonsResponse

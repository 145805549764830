import React from "react"
import { Redirect } from "react-router-dom"

// Happy60 pages
import ServiceTypePage from "../pages/Happy60/ServiceTypePage"
import ServiceOfferPage from "../pages/Happy60/ServiceOfferPage"
import BookingPage from "../pages/Happy60/BookingPage"
import AssignmentPage from "../pages/Happy60/AssignmentPage"
import Registeration from "../pages/Happy60/Registeration"
import OnBoarding from "../pages/Happy60/OnBoarding"
import ApprovalPage from "../pages/Happy60/ApprovalPage"
import BlogPage from "pages/Happy60/Blog"
import UserProfile from "../pages/Authentication/user-profile"
import PackagesPage from "pages/Happy60/PackagesPage"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Register from "../pages/Authentication/Register"
import RegisterSeniorCitizen from "pages/Authentication/registerSeniorCitizen"
import RegisterServiceProvider from "pages/Authentication/registerServiceProvider"
import SenagerClubModal from "pages/Authentication/SenagerClubModal"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import MobileServicesRedirectionPage from "pages/Happy60/MobileServicesRedirectionPage"
import MobileServicesPage from "pages/Happy60/MobileServicesPage"

// import Profile from "../pages/Profile"
// import Contact from "../pages/Profile/Contact"
// import Vehicle from "../pages/Profile/Vehicle"
import GoogleMaps from "../pages/Gmap"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import PaymentPage from "pages/Happy60/paymentPage"
import ServiceAssignmentPage from "pages/Happy60/serviceAssignment"
import UserPage from "pages/Happy60/UserPage"
import EnquiryPage from "pages/Happy60/EnquiryPage"
import TripPage from "pages/Happy60/Trip"
import SubscriptionEventHandler from "pages/Authentication/SubscriptionEventHandler"
import HomePageService from "pages/Happy60/HomePageService"

const authProtectedRoutes = [
  { path: "/services", component: ServiceTypePage },
  { path: "/services/book/daily-activity", component: ServiceTypePage },
  { path: "/services/:id", component: ServiceTypePage },
  { path: "/packages", component: PackagesPage },
  { path: "/offers", component: ServiceOfferPage },
  { path: "/bookings", component: BookingPage },
  { path: "/assignment", component: AssignmentPage },
  { path: "/register-verify", component: Registeration },
  { path: "/onboard", component: OnBoarding },
  { path: "/approval", component: ApprovalPage },
  { path: "/serviceAssignment", component: ServiceAssignmentPage },
  { path: "/dashboard", component: Dashboard },
  { path: "/blog", component: BlogPage },
  { path: "/users", component: UserPage },
  { path: "/enquiry", component: EnquiryPage },
  { path: "/home-page-service", component: HomePageService },
  { path: "/trip", component: TripPage },

  // //profile
  { path: "/profile", component: UserProfile },

  // { path: "/profile_mgmt", exact: true, component: Profile },
  // { path: "/contact", exact: true, component: Contact },
  // { path: "/vehicle", exact: true, component: Vehicle },
  { path: "/gmap", exact: true, component: GoogleMaps },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/profile" /> },
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/payment", component: PaymentPage },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/registerSC", component: RegisterSeniorCitizen },
  { path: "/registerSP", component: RegisterServiceProvider },
  { path: "/gold-card", component: SenagerClubModal },
  { path: "/gold-card/:subscriptionId/:userId", component: SenagerClubModal },
  { path: "/join-event/:id", component: SubscriptionEventHandler },
  { path: "/mobile-services/:token", component: MobileServicesRedirectionPage },
  { path: "/mobile-services", component: MobileServicesPage },
]

export { authProtectedRoutes, publicRoutes }

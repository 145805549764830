import React, { useCallback, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Input,
  Button,
  FormFeedback,
  FormText,
} from "reactstrap"
import { connect } from "react-redux"
import PropTypes from "prop-types"

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvInput,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation"

//i18n
import { withTranslation } from "react-i18next"

import classnames from "classnames"

// action
import {
  registerUser,
  apiError,
  validateReferralCode,
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link, useParams, withRouter } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import happy60plus from "assets/images/happy60plus/logo.png"

// import { history } from "../../history"

import countryCode from "../../utils/countryCode"
import { USER_TYPE } from "constants/common"
const RegistrationUserDetail = ({ t, userType, history }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.RegisterReducer.user)
  const registrationError = useSelector(
    state => state.RegisterReducer.registrationError
    )

  const mobileNumberDetails = useSelector(state => state.Login.mobileNumber)
  const validReferralCode = useSelector(
    state => state.RegisterReducer.validReferralCode
  )
  const referralId = useSelector(state => state.RegisterReducer.referralId)
  const [referralCode, setReferralCode] = useState("")
  const [selectedCountry, setCountry] = useState(
    mobileNumberDetails?.countryCode ? mobileNumberDetails?.countryCode : "+91"
  )

  const checkReferralCode = useCallback(() => {
    dispatch(validateReferralCode(referralCode))
  }, [referralCode])

  let validationError = null
  

  const validateForm = formData => {
    let valid = true
    if (formData.password !== formData.confirmPassword) {
      valid = false
      validationError += "Password and Confirm password should match"
    }
    if (valid) {
      return true
    }
    return false
  }

  const signupSubmit = (e, data) => {
    console.log(e)
    validationError = ""
    e.preventDefault()
    let formData = { ...data, type: userType }
    if (validateForm(formData)) {
      delete formData.confirmPassword
      // formData.mobileNumber = selectedCountry + formData.mobileNumber
      // formData.mobileNumber = formData.mobileNumber.slice(1)
      formData.countryCode = selectedCountry.slice(1)
      if (validReferralCode) {
        formData.isReferredBy = referralId
        formData.referredCount = 0
      }
      formData.type = userType === USER_TYPE.SENIOR_CITIZEN ? 'SeniorCitizen' : 'HelpBuddy'
      console.log('Debug ::> formData', formData)
      dispatch(registerUser(formData, history))
    }
    // addToast('Alert', { appearance: 'error' });
    // submitUserDetail(formData)
    //   .then(
    //     (resp) => {
    //       console.log(resp);
    //     }
    //   )
    //   .catch(err => console.log(err));
  }

  // const handleUserResponse = user => {
  //   console.log('Debug ::> user', user)
  //   if (user && user !== null) {
  //     console.log('Debug ::> 1122233', 1122233)
  //     history.push("/login")
  //     // window.location.reload()
  //   }
  // }

  const [showSussesMsg, setShowSussesMsg] = useState(false)
  useEffect(() => {
    if (user?.id) {
      setShowSussesMsg(true)
      dispatch(apiError(""))
    }
    const timer = setTimeout(() => {
      setShowSussesMsg(false)
    }, 3000)

    return () => {
      setShowSussesMsg(false)
      clearTimeout(timer)
    }
  }, [user])

  return (
    <React.Fragment>
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-primary bg-soft">
                <Row>
                  <Col className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Register</h5>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profileImg} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to="/">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={happy60plus}
                          alt=""
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      signupSubmit(e, v)
                    }}
                  >
                    {showSussesMsg ? (
                      <Alert color="success">Register User Successfully</Alert>
                    ) : null}
                    
                    {registrationError && registrationError ? (
                      <Alert color="danger">{registrationError}</Alert>
                    ) : null}
                    {validationError && validationError ? (
                      <Alert color="danger">{validationError}</Alert>
                    ) : null}
                    <div className="mb-3">
                      <AvGroup>
                        <Label for="mobileNumber">Mobile Number</Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <select
                              id="dialCode"
                              name="dialCode"
                              className={`form-control form-select`}
                              style={{
                                textAlignLast: "center",
                                width: 80,
                              }}
                              onChange={e => {
                                setCountry(e.target.value)
                              }}
                              defaultValue={selectedCountry}
                            >
                              {countryCode &&
                                countryCode.map(country => (
                                  <option
                                    key={
                                      country.dialCode +
                                      country.name.replace(" ", "")
                                    }
                                    value={country.dialCode}
                                  >
                                    {country.dialCode === selectedCountry
                                      ? country.dialCode
                                      : `${country.name} ${country.dialCode}`}
                                  </option>
                                ))}
                            </select>
                          </InputGroupAddon>
                          <AvInput
                            name="mobileNumber"
                            id="mobileNumber"
                            placeholder="Mobile Number"
                            type="number"
                            value={
                              mobileNumberDetails?.mobileNumber
                                ? mobileNumberDetails?.mobileNumber
                                : ""
                            }
                            required
                          />
                          <AvFeedback>This field is required.</AvFeedback>
                        </InputGroup>
                      </AvGroup>
                    </div>
                    <div className="mb-3">
                      <AvField
                        id="firstName"
                        name="firstName"
                        label="Full Name"
                        className="form-control"
                        placeholder="Full Name"
                        type="text"
                        required
                      />
                    </div>
                    {/* <div className="mb-3">
                      <AvField
                        id="lastName"
                        name="lastName"
                        label="last Name"
                        className="form-control"
                        placeholder="Last Name"
                        type="text"
                        required
                      />
                    </div> */}
                    <div className="mb-3">
                      <AvField
                        name="password"
                        label="Password"
                        type="password"
                        required
                        placeholder="Enter Password"
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        id="confirmPassword"
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        required
                        placeholder="ReEnterPassword"
                      />
                    </div>

                    {/* <div className="mb-3">
                      <AvField
                        id="email"
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                      />
                    </div> */}

                    {/* TODO REF */}
                    {/* <div className="mb-3">
                      <FormGroup>
                        <Label
                          className={classnames({
                            "text-danger": validReferralCode === false,
                          })}
                          for="promo"
                        >
                          Referral Code
                        </Label>
                        <InputGroup>
                          <Input
                            id="promo"
                            type="text"
                            value={referralCode}
                            onChange={e => {
                              //   resetReferralCodeValidity()
                              setReferralCode(e.target.value)
                            }}
                            invalid={validReferralCode === false}
                            placeholder="Referral Code"
                            valid={validReferralCode === true}
                          />
                          <Button
                            onClick={checkReferralCode}
                            disabled={!Boolean(referralCode)}
                          >
                            Apply
                          </Button>
                          {validReferralCode === false && (
                            <FormFeedback>Invalid Referral Code</FormFeedback>
                          )}
                          {validReferralCode === true && (
                            <FormFeedback valid>
                              Valid Referral Code
                            </FormFeedback>
                          )}
                        </InputGroup>
                        {referralCode && validReferralCode === null && (
                          <FormText>*Referral Code not applied</FormText>
                        )}
                      </FormGroup>
                    </div> */}

                    <button type="submit" className="btn btn-primary">
                      Register
                    </button>
                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        By registering you agree to the{" "}
                        <Link to="#" className="text-primary">
                          Terms of Use
                        </Link>
                      </p>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                Already have an account ?{" "}
                <Link to="/login" className="font-weight-medium text-primary">
                  {" "}
                  Login
                </Link>{" "}
              </p>
              <p>© {new Date().getFullYear()} Happy60Plus</p>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

RegistrationUserDetail.propTypes = {
  t: PropTypes.any,
  userType: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(withRouter(RegistrationUserDetail))

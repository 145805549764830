import { all, call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ALL_ENQUIRY_LIST,
  ALL_ENQUIRY_LIST,
  UPDATE_ENQUIRY,
  SEARCH_ENQUIRY,
} from "./actionTypes"
import { allEnquiryList, stopLoading } from "./actions"

import { changeEnquiryStatus, getEnquiryList, searchEnquiry } from "utils/Apiv2"

function* getAllEnquiryListSaga(data) {
  try {
    const resp = yield call(getEnquiryList, data.payload)
    if (resp) {
      yield put(allEnquiryList(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* searchEnquirySaga(data) {
  try {
    const resp = yield call(searchEnquiry, data.payload)
    if (resp) {
      yield put(allEnquiryList(resp))
    }
  } catch (error) {
    console.error("Debug ::> ", error)
  }
}

function* updateEnquiryStatus(data) {
  try {
    const resp = yield call(changeEnquiryStatus, data.payload)
    if (resp) {
      const enquiryData = {
        searchType: data.payload.enquiryType,
      }
      const resp = yield call(searchEnquiry, enquiryData)
      if (resp) {
        yield put(allEnquiryList(resp))
      } else {
        yield put(stopLoading())
      }
    }
  } catch (error) {
    yield put(stopLoading())
    console.error(error)
  }
}

function* Users() {
  yield takeEvery(GET_ALL_ENQUIRY_LIST, getAllEnquiryListSaga)
  yield takeEvery(UPDATE_ENQUIRY, updateEnquiryStatus)
  yield takeEvery(SEARCH_ENQUIRY, searchEnquirySaga)
}

export default Users

import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap";
import SimplePieChart from "../../components/Charts/SimplePieChart"
import { useSelector } from "react-redux";

const ServiceBookingByType = () => {

    const { periodData } = useSelector(state => ({
        periodData: state.Dashboard.serviceBookingByType || []
    }))

    return (
        <Card>
            <CardBody>
                <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Service Booking by Type(# of bookings)</h4>
                </div>
                {/* <div className="clearfix"></div> */}
                <SimplePieChart periodData={periodData} />
            </CardBody>
        </Card>
    )
}

export default withRouter(ServiceBookingByType)

import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Media,
  Label,
} from "reactstrap"

// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { downloadUserDocument, getServiceType, uploadUserDocument, uploadUserDocumentResp } from "store/actions"
import { getImageForUser } from "utils/Common"

const ServiceProviderFields = ({
  t,
  data,
  updateData
}) => {
  const {
    age, gender, drivingLicenseNumber, adhaarNumber, drivingLicenseImageURL, adhaarImageURL,
    allowedServices, languagesKnown, hobbies, shortNotes, latestQualification, verifictionStatus
  } = useMemo(() => data, [data])

  const dispatch = useDispatch();
  let selectedService = data.allowedServices ? data.allowedServices : [];
  let selectedLanguages = languagesKnown ? languagesKnown : [];

  let uploadFileResponse = useSelector(state => state.CommonReducer.uploadDocResp);
  let serviceTypeList = useSelector(state => state.ServiceReducer.serviceType);
  let languageList = [
    {
      key: 'hindi',
      value: 'Hindi'
    },
    {
      key: 'english',
      value: 'English'
    },
    {
      key: 'kannada',
      value: 'Kannada'
    },
    {
      key: 'tamil',
      value: 'Tamil'
    },
    {
      key: 'telugu',
      value: 'Telugu'
    },
    {
      key: 'malayalam',
      value: 'Malayalam'
    }
  ]

  const uploadFile = (key, data) => {
    console.log(data);
    dispatch(uploadUserDocument(key, data));
  }
  const downloadFile = (file_id) => {
    dispatch(downloadUserDocument(file_id));
  }

  const changed = (e) => {
    if (e.target.checked) {
      selectedService.push(e.target.value);
    }
    else {
      selectedService = selectedService.filter(item => item !== e.target.value)
    }
    updateData('allowedServices', selectedService);
  }

  const languageSelected = (e) => {
    if (e.target.checked) {
      selectedLanguages.push(e.target.value);
    }
    else {
      selectedLanguages = selectedLanguages.filter(item => item !== e.target.value)
    }
    updateData('languagesKnown', selectedLanguages)
  }

  useEffect(() => {
    dispatch(getServiceType());
  }, [dispatch])

  useEffect(() => {
    if (uploadFileResponse && uploadFileResponse !== null) {
      updateData(uploadFileResponse.key, uploadFileResponse.data);
      dispatch(uploadUserDocumentResp(null));
    }
  }, [uploadFileResponse])

  return (
    <Row>
      <Col xs={12} md={6} xl={6} className="mb-4">
        <FormGroup>
          <AvField
            id="age"
            name="age"
            label={t("Age")}
            className="form-control"
            type="number"
            required
            placeholder={t("Enter Age")}
            value={age}
            onChange={(e) => {
              updateData("age", e.target.value)
            }}
            min={0}
          />
        </FormGroup>
      </Col>

      <Col xs={12} md={6} xl={6} className="mb-4">
        <label className="form-label">{t("Gender")}</label>
        <select
          className="form-control"
          value={gender}
          onChange={(e) => {
            updateData("gender", e.target.value)
          }}
        >
          <option value={"MALE"}>{t("Male")}</option>
          <option value={"FEMALE"} >{t("Female")}</option>
          <option value={"OTHERS"} >{t("Others")}</option>
        </select>
      </Col>

      <Col xs={12} md={6} xl={6} className="mb-4">
        <FormGroup>
          <AvField
            id="drivingLicenseNumber"
            name="drivingLicenseNumber"
            label={t("Driving License Number")}
            className="form-control"
            type="text"
            required
            placeholder={t("Enter Driving License Number")}
            value={drivingLicenseNumber}
            onChange={(e) => {
              updateData("drivingLicenseNumber", e.target.value)
            }}
            min={0}
          />
        </FormGroup>
      </Col>

      <Col xs={12} md={6} xl={6} className="mb-4">
        <FormGroup>
          {(drivingLicenseImageURL === "" || drivingLicenseImageURL === null) && (
            <AvField
              id="drivingLicenseImageURL"
              name="drivingLicenseImageURL"
              label={t("Driving License Image")}
              className="form-control"
              type="file"
              placeholder={t("Upload Driving License Image")}
              // value={drivingLicenseImageURL}
              onChange={(e) => {
                uploadFile('drivingLicenseImageURL', e.target.files[0])
              }}
              min={0}
            />
          )}

          {drivingLicenseImageURL && drivingLicenseImageURL !== null && drivingLicenseImageURL !== "" && (
            <>
              <label>Driving License Image</label>
              <ul className="list-inline mb-0 font-size-14">
                <li className="list-inline-item">
                  {/* <label>{drivingLicenseImageURL}</label> */}
                  <img style={{ "height": '130px', "width": "auto" }} src={getImageForUser(data.id, drivingLicenseImageURL)} alt="" />
                  <i className="bx bxs-download text-success" style={{ "height": "44px", "width": "44px", "marginLeft": "10px" }} onClick={() => {
                    downloadFile(drivingLicenseImageURL)
                  }} id="downloadDoc"></i>
                  <i className="bx bx-x text-danger" style={{ "height": "44px", "width": "44px", "marginLeft": "10px" }} onClick={() => {
                    updateData('drivingLicenseImageURL', "")
                  }} id="downloadDoc"></i>
                </li>
              </ul>
            </>
          )}

        </FormGroup>
      </Col>
      <Col xs={12} md={6} xl={6} className="mb-4">
        <FormGroup>
          <AvField
            id="adhaarImageURL"
            name="adhaarImageURL"
            label={t("Aadhar card number")}
            className="form-control"
            type="text"
            placeholder={t("1234-1234-1234")}
            value={adhaarImageURL}
            onChange={(e) => {
              updateData("adhaarImageURL", e.target.value)
            }}
            min={0}
          />
        </FormGroup>
      </Col>



      <Col xs={12} md={6} xl={6} className="mb-4">
        <FormGroup>
          <AvField
            id="hobbies"
            name="hobbies"
            label={t("Hobbies")}
            className="form-control"
            type="text"
            placeholder={t("Enter Hobbies")}
            value={hobbies}
            onChange={(e) => {
              updateData("hobbies", e.target.value)
            }}
            min={0}
          />
        </FormGroup>
      </Col>

      <Col xs={12} md={6} xl={6} className="mb-4">
        <FormGroup>
          <AvField
            id="shortNotes"
            name="shortNotes"
            label={t("Short Notes")}
            className="form-control"
            type="text"
            placeholder={t("Enter Short Notes")}
            value={shortNotes}
            onChange={(e) => {
              updateData("shortNotes", e.target.value)
            }}
            min={0}
          />
        </FormGroup>
      </Col>

      <Col xs={12} md={6} xl={6} className="mb-4">
        <FormGroup>
          <AvField
            id="latestQualification"
            name="latestQualification"
            label={t("Latest Qualification")}
            className="form-control"
            type="text"
            required
            placeholder={t("Enter Latest Qualification")}
            value={latestQualification}
            onChange={(e) => {
              updateData("latestQualification", e.target.value)
            }}
            min={0}
          />
        </FormGroup>
      </Col>
      <Col xs={12} md={6} xl={6} className="mb-4">
        <FormGroup>
          <Label>Languages known</Label>
          <AvCheckboxGroup name="checkboxExample" value="allowedServices" required>
            {languageList && languageList.map(item => {
              return (
                <AvCheckbox checked={selectedLanguages && selectedLanguages.includes(item.key)} label={item.value} key={item.key} value={item.key} onChange={(e) => {
                  languageSelected(e)
                }} />
              )
            })}
          </AvCheckboxGroup>
        </FormGroup>
      </Col>
      <Col xs={12} md={6} xl={6} className="mb-4">

        <FormGroup>
          <Label>Select servable services</Label>
          <AvCheckboxGroup name="checkboxExample" value="allowedServices" required>
            {serviceTypeList && serviceTypeList.map(item => {
              return (
                <AvCheckbox checked={selectedService.includes(item.id)} label={item.serviceType} key={item.id} value={item.id} onChange={(e) => {
                  changed(e)
                }} />
              )
            })}
          </AvCheckboxGroup>
        </FormGroup>
      </Col>
    </Row>
  )
}

ServiceProviderFields.propTypes = {
  t: PropTypes.any,
  data: PropTypes.instanceOf(Object),
  updateData: PropTypes.func
}

export default withTranslation()(ServiceProviderFields)

import { takeEvery, call, put, all } from "redux-saga/effects"
import {
  FETTH_PAYMENT_INFO_BY_SERVICEBOOKING_ID,
  GENERATE_INVOICE,
  GENERATE_ORDER,
  GET_ALL_WALLET_BALANCE,
  GET_PAYMENT_DETAIL,
  GET_WALLET_BALANCE_BY_USER_ID,
  LOG_PAYMENT_DETAIL,
  MAKE_PAYMENT,
  UPDATE_WALLET_BALANCE,
} from "./actionTypes"
import {
  generateInvoice,
  generateOrder,
  getAllWalletBalance,
  getAnnualSubPricingDetail,
  getPaymentInfoByServiceBookingId,
  getWalletBalance,
  logPaymentDetail,
  makePaymentService,
  updateWalletBalance,
} from "../../utils/Apiv2"
import {
  paymentStatus,
  makePayment,
  paymentDetailByServiceIdResponse,
  logPaymentDetailResponse,
  walletBalanceByUserId,
  allWalletBalance,
  updateWalletBalanceResp,
  getPricingDetailResp,
  getAnnualPricingDetailResp,
} from "./actions"
import { PAYMENT_MODE } from "constants/common"

function* generateOrderDetail(data) {
  try {
    const resp = yield call(generateOrder, data.payload)
    if (resp) {
      if (resp.paymentMode === PAYMENT_MODE.ONLINE) {
        yield put(makePayment(resp.data))
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* makePaymentSaga(data) {
  try {
    const resp = yield call(makePaymentService, data.payload)
    // yield put(paymentStatus(resp))
  } catch (error) {
    console.error(error)
  }
}

function* logPaymentDetailSaga(data) {
  try {
    const resp = yield call(logPaymentDetail, data.payload)
    if (resp) {
      yield put(logPaymentDetailResponse(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* generateInvoiceSaga(data) {
  try {
    const resp = yield call(generateInvoice, data.payload)
    if (resp) {
      // yield put(logPaymentDetailResponse(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* getPaymentInfoByServiceBookingIdSaga(data) {
  try {
    const resp = yield call(getPaymentInfoByServiceBookingId, data.payload)
    yield put(paymentDetailByServiceIdResponse(resp))
  } catch (error) {
    console.error(error)
  }
}

function* getWalletBalanceSaga(data) {
  try {
    const resp = yield call(getWalletBalance, data.payload)
    if (resp) {
      yield put(walletBalanceByUserId(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* getAllWalletBalanceSaga(data) {
  try {
    const resp = yield call(getAllWalletBalance, null)
    if (resp) {
      yield put(allWalletBalance(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* getPricingDetailSaga(data) {
  try {
    const resp = yield call(getAnnualSubPricingDetail, data.payload)
    if (resp) {
      console.log('Debug ::> resp', resp)
      yield put(getAnnualPricingDetailResp(resp))
    }
  } catch (error) {
    console.log('Debug ::> 102', 102)
    yield put(getAnnualPricingDetailResp(error))
    console.error(error)
  }
}

function* updateWalletBalanceSaga(data) {
  try {
    const resp = yield call(updateWalletBalance, data.payload)
    if (resp) {
      yield all([
        put(updateWalletBalanceResp(resp)),
        put(walletBalanceByUserId(resp)),
      ])
    }
  } catch (error) {
    console.error(error)
  }
}

function* PaymentSaga() {
  yield takeEvery(GENERATE_ORDER, generateOrderDetail)
  yield takeEvery(MAKE_PAYMENT, makePaymentSaga)
  yield takeEvery(LOG_PAYMENT_DETAIL, logPaymentDetailSaga)
  yield takeEvery(
    FETTH_PAYMENT_INFO_BY_SERVICEBOOKING_ID,
    getPaymentInfoByServiceBookingIdSaga
  )
  yield takeEvery(GET_WALLET_BALANCE_BY_USER_ID, getWalletBalanceSaga)
  yield takeEvery(GET_ALL_WALLET_BALANCE, getAllWalletBalanceSaga)
  yield takeEvery(UPDATE_WALLET_BALANCE, updateWalletBalanceSaga)
  yield takeEvery(GET_PAYMENT_DETAIL, getPricingDetailSaga)
  yield takeEvery(GENERATE_INVOICE, generateInvoiceSaga)
}

export default PaymentSaga

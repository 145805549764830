import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types'

import { getMinMaxTimeList } from "../../../utils/validations"

const DateTimeResponse = ({
    datetimePickersRef,
    type,
    title,
    updateUserMessage,
    setUserInputBtnDisabled,
    widgetStyle
}) => {
    const {
        primaryBGC,
        primaryTextColor,
        botIcon
    } = useMemo(() => widgetStyle, [widgetStyle])

    const getMinDateTime = useCallback(() => {
        // min date time calculations
        const today = new Date()
        let minMonth = today.getMonth() + 1
        minMonth = ("0" + minMonth).slice(-2)
        let minDayDate = today.getDate()

        // do not allow if current time > 9 pm
        if (today.getHours() > 21) {
            today.setDate(today.getDate() + 1)
        }
        minDayDate = ("0" + minDayDate).slice(-2)
        const minDate = today.getFullYear() + '-' + minMonth + '-' + minDayDate

        const minTime = "08:00"

        switch (type) {
            case "date":
                return minDate
            case "time":
                return minTime
            case "datetime": {
                return `${minDate}T${minTime}`
            }
            default:
                return minDate
        }
    }, [type])

    const getMaxDateTime = useCallback(() => {
        // max date time calculations
        const today = new Date()
        const nextOneMonth = new Date(new Date().setMonth(today.getMonth() + 1))
        let maxMonth = nextOneMonth.getMonth() + 1
        maxMonth = ("0" + maxMonth).slice(-2)
        let maxDayDate = nextOneMonth.getDate()
        maxDayDate = ("0" + maxDayDate).slice(-2)
        const maxDate = nextOneMonth.getFullYear() + '-' + maxMonth + '-' + maxDayDate

        const maxTime = "21:00"

        switch (type) {
            case "date":
                return maxDate
            case "time":
                return maxTime
            case "datetime": {
                return `${maxDate}T${maxTime}`
            }
            default:
                return maxDate
        }
    }, [type])

    const [datetimeValue, setDatetimeValue] = useState("")

    const datetimeChangeHandle = useCallback((value) => {
        const valueNew = value.target.value
        switch (type) {
            case "date": {
                const [year, month, day] = valueNew.split("-")
                const newValue = `${day}-${month}-${year}`

                setDatetimeValue(valueNew)
                updateUserMessage(newValue)
                break
            }
            case "time": {
                setDatetimeValue(valueNew)
                updateUserMessage(valueNew)
                break
            }
            case "datetime": {
                setDatetimeValue(valueNew)

                const [dateString, timeString] = valueNew.split("T")
                const [year, month, date] = dateString.split("-")
                const [hours, minutes] = timeString.split(":")

                updateUserMessage(`${year}-${month}-${date} ${hours}:${minutes}`)
                break
            }
            default:
                break
        }
    }, [setDatetimeValue, updateUserMessage, type])

    useEffect(() => {
        setUserInputBtnDisabled(true)

        return (() => {
            setUserInputBtnDisabled(false)
            setDatetimeValue("")
        })
    }, [setUserInputBtnDisabled, type])

    const dateTimePicker = useMemo(() => {
        switch (type) {
            case "date": {
                return (
                    <input
                        className="date-input"
                        type="date"
                        id="date-input"
                        min={getMinDateTime()}
                        max={getMaxDateTime()}
                        value={datetimeValue}
                        onChange={datetimeChangeHandle}
                    />
                )
            }
            case "time": {
                const minTime = getMinDateTime()
                const maxTime = getMaxDateTime()

                const timestampId = new Date().getTime().toString()

                return (
                    <>
                        {/* <input
              type="time"
              id="time-input"
              className="time-input"
              min={getMinDateTime()}
              max={getMaxDateTime()}
              value={datetimeValue}
              onChange={datetimeChangeHandle}
            /> */}

                        <select
                            className="form-select form-select-sm"
                            aria-label="Default select example"
                            value={datetimeValue}
                            onChange={datetimeChangeHandle}
                        >
                            {getMinMaxTimeList(minTime, maxTime, 30, timestampId).map((value) => <option key={value} value={value}>{value}</option>)}
                        </select>
                    </>
                )
            }
            case "datetime": {
                return (
                    <input
                        className="datetime-input"
                        type="datetime-local"
                        id="datetime-input"
                        min={getMinDateTime()}
                        max={getMaxDateTime()}
                        value={datetimeValue}
                        onChange={datetimeChangeHandle}
                    />
                )
            }
            default:
                break
        }
    }, [
        type, datetimeValue, datetimeChangeHandle,
        getMinDateTime, getMaxDateTime
    ])

    return (
        <>
            <div className="bot-response">
                <div>
                    <img src={botIcon} className="bot-profile-photo" alt="elie" />
                </div>

                <div className="bot-msg-div">
                    <div className="bot-msg" style={{ background: primaryBGC, color: primaryTextColor }}>
                        {/* <p style={{ color: primaryTextColor }}>
              {title}
            </p> */}

                        <div dangerouslySetInnerHTML={{ __html: title }} style={{ color: primaryTextColor }}>
                            {/* {title} */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="datetimePickersRef" ref={datetimePickersRef}>
                <div className="datetime-picker-response">
                    {dateTimePicker}
                </div>
            </div>
        </>
    )
}

DateTimeResponse.propTypes = {
    datetimePickersRef: PropTypes.any,
    type: PropTypes.string,
    title: PropTypes.string,
    updateUserMessage: PropTypes.func,
    setUserInputBtnDisabled: PropTypes.func,
    widgetStyle: PropTypes.any
}

export default DateTimeResponse

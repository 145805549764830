import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/sc.png"
import default_avatar from '../../assets/images/users/default_avatar.png';
import profileImg from "../../assets/images/profile-img.png"
import { useSelector } from "react-redux"
import { getUserDetail } from "utils/userUtil"
import { getImageForUser } from "utils/Common"
import { USER_TYPE } from "constants/common";

const socials = [
  {
    title: "Facebook",
    bgColor: "bg-primary",
    iconClass: "mdi-facebook",
    description: "182",
    param: 'likes'
  },
  {
    title: "Twitter",
    bgColor: "bg-info",
    iconClass: "mdi-twitter",
    description: "141",
    param: 'follower'
  },
  {
    title: "Instagram",
    bgColor: "bg-pink",
    iconClass: "mdi-instagram",
    description: "13",
    param: 'followers'
  },
]

const WelcomeComp = () => {
  let userDetail = JSON.parse(getUserDetail())

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome {userDetail?.firstName}!</h5>
                <p>Happy60Plus Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row style={{ "marginTop": "-30px" }}>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={userDetail ? getImageForUser(userDetail.id, userDetail.profileImageURL) : default_avatar}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              {/* <h5 className="font-size-15 text-truncate">{userDetail?.firstName} {" "} {userDetail?.lastName}</h5> */}
              {/* <p className="text-muted mb-0 text-truncate">UI/UX Designer</p> */}
            </Col>

            {/* <Col sm="8">
              <div className="pt-4">
                <div className="mt-4">
                  <Link
                    to="/profile"
                    className="btn btn-primary  btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col> */}
          </Row>
          {userDetail?.type === USER_TYPE.ADMIN && <Row style={{ "marginTop": "-30px" }}>
            {socials.map((social, key) => (
              <Col xs="4" key={"_li_" + key}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">

                    <span
                      className={
                        "avatar-title rounded-circle " +
                        social.bgColor +
                        " font-size-16"
                      }
                    >
                      <i
                        className={"mdi " + social.iconClass + " text-white"}
                      ></i>
                    </span>
                  </div>
                  <h5 className="font-size-15">{social.title}</h5>
                  <p className="text-muted mb-0">{social.description} {social.param}</p>
                </div>
              </Col>
            ))}
          </Row>}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp

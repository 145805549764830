import { ENV, URL } from 'constants/common';

function getServerDetail() {
    if (window.location.hostname === 'app.happy60plus.com') {
        return {
            env: ENV.PROD,
            url: URL.PROD
        };
    }
    else if (window.location.hostname.indexOf('test') !== -1) {
        return {
            env: ENV.TEST,
            url: URL.TEST
        };
    }
    else if (window.location.hostname == "localhost") {
        return {
            env: ENV.LOCAL,
            url: URL.TEST
        };
    }

}

export {
    getServerDetail
}
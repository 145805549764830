import React from 'react';
import { Label } from 'reactstrap';

const PaymentDisclosure = () => {
    return (
        <div style={{ "fontSize": '10px' }}>
            <Label>If you have make online payment, it might take maximum 30 min to reflect.</Label>
            <Label>If you have any issue related to payment or service booking, please contact support team support[at]happy60plus[dot]com</Label>
        </div>

    )
}

export default PaymentDisclosure;
import {
    PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, PROFILE_DETAIL,
    ADDRESS_DETAIL, CONTACT_DETAIL, VEHICLE_DETAIL, SENIOR_CITIZEN_DETAIL, HELP_BUDDY_DETAIL,
    GET_PROFILE, UPDATE_PROFILE, GET_ADDRESS_DETAIL, GET_VEHICLE_DETAIL, GET_CONTACT_DETAIL, GET_HELP_BUDDY_DETAIL,
    UPDATE_SENIOR_CITIZEN_DETAIL, UPDATE_HELP_BUDDY_DETAIL, UPDATE_PROFILE_STATUS,
    UPDATE_ADDRESS, UPDATE_VEHICLE_DETAIL, UPDATE_CONTACT_DETAIL,
    DELETE_CONTACT, DELETE_VEHICLE, DELETE_ADDRESS, GET_NON_APPROVED_HELP_BUDDY_DETAIL, NON_APPROVED_HELP_BUDDY_DETAIL, GET_APPROVED_HELP_BUDDY, APPROVED_HELP_BUDDY,
    CHANGE_PASSWORD, CHANGE_PASSWORD_RESPONSE, GET_SENIOR_CITIZEN_DETAIL, SELECTED_USER, VERIFY_SUBSCRIPTION, VERIFY_SUBSCRIPTION_RESPONSE,
} from "./actionTypes"

export const getProfileDetail = id => {
    return {
        type: GET_PROFILE,
        payload: id
    }
}

export const profileDetail = user => {
    return {
        type: PROFILE_DETAIL,
        payload: { user }
    }
}
export const editProfile = user => {
    return {
        type: EDIT_PROFILE,
        payload: { user },
    }
}

export const selectProfile = user => {
    return {
        type: SELECTED_USER,
        payload: { ...user },
    }
}

export const updateProfileDetail = (data) => {
    return {
        type: UPDATE_PROFILE,
        payload: data
    }
}

export const profileSuccess = msg => {
    return {
        type: PROFILE_SUCCESS,
        payload: msg,
    }
}

export const profileError = error => {
    return {
        type: PROFILE_ERROR,
        payload: error,
    }
}

export const resetProfileFlag = error => {
    return {
        type: RESET_PROFILE_FLAG,
    }
}

export const seniorCitizenDetail = (userData) => {
    return {
        type: SENIOR_CITIZEN_DETAIL,
        payload: userData
    }
}

export const getSeniorCitizenDetail = (id) => {
    return {
        type: GET_SENIOR_CITIZEN_DETAIL,
        payload: id
    }
}

export const updateSeniorCitizenDetail = (data) => {
    return {
        type: UPDATE_SENIOR_CITIZEN_DETAIL,
        payload: data
    }
}

export const helpBuddyDetail = (userData) => {
    return {
        type: HELP_BUDDY_DETAIL,
        payload: userData
    }
}

export const getHelpBuddyDetail = (id) => {
    return {
        type: GET_HELP_BUDDY_DETAIL,
        payload: id
    }
}

export const getNonApprovedHelpBuddyDetail = () => {
    return {
        type: GET_NON_APPROVED_HELP_BUDDY_DETAIL,
        payload: null
    }
}

export const nonApprovedHelpBuddyDetail = (data) => {
    return {
        type: NON_APPROVED_HELP_BUDDY_DETAIL,
        payload: data
    }
}

export const getApprovedHelpBuddyDetail = () => {
    return {
        type: GET_APPROVED_HELP_BUDDY,
        payload: null
    }
}

export const ApprovedHelpBuddyDetail = (data) => {
    return {
        type: APPROVED_HELP_BUDDY,
        payload: data
    }
}

export const updateHelpBuddyDetail = (data) => {
    return {
        type: UPDATE_HELP_BUDDY_DETAIL,
        payload: data
    }
}

export const updateProfileResponseStatus = (data) => {
    return {
        type: UPDATE_PROFILE_STATUS,
        payload: data
    }
}

export const getAddressDetail = (id) => {
    return {
        type: GET_ADDRESS_DETAIL,
        payload: id
    }
}

export const addressDetail = addressInfo => {
    return {
        type: ADDRESS_DETAIL,
        payload: addressInfo
    }
}

export const updateAddressDetail = (data) => {
    return {
        type: UPDATE_ADDRESS,
        payload: data
    }
}

export const deleteAddresses = (address) => {
    return {
        type: DELETE_ADDRESS,
        payload: address
    }
}

export const getVehicleDetail = (id) => {
    return {
        type: GET_VEHICLE_DETAIL,
        payload: id
    }
}


export const vehicleDetail = vehicleInfo => {
    return {
        type: VEHICLE_DETAIL,
        payload: vehicleInfo
    }
}

export const updateVehicleDetail = (data) => {
    return {
        type: UPDATE_VEHICLE_DETAIL,
        payload: data
    }
}

export const deleteVehicles = (vehicleDetail) => {
    return {
        type: DELETE_VEHICLE,
        payload: vehicleDetail
    }
}

export const getContactDetail = (id) => {
    return {
        type: GET_CONTACT_DETAIL,
        payload: id
    }
}

export const updateContactDetail = (data) => {
    return {
        type: UPDATE_CONTACT_DETAIL,
        payload: data
    }
}

export const deleteContacts = (contactInfo) => {
    return {
        type: DELETE_CONTACT,
        payload: contactInfo
    }
}

export const contactDetail = contactInfo => {
    return {
        type: CONTACT_DETAIL,
        payload: contactInfo
    }
}

export const changePassword = (passwordInfo) => {
    return {
        type: CHANGE_PASSWORD,
        payload: passwordInfo
    }
}

export const changePasswordResponse = (resp) => {
    return {
        type: CHANGE_PASSWORD_RESPONSE,
        payload: resp
    }
}

export const verifySubscription = (resp) => {
    return {
        type: VERIFY_SUBSCRIPTION,
        payload: resp
    }
}
export const verifySubscriptionResponse = (resp) => {
    return {
        type: VERIFY_SUBSCRIPTION_RESPONSE,
        payload: resp
    }
}

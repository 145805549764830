import React, { useState } from "react";
import {
    Container
} from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumb from "../../components/Common/Breadcrumb";
import AssignmentMgmt from '../../components/Happy60/AssignmentMgmt'
import { useSelector } from "react-redux";
import HealthCareBooking from "components/Happy60/healthCareBooking";
import { USER_TYPE } from "constants/common";

const AssignmentPage = () => {
    const userDetail = useSelector(state => state.Profile.userProfile)
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Assignments | Happy60Plus</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    {userDetail && userDetail.type === USER_TYPE.PARTNER ? (
                        <HealthCareBooking />
                    ) : (
                        <>
                            <Breadcrumb title="Home" breadcrumbItem="Assignments" />
                            <AssignmentMgmt></AssignmentMgmt>
                        </>
                    )}
                </Container>
            </div >
        </React.Fragment >
    );
}

export default AssignmentPage;
import React from "react";
import {
    Container
} from "reactstrap";
import MetaTags from "react-meta-tags";

import Breadcrumb from "../../components/Common/Breadcrumb";

import Packages from '../../components/Happy60/Packages'

const PackagesPage = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Packages | Happy60Plus</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Home" breadcrumbItem="Packages" />
                    <Packages />

                </Container>
            </div>
        </React.Fragment>
    );
}

export default PackagesPage;

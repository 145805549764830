import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER, VALIDATE_REFERRAL_CODE } from "./actionTypes"
import {
  registerUserSuccessful,
  registerUserFailed,
  setReferralCodeValidity,
} from "./actions"

import { checkReferralCodeValidity } from "../../../utils/Apiv2"
import { submitUserDetail } from "../../../utils/Apiv2"
import { setUserDetail } from "utils/userUtil"
import { loginSuccess } from "../login/actions"
import { getProfileDetail, helpBuddyDetail } from "../profile/actions"
import { USER_TYPE } from "constants/common"
import { getWalletBalanceByUserId } from "store/actions"

// Is user register successfull then direct plot user in redux.
function* registerUserSaga({ payload: { user, history } }) {
  try {
    const resp = yield call(submitUserDetail, user)
    if (resp.status) {
      yield put(registerUserSuccessful(resp))
      const userInfo = resp.data
      setUserDetail(userInfo)
      yield all([
        put(loginSuccess(userInfo)),
        put(getProfileDetail(userInfo.id)),
      ])
      if (resp?.data?.helpBuddy?.length) {
        yield put(helpBuddyDetail(resp.data.helpBuddy[0]))
      }
      if (userInfo.type === USER_TYPE.ADMIN) {
        history.push("/dashboard")
      } else if (userInfo.type === USER_TYPE.SENIOR_CITIZEN) {
        history.push("/services")
        yield put(getWalletBalanceByUserId())
      } else if (userInfo.type === USER_TYPE.SERVICE_PROVIDER) {
        if (resp?.data?.helpBuddy?.length) {
          if (resp.data.helpBuddy[0].verified) {
            history.push("/assignment")
          } else {
            history.push("/onboard")
          }
        } else {
          history.push("/profile")
        }
      } else if (userInfo.type === USER_TYPE.PARTNER) {
        history.push("/dashboard")
      } else {
        history.push("/profile")
      }
    } else {
      yield put(registerUserFailed(resp))
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

function* checkReferralCode(data) {
  try {
    const resp = yield call(checkReferralCodeValidity, data.payload)
    if (resp) {
      yield put(setReferralCodeValidity(resp || false))
    }
  } catch (e) {
    yield put(setReferralCodeValidity(false))
    console.log("Debug ::> e", e)
  }
}

function* RegisterSaga() {
  yield takeEvery(REGISTER_USER, registerUserSaga)
  yield takeEvery(VALIDATE_REFERRAL_CODE, checkReferralCode)
}

export default RegisterSaga

import React, { useMemo } from "react"
import PropTypes from "prop-types"

import { withTranslation } from "react-i18next"
import { Card, CardHeader, CardBody, CardText, Col, Row, FormGroup, Label } from 'reactstrap';
import { USER_TYPE } from '../../../constants/common';

const USER_TYPE_ADMIN = USER_TYPE.ADMIN

const PackageDetail = ({
    key,
    data,
    userDetail,
    bookPackage,
    knowMore,
    editPackage,
    deletePackage
}) => {
    const { name, totalPackagePrice, validity, description } = useMemo(() => data || {}, [data])
    const { type: userType } = useMemo(() => userDetail || {}, [userDetail])

    return (
        <Col lg={4} sm={6} xs={12}>
            <Card key={key} outline className="border height-adusted-card" color="primary">
                <CardHeader className="bg-transparent">
                    <h5 className="my-0 text-primary">
                        <i className="mdi mdi-bullseye-arrow me-3" />{name}
                    </h5>
                </CardHeader>
                <CardBody>
                    <CardText>
                        <Row>
                            <Col xs={12} sm={12}>
                                Price: {totalPackagePrice ? "₹ " + totalPackagePrice : ""}
                            </Col>
                            <Col xs={12} sm={12}>
                                Validity: {validity ? validity + " Days" : ""}
                            </Col>
                            <Col xs={12} sm={12}>
                                Description: {description}
                            </Col>
                        </Row>
                    </CardText>

                    <Row>
                        <Col>
                            <div
                                className="btn-group btn-group-example mb-3"
                                role="group"
                            >
                                {
                                    userType === USER_TYPE_ADMIN ? (
                                        <>
                                            {editPackage && (
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary w-sm"
                                                    onClick={() => { editPackage(data) }}
                                                    style={{ 'cursor': 'pointer' }}
                                                >
                                                    Edit
                                                </button>
                                            )}

                                            {deletePackage && (
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-danger w-sm"
                                                    onClick={() => { deletePackage(data) }}
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {bookPackage && (
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary w-sm"
                                                    onClick={() => { bookPackage(data) }}
                                                    style={{ 'cursor': 'pointer' }}
                                                >
                                                    Book Now
                                                </button>
                                            )}
                                        </>
                                    )
                                }

                                {knowMore && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary w-sm"
                                        onClick={() => { knowMore(data) }}
                                    >
                                        Know More
                                    </button>
                                )}
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

PackageDetail.propTypes = {
    t: PropTypes.any,
    data: PropTypes.any,
    key: PropTypes.any,
    userDetail: PropTypes.any,
    bookPackage: PropTypes.func,
    knowMore: PropTypes.func,
    editPackage: PropTypes.func,
    deletePackage: PropTypes.func,
    showDetails: PropTypes.func
}

export default withTranslation()(PackageDetail);

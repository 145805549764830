import React from "react"
import { Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import Breadcrumb from "../../components/Common/Breadcrumb"
import ServiceType from "../../components/Happy60/ServiceType"
// import ServiceOffer from "../../components/Happy60/ServiceOffer"

const ServiceTypePage = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Services | Happy60Plus</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Home" breadcrumbItem="Service Types" />
          <ServiceType />
          {/* <ServiceOffer /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ServiceTypePage

const { EMOTIONAL_QUOTIENTS, SOCIAL_BEHAVIOUR, UPDATE_EMOTIONAL_QUOTIENT, UPDATE_SOCIAL_BEHAVIOUR, BEHAVIOURAL_ANALYSIS_BY_USER_ID, EMOTIONAL_QUOTIENT_BY_USER_ID } = require("./actionTypes")

const initialState = {
    emotionalQuotient: [],
    socialBehaviour: [],
    behaviouranalysisByUserId: [],
    emotionalQuotientByUserId: []
}

const OnboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMOTIONAL_QUOTIENTS:
            return { ...state, emotionalQuotient: action.payload }
        case UPDATE_EMOTIONAL_QUOTIENT:
            return {
                ...state,
                emotionalQuotient: state.emotionalQuotient.filter(item => item.eqId === action.payload.field).length
                    ? state.emotionalQuotient.map(item => {
                        if (item.eqId === action.payload.field) {
                            item.value = action.payload.value;
                        }
                        return item;
                    })
                    : [...state.emotionalQuotient, { eqId: action.payload.field, value: action.payload.value }]
            }
        case SOCIAL_BEHAVIOUR: {
            return { ...state, socialBehaviour: action.payload }
        }
        case UPDATE_SOCIAL_BEHAVIOUR:
            return {
                ...state,
                socialBehaviour: state.socialBehaviour.filter(item => item.name === action.payload.field).length ? state.socialBehaviour.map(item => {
                    if (item.name === action.payload.field) {
                        item.socialUrl = action.payload.value;
                    }
                    return item;
                }) : [...state.socialBehaviour, { name: action.payload.field, socialUrl: action.payload.value }]
            }
        case BEHAVIOURAL_ANALYSIS_BY_USER_ID:
            return {
                ...state,
                behaviouranalysisByUserId: action.payload
            }
        case EMOTIONAL_QUOTIENT_BY_USER_ID:
            return {
                ...state,
                emotionalQuotientByUserId: action.payload
            }
        default:
            return state;

    }
}

export default OnboardReducer;
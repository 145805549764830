export const GET_ALL_USER = "GET_ALL_USER";
export const ALL_USER = "ALL_USER";

export const GET_ALL_SENIOR_CITIZEN = "GET_ALL_SENIOR_CITIZEN";
export const ALL_SENIOR_CITIZEN = "ALL_SENIOR_CITIZEN";

export const GET_ALL_HELP_BUDDY_LIST = "GET_ALL_HELP_BUDDY_LIST";
export const ALL_HELP_BUDDY_LIST = "ALL_HELP_BUDDY_LIST";

export const ALL_PARTNER_LIST = "ALL_PARTNER_LIST";
export const CREATE_PARTNER = "CREATE_PARTNER"
export const CREATE_PARTNER_RESPONSE = "CREATE_PARTNER_RESPONSE"
export const CREATE_PARTNER_ERROR = "CREATE_PARTNER_ERROR"


export const CREATE_HEALTH_BUDDY_LIST_RESPONSE = "CREATE_HEALTH_BUDDY_LIST_RESPONSE"
export const GET_HEALTH_BUDDY_LIST = "GET_HEALTH_BUDDY_LIST"
export const GET_HEALTH_BUDDY_LIST_RESPONSE = "GET_HEALTH_BUDDY_LIST_RESPONSE"
export const UPDATE_HEALTH_BUDDY_LIST = "UPDATE_HEALTH_BUDDY_LIST"
export const UPDATE_HEALTH_BUDDY_LIST_RESPONSE = "UPDATE_HEALTH_BUDDY_LIST_RESPONSE"

export const SEARCH_USER = "SEARCH_USER"
import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch, useSelector } from "react-redux";
import AssignmentDetail from "./assignmentDetailModal";
import { Button, Label } from "reactstrap";
import moment from 'moment';
import { getAllAssignments, getServiceType, rejectService } from "store/actions";
// import ConfirmModal from '../shared/confirmModal';
import ConfirmModal from "components/Common/ConfirmModal";
import { AddressFormatter } from "../shared/addressFormatter";
import { USER_TYPE, PAYMENT_STATUS } from "constants/common";
import PaymentModal from "../Payment/paymentModal";
import DataTable from "react-data-table-component";
import { getUserDetail } from "utils/userUtil";

const AllAssignments = ({
    userRole
}) => {
    const dispatch = useDispatch();
    const userId = getUserDetail('id')
    const [isPartner, setIsPartner] = useState(false)
    useEffect(() => {
        if (userRole && userRole === USER_TYPE.PARTNER && userId) {
            dispatch(getAllAssignments({userId, type: 'HealthCare'}));
            setIsPartner(true);
        }else {
            dispatch(getAllAssignments());
        }     
    }, [dispatch, userId])
    const [assignmentDetailModal, setAssignmentDetailModal] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const allAssignmentList = useSelector(state => state.AssignmentReducer.allAssignments);
    let modeList = useSelector(state => state.CommonReducer.serviceMode);
    let serviceType = useSelector(state => state.ServiceReducer.serviceType);
    
    useEffect(() => {
        if (!serviceType?.length) {
          dispatch(getServiceType())
        }
      }, [serviceType])

    const getServiceName = (serviceId) => {
        let serviceObj = serviceType.find(item => item.id === serviceId);
        return serviceObj && serviceObj.serviceType ? serviceObj.serviceType : "-";
    }
    const getMode = (modeId) => {
        let mode = modeList.filter(item => item.id === modeId)
        if (mode && mode.length) {
            return mode[0].description;
        }
        else {
            return "NA"
        }
    }

    const rejectAssignment = () => {
        dispatch(rejectService({ ...selectedAssignment, serviceStatus: 'REJECTED' }));
    }

    let columns = [
        {
            id: 1,
            name: "Service",
            selector: (item) => isPartner ? item?.bookingDetail?.serviceID : item.serviceID,
            format: (item) => (getServiceName(isPartner ? item?.bookingDetail?.serviceID : item.serviceID)),
            sortable: true,
            reorder: true
        },
        {
            id: 2,
            name: "Date",
            selector: (item) => item.dateTimeFrom,
            format: (item) => (moment(item.dateTimeFrom).isValid() ? moment.utc(item.dateTimeFrom).local().format("DD-MM-YYYY hh:mm A") : '-'),
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "Duration",
            selector: (item) => isPartner ? item?.bookingDetail?.duration : item.duration,
            sortable: true,
            // right: true,
            reorder: true
        },
        {
            id: 4,
            name: "Address",
            selector: (item) => (
                <AddressFormatter data={item.address} props={{ className: 'mb-0' }} />
            ),
            sortable: true,
            // right: true,
            reorder: true
        },
    ];

    if (userRole && userRole === USER_TYPE.ADMIN) {
        columns = [
            ...columns,
            {
                id: 5,
                name: "UserName",
                selector: (item) => item,
                format: (item) => (item.user.firstName + " " + item.user.lastName),
                sortable: true,
                // right: true,
                reorder: true
            }, {
                id: 6,
                name: "Contact No",
                selector: (item) => item.user.mobileNumber,
                sortable: true,
                // right: true,
                reorder: true
            }, {
                id: 7,
                name: "Booked Date",
                selector: (item) => item.createdOn,
                format: (item) => (item.createdOn
                    ? moment(item.createdOn).isValid()
                        ? moment.utc(item.createdOn).local().format("DD-MM-YYYY hh:mm A")
                        : moment(Number(item.createdOn)).isValid()
                            ? moment(Number(item.createdOn)).local().format("DD-MM-YYYY hh:mm A")
                            : '-'
                    : '-'),
                sortable: true,
                // right: true,
                reorder: true
            }]
    }
    if (userRole && userRole === USER_TYPE.PARTNER) {
        columns = [
            ...columns,
            {
                id: 5,
                name: "Diagnosis",
                selector: (item) => (
                    <span>{item.diagnosis}</span>
                ),
                sortable: true,
                // right: true,
                reorder: true
            }
        ]
    }
    columns = [
        ...columns,
        {
            id: 8,
            name: "Service Mode",
            selector: (item) => (
                <span>{getMode(item.serviceMode)}</span>
            ),
            sortable: true,
            // right: true,
            reorder: true
        },
        {
            id: 9,
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
            // right: true,
            reorder: true
        },
        {
            id: 10,
            name: "Action",
            selector: (item) => (
                <div>
                    <ul className="list-inline mb-0 font-size-20">
                        {userRole && userRole === USER_TYPE.ADMIN && (
                            <>
                                <li className="list-inline-item">
                                    <i className="bx bxs-user-detail text-primary" onClick={() => {
                                        setSelectedAssignment(item)
                                        setAssignmentDetailModal(true)
                                    }} />
                                </li>
                                {item.paymentStatus && item.paymentStatus !== PAYMENT_STATUS.PAID && (
                                    <li className="list-inline-item">
                                        <i className="bx bx-money text-success" onClick={() => {
                                            setSelectedAssignment(item)
                                            setPaymentModal(true)
                                        }} ></i>
                                    </li>
                                )}
                            </>
                        )}
                        {userRole && userRole === USER_TYPE.SERVICE_PROVIDER && (
                            <>
                                <li className="list-inline-item">
                                    <i className="bx bx-check text-success" onClick={() => {
                                        setSelectedAssignment(item)
                                        setAssignmentDetailModal(true)
                                    }} />
                                </li>
                                <li className="list-inline-item">
                                    <i className="bx bx-x text-danger" onClick={() => {
                                        setSelectedAssignment(item)
                                        setAssignmentDetailModal(true)
                                    }} />
                                </li>
                            </>
                        )}
                        {userRole && userRole === USER_TYPE.PARTNER && (
                            <>
                                <li className="list-inline-item">
                                    <i className="bx bxs-user-detail text-primary" onClick={() => {
                                        setSelectedAssignment(item)
                                        setAssignmentDetailModal(true)
                                    }} />
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            ),
            sortable: true,
            // right: true,
            reorder: true
        }]

        // console.log('Debug ::> allAssignmentList', allAssignmentList)

    return (
        <React.Fragment>
            {
                allAssignmentList.length > 0 && (
                    <DataTable
                        noHeader
                        // title="Senior Citizen"
                        columns={columns}
                        data={allAssignmentList}
                        defaultSortFieldId={2}
                        // sortIcon={<SortIcon />}
                        pagination
                        highlightOnHover
                    // selectableRows
                    />
                )
            }
            {
                allAssignmentList.length === 0 && (
                    <Label>No Assignments found</Label>
                )
            }

            {
                selectedAssignment && (
                    <>
                        <AssignmentDetail
                            isOpen={assignmentDetailModal}
                            data={selectedAssignment}
                            closeModal={() => {
                                setAssignmentDetailModal(false)
                            }}
                            userRole={userRole}
                            onCancelClick={() => {
                                setAssignmentDetailModal(false)
                                setShowConfirmModal(true)
                            }}
                        >
                        </AssignmentDetail>
                        <PaymentModal
                            isOpen={paymentModal}
                            data={selectedAssignment}
                            onCancelClick={() => {
                                setPaymentModal(false)
                            }}
                            closeModal={() => {
                                setPaymentModal(false)
                            }}>

                        </PaymentModal>
                    </>
                )
            }
            <ConfirmModal
                isOpen={showConfirmModal}
                titleText="Cancel Assignment"
                bodyText="Are you sure you want to reject assignment"
                onYesClick={() => {
                    rejectAssignment()
                    setShowConfirmModal(false)
                }}
                onNoClick={() => {
                    setShowConfirmModal(false)
                }}
            />
        </React.Fragment>
    )
}

AllAssignments.propTypes = {
    userRole: PropTypes.any
}

export default AllAssignments

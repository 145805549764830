import { DOWNLOAD_USER_DOCUMENT, DOWNLOAD_USER_DOCUMENT_RESP, GET_VALUE_HELPS, LOAD_SCRIPT, SEND_WHATAPP_MESSAGE, UPLOAD_USER_DOCUMENT, UPLOAD_USER_DOCUMENT_RESP, VALUE_HELPS } from './actionTypes';

export const loadScript = (script) => {
    return {
        type: LOAD_SCRIPT,
        payload: script
    }
}

export const getValueHelps = () => {
    return {
        type: GET_VALUE_HELPS,
        payload: null
    }
}

export const valueHelps = (valueHelps) => {
    return {
        type: VALUE_HELPS,
        payload: valueHelps
    }
}

export const sendWhatsappMessage = (buddyId) => {
    return {
        type: SEND_WHATAPP_MESSAGE,
        payload: buddyId
    }
}

export const uploadUserDocument = (key, file) => {
    return {
        type: UPLOAD_USER_DOCUMENT,
        payload: {
            key, file
        }
    }
}

export const uploadUserDocumentResp = (data) => {
    return {
        type: UPLOAD_USER_DOCUMENT_RESP,
        payload: data
    }
}

export const downloadUserDocument = (doc_id) => {
    return {
        type: DOWNLOAD_USER_DOCUMENT,
        payload: doc_id
    }
}

export const downloadUserDocumentResp = (data) => {
    return {
        type: DOWNLOAD_USER_DOCUMENT_RESP,
        payload: data
    }
}
export const GENERATE_ORDER = "generate_order";
export const ORDER_RESPONSE = "order_response";
export const MAKE_PAYMENT = "make_payment";
export const PAYMENT_STATUS = "payment_status";
export const CLEAR_PAYMENT_STATUS = "CLEAR_PAYMENT_STATUS";
export const LOG_PAYMENT_DETAIL = "LOG_PAYMENT_DETAIL";
export const LOG_PAYMENT_DETAIL_RESPONSE = "LOG_PAYMENT_DETAIL_RESPONSE"

export const FETTH_PAYMENT_INFO_BY_SERVICEBOOKING_ID = "FETTH_PAYMENT_INFO_BY_SERVICEBOOKING_ID";
export const PAYMENT_INFO_BY_SERVICE_ID = "PAYMENT_INFO_BY_SERVICE_ID";
export const GET_WALLET_BALANCE_BY_USER_ID = "GET_WALLET_BALANCE_BY_USER_ID";
export const WALLET_BALANCE_BY_USER_ID = "WALLET_BALANCE_BY_USER_ID";
export const GET_ALL_WALLET_BALANCE = "GET_ALL_WALLET_BALANCE";
export const ALL_WALLET_BALANCE = "ALL_WALLET_BALANCE";
export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
export const UPDATE_WALLET_BALANCE_RESP = "UPDATE_WALLET_BALANCE_RESP"

export const GET_PAYMENT_DETAIL = "GET_PAYMENT_DETAIL"
export const GET_PAYMENT_DETAIL_RESP = "GET_PAYMENT_DETAIL_RESP"


export const GENERATE_INVOICE = "GENERATE_INVOICE"
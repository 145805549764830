// Admin Packages
export const GET_ADMIN_PACKAGES = "GET_ADMIN_PACKAGES"
export const ADMIN_PACKAGES = "ADMIN_PACKAGES"

// User Packages
export const GET_USER_PACKAGES = "GET_USER_PACKAGES"
export const USER_PACKAGES = "USER_PACKAGES"

// Packages by User Id
export const GET_PACKAGES_BY_USER_ID = "GET_PACKAGES_BY_USER_ID"
export const PACKAGES_BY_USER_ID = "PACKAGES_BY_USER_ID"

// Packages CRUD
export const ADD_PACKAGE = "ADD_PACKAGE"
export const ADD_PACKAGE_RESPONSE = "ADD_PACKAGE_RESPONSE"

export const UPDATE_PACKAGE = "UPDATE_PACKAGE"
export const UPDATE_PACKAGE_RESPONSE = "UPDATE_PACKAGE_RESPONSE"

export const DELETE_PACKAGE = "DELETE_PACKAGE"
export const DELETE_PACKAGE_RESPONSE = "DELETE_PACKAGE_RESPONSE"

// Book Package
export const BOOK_PACKAGE = "BOOK_PACKAGE"
export const BOOK_PACKAGE_RESPONSE = "BOOK_PACKAGE_RESPONSE"

// Book Service from Package
export const BOOK_SERVICE_FROM_PACKAGE = "BOOK_SERVICE_FROM_PACKAGE"
export const BOOK_SERVICE_FROM_PACKAGE_RESPONSE = "BOOK_SERVICE_FROM_PACKAGE_RESPONSE"

// Package Booking
export const GET_PACKAGE_BOOKINGS = "GET_PACKAGE_BOOKINGS"
export const PACKAGE_BOOKINGS = "PACKAGE_BOOKINGS"

export const GET_PACKAGE_BOOKINGS_BY_USER_ID = "GET_PACKAGE_BOOKINGS_BY_USER_ID"
export const PACKAGE_BOOKINGS_BY_USER_ID = "PACKAGE_BOOKINGS_BY_USER_ID"

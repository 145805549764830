import React, { useEffect } from "react"
import PropTypes from "prop-types"

import { Modal } from "reactstrap"

const TripAndToursKnowMore = ({ knowMoreData, isOpen, closeKnowMore }) => {
  return (
    <div>
      <Modal
        id="TripModal"
        isOpen={isOpen}
        centered={true}
        className="modal-dialog-centered modal-xl"
      >
        <div className="modal-header" style={{ borderBottom: "none" }}>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => closeKnowMore()}
          ></button>
        </div>

        <iframe
          src={knowMoreData.pdfUrl}
          // frameBorder="0"
          // scrolling="auto"
          height="100%"
          width="100%"
          style={{
            minWidth: 720,
            minHeight: 750,
            border: 0,
            overflow: "hidden",
          }}
        ></iframe>
      </Modal>
    </div>
  )
}

TripAndToursKnowMore.propTypes = {
  knowMoreData: PropTypes.any,
  isOpen: PropTypes.any,
  closeKnowMore: PropTypes.any,
}

export default TripAndToursKnowMore

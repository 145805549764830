import React from "react"
import { useSelector } from "react-redux";
import { Row, Col, CardBody, Card, CardHeader, CardText } from "reactstrap"
import { USER_TYPE } from '../../constants/common';
import { getUserDetail } from "utils/userUtil";

const ServiceOffer = () => {
    let userDetail = JSON.parse(getUserDetail())
    return (
        <React.Fragment>
            <Row>
                <Col lg={4} sm={6} xs={12}>
                    <Card color="primary" outline className="border height-adusted-card">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0 text-primary">
                                <i className="mdi mdi-bullseye-arrow me-3" />Winter Sale
                            </h5>
                        </CardHeader>
                        <CardBody>

                            <CardText>
                                Get Flat 10% on all the services. T&C Apply
                                <br />
                                <ul>
                                    <li>Offer valid till 12th March</li>
                                    <li>Offer only valid for first Service</li>
                                    <li>Maximum discount is 500 INR</li>
                                </ul>
                                {userDetail && userDetail.type !== USER_TYPE.ADMIN && (
                                    <div
                                        className="btn-group btn-group-example mb-3"
                                        role="group"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary w-sm"
                                        >
                                            Book Now
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary w-sm"
                                        >
                                            Know More
                                        </button>
                                    </div>
                                )}
                                {userDetail && userDetail.type === USER_TYPE.ADMIN && (
                                    <div className="btn-group btn-group-example mb-3">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary w-sm"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger w-sm"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                )}
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg={4} sm={6} xs={12}>
                    <Card color="primary" outline className="border">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0 text-primary">
                                <i className="mdi mdi-bullseye-arrow me-3" />Flat 30% on Healthcare Services
                            </h5>
                        </CardHeader>
                        <CardBody>

                            <CardText>
                                Avail 30% discount on all Healthcare related services. T&C Apply.
                                <br />
                                <ul>
                                    <li>Offer valid till 12th March</li>
                                    <li>Offer only valid for first Service</li>
                                    <li>Maximum discount is 500 INR</li>
                                </ul>

                                <div
                                    className="btn-group btn-group-example mb-3"
                                    role="group"
                                >
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary w-sm"
                                    >
                                        Book Now
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary w-sm"
                                    >
                                        Know More
                                    </button>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ServiceOffer;
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import countryCode from "../../utils/countryCode"
import {
  bookAnnualSubscription,
  clearPaymentStatus,
  generateInvoice,
  getAnnualPricingDetail,
  getPricingDetail,
  loadScript,
  logPaymentDetail,
  makePayment,
} from "store/actions"
import { PAYMENT_MODE, RZP_SCRIPT } from "constants/common"
import { useSelector, useDispatch } from "react-redux"

import { Link, useParams } from "react-router-dom"
import { MetaTags } from "react-meta-tags"

import goldCard from "../../assets/images/gold-card.jpeg"
import { getUserDetail } from "utils/userUtil"

const URL = {
  PROD: "https://server.happy60plus.com",
  TEST: "https://test.server.happy60plus.com",
}

const eventFun = event => {
  event.preventDefault()
  event.returnValue = ""
}

const remove = () => {
  window.removeEventListener("beforeunload", eventFun)
}
const add = () => {
  window.addEventListener("beforeunload", eventFun)
}

const SenagerClubModal = () => {
  const dispatch = useDispatch()
  const routeParams = useParams()

  const paymentStatus = useSelector(state => state.PaymentReducer.paymentStatus)
  let orderDetail = useSelector(state => state.PaymentReducer.orderDetail)
  let paymentDetail = useSelector(
    state => state.PaymentReducer.annualSubPaymentDetail
  )
  let subscriptionData = useSelector(
    state => state.ServiceReducer?.annualSubscriptionData
  )
  let subscriptionDataMessage = useSelector(
    state => state.ServiceReducer?.annualSubscriptionData
  )

  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState(
    "Thank you, For subscription Please Pay to confirm your subscription."
  )
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [selectedCountry, setCountry] = useState("+91")
  const [paymentDetailModal, setPaymentDetailModal] = useState(false)
  const [isTrialSelected, setIsTrial] = useState(false)
  const [errors, setError] = useState({
    name: false,
    mobileNumber: false,
    serviceID: false,
  })

  const togglePayment = () => setPaymentDetailModal(!paymentDetailModal)
  const formatAmount = amount => {
    return parseFloat(amount / 100).toFixed(2)
  }

  useEffect(() => {
    if (routeParams?.subscriptionId && routeParams?.userId) {
      setSuccessMessage(
        "Thank you, For subscription Please Pay to confirm your subscription."
      )
      setIsTrial(false)
      setShowSuccess(true)
      setErrorMessage(false)
      setShowError(false)
      dispatch(getAnnualPricingDetail(routeParams?.subscriptionId))
    }
  }, [])

  useEffect(() => {
    if (subscriptionData?.id && !isTrialSelected) {
      console.log("Debug :::> 1", 1)
      setSuccessMessage(
        "Thank you, For subscription Please Pay to confirm your subscription."
      )
      setIsTrial(false)
      setShowSuccess(true)
      setErrorMessage(false)
      setShowError(false)
      // payServiceFees()
      dispatch(getAnnualPricingDetail(subscriptionData?.id))
    } else if (subscriptionData?.id && isTrialSelected) {
      console.log("Debug :::> 1", 12)
      setShowSuccess(true)
      setErrorMessage(false)
      setShowError(false)
      // setSuccessMessage("Thank you, For subscription.")
    } else if (subscriptionData && !subscriptionData?.id) {
      console.log("Debug :::> 1", 13)
      setErrorMessage(true)
      setShowError(false)
      setShowSuccess(false)
      // setSuccessMessage(
      //   "Thank you, For subscription Please Pay to confirm your subscription."
      // )
      setIsTrial(false)
    } else if (subscriptionDataMessage && subscriptionDataMessage?.message) {
      console.log("Debug :::> 1", 14)
      setErrorMessage(false)
      setShowError(false)
      setShowSuccess(true)
      // setSuccessMessage(subscriptionDataMessage?.message)
      setIsTrial(false)
    }
    if (subscriptionData || subscriptionDataMessage) {
      if (subscriptionData?.message || subscriptionDataMessage?.message) {
        console.log(
          "Debug ::> subscriptionData?.message",
          subscriptionData?.message || subscriptionDataMessage?.message
        )
        setSuccessMessage(
          subscriptionData?.message ?? subscriptionDataMessage?.message
        )
      }
    }
  }, [subscriptionData, subscriptionDataMessage])

  useEffect(() => {
    if (paymentDetail) {
      togglePayment()
    }
    // if (!paymentDetail && !paymentDetail?.id) {
    //   setShowError(true)
    //   setErrorMessage(false)
    //   setShowSuccess(false)
    // }
  }, [paymentDetail])

  useEffect(() => {
    if (paymentStatus?.razorpay_payment_id) {
      // This dispatch should call once we get data from razorpay

      const paymentLog = {
        id: paymentDetail?.paymentId,
        bookingID: subscriptionData?.id || routeParams?.subscriptionId,
        userID: subscriptionData?.userID || routeParams?.userId,
        paymentMode: "ONLINE",
        razorPayDetails: {
          razorPayOrderID: paymentDetail.razorPayOrderId,
          razorPayPaymentID: paymentStatus?.razorpay_payment_id,
        },
        amount: subscriptionData?.servicePrice,
        isTransportationService: false,
        isGoldMembership: true,
      }
      console.log(
        "APP-LOG: File: SenagerClubModal.js Line: #166::> paymentLog",
        paymentLog
      )

      dispatch(logPaymentDetail(paymentLog))
      dispatch(
        generateInvoice({
          userID: subscriptionData?.userID || routeParams?.userId,
          serviceMode: "ONLINE",
          razorPayPaymentId: paymentStatus?.razorpay_payment_id,
          amount: subscriptionData?.servicePrice - subscriptionData?.gstPrice,
        })
      )
      setShowSuccess(false)
      setShowError(false)
      setPaymentDetailModal(false)
      remove()
      setTimeout(() => {
        document.getElementById("EnquiryForm").reset()
        dispatch(clearPaymentStatus())
        setTimeout(() => {
          setCountry("+91")
        }, 500)
        setSuccessMessage(
          "Thank you, For subscription Please Pay to confirm your subscription."
        )
        setIsTrial(false)
      }, 1500)
    }
  }, [paymentStatus])

  const payServiceFees = () => {
    dispatch(loadScript(RZP_SCRIPT))
    add()
    setTimeout(() => {
      dispatch(
        makePayment({
          ...subscriptionData,
          amount: subscriptionData?.servicePrice,
          razorPayOrderId: paymentDetail.razorPayOrderId,
        })
      )
    }, 500)
  }

  function isValid(formSubmitted = false) {
    if (!formSubmitted && !isFormSubmitted) {
      return
    }

    const form = document.getElementById("EnquiryForm")
    const formData = new FormData(form)
    const data = Object.fromEntries(formData.entries())

    let isValid = true
    const error = {
      name: false,
      mobileNumber: false,
    }

    for (const [key, value] of Object.entries(data)) {
      if (!value || value.trim() === "") {
        if (key !== "email") {
          error[key] = true
          isValid = false
        }
        if (key === "pincode") {
          error[key] = false
          isValid = true
        }
      } else if (key === "mobileNumber") {
        const regex = /^[0-9 ~@#$^*()_+=[\]{}|\\,.?: -]{10,15}$/
        const isValidPhone = regex.test(value)
        if (!isValidPhone) {
          error[key] = true
          isValid = false
        }
      }
    }
    setError(error)
    return isValid
  }

  const handleSubmit = event => {
    event.preventDefault()
    setFormSubmitted(true)
    const formData = new FormData(event.target)
    const formJSON = Object.fromEntries(formData.entries())
    if (isValid(true)) {
      formJSON.countryCode = formJSON.dialCode.slice(1)
      formJSON.isTrialSelected = isTrialSelected || false
      delete formJSON.dialCode
      dispatch(bookAnnualSubscription(formJSON))
    }
    return false
  }

  return (
    <>
      <MetaTags>
        <title>Gold Card | Happy60Plus</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-2 pt-sm-2">
        <div className="gold-card">
          <img src={goldCard} className="rounded w-100" alt="Gold Card" />
          <div className="faq-contact triangle">
            <div className="enquiry-wrapper">
              <form
                id="EnquiryForm"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <div className="row">
                  <div className="col-lg-12 col-md-12 p-3 px-5">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        className={`form-control ${
                          errors.name && "is-invalid"
                        }`}
                        style={{
                          fontSize: "1.8rem",
                        }}
                        onChange={() => isValid()}
                      />
                    </div>
                  </div>

                  <div className={`col-lg-12 col-md-12 p-3 px-5`}>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-4 pe-0 border-end">
                          <select
                            id="dialCode"
                            name="dialCode"
                            className={`form-control form-select`}
                            style={{
                              textAlignLast: "center",
                              fontSize: "1.8rem",
                              borderBottomRightRadius: "unset",
                              borderTopRightRadius: "unset",
                            }}
                            defaultValue={selectedCountry}
                            onChange={e => {
                              setCountry(e.target.value)
                            }}
                          >
                            {countryCode &&
                              countryCode.map(country => (
                                <option
                                  key={`k-${country.name}`}
                                  value={country.dialCode}
                                >
                                  {country.dialCode === selectedCountry
                                    ? country.dialCode
                                    : `${country.name} ${country.dialCode}`}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-8 ps-0">
                          <input
                            type="tel"
                            // pattern="^\d{10}$"
                            name="mobileNumber"
                            placeholder="Phone Number"
                            className={`form-control ${
                              errors.mobileNumber && "is-invalid"
                            }`}
                            onChange={() => isValid()}
                            style={{
                              fontSize: "1.8rem",
                              borderBottomLeftRadius: "unset",
                              borderTopLeftRadius: "unset",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                  <div className="col-lg-6 col-md-6 d-grid mx-auto">
                      <button
                        className="btn btn-gradient services-button enquiry-button text-uppercase"
                        type="submit"
                        onClick={() => {
                          setIsTrial(true)
                        }}
                      >
                        Subscribe for Trial
                      </button>
                    </div> */}
                  <div className="col-lg-8 col-md-8 d-grid mx-auto">
                    <button
                      className="btn btn-gradient services-button enquiry-button text-uppercase"
                      type="submit"
                      onClick={() => {
                        setIsTrial(false)
                      }}
                    >
                      Subscribe Now
                    </button>
                  </div>
                  {/* </div> */}
                </div>
              </form>
            </div>
          </div>
          {showSuccess && !showError && !errorMessage && (
            <div className="col-lg-12 col-md-12 d-grid gap-2 mx-auto p-2 w-75">
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            </div>
          )}
          {showError && !errorMessage && !showSuccess && (
            <div className="col-lg-12 col-md-12 d-grid gap-2 mx-auto p-2 w-75">
              <div className="alert alert-danger" role="alert">
                Something Went wrong, Please Try again!
              </div>
            </div>
          )}
          {errorMessage && (
            <div className="col-lg-12 col-md-12 d-grid gap-2 mx-auto p-2 w-75">
              <div className="alert alert-danger" role="alert">
                Annual Subscription already booked!!
              </div>
            </div>
          )}
        </div>
        {/* </Card> */}
      </div>

      {paymentDetailModal && (
        <Modal
          id="PriceModal"
          isOpen={paymentDetailModal}
          toggle={togglePayment}
        >
          <ModalHeader toggle={togglePayment}>
            <h5 className="modal-title mt-0">Subscribe Gold Club</h5>
          </ModalHeader>
          <ModalBody>
            <h5
              className="text-uppercase"
              style={{
                fontSize: "1rem",
              }}
            >
              Benefits
            </h5>
            <ul className="text-capitalize">
              <li>
                Free access to daily online engagement activities - yoga, music,
                tambola, fun activities, mind games, hobby sessions, discussion
                forums
              </li>
              <li>Free registration for webinars</li>
              <li>
                Attractive offers & discounts on other Happy60plus services
              </li>
              <li>Discounts and offers through Happy60plus partners</li>
            </ul>
            <h5>
              Price : ₹
              {formatAmount(
                subscriptionData?.servicePrice - subscriptionData?.gstPrice
              )}
            </h5>
            <h5>GST : ₹{formatAmount(subscriptionData?.gstPrice)}</h5>
            <h4>
              Price: ₹{formatAmount(subscriptionData?.servicePrice)} per annum
            </h4>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                togglePayment()
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={payServiceFees}
            >
              {paymentDetail &&
              paymentDetail.paymentMode &&
              paymentDetail.paymentMode === PAYMENT_MODE.ONLINE
                ? "Make Payment & Subscribe"
                : "Subscribe"}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

SenagerClubModal.propTypes = {}

export default SenagerClubModal

import React, { useMemo } from "react";
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import {
  Modal,
  Row,
  Col,
  FormGroup
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

const AddEditContact = ({
  t,
  isOpen,
  currentContactObject,
  onSubmitClick,
  onCancelClick
}) => {

  const { id, firstName, lastName, mobileNumber } = useMemo(() => currentContactObject || {}, [currentContactObject])

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    onSubmitClick({ ...currentContactObject, ...v })
  }

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {id ? t("Update Contact") : t("Add Contact")}
        </h5>
      </div>
      <AvForm className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v)
        }}>
        <div className="modal-body">

          <Row>
            <Col xs={12} sm={12} className="mb-4">
              <FormGroup>
                <AvField
                  name="firstName"
                  label={t("First Name")}
                  value={firstName}
                  className="form-control"
                  placeholder={t("Enter First Name")}
                  type="text"
                  required
                />
              </FormGroup>
            </Col>

            <Col xs={12} className="mb-4">
              <FormGroup>
                <AvField
                  name="lastName"
                  label={t("Last Name")}
                  value={lastName}
                  className="form-control"
                  placeholder={t("Enter Last Name")}
                  type="text"
                  required
                />
              </FormGroup>
            </Col>

            <Col xs={12}>
              <FormGroup>
                <AvField
                  name="mobileNumber"
                  label={t("Mobile Number")}
                  value={mobileNumber}
                  className="form-control"
                  placeholder={t("Enter Mobile Number")}
                  type="number"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              onCancelClick();
            }}
            className="btn btn-secondary px-4"
            data-dismiss="modal"
          >
            {t("Cancel")}
          </button>

          <button
            type="submit"
            className="btn btn-primary px-4"
          >
            {currentContactObject && currentContactObject._links ? t("Update") : t("Add")}
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

AddEditContact.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  currentContactObject: PropTypes.any
}

export default withTranslation()(AddEditContact)

function extractUrlParam(url = null) {
    let queryParam = {};

    const allQueryParam = url ? url.substr(1) : window.location.search.substr(1);

    allQueryParam.split("&").forEach(item => {
        let qParam = item.split('=');
        if (qParam[0] !== '' && qParam[0] !== null && qParam[1] !== "" && qParam[1] !== null) {
            queryParam[qParam[0]] = qParam[1]
        }
    })
    console.log(queryParam);

    return queryParam;
}

export {
    extractUrlParam
}
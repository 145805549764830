import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link, withRouter, Switch } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import { connect, useSelector } from "react-redux"

import { USER_TYPE } from "../../constants/common"
import SenagerClubModal from "pages/Authentication/SenagerClubModal"
import { getUserDetail } from "utils/userUtil"

const Navbar = props => {
  let userDetail = JSON.parse(getUserDetail())
  let helpBuddyDetail = useSelector(state => state.Profile.helpBuddyDetail)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  const homePageServiceAccessIds = [
    "65045c599e9da24406a59df7",
    "6544998f07a94e388fa931b9",
    "661f64089f845849e975dc4a",
  ]
  const showHomePageServiceAccessIds = homePageServiceAccessIds.includes(
    userDetail.id
  )

  return (
    <Switch>
      <>
        <div className="topnav">
          <div className="container-fluid">
            {/* <Tabs /> */}
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              {/* <Tabs /> */}
              <ul className="navbar-nav">
                {userDetail && userDetail.type === USER_TYPE.ADMIN && (
                  <>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/dashboard"
                      >
                        <i className="bx bxs-home-circle me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Dashboard")}{" "}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/approval"
                      >
                        <i className="bx bx-check me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Approvals")}{" "}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/assignment"
                      >
                        <i className="bx bx-task me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Assignment")}{" "}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/services"
                      >
                        <i className="bx bxs-home-circle me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Services")}{" "}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/packages"
                      >
                        <i className="bx bxs-home-circle me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Packages")}{" "}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/users"
                      >
                        <i className="bx bxs-user me-2"></i>
                        <span className="hidden-xs">{props.t("Users")} </span>
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            to="/blog"
                                        >
                                            <i className="bx bx-home-circle me-2"></i>
                                            <span className="hidden-xs">{props.t("Blogs")} </span>
                                        </Link>
                                    </li> */}
                  </>
                )}
                {userDetail &&
                  userDetail.type === USER_TYPE.SERVICE_PROVIDER &&
                  helpBuddyDetail &&
                  helpBuddyDetail.verified && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/assignment"
                      >
                        <i className="bx bx-task me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Assignment")}{" "}
                        </span>
                      </Link>
                    </li>
                  )}
                {userDetail &&
                  userDetail.type === USER_TYPE.SERVICE_PROVIDER &&
                  helpBuddyDetail &&
                  !helpBuddyDetail.verified && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/onboard"
                      >
                        <i className="bx bx-home-circle me-2"></i>
                        <span className="hidden-xs">{props.t("Onboard")} </span>
                      </Link>
                    </li>
                  )}
                {userDetail && userDetail.type === USER_TYPE.SENIOR_CITIZEN && (
                  <React.Fragment>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/services"
                      >
                        <i className="bx bxs-home-circle me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Services")}{" "}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/packages"
                      >
                        <i className="bx bxs-home-circle me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Packages")}{" "}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/offers"
                      >
                        <i className="bx bxs-offer me-2"></i>
                        <span className="hidden-xs">{props.t("Offers")} </span>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/bookings"
                      >
                        <i className="bx bxs-collection me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Bookings")}{" "}
                        </span>
                      </Link>
                    </li>
                  </React.Fragment>
                )}
                {userDetail && userDetail.type === USER_TYPE.PARTNER && (
                  <>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/dashboard"
                      >
                        <i className="bx bxs-home-circle me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Dashboard")}{" "}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/users"
                      >
                        <i className="bx bx-task me-2"></i>
                        <span className="hidden-xs">{props.t("Users")} </span>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/assignment"
                      >
                        <i className="bx bx-task me-2"></i>
                        <span className="hidden-xs">
                          {props.t("Assignment")}{" "}
                        </span>
                      </Link>
                    </li>
                  </>
                )}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/profile"
                  >
                    <i className="bx bxs-user-account me-2"></i>
                    <span className="hidden-xs">{props.t("Profile")} </span>
                  </Link>
                </li>

                {userDetail && userDetail.type === USER_TYPE.ADMIN && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/enquiry"
                    >
                      <i className="bx bxs-user me-2"></i>
                      <span className="hidden-xs">{props.t("Enquiry")} </span>
                    </Link>
                  </li>
                )}

                {/* {userDetail && userDetail.type === USER_TYPE.ADMIN && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/trip"
                    >
                      <i className="bx bxs-user me-2"></i>
                      <span className="hidden-xs">{props.t("Trip")} </span>
                    </Link>
                  </li>
                )} */}
                {userDetail && userDetail.type === USER_TYPE.SENIOR_CITIZEN && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/trip"
                    >
                      <i className="bx bxs-user me-2"></i>
                      <span className="hidden-xs">{props.t("Trip")} </span>
                    </Link>
                  </li>
                )}

                {showHomePageServiceAccessIds && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/home-page-service"
                    >
                      <i className="bx bxs-user me-2"></i>
                      <span className="hidden-xs">
                        {props.t("Home Page Services")}{" "}
                      </span>
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
        {userDetail && userDetail.type === USER_TYPE.SENIOR_CITIZEN && (
          <div
            className="topnav"
            style={{
              backgroundColor: "#87308f",
              marginTop: 120,
            }}
          >
            <div className="container-fluid">
              {/* <Tabs /> */}
              <nav
                className="navbar navbar-light navbar-expand-lg topnav-menu"
                id="navigation"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/gold-card"
                      style={{ color: "#FFF" }}
                      // onClick={toggle}
                    >
                      Online activities for seniors. Click here to subscribe.
                    </Link>
                    {/* <SenagerClubModal modal={modal} toggle={toggle} /> */}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </>
    </Switch>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)

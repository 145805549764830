import {
    USER_REGISTRATION_DATA,
    REVENUE_DATA,
    SERVICE_BOOKING_BY_TYPE_DATA,
    TOTAL_SERVICE_BOOKING_DATA,
    GENERAL_STATS,
    TOTAL_REGISTERED_USER
} from "./actionTypes";

const INIT_STATE = {
    totalServiceBookingData: [],
    totalRegisteredUser: 0,
    UserRegistrationData: {},
    revenueData: {},
    serviceBookingByType: {},
    generalStats: {}
};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOTAL_SERVICE_BOOKING_DATA:
            return {
                ...state,
                totalServiceBookingData: action.payload
            };
        case TOTAL_REGISTERED_USER:
            return {
                ...state,
                totalRegisteredUser: action.payload
            };
        case USER_REGISTRATION_DATA:
            return {
                ...state,
                UserRegistrationData: action.payload
            };
        case REVENUE_DATA:
            return {
                ...state,
                revenueData: action.payload
            };
        case SERVICE_BOOKING_BY_TYPE_DATA:
            return {
                ...state,
                serviceBookingByType: action.payload
            };
        case GENERAL_STATS:
            return {
                ...state,
                generalStats: action.payload
            };
        default:
            return state;
    }
}


export default Dashboard;
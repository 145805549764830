import { ALL_WALLET_BALANCE, CLEAR_PAYMENT_STATUS, FETTH_PAYMENT_INFO_BY_SERVICEBOOKING_ID, GENERATE_INVOICE, GENERATE_ORDER, GET_ALL_WALLET_BALANCE, GET_PAYMENT_DETAIL, GET_PAYMENT_DETAIL_RESP, GET_WALLET_BALANCE_BY_USER_ID, LOG_PAYMENT_DETAIL, LOG_PAYMENT_DETAIL_RESPONSE, MAKE_PAYMENT, ORDER_RESPONSE, PAYMENT_INFO_BY_SERVICE_ID, PAYMENT_STATUS, UPDATE_WALLET_BALANCE, UPDATE_WALLET_BALANCE_RESP, WALLET_BALANCE_BY_USER_ID } from './actionTypes';

export const generateOrder = (orderInfo) => {
    return {
        type: GENERATE_ORDER,
        payload: orderInfo
    }
}

export const orderResponse = (orderResp) => {
    return {
        type: ORDER_RESPONSE,
        payload: orderResp
    }
}

export const makePayment = (paymentDetail) => {
    return {
        type: MAKE_PAYMENT,
        payload: paymentDetail
    }
}

export const paymentStatus = (resp) => {
    return {
        type: PAYMENT_STATUS,
        payload: resp
    }
}

export const clearPaymentStatus = () => {
    return {
        type: CLEAR_PAYMENT_STATUS,
        payload: null
    }
}

export const logPaymentDetail = (data) => {
    return {
        type: LOG_PAYMENT_DETAIL,
        payload: data
    }
}

export const generateInvoice = (data) => {
    return {
        type: GENERATE_INVOICE,
        payload: data
    }
}

export const getAnnualPricingDetail = (data) => {
    return {
        type: GET_PAYMENT_DETAIL,
        payload: data
    }
}

export const getAnnualPricingDetailResp = (data) => {
    return {
        type: GET_PAYMENT_DETAIL_RESP,
        payload: data
    }
}

export const logPaymentDetailResponse = (data) => {
    return {
        type: LOG_PAYMENT_DETAIL_RESPONSE,
        payload: data
    }
}

export const fetchPaymentDetailByServiceBookingId = (serviceBookingId) => {
    return {
        type: FETTH_PAYMENT_INFO_BY_SERVICEBOOKING_ID,
        payload: serviceBookingId
    }
}

export const paymentDetailByServiceIdResponse = (resp) => {
    return {
        type: PAYMENT_INFO_BY_SERVICE_ID,
        payload: resp
    }
}

export const getWalletBalanceByUserId = (userId = null) => {
    return {
        type: GET_WALLET_BALANCE_BY_USER_ID,
        payload: userId
    }
}

export const walletBalanceByUserId = (data) => {
    return {
        type: WALLET_BALANCE_BY_USER_ID,
        payload: data
    }
}

export const getAllWalletBalance = () => {
    return {
        type: GET_ALL_WALLET_BALANCE,
        payload: null
    }
}

export const allWalletBalance = (data) => {
    return {
        type: ALL_WALLET_BALANCE,
        payload: data
    }
}

export const updateWalletBalance = (data) => {
    return {
        type: UPDATE_WALLET_BALANCE,
        payload: data
    }
}

export const updateWalletBalanceResp = (data) => {
    return {
        type: UPDATE_WALLET_BALANCE_RESP,
        payload: data
    }
}
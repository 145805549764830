// Core libraries
import React, { Component } from "react";
import cloneDeep from 'lodash/cloneDeep';
import axios from "axios"

// Local UI Components
import ChatbotWidgetComponent from "./ChatbotWidgetComponent"

import { initialiseChat, sendUserMessage, getActionDetails, getSlotDetails, post } from "../../utils/Apiv2"

import alertCall from "../Common/CustomAlert"

import h60pIconImage from "../../assets/images/happy60plus/happy60plus_icon-square.png"

const widgetStyleDefault = {
    botColor: "#556ee6",
    // botIcon: "https://botlist.cloudhub360.com/api/photo/629ee16e850d2853d58d13dd",
    botIcon: h60pIconImage,
    botName: "askanni",
    primaryBGC: "#E3E7EE",
    primaryTextColor: "#000000",
    secondaryBGC: "#556ee6",
    secondaryTextColor: "#FFFFFF",
    userIcon: "https://botlist.cloudhub360.com/api/photo/629d9678850d2853d58d13db",
    standardErrorMessage: "Sorry, I didn't get you!"
}

let source

const dateSampleObject = {
    recipient_id: "61540b99fc2275007191002f",
    custom: {
        payload: "date",
        title: "Please select a service date"
    }
}

const timeSampleObject = {
    recipient_id: "61540b99fc2275007191002f",
    custom: {
        payload: "time",
        title: "Please provide a service starting time between 08:00 to 21:00.<br>Time Format => *hh:mm* (24 Hours)"
    }
}

const durationSampleObject = {
    recipient_id: "61540b99fc2275007191002f",
    custom: {
        payload: "duration",
        title: "You have to book atleat 1 hour of this service.",
        min: 60,
        max: 1440,
        step: 30
    }
}

class ChatbotWidgetContainer extends Component {
    state = {
        chatHistory: [],
        slotDetails: {},
        actionDetails: {},
        botTyping: false,
        widgetOverlayloading: false,
        fileToBeUploaded: null,
        isUploadOrExtractionInProgress: false,
        showWidget: false,
        chatbotInitialised: false
    };

    initialiseChatbot = () => {
        this.setState({ widgetOverlayloading: true, botTyping: true }, () => {
            initialiseChat()
                .then((response) => {
                    // console.log("initialiseChat response", response)
                    this.setState((prevState) => ({
                        widgetOverlayloading: false, botTyping: false,
                        chatbotInitialised: true,
                        chatHistory: prevState.chatHistory.concat({ sender: "bot", ...response })
                        // chatHistory: prevState.chatHistory.concat({ sender: "bot", messages: [dateSampleObject] })
                        // chatHistory: prevState.chatHistory.concat({ sender: "bot", messages: [timeSampleObject] })
                        // chatHistory: prevState.chatHistory.concat({ sender: "bot", messages: [durationSampleObject] })
                    }))
                })
                .catch((e) => {
                    // console.log("initialiseChat catch error", e)
                    this.setState((prevState) => ({
                        widgetOverlayloading: false, botTyping: false,
                        chatHistory: prevState.chatHistory.concat({ sender: "bot", messages: [{ text: widgetStyleDefault.standardErrorMessage }] })
                    }))
                })
        })
    }

    setShowWidget = (show) => {
        this.setState({ showWidget: show }, () => {
            if (show && !this.state.chatbotInitialised) {
                this.initialiseChatbot()
            }
        })
    }

    handleSendSubmit = async ({ message, addUserMessage = true, title = "" }) => {
        const standardErrorMessage = widgetStyleDefault.standardErrorMessage

        this.setState((prevState) => ({
            chatHistory: addUserMessage ? prevState.chatHistory.concat({ sender: "user", messages: message, title }) : prevState.chatHistory,
            botTyping: true,
            fileToBeUploaded: null
        }), () => {
            sendUserMessage({ message }).then(async (response) => {
                // console.log("sendUserMessage response", response)
                let responseMessage = response
                if (!response || (response instanceof Array && response.length === 0)) {
                    responseMessage = [{ text: standardErrorMessage }]
                } else if (!response instanceof Array && response instanceof Object) {
                    responseMessage = [response]
                }

                let slotId, actionId
                let actionDetails = {}, slotDetails = {}

                responseMessage.forEach((dataObject, messageIndex) => {
                    const buttonsValues = dataObject.buttons
                    if (buttonsValues) {
                        buttonsValues.forEach(({ payload }, buttonIndex) => {
                            if (String(payload).toLowerCase() === "submit") {
                                responseMessage[messageIndex].buttons[buttonIndex].isFileSubmitButton = true
                            } else {
                                const payloadSplitArr = payload?.split("_")
                                if (["upload", "extraction"].includes(String(payloadSplitArr?.[0]).toLowerCase())) {
                                    responseMessage[messageIndex].buttons[buttonIndex].isFileUploadButton = true

                                    if (String(payloadSplitArr?.[1]).toLowerCase() === "slot") {
                                        slotId = payloadSplitArr[2]
                                    } else {
                                        actionId = payloadSplitArr[1]
                                    }
                                }
                            }
                        })
                    }
                })

                if (slotId) {
                    slotDetails = await getSlotDetails(slotId)
                } else if (actionId) {
                    actionDetails = await getActionDetails(actionId)
                }

                this.setState((prevState) => ({
                    chatHistory: prevState.chatHistory.concat({ sender: "bot", messages: responseMessage }),
                    actionDetails,
                    slotDetails,
                    botTyping: false
                }))
            }, (err) => {
                // console.log("sendUserMessage err", err)
                this.setState((prevState) => ({
                    chatHistory: prevState.chatHistory.concat({ sender: "bot", messages: [{ text: standardErrorMessage }] }),
                    botTyping: false
                }))
            })
        })
    }

    clearChatHistory = () => {
        this.setState({ chatHistory: [] })
    }

    restartChatbot = () => {
        this.setState({ chatHistory: [] }, () => {
            this.initialiseChatbot()
        })
    }

    handleFileUploadChange = (e) => {
        const file = e.target.files?.[0]
        if (file) {
            this.setState({ fileToBeUploaded: file })
        } else {
            this.setState({ fileToBeUploaded: null })
        }
    }

    handleFileUploadSubmit = () => {
        const { slotDetails, actionDetails } = this.state
        if (slotDetails?.id) {
            let responseValue = slotDetails?.slotQuery
            responseValue = responseValue?.uploadValue || responseValue?.extractionValue
            if (responseValue) {
                const {
                    apiPath: URL,
                    config: { attachmentName },
                    askFollowUpQuestion,
                    followUpQuery,
                    fileUploadSucessResponse,
                    fileUploadFailResponse
                } = responseValue
                if (URL && attachmentName) {
                    const { fileToBeUploaded } = this.state
                    const formData = new FormData()
                    formData.append(attachmentName, fileToBeUploaded)

                    this.setState({ widgetOverlayloading: true, isUploadOrExtractionInProgress: true }, () => {
                        source = axios.CancelToken.source();
                        post(URL, formData, { cancelToken: source.token })
                            .then(
                                (uploadResponse) => {
                                    const uploadResponseData = uploadResponse.data
                                    const newState = cloneDeep(this.state)

                                    newState.widgetOverlayloading = false
                                    newState.isUploadOrExtractionInProgress = false
                                    newState.fileToBeUploaded = null
                                    newState.slotDetails = {}

                                    const successMessage = fileUploadSucessResponse || `File "${fileToBeUploaded.name}" uploaded successfully!`
                                    newState.chatHistory = newState.chatHistory
                                        .concat({ sender: "bot", messages: [{ text: successMessage }] })

                                    // clear button response suggestions
                                    const buttonsResponses = document.getElementsByClassName("buttonsResponseRef")
                                    const buttonsResponsesLength = buttonsResponses.length

                                    for (let index = 0; index < buttonsResponsesLength; index += 1) {
                                        buttonsResponses[index].innerHTML = ""
                                    }

                                    if (slotDetails.type === "EXTRACTION") {
                                        let entities
                                        if (uploadResponseData instanceof Array) {
                                            entities = uploadResponseData[0].entities
                                        } else if (uploadResponseData instanceof Object) {
                                            entities = uploadResponseData.entities
                                        }

                                        if (entities?.length) {
                                            let itemDetails = `Following details have been extracted from "${fileToBeUploaded.name}"-\n`
                                            entities.forEach(({ type, value }) => {
                                                itemDetails += ` ${type} - ${value}\n`
                                            })

                                            newState.chatHistory = newState.chatHistory
                                                .concat({ sender: "bot", messages: [{ text: itemDetails }] })
                                        }
                                    }

                                    this.setState(newState, () => {
                                        if (askFollowUpQuestion && followUpQuery) {
                                            this.handleSendSubmit({ message: followUpQuery, addUserMessage: false })
                                        }

                                        this.handleSendSubmit({ message: successMessage, addUserMessage: false })
                                    })
                                })
                            .catch((err) => {
                                // console.log("handleFileUploadSubmit catch err", err)
                                const errorMessage = fileUploadFailResponse || 'Error in file upload, please try again!'
                                if (axios.isCancel(err)) {
                                    alertCall(err.message, "danger")
                                    this.handleSendSubmit({ message: err.message, addUserMessage: false })
                                } else {
                                    alertCall(errorMessage, "danger")
                                    this.handleSendSubmit({ message: errorMessage, addUserMessage: false })
                                }
                                this.setState({ widgetOverlayloading: false, isUploadOrExtractionInProgress: false })
                            })
                    })
                } else {
                    alertCall("Missing apiPath / config.attachmentName in uploadValue in slot details!", "danger")
                }
            } else {
                alertCall("No upload/extraction details in slot data!", "danger")
            }
        } else if (actionDetails?.id) {
            let responseValue = actionDetails?.staticResponses
            responseValue = responseValue?.uploadValue || responseValue?.extractionValue
            if (responseValue) {
                const {
                    apiPath: URL,
                    config: { attachmentName },
                    askFollowUpQuestion,
                    followUpQuery,
                    fileUploadSucessResponse,
                    fileUploadFailResponse
                } = responseValue
                if (URL && attachmentName) {
                    const { fileToBeUploaded } = this.state
                    const formData = new FormData()
                    formData.append(attachmentName, fileToBeUploaded)

                    this.setState({ widgetOverlayloading: true, isUploadOrExtractionInProgress: true }, () => {
                        source = axios.CancelToken.source();
                        post(URL, formData, { cancelToken: source.token })
                            .then(
                                (uploadResponse) => {
                                    const uploadResponseData = uploadResponse.data
                                    const newState = cloneDeep(this.state)

                                    newState.widgetOverlayloading = false
                                    newState.isUploadOrExtractionInProgress = false
                                    newState.fileToBeUploaded = null
                                    newState.actionDetails = {}

                                    const successMessage = fileUploadSucessResponse || `File "${fileToBeUploaded.name}" uploaded successfully!`
                                    newState.chatHistory = newState.chatHistory
                                        .concat({ sender: "bot", messages: [{ text: successMessage }] })

                                    // clear button response suggestions
                                    const buttonsResponses = document.getElementsByClassName("buttonsResponseRef")
                                    const buttonsResponsesLength = buttonsResponses.length

                                    for (let index = 0; index < buttonsResponsesLength; index += 1) {
                                        buttonsResponses[index].innerHTML = ""
                                    }

                                    if (actionDetails.type === "EXTRACTION") {
                                        let entities
                                        if (uploadResponseData instanceof Array) {
                                            entities = uploadResponseData[0].entities
                                        } else if (uploadResponseData instanceof Object) {
                                            entities = uploadResponseData.entities
                                        }

                                        if (entities?.length) {
                                            let itemDetails = `Following details have been extracted from "${fileToBeUploaded.name}"-\n`
                                            entities.forEach(({ type, value }) => {
                                                itemDetails += ` ${type} - ${value}\n`
                                            })

                                            newState.chatHistory = newState.chatHistory
                                                .concat({ sender: "bot", messages: [{ text: itemDetails }] })
                                        }
                                    }

                                    this.setState(newState, () => {
                                        if (askFollowUpQuestion && followUpQuery) {
                                            this.handleSendSubmit({ message: followUpQuery, addUserMessage: false })
                                        }
                                    })
                                })
                            .catch((err) => {
                                // console.log("handleFileUploadSubmit catch err", err)
                                const errorMessage = fileUploadFailResponse || 'Error in file upload, please try again!'
                                if (axios.isCancel(err)) {
                                    alertCall(err.message, "danger")
                                } else {
                                    alertCall(errorMessage, "danger")
                                }
                                this.setState({ widgetOverlayloading: false, isUploadOrExtractionInProgress: false })
                            })
                    })
                } else {
                    alertCall("Missing apiPath / config.attachmentName in uploadValue in action details!", "danger")
                }
            } else {
                alertCall("No upload/extraction details in action data!", "danger")
            }
        } else {
            alertCall("No action id in slot / action data!", "danger")
        }
    }

    handleAbortUploadSubmit = () => {
        const { isUploadOrExtractionInProgress } = this.state
        if (isUploadOrExtractionInProgress && source?.cancel) {
            source.cancel('Operation cancelled by the user.');
        }
    }

    handleDateTimePickerSubmit = (message) => {
        this.handleSendSubmit({ message })

        const datetimePickersElements = document.getElementsByClassName("datetimePickersRef")
        const datetimePickersElementsLength = datetimePickersElements.length

        for (let index = 0; index < datetimePickersElementsLength; index += 1) {
            datetimePickersElements[index].innerHTML = ""
        }
    }

    handleDurationSelectorsSubmit = (message) => {
        this.handleSendSubmit({ message })

        const durationSelectorsElements = document.getElementsByClassName("durationSelectorsRef")
        const durationSelectorsElementsLength = durationSelectorsElements.length

        for (let index = 0; index < durationSelectorsElementsLength; index += 1) {
            durationSelectorsElements[index].innerHTML = ""
        }
    }

    render() {
        const {
            chatHistory, botTyping, actionDetails, fileToBeUploaded,
            widgetOverlayloading, isUploadOrExtractionInProgress,
            showWidget
        } = this.state

        return (
            <ChatbotWidgetComponent
                showWidget={showWidget}
                setShowWidget={this.setShowWidget}
                widgetStyleDefault={widgetStyleDefault}
                onSubmit={this.handleSendSubmit}
                chatHistory={chatHistory}
                clearChatHistory={this.clearChatHistory}
                restartChatbot={this.restartChatbot}
                botTyping={botTyping}
                actionDetails={actionDetails}
                handleFileUploadChange={this.handleFileUploadChange}
                handleFileUploadSubmit={this.handleFileUploadSubmit}
                fileToBeUploaded={fileToBeUploaded}
                widgetOverlayloading={widgetOverlayloading}
                isUploadOrExtractionInProgress={isUploadOrExtractionInProgress}
                handleAbortUploadSubmit={this.handleAbortUploadSubmit}
                handleDateTimePickerSubmit={this.handleDateTimePickerSubmit}
                handleDurationSelectorsSubmit={this.handleDurationSelectorsSubmit}
            />
        );
    }
}

export default ChatbotWidgetContainer;

import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import {
  Row,
  Col,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap"

// availity-reactstrap-validation
import {
  AvFeedback,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { uploadUserDocument } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { getImageForUser } from "utils/Common"
import countryCode from "../../../utils/countryCode"

const PersonalDetails = ({ t, data, updateData }) => {
  const {
    firstName,
    lastName,
    mobileNumber,
    email,
    type,
    password,
    profileImageURL,
  } = useMemo(() => data, [data])
  const dispatch = useDispatch()

  let uploadFileResponse = useSelector(
    state => state.CommonReducer.uploadDocResp
  )

  const [selectedCountry, setCountry] = useState("+91")

  const uploadImage = data => {
    dispatch(uploadUserDocument("profileImageURL", data))
  }

  useEffect(() => {
    if (uploadFileResponse) {
      console.log(uploadFileResponse)
      updateData(uploadFileResponse.key, uploadFileResponse.data)
    }
  }, [uploadFileResponse])

  return (
    <>
      <Row>
        <Col xs={12} md={6} xl={6} className="mb-4">
          <FormGroup>
            <AvField
              id="firstName"
              name="firstName"
              label={t("First Name")}
              className="form-control"
              placeholder={t("Enter First Name")}
              type="text"
              required
              value={firstName}
              onChange={e => {
                updateData("firstName", e.target.value)
              }}
            />
          </FormGroup>
        </Col>

        <Col xs={12} md={6} xl={6} className="mb-4">
          <FormGroup>
            <AvField
              id="lastName"
              name="lastName"
              label={t("Last Name")}
              className="form-control"
              placeholder={t("Enter Last Name")}
              type="text"
              required
              value={lastName}
              onChange={e => {
                updateData("lastName", e.target.value)
              }}
            />
          </FormGroup>
        </Col>

        <Col xs={12} md={6} xl={6} className="mb-4">
          <AvGroup>
            <Label for="mobileNumber">{t("Mobile Number")}</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <select
                  id="dialCode"
                  name="dialCode"
                  className={`form-control form-select`}
                  style={{
                    textAlignLast: "center",
                    width: 80,
                  }}
                  onChange={e => {
                    setCountry(e.target.value)
                    updateData("countryCode", e.target.value)
                  }}
                  defaultValue={selectedCountry}
                >
                  {countryCode &&
                    countryCode.map(country => (
                      <option
                        key={country.dialCode + country.name.replace(" ", "")}
                        value={country.dialCode}
                      >
                        {country.dialCode === selectedCountry
                          ? country.dialCode
                          : `${country.name} ${country.dialCode}`}
                      </option>
                    ))}
                </select>
              </InputGroupAddon>
              <AvInput
                name="mobileNumber"
                id="mobileNumber"
                placeholder={t("Enter Mobile Number")}
                type="number"
                value={data.mobileNumber}
                onChange={e => {
                  updateData("mobileNumber", e.target.value)
                }}
                required
              />
              <AvFeedback>This field is required.</AvFeedback>
            </InputGroup>
          </AvGroup>
        </Col>

        <Col xs={12} md={6} xl={6} className="mb-4">
          <FormGroup>
            <AvField
              id="email"
              name="email"
              label={t("Email")}
              className="form-control"
              placeholder={t("Enter email")}
              type="email"
              value={email}
              onChange={e => {
                updateData("email", e.target.value)
              }}
            />
          </FormGroup>
        </Col>

        {password === null && (
          <Col xs={12} md={6} xl={6} className="mb-4">
            <FormGroup>
              <AvField
                id="password"
                name="password"
                label={t("Password")}
                className="form-control"
                placeholder={t("Enter Password")}
                type="password"
                required
                value={password}
                onChange={e => {
                  updateData("password", btoa(e.target.value))
                }}
              />
            </FormGroup>
          </Col>
        )}
        {(!profileImageURL || profileImageURL === null) && (
          <Col xs={12} md={6} xl={6} className="mb-4">
            <FormGroup>
              <AvField
                id="profileImageURL"
                name="profileImageURL"
                label={t("Profile Photo")}
                className="form-control"
                placeholder={t("Upload Profile Photo")}
                type="file"
                // value={profileImageURL}
                onChange={e => {
                  uploadImage(e.target.files[0])
                }}
              />
            </FormGroup>
          </Col>
        )}

        {profileImageURL && (
          <Col xs={12} md={6} xl={6} className="mb-4">
            <label>Profile Photo</label>
            <div className="mb-4">
              <img
                className="rounded-circle avatar-sm"
                src={getImageForUser(data.id, profileImageURL)}
                alt=""
              />
              <i
                className="bx bxs-trash text-danger"
                style={{
                  marginLeft: "20px",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
                onClick={() => {
                  updateData("profileImageURL", null)
                }}
                id="deleteImage"
              ></i>
            </div>
          </Col>
        )}
      </Row>
    </>
  )
}

PersonalDetails.propTypes = {
  t: PropTypes.any,
  data: PropTypes.instanceOf(Object),
  updateData: PropTypes.func,
}

export default withTranslation()(PersonalDetails)

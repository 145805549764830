import React from "react"
import { Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import Breadcrumb from "../../components/Common/Breadcrumb"
import Trip from "components/Happy60/Trip"
import { getUserDetail } from "utils/userUtil"
import { USER_TYPE } from "constants/common"
import TripSC from "components/Happy60/TripSC"
import TripSC2 from "components/Happy60/TripSC2"

const TripPage = () => {
  const userRole = getUserDetail("type")

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Trip | Happy60Plus</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Home" breadcrumbItem="Trip" />
          {userRole === USER_TYPE.ADMIN && <Trip></Trip>}
          {/* {userRole === USER_TYPE.SENIOR_CITIZEN && <TripSC></TripSC>} */}
          {userRole === USER_TYPE.SENIOR_CITIZEN && <TripSC2></TripSC2>}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TripPage

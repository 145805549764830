import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Label } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import AssignmentDetail from "./assignmentDetailModal"
import { AddressFormatter } from "../shared/addressFormatter"
import { getOngoingAssignments, getServiceType } from "store/actions"
import { USER_TYPE, PAYMENT_STATUS } from "constants/common"
import { getUserDetail } from "utils/userUtil"
import PaymentModal from "../Payment/paymentModal"
import DataTable from "react-data-table-component"

const OngoingAssignment = ({ userRole }) => {
  const dispatch = useDispatch()

  const [assignmentDetailModal, setAssignmentDetailModal] = useState(false)
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [paymentModal, setPaymentModal] = useState(false)
  const [perPage, setPerPage] = useState(10)

  const ongoingAssignmentList = useSelector(
    state => state.AssignmentReducer.ongoingAssignments.data
  )
  const page = useSelector(
    state => state.AssignmentReducer.ongoingAssignments.page
  )
  let serviceType = useSelector(state => state.ServiceReducer.serviceType)
  let addressList = useSelector(state => state.Profile.address)
  let modeList = useSelector(state => state.CommonReducer.serviceMode)

  useEffect(() => {
    handlePageChange(1)
  }, [dispatch])

  useEffect(() => {
    if (!serviceType?.length) {
      dispatch(getServiceType())
    }
  }, [serviceType])

  const handlePageChange = page => {
    dispatch(getOngoingAssignments(null, page, perPage))
  }

  const handlePerRowsChange = (newPerPage, page) => {
    dispatch(getOngoingAssignments(null, page, newPerPage))
    setPerPage(newPerPage)
  }

  const getServiceName = serviceId => {
    let serviceObj = serviceType.find(item => item.id === serviceId)
    return serviceObj && serviceObj.serviceType ? serviceObj.serviceType : "-"
  }

  const getMode = modeId => {
    let mode = modeList.filter(item => item.id === modeId)
    if (mode && mode.length) {
      return mode[0].description
    } else {
      return "NA"
    }
  }

  const getAddressTag = addressId => {
    let addressObj = addressList.find(item => item.id === addressId)
    return addressObj && addressObj.tags ? addressObj.tags : "-"
  }

  let columns = [
    {
      id: 1,
      name: "Service",
      selector: item => <span>{getServiceName(item.serviceID)}</span>,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Date",
      selector: (item) => item.dateTimeFrom,
      format: (item) => (moment(item.dateTimeFrom).isValid() ? moment.utc(item.dateTimeFrom).local().format("DD-MM-YYYY hh:mm A") : '-'),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Duration",
      selector: row => row.duration,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Address",
      selector: item => <AddressFormatter data={item.address} />,
      sortable: true,
      // right: true,
      reorder: true,
    },
  ]

  if (userRole && userRole === USER_TYPE.ADMIN) {
    columns = [
      ...columns,
      {
        id: 5,
        name: "UserName",
        selector: item => item,
        format: item => item.user.firstName + " " + item.user.lastName,
        sortable: true,
        // right: true,
        reorder: true,
      },
      {
        id: 6,
        name: "Contact No",
        selector: item => item.user.mobileNumber,
        sortable: true,
        // right: true,
        reorder: true,
      },
      {
        id: 7,
        name: "Booked Date",
        selector: item => item.createdOn,
        format: item =>
          item.createdOn
            ? moment(item.createdOn).isValid()
              ? moment.utc(item.createdOn).local().format("DD-MM-YYYY hh:mm A")
              : moment(Number(item.createdOn)).isValid()
              ? moment(Number(item.createdOn))
                  .local()
                  .format("DD-MM-YYYY hh:mm A")
              : "-"
            : "-",
        sortable: true,
        // right: true,
        reorder: true,
      },
    ]
  }
  columns = [
    ...columns,
    {
      id: 8,
      name: "Service Mode",
      selector: item => <span>{getMode(item.serviceMode)}</span>,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Description",
      selector: row => row.description,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 10,
      name: "Action",
      selector: item => (
        <div>
          <ul className="list-inline mb-0 font-size-20">
            <li className="list-inline-item">
              <i
                className="bx bx-info-circle text-primary"
                onClick={() => {
                  setSelectedAssignment(item)
                  setAssignmentDetailModal(true)
                }}
              />
            </li>
            {item.paymentStatus &&
              item.paymentStatus !== PAYMENT_STATUS.PAID &&
              item.paymentStatus !== PAYMENT_STATUS.REFUND_PENDING && (
                <>
                  {userRole && userRole === USER_TYPE.ADMIN && (
                    <li className="list-inline-item">
                      <i
                        className="bx bx-money text-success"
                        onClick={() => {
                          setSelectedAssignment(item)
                          setPaymentModal(true)
                        }}
                      ></i>
                    </li>
                  )}
                </>
              )}
          </ul>
        </div>
      ),
      sortable: true,
      // right: true,
      reorder: true,
    },
  ]

  return (
    <React.Fragment>
      {ongoingAssignmentList?.length > 0 && (
        <DataTable
          noHeader
          // title="Senior Citizen"
          columns={columns}
          data={ongoingAssignmentList}
          defaultSortFieldId={2}
          // sortIcon={<SortIcon />}
          pagination
          highlightOnHover
          // selectableRows

          paginationServer
          paginationTotalRows={page.totalElements || 10}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
        />
      )}
      {ongoingAssignmentList?.length === 0 && (
        <Label>No Assignments found</Label>
      )}
      {selectedAssignment && (
        <>
          <AssignmentDetail
            isOpen={assignmentDetailModal}
            data={selectedAssignment}
            closeModal={() => {
              setAssignmentDetailModal(false)
            }}
            userRole={getUserDetail("type")}
            onCancelClick={() => {
              setAssignmentDetailModal(false)
            }}
          ></AssignmentDetail>
          <PaymentModal
            isOpen={paymentModal}
            data={selectedAssignment}
            onCancelClick={() => {
              setPaymentModal(false)
            }}
            closeModal={() => {
              setPaymentModal(false)
            }}
          ></PaymentModal>
        </>
      )}
    </React.Fragment>
  )
}

OngoingAssignment.propTypes = {
  userRole: PropTypes.any,
}

export default OngoingAssignment

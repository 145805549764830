import React, { useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { Modal, Row, Col } from "reactstrap"
import { withTranslation } from "react-i18next"

import { useDispatch } from "react-redux"

import { clearWebinarBookingStatus } from "store/actions"

const WebinarInfoModal = ({ t, webinarModalDetails, closeModal }) => {
  const dispatch = useDispatch()

  const { show, data } = webinarModalDetails
  const { title, date, time, zoomLink, meetingId, meetingPassword } = useMemo(
    () => data,
    [data]
  )

  const onCloseClick = useCallback(() => {
    dispatch(clearWebinarBookingStatus())
    closeModal()
  }, [clearWebinarBookingStatus])

  return (
    <Modal isOpen={show} centered={true}>
      <div className="modal-body">
        <div className="modal-header">
          <h5 className="modal-title mt-0">{t("Webinar Details")}</h5>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onCloseClick}
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col xs={12}>
              <p>
                {t(
                  "Thanks for registering for the webinar, please find the details below"
                )}
                :
              </p>

              <p>
                {t("Title")}: {title}
                <br />
                {t("Day")}: {date}
                <br />
                {t("Time")}: {time}
                <br />
                {t("Zoom Link")}:{" "}
                <a href={zoomLink} target="_blank" rel="noreferrer">
                  {zoomLink}
                </a>
                {/* <br /> */}
                {/* {t("Meeting Id")}: {meetingId}<br /> */}
                {/* {t("Meeting Password")}: {meetingPassword} */}
              </p>

              <p>
                {t(
                  "We will also send you the zoom link on your registered whatsapp number within 24 hours."
                )}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

WebinarInfoModal.propTypes = {
  t: PropTypes.any,
  webinarModalDetails: PropTypes.any,
  closeModal: PropTypes.any,
}

export default withTranslation()(WebinarInfoModal)

import React from "react"
import PropTypes from 'prop-types'

import h60pIconImage from "../../assets/images/happy60plus/happy60plus_icon-square.png"

const OverlaySpinner = ({
    widgetOverlayloading,
    showCancelButton,
    onCancel
}) => {
    return (
        <div className={`chat-overlay-spinner ${widgetOverlayloading ? "d-block" : "d-none"}`}>
            <div className="spinner-container">
                <img src={h60pIconImage} alt="Loader" />

                {
                    showCancelButton ? (
                        <button type="button" className="btn btn-outline-danger" onClick={onCancel}>Cancel</button>
                    ) : null
                }
            </div>
        </div>
    )
}

OverlaySpinner.propTypes = {
    widgetOverlayloading: PropTypes.bool,
    showCancelButton: PropTypes.bool,
    onCancel: PropTypes.func
}

export default OverlaySpinner

import {
    GET_ADMIN_PACKAGES, ADMIN_PACKAGES, GET_USER_PACKAGES, USER_PACKAGES,
    GET_PACKAGES_BY_USER_ID, PACKAGES_BY_USER_ID,
    ADD_PACKAGE, ADD_PACKAGE_RESPONSE, UPDATE_PACKAGE, UPDATE_PACKAGE_RESPONSE, DELETE_PACKAGE, DELETE_PACKAGE_RESPONSE,
    BOOK_PACKAGE, BOOK_PACKAGE_RESPONSE, BOOK_SERVICE_FROM_PACKAGE, BOOK_SERVICE_FROM_PACKAGE_RESPONSE,
    GET_PACKAGE_BOOKINGS, PACKAGE_BOOKINGS, GET_PACKAGE_BOOKINGS_BY_USER_ID, PACKAGE_BOOKINGS_BY_USER_ID
} from "./actionTypes"

export const getAdminPackages = () => {
    return {
        type: GET_ADMIN_PACKAGES,
        payload: null
    }
}
export const setAdminPackages = (data) => {
    return {
        type: ADMIN_PACKAGES,
        payload: data
    }
}

export const getUserPackages = () => {
    return {
        type: GET_USER_PACKAGES,
        payload: null
    }
}
export const setUserPackages = (data) => {
    return {
        type: USER_PACKAGES,
        payload: data
    }
}

export const getPackagesByUserId = () => {
    return {
        type: GET_PACKAGES_BY_USER_ID,
        payload: null
    }
}
export const setPackagesByUserId = (data) => {
    return {
        type: PACKAGES_BY_USER_ID,
        payload: data
    }
}

export const addPackage = (data) => {
    return {
        type: ADD_PACKAGE,
        payload: data
    }
}
export const addPackageResponse = (data) => {
    return {
        type: ADD_PACKAGE_RESPONSE,
        payload: data
    }
}

export const updatePackage = (data) => {
    return {
        type: UPDATE_PACKAGE,
        payload: data
    }
}
export const updatePackageResponse = (data) => {
    return {
        type: UPDATE_PACKAGE_RESPONSE,
        payload: data
    }
}

export const deletePackage = (data) => {
    return {
        type: DELETE_PACKAGE,
        payload: data
    }
}
export const deletePackageResponse = (data) => {
    return {
        type: DELETE_PACKAGE_RESPONSE,
        payload: data
    }
}

export const bookPackage = (data) => {
    return {
        type: BOOK_PACKAGE,
        payload: data
    }
}
export const bookPackageResponse = (data) => {
    return {
        type: BOOK_PACKAGE_RESPONSE,
        payload: data
    }
}

export const bookServiceFromPackage = (data) => {
    return {
        type: BOOK_SERVICE_FROM_PACKAGE,
        payload: data
    }
}
export const bookServiceFromPackageResponse = (data) => {
    return {
        type: BOOK_SERVICE_FROM_PACKAGE_RESPONSE,
        payload: data
    }
}

export const getPackageBookings = () => {
    return {
        type: GET_PACKAGE_BOOKINGS,
        payload: null
    }
}
export const setPackageBookings = (data) => {
    return {
        type: PACKAGE_BOOKINGS,
        payload: data
    }
}

export const getPackageBookingsByUserId = () => {
    return {
        type: GET_PACKAGE_BOOKINGS_BY_USER_ID,
        payload: null
    }
}
export const setPackageBookingsByUserId = (data) => {
    return {
        type: PACKAGE_BOOKINGS_BY_USER_ID,
        payload: data
    }
}

import { call, put, takeEvery, takeLatest, all } from "redux-saga/effects"

// Login Redux States
import {
  GENERATE_OTP,
  LOGIN_USER,
  LOGIN_WITH_TOKEN,
  VERIFY_MOBILE_NUMBER,
  VERIFY_OTP,
} from "./actionTypes"
import {
  apiError,
  loginFailure,
  loginSuccess,
  mobileNumberVerificationStatus,
  otpVerificationStatus,
} from "./actions"

import {
  userLogin,
  verifyOtp,
  verifyMobileNumber,
  generateOtp,
  loginWithToken,
} from "../../../utils/Apiv2"

import { getProfileDetail, helpBuddyDetail } from "../profile/actions"
import { USER_TYPE } from "constants/common"
import { removeUserDetail, setUserDetail } from "utils/userUtil"
import { getWalletBalanceByUserId } from "store/actions"

function* loginUser({ payload: { user, history } }) {
  try {
    let response = yield call(userLogin, { user })
    console.log("Debug ::> response", response)

    if (response.status === "success") {
      let userInfo = response.data
      console.log("Debug ::> response", response)
      // localStorage.setItem("authUser", JSON.stringify(userInfo))
      console.log("Debug ::> userInfo", userInfo)
      setUserDetail(userInfo)
      yield all([
        put(loginSuccess(userInfo)),
        put(getProfileDetail(userInfo.id)),
      ])
      if (response?.data?.helpBuddy?.length) {
        yield put(helpBuddyDetail(response.data.helpBuddy[0]))
      }
      if (userInfo.type === USER_TYPE.ADMIN) {
        history.push("/dashboard")
      } else if (userInfo.type === USER_TYPE.SENIOR_CITIZEN) {
        history.push("/services")
        yield put(getWalletBalanceByUserId())
      } else if (userInfo.type === USER_TYPE.SERVICE_PROVIDER) {
        if (response.data.helpBuddy.length) {
          if (response.data.helpBuddy[0].verified) {
            history.push("/assignment")
          } else {
            history.push("/onboard")
          }
        } else {
          history.push("/profile")
        }
      } else if (userInfo.type === USER_TYPE.PARTNER) {
        history.push("/dashboard")
      } else {
        history.push("/profile")
      }
    } else {
      console.log("DEBUG ::>", response)
      yield put(loginFailure(response.data))
    }
  } catch (error) {
    console.log("Debug ::> error", error)
    yield put(apiError(error))
  }
}

function* generateOtpSaga(data) {
  try {
    const resp = yield call(generateOtp, data.payload)
  } catch (error) {
    console.error(error)
  }
}

function* verifyMobileNumberSaga(data) {
  try {
    const resp = yield call(verifyMobileNumber, data.payload)
    console.log("Debug ::> resp", resp)
    yield put(
      mobileNumberVerificationStatus(resp, {
        ...data.payload,
      })
    )
  } catch (error) {
    console.log("Debug ::> error", error)
    yield put(
      mobileNumberVerificationStatus(error, {
        data: false,
        errorMessage: error.errorMessage,
      })
    )
  }
}

function* loginWithTokenSaga(data) {
  try {
    console.log("Debug :::> data111", data)
    const response = yield call(loginWithToken, data.payload)
    if (response.status === "success") {
      let userInfo = response.data
      setUserDetail(userInfo)
      yield all([
        put(loginSuccess(response.data)),
        put(getProfileDetail(response.data.id)),
      ])
      window.location.href = "/mobile-services"
    }
  } catch (error) {
    console.log("Debug ::> error", error)
    yield put(
      mobileNumberVerificationStatus(error, {
        data: false,
        errorMessage: error.errorMessage,
      })
    )
  }
}

function* verifyOtpSaga(data) {
  try {
    const response = yield call(verifyOtp, {
      username: data.payload.username,
      otp: data.payload.otp,
    })
    let userInfo = response.data
    if (response.status) {
      // localStorage.setItem("authUser", JSON.stringify(response.data))
      setUserDetail(userInfo)
      yield all([
        put(loginSuccess(response.data)),
        put(getProfileDetail(response.data.id)),
      ])
      if (data.payload.redirection) {
        if (response.data.type === USER_TYPE.ADMIN) {
          data.payload.history.push("/dashboard")
        } else if (response.data.type === USER_TYPE.SENIOR_CITIZEN) {
          data.payload.history.push("/services")
        } else if (response.data.type === USER_TYPE.SERVICE_PROVIDER) {
          data.payload.history.push("/assignment")
        } else if (response.data.type === USER_TYPE.PARTNER) {
          data.payload.history.push("/dashboard")
        }
      } else {
        yield put(otpVerificationStatus(response.data))
      }
    } else {
      yield put(otpVerificationStatus(response.data))
    }
  } catch (error) {
    console.error(error)
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(GENERATE_OTP, generateOtpSaga)
  yield takeEvery(VERIFY_OTP, verifyOtpSaga)
  yield takeEvery(VERIFY_MOBILE_NUMBER, verifyMobileNumberSaga)
  yield takeEvery(LOGIN_WITH_TOKEN, loginWithTokenSaga)
}

export default authSaga

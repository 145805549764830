import React, { useMemo } from "react"
import PropTypes from 'prop-types'

import { Link } from "react-feather"

const LinkResponse = ({ data, widgetStyle }) => {
  const { title, url } = useMemo(() => {
    if (data instanceof Array) {
      return data?.[0] || {}
    }
    return data || {}
  }, [data])

  const {
    secondaryBGC,
    secondaryTextColor,
    botIcon
  } = useMemo(() => widgetStyle, [widgetStyle])

  return (
    <div className="bot-response">
      <div className="invisible">
        <img src={botIcon} className="bot-profile-photo" alt="elie" />
      </div>

      <div className="d-flex" id="pdf-response" style={{ background: secondaryBGC }}>
        <div className="pdf-icon-div">
          <Link size={12} color={secondaryTextColor} />
        </div>
        <div className="pdf-link-div" style={{ background: secondaryTextColor }}>
          <a href={url} target="_blank" rel="noopener noreferrer" style={{ color: secondaryBGC }} dangerouslySetInnerHTML={{ __html: title || "Link" }}>
            {/* {title || "Link"} */}
          </a>
        </div>
      </div>
    </div>
  )
}

LinkResponse.propTypes = {
  data: PropTypes.any,
  widgetStyle: PropTypes.any
}

export default LinkResponse

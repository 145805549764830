import {
  ALL_HELP_BUDDY_LIST, ALL_PARTNER_LIST, ALL_SENIOR_CITIZEN, ALL_USER, CREATE_PARTNER_RESPONSE, CREATE_HEALTH_BUDDY_LIST_RESPONSE, GET_HEALTH_BUDDY_LIST_RESPONSE, CREATE_PARTNER_ERROR,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  seniorCitizen: [],
  helpBuddy: [],
  partners: [],
  healthBuddy: [],
  partnerError: null
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_USER:
      return {
        ...state,
        users: action.payload
      }
    case ALL_SENIOR_CITIZEN:
      return {
        ...state,
        seniorCitizen: action.payload,
      }
    case ALL_HELP_BUDDY_LIST:
      return {
        ...state,
        helpBuddy: action.payload,
      }
    case ALL_PARTNER_LIST:
      return {
        ...state,
        partners: action.payload,
      }
    case GET_HEALTH_BUDDY_LIST_RESPONSE:
      return {
        ...state,
        healthBuddy: action.payload,
      }
    case CREATE_PARTNER_RESPONSE:
      return {
        ...state,
        partners: [
          ...state.partners,
          action.payload
        ],
      }
    case CREATE_PARTNER_ERROR:
      return {
        ...state,
        partnerError: action.payload
      }
    case CREATE_HEALTH_BUDDY_LIST_RESPONSE:
      return {
        ...state,
        healthBuddy: [
          ...state.healthBuddy,
          action.payload
        ],
      }

    default:
      return state
  }
}

export default Users

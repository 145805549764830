import willMakingWebinarImage from "../assets/images/happy60plus/webinar-images/will-making.jpeg"
import yogaWebinarImage from "../assets/images/happy60plus/webinar-images/yoga.jpeg"

const USER_TYPE = {
  ADMIN: "Admin",
  SERVICE_PROVIDER: "HelpBuddy",
  SENIOR_CITIZEN: "SeniorCitizen",
  PARTNER: "PartnerOrganisation",
  HEALTH_BUDDY: "HealthBuddy",
}

const LOCAL_STORAGE_KEY = {
  PROD_USER_DATA: "authUserProd",
  TEST_USER_DATA: "authUserTest",
}

const ENQUIRY_TYPE = {
  RAISED: "RAISED",
  UNDER_PROCESS: "UNDER_PROCESS",
  COMPLETED: "COMPLETED",
  UNSERVICEABLE: "UNSERVICEABLE",
  NOT_FULFILLED: "NOT_FULFILLED",
}

const PAYMENT_STATUS = {
  PENDING: "120001",
  PAID: "120002",
  PARTIALLY_PAID: "120003",
  REFUND_PENDING: "120004",
  REFUND_INITIATED: "120005",
  REFUND_COMPLATED: "120006",
}
const HELP_BUDDY_APP_STATUS = {
  PENDING: "40001",
  APPROVED: "40002",
  AADHAR_VERIFICATION_PENDING: "40003",
  DRIVING_LICENCE_PENDING: "40004",
  COMPLETED: "40005",
}

const PAYMENT_MODE = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  WALLET: "WALLET",
}

const PERIOD = {
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  YEARLY: "yearly",
}

const CHART_MISC_PARAM = {
  IS_CURRENCY_FORMATTING: "IS_CURRENCY_FORMATTING",
}

const RZP_SCRIPT = "https://checkout.razorpay.com/v1/checkout.js"

const RZP_API_KEY_TEST = "rzp_test_4S2FcDlngbeheU"
const RZP_API_KEY_PROD = "rzp_live_zyeMZx9yCEuLXu"

const RZP_SECRET = "xXFXzEMmproZiclqn9M41zJv"

const WHATSAPP_PROD = "+918088251955"
const WHATSAPP_TEST = "+919972432012"

const ENV = {
  PROD: "prod",
  TEST: "test",
  LOCAL: "local",
}

const URL = {
  PROD: "https://server.happy60plus.com",
  TEST: "https://test.server.happy60plus.com",
}

const WEBINAR_SERVICES = [
  // {
  //   id: "YOGA",
  //   title: "YOGA FOR MIND, BODY AND SOUL",
  //   date: "Friday, Saturday and Sunday",
  //   time: "09:00 am to 10:00 am",
  //   zoomLink:
  //     "https://us06web.zoom.us/j/84663575967?pwd=xAFfJU8zekForFhlBJze4Ff7tAoGbK.1",
  //   meetingId: "",
  //   meetingPassword: "",
  //   active: true,
  //   image: yogaWebinarImage,
  //   type: "YOGA",
  // },
  // {
  //   id: "WILL_MAKING",
  //   title: "Learn how to make a Legal Will",
  //   date: "2nd September 2023",
  //   time: "04:30 pm",
  //   zoomLink:
  //     "https://us06web.zoom.us/j/85724723533?pwd=Mn960wU4JU1bLbKbhfSJm2mARUQcYj.1",
  //   meetingId: "",
  //   meetingPassword: "",
  //   active: true,
  //   image: willMakingWebinarImage,
  //   type: "WILL_MAKING",
  // },
]

const GENERAL_SERVICE_DURATIONS = [
  { label: "30 minutes", value: 30 },
  { label: "1 hour", value: 60 },
  { label: "1 hour 30 minutes", value: 90 },
  { label: "2 hours", value: 120 },
  { label: "2 hours 30 minutes", value: 150 },
  { label: "3 hours", value: 180 },
  { label: "3 hours 30 minutes", value: 210 },
  { label: "4 hours", value: 240 },
  { label: "4 hours 30 minutes", value: 270 },
  { label: "5 hours", value: 300 },
  { label: "5 hours 30 minutes", value: 330 },
  { label: "6 hours", value: 360 },
  { label: "6 hours 30 minutes", value: 390 },
  { label: "7 hours", value: 420 },
  { label: "7 hours 30 minutes", value: 450 },
  { label: "8 hours", value: 480 },
  { label: "8 hours 30 minutes", value: 510 },
  { label: "9 hours", value: 540 },
  { label: "9 hours 30 minutes", value: 570 },
  { label: "10 hours", value: 600 },
]

const TRANSPORTATION_SERVICE_DURATIONS = [
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 120 },
  { label: "3 hours", value: 180 },
  { label: "4 hours", value: 240 },
  { label: "5 hours", value: 300 },
  { label: "6 hours", value: 360 },
  { label: "7 hours", value: 420 },
  { label: "8 hours", value: 480 },
  { label: "9 hours", value: 540 },
  { label: "10 hours", value: 600 },
]

export {
  USER_TYPE,
  HELP_BUDDY_APP_STATUS,
  RZP_SCRIPT,
  LOCAL_STORAGE_KEY,
  ENQUIRY_TYPE,
  RZP_API_KEY_PROD,
  RZP_API_KEY_TEST,
  WHATSAPP_PROD,
  WHATSAPP_TEST,
  ENV,
  URL,
  PAYMENT_MODE,
  WEBINAR_SERVICES,
  PAYMENT_STATUS,
  PERIOD,
  CHART_MISC_PARAM,
  GENERAL_SERVICE_DURATIONS,
  TRANSPORTATION_SERVICE_DURATIONS,
}

import React from "react";
import { withTranslation } from 'react-i18next';
import { FormGroup, Modal, Row, Col, Label, Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector, } from "react-redux";
import { updateWalletBalance, updateWalletBalanceResp } from 'store/actions'

const WalletUpdateModal = ({
    t,
    isOpen,
    data,
    closeModal
}) => {
    const dispatch = useDispatch();
    const walletBalance = useSelector(state => state.PaymentReducer.walletBalance);
    const walletBalanceUpdateResp = useSelector(state => state.PaymentReducer.updateWalletBalanceResp);

    const handleValidSubmit = (e, v) => {
        dispatch(updateWalletBalance({
            id: walletBalance && walletBalance.id ? walletBalance.id : null,
            userId: data.id,
            amount: v.walletAmount * 100,
            isActive: true
        }));
    }
    return (
        <Modal
            isOpen={isOpen}
            centered={true}>
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                    {t('Update Wallet Balance')}
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                        closeModal()
                        dispatch(updateWalletBalanceResp(null))
                    }}
                >
                </button>
            </div>
            <AvForm
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
            >
                <div className="modal-body">
                    <Row>
                        <Col xs={12} sm={12} className="mb-4">
                            <Label>
                                Name: {data.firstName} {data.lastName}
                            </Label>
                        </Col>
                        <Col xs={12} sm={12} className="mb-4">
                            Wallet Balance: ₹ {walletBalance ? walletBalance.amount / 100 : 0}
                        </Col>
                        <Col xs={12} sm={12} className="mb-4">
                            <FormGroup>
                                <AvField
                                    id="walletAmount"
                                    name="walletAmount"
                                    label={t("Updated Wallet balance")}
                                    placeholder={t("Provide wallet amount in ₹")}
                                    type="number"
                                    value={0}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                {walletBalanceUpdateResp ? <Alert color={"success"}>{"Wallet balance update successfully"}</Alert> : null}
                <div className="modal-footer">
                    <button
                        type="submit"
                        className="btn btn-primary"
                    >
                        {t("Update")}
                    </button>
                </div>
            </AvForm>
        </Modal >
    )
}

WalletUpdateModal.propTypes = {
    t: PropTypes.any,
    isOpen: PropTypes.any,
    data: PropTypes.any,
    closeModal: PropTypes.any
}

export default withTranslation()(WalletUpdateModal);
import React from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { verifySubscription } from "store/actions"

const SubscriptionEventHandler = () => {
  const routeParams = useParams()
  const dispatch = useDispatch()
  const goldSubVerification = useSelector(
    state => state.Profile.goldSubVerification
  )

  useEffect(() => {
    if (routeParams.id) {
      dispatch(verifySubscription(routeParams.id))
    }
  }, [])

  useEffect(() => {
    if (goldSubVerification) {
      if (goldSubVerification?.zoomLink) {
        window.location.href = goldSubVerification?.zoomLink
      } else if (goldSubVerification?.GoldMembershipSubscription?.id) {
        window.location.href =
          window.location.origin +
          `/gold-card/${goldSubVerification?.GoldMembershipSubscription?.id}/${goldSubVerification?.GoldMembershipSubscription?.userID}`
      } else {
        window.location.href = window.location.origin + "/gold-card"
      }
    }
  }, [goldSubVerification])

  return <div>loading....</div>
}

export default SubscriptionEventHandler

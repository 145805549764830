import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { FormGroup, Modal, Row, Col, Label, Button } from "reactstrap"
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { PAYMENT_MODE, RZP_SCRIPT } from "../../../constants/common"
import { useDispatch, useSelector } from "react-redux"
import {
  acceptService,
  completeService,
  startService,
  getAllAssignments,
  getHealthBuddy,
  makePayment,
  loadScript,
  getAllHealthCareBookingDetail,
  getServiceType,
  updateHealthBuddy,
} from "store/actions"
import PaymentHistory from "../Payment/paymentHistory"
import moment from "moment"
import { USER_TYPE } from "constants/common"
import { getImageForUser } from "utils/Common"

const AssignmentDetail = ({
  t,
  isOpen,
  userRole,
  data,
  closeModal,
  isHealthCareBooking = false,
  isSelected = false,
}) => {
  const dispatch = useDispatch()
  const [helpBuddyId, setHelpBuddyId] = useState(null)
  const [healthBuddyId, setHealthBuddyId] = useState(null)
  const [estimationPrice, setEstimationPrice] = useState(0)
  let serviceType = useSelector(state => state.ServiceReducer.serviceType)
  const healthBuddyList = useSelector(state => state.Users.healthBuddy)
  const approvedHelpBuddyDetail = useSelector(
    state => state.Profile.approvedHelpBuddy
  )
  const userDetail = useSelector(state => state.Profile.userProfile)
  const healthCarePaymentDetail = useSelector(
    state => state.AssignmentReducer.healthCarePaymentDetail
  )

  const healthCarePaymentStatus = useSelector(
    state => state.PaymentReducer.paymentStatus
  )

  useEffect(() => {
    if (!serviceType?.length) {
      dispatch(getServiceType())
    }
  }, [serviceType])

  const getServiceName = serviceId => {
    let serviceObj = serviceType.find(item => item.id === serviceId)
    return serviceObj && serviceObj.serviceType ? serviceObj.serviceType : "-"
  }

  useEffect(() => {
    if (
      userDetail &&
      userDetail.type === USER_TYPE.PARTNER &&
      !healthBuddyList.length
    ) {
      dispatch(getHealthBuddy(userDetail.id))
    } else {
      dispatch(getHealthBuddy())
    }
  }, [])

  useEffect(() => {
    console.log(approvedHelpBuddyDetail)
  }, [approvedHelpBuddyDetail])
  const onAcceptClick = () => {
    dispatch(acceptService({ ...data, serviceStatus: "ASSIGNED" }))
    dispatch(getAllAssignments())
    closeModal()
  }

  const onAssignClick = () => {
    if (userRole === USER_TYPE.SENIOR_CITIZEN) {
      const selectedHealthBuddy = healthBuddyList.find(
        buddy => buddy.id === healthBuddyId
      )
      const reqData = {
        healthBuddyID: healthBuddyId,
        partnerOrgID: userDetail.id,
        healthCareBookingID: data.id,
        price12Hr: selectedHealthBuddy.servicePrice12Hr,
        price24Hr: selectedHealthBuddy.servicePrice24Hr,
      }
      dispatch(acceptService({ reqData, type: "HealthBuddy" }))
      dispatch(
        getAllHealthCareBookingDetail({
          id: userDetail.id,
          userRole: userDetail.type,
        })
      )
    } else {
      dispatch(
        acceptService({
          ...data,
          serviceStatus: "ASSIGNED",
          buddyID: helpBuddyId,
        })
      )
    }

    dispatch(getAllAssignments())
    closeModal()
  }

  const payServiceFees = paymentInfo => {
    dispatch(loadScript(RZP_SCRIPT))
    setTimeout(() => {
      dispatch(makePayment({ ...paymentInfo, amount: paymentInfo.amount }))
    }, 500)
  }

  useEffect(() => {
    if (healthCarePaymentDetail && healthCarePaymentDetail.amount) {
      payServiceFees(healthCarePaymentDetail)
    }
  }, [healthCarePaymentDetail])
  useEffect(() => {
    if (
      healthCarePaymentStatus &&
      healthCarePaymentStatus.razorpay_payment_id
    ) {
      dispatch(
        getAllHealthCareBookingDetail({
          id: userDetail.id,
          userRole: userDetail.type,
        })
      )
      closeModal()
    }
  }, [healthCarePaymentStatus])

  const onStartClick = () => {
    dispatch(startService({ ...data, serviceStatus: "STARTED" }))
    dispatch(getAllAssignments())
    closeModal()
  }
  const onCompleteClick = () => {
    dispatch(completeService({ ...data, serviceStatus: "COMPLETED" }))
    dispatch(getAllAssignments())
    closeModal()
  }

  const openMaps = () => {
    // window.open('https://www.google.com/maps/place/Sector+6,+HSR+Layout,+Bengaluru,+Karnataka/@12.9141095,77.6259271,16z/data=!3m1!4b1!4m5!3m4!1s0x3bae14f2595f3d03:0xe5e8d4041e7b2512!8m2!3d12.9137705!4d77.6292697', "_blank");

    const addressData = data?.address

    const { latitude, longitude } = addressData?.location || {}

    if (latitude && longitude) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
        "_blank"
      )
    } else {
      const { address, landmark, city, state, pincode } = addressData
      let searchString = ""

      if (address) {
        searchString += address
      }
      if (landmark) {
        searchString += ` ${landmark}`
      }
      if (city) {
        searchString += ` ${city}`
      }
      if (state) {
        searchString += ` ${state}`
      }
      if (pincode) {
        searchString += ` ${pincode}`
      }

      window.open(
        `https://www.google.com/maps/search/?api=1&query=${searchString}`,
        "_blank"
      )
    }
  }

  const healthBuddyType = {
    110001: "Care Taker",
    110002: "Associate Nurse",
    110003: "Registered Nurse",
  }

  const scLangs = data.languagePreference
  const filterHealthBuddies = hbLangs => {
    return hbLangs.some(item => scLangs.includes(item))
  }
  // health buddy filtered by languages known and health buddy type
  const hbList = healthBuddyList.filter(
    buddy =>
      filterHealthBuddies(buddy.languagesKnown) &&
      buddy.healthBuddyType === data.healthBuddyType
  )

  const updateAvailability = (event, values) => {
    event.preventDefault()
    const healthBuddyData = {
      ...values,
      available: false,
    }
    dispatch(updateHealthBuddy(healthBuddyData))
  }

  const onSelect = () => {
    const selectedHBList = data?.healthBuddies || []
    const selectedHB = selectedHBList[0]
    const reqData = {
      healthCareBookingID: data.id,
      healthBuddyId: selectedHB.id,
    }

    dispatch(acceptService({ reqData, type: "HealthBuddy" }))
    closeModal()
  }

  const onSelectAndPay = () => {
    const selectedHealthBuddy = selectedHB
    const reqData = {
      id: selectedHealthBuddy.id,
      healthBuddyID: selectedHealthBuddy.healthBuddyID,
      partnerOrgID: selectedHealthBuddy.partnerOrgID,
      healthCareBookingID: data.id,
      price12Hr: selectedHealthBuddy.price12Hr,
      price24Hr: selectedHealthBuddy.price24Hr,
      selected: true,
    }
    dispatch(acceptService({ reqData, type: "HealthBuddySelected" }))
    // closeModal()
  }

  const selectedHBList = data?.healthBuddies || []
  const selectedHB = selectedHBList[0]
  const healthBType = {
    110001: "Care Taker",
    110002: "Associate Nurse",
    110003: "Registered Nurse",
  }

  return (
    <Modal size="lg" isOpen={isOpen} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t("Assignment Detail")}</h5>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body">
        <Row>
          <Col xs={12} sm={12} className="p-2">
            <Row>
              <Col xs={2} sm={2}>
                <b>Service Type:</b>
              </Col>
              <Col xs={10} sm={10}>
                {getServiceName(
                  data.serviceID || data?.bookingDetail?.serviceID
                )}
              </Col>
            </Row>
          </Col>
          {/* <Col xs={12} sm={12} className="mb-1">
            <Label>
              Vehicle Type: {data.vehicleType}
            </Label>
          </Col> */}

          {userDetail?.type === USER_TYPE.PARTNER || isHealthCareBooking ? (
            <>
              <Col xs={12} sm={12} className="p-2">
                <Row>
                  <Col xs={2} sm={2}>
                    <b>Start Date:</b>
                  </Col>
                  <Col xs={10} sm={10}>
                    {moment(data.startDate).isValid()
                      ? moment
                          .utc(data.bookingDetail.startDate)
                          .local()
                          .format("DD/MM/YYYY")
                      : "-"}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} className="p-2">
                <Row>
                  <Col xs={2} sm={2}>
                    <b>End Date:</b>
                  </Col>
                  <Col xs={10} sm={10}>
                    {moment(data.endDate).isValid()
                      ? moment
                          .utc(data.bookingDetail.endDate)
                          .local()
                          .format("DD/MM/YYYY")
                      : "-"}
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <Col xs={12} sm={12} className="p-2">
              <Row>
                <Col xs={2} sm={2}>
                  <b>Date of Service:</b>
                </Col>
                <Col xs={10} sm={10}>
                  {moment(data.dateTimeFrom).isValid()
                    ? moment
                        .utc(data.dateTimeFrom)
                        .local()
                        .format("DD/MM/YYYY hh:mm A")
                    : "-"}
                </Col>
              </Row>
            </Col>
          )}

          <Col xs={12} sm={12} className="p-2">
            {!!(userDetail && userDetail.type === USER_TYPE.PARTNER) ||
            isHealthCareBooking ? (
              <Row>
                <Col xs={2} sm={2}>
                  <b>Duration of Service(hours):</b>
                </Col>
                <Col xs={10} sm={10}>
                  {data?.bookingDetail?.duration}
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={2} sm={2}>
                  <b>Duration of Service(Minutes):</b>
                </Col>
                <Col xs={10} sm={10}>
                  {data.duration}
                </Col>
              </Row>
            )}
          </Col>
          {userRole && userRole === USER_TYPE.ADMIN && (
            <>
              <Col xs={12} sm={12} className="p-2">
                <Row>
                  <Col xs={2} sm={2}>
                    <b>Actual duration(Minutes):</b>
                  </Col>
                  <Col xs={10} sm={10}>
                    {data.actualDuration}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} className="p-2">
                <Row>
                  <Col xs={2} sm={2}>
                    <b>Estimated service cost:</b>
                  </Col>
                  <Col xs={10} sm={10}>
                    ₹ {parseFloat(data.serviceEstimates / 100).toFixed(2)}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} className="p-2">
                <Row>
                  <Col xs={2} sm={2}>
                    <b>Final service cost:</b>
                  </Col>
                  <Col xs={10} sm={10}>
                    ₹ {parseFloat(data.serviceActuals / 100).toFixed(2)}
                  </Col>
                </Row>
              </Col>
            </>
          )}
          {data.serviceMode && data.serviceMode !== "ONLINE" && (
            <Col xs={12} sm={12} className="p-2">
              <Row>
                <Col xs={2} sm={2}>
                  <b>Address:</b>
                </Col>
                <Col xs={10} sm={10}>
                  {data.serviceAddress}
                  <Button
                    id="navigationButton"
                    type="button"
                    className="btn-sm"
                    color="info"
                    style={{ marginLeft: "10px" }}
                    outline
                    onClick={openMaps}
                  >
                    <i className="bx bx-navigation"></i>
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
          {!!(
            userDetail?.type !== USER_TYPE.PARTNER && !isHealthCareBooking
          ) && (
            <Col xs={12} sm={12} className="p-2">
              <Row>
                <Col xs={2} sm={2}>
                  <b>Description:</b>
                </Col>
                <Col xs={10} sm={10}>
                  {data.description}
                </Col>
              </Row>
            </Col>
          )}
          {!!(
            (userDetail && userDetail.type === USER_TYPE.PARTNER) ||
            isHealthCareBooking
          ) && (
            <Col xs={12} sm={12} className="p-2">
              <Row>
                <Col xs={2} sm={2}>
                  <b>Diagnosis:</b>
                </Col>
                <Col xs={10} sm={10}>
                  {data.diagnosis}
                </Col>
              </Row>
            </Col>
          )}
          {!!(
            (userDetail && userDetail.type === USER_TYPE.PARTNER) ||
            isHealthCareBooking
          ) && (
            <Col xs={12} sm={12} className="p-2">
              <Row>
                <Col xs={2} sm={2}>
                  <b>Health Buddy Type:</b>
                </Col>
                <Col xs={10} sm={10}>
                  {healthBuddyType[data.healthBuddyType]}
                </Col>
              </Row>
            </Col>
          )}
          {!(
            (userDetail && userDetail.type === USER_TYPE.PARTNER) ||
            isHealthCareBooking
          ) && (
            <>
              <Col xs={12} sm={12} className="p-2">
                <Row>
                  <Col xs={2} sm={2}>
                    <b>User Name :</b>
                  </Col>
                  <Col xs={10} sm={10}>
                    {data?.user?.firstName} {data?.user?.lastName}
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={12} className="p-2">
                <Row>
                  <Col xs={2} sm={2}>
                    <b>Contact No :</b>
                  </Col>
                  <Col xs={10} sm={10}>
                    {data?.user?.mobileNumber}
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={12} className="p-2">
                <Row>
                  <Col xs={2} sm={2}>
                    <b>Email Id :</b>
                  </Col>
                  <Col xs={10} sm={10}>
                    {data?.user?.email}
                  </Col>
                </Row>
              </Col>
            </>
          )}

          {userRole &&
            userRole === USER_TYPE.ADMIN &&
            data &&
            data.serviceStatus === "CREATED" && (
              <Col xs={12} sm={12} className="p-2">
                <FormGroup>
                  <AvForm>
                    <AvField
                      type="select"
                      name="helpBuddyId"
                      label="Helpbuddy"
                      value={""}
                      onChange={e => {
                        setHelpBuddyId(e.target.value)
                      }}
                    >
                      <option value={-1} key={-1} selected>
                        Select
                      </option>
                      {approvedHelpBuddyDetail &&
                        approvedHelpBuddyDetail.map(item => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.user.firstName} {item.user.lastName}
                            </option>
                          )
                        })}
                    </AvField>
                  </AvForm>
                </FormGroup>
              </Col>
            )}
          {/* {userRole &&
            userRole === USER_TYPE.SENIOR_CITIZEN &&
            data &&
            isHealthCareBooking && (
              <Col xs={12} sm={12} className="mb-1">
                <AvForm onValidSubmit={(e, v) => {}}>
                  <AvRadioGroup
                    inline
                    name="selectHealthBuddy"
                    value={healthBuddyId || " "}
                    onChange={e => {
                      setHealthBuddyId(e.target.value)
                    }}
                  >
                    <table className="table table-hover align-middle">
                      <thead>
                        <tr className="align-bottom">
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Age</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Experience</th>
                          <th scope="col">Languages Known</th>
                          <th scope="col">Base Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hbList?.map((item, index) => (
                          <tr key={item.id}>
                            <th scope="row">
                              <AvRadio
                                customInput
                                label={" "}
                                value={item.id}
                              />
                            </th>
                            <td>{`${item.firstName} ${item.lastName}`}</td>
                            <td>{item.age || "NA"}</td>
                            <td>{item?.gender?.toUpperCase() || "NA"}</td>
                            <td>{item.experience || "NA"}</td>
                            <td>
                              {item?.languagesKnown
                                ?.join(", ")
                                ?.toUpperCase() || "NA"}
                            </td>
                            <td>
                              {data?.bookingDetail.duration === 12
                                ? item?.servicePrice12Hr
                                : item?.servicePrice24Hr}
                              / {data?.bookingDetail.duration} Hours
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </AvRadioGroup>
                </AvForm>
              </Col>
            )}
          {isHealthCareBooking &&
            data?.bookingStatus === "HelpBuddyAssigned" && (
              <Col xs={12} sm={12} className="mb-1">
                <AvForm onValidSubmit={(e, v) => {}}>
                  <AvRadioGroup
                    inline
                    name="selectHealthBuddy"
                    value={healthBuddyId || " "}
                    onChange={e => {
                      setHealthBuddyId(e.target.value)
                    }}
                  >
                    <table className="table table-hover align-middle">
                      <thead>
                        <tr className="align-bottom">
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Age</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Experience</th>
                          <th scope="col">Languages Known</th>
                          <th scope="col">Base Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.healthBuddies?.map((item, index) => (
                          <tr key={item.id}>
                            <th scope="row">
                              <AvRadio
                                customInput
                                label={" "}
                                value={item.id}
                              />
                            </th>
                            <td>{item.name || "NA"}</td>
                            <td>{item.age || "NA"}</td>
                            <td>{item.gender?.toUpperCase() || "NA"}</td>
                            <td>{item.experience || "NA"}</td>
                            <td>
                              {item.languagesKnown?.join(", ")?.toUpperCase() ||
                                "NA"}
                            </td>
                            <td>
                              {data?.bookingDetail.duration === 12
                                ? item?.price12Hr
                                : item?.price24Hr}
                              / {data?.bookingDetail.duration} Hours
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </AvRadioGroup>
                </AvForm>
              </Col>
            )} */}

          {data.users && (
            <>
              <hr />
              <h5>Senior Citizen Details</h5>
            </>
          )}
          {data.users && (
            <Row className="ms-0">
              <Col xs={12} sm={12}>
                <div
                  className={`card border-dark healthBuddy selected-healthBuddy`}
                >
                  <div className="row g-0">
                    <div className="col-md-3">
                      <img
                        src={getImageForUser(
                          data.users.id,
                          data.users.profileImageURL
                        )}
                        style={{
                          height: "100%",
                        }}
                        className="img-fluid rounded-start"
                        alt={`${data.users.firstName} ${data.users.lastName}`}
                      />
                    </div>
                    <div className="col-md-9">
                      <div className="card-body">
                        <h5 className="card-title">{`${data.users.firstName} ${data.users.lastName}`}</h5>
                        <div className="row">
                          <div className="col-md-5">
                            <p className="card-text">
                              {data.users?.gender || "N/A"}&nbsp;
                              {data.users.age
                                ? `${data.users.age} years`
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {selectedHB && (
            <>
              <hr />
              <h5>Health Buddy Details</h5>
            </>
          )}
          {selectedHB && (
            <Row className="ms-0">
              <Col xs={12} sm={12}>
                <div
                  className={`card border-dark healthBuddy selected-healthBuddy`}
                  // onClick={() => {
                  //   setSelectedHealthBuddy(healthBuddy)
                  // }}
                >
                  <div className="row g-0">
                    <div className="col-md-3">
                      <img
                        src={getImageForUser(
                          selectedHB.healthBuddyID,
                          selectedHB.profileImageURL
                        )}
                        style={{
                          height: "100%",
                        }}
                        className="img-fluid rounded-start"
                        alt={`${selectedHB.name}`}
                      />
                    </div>
                    <div className="col-md-9">
                      <div className="card-body">
                        <h5 className="card-title">{`${selectedHB?.name}`}</h5>
                        <div className="row">
                          <div className="col-md-5">
                            <p className="card-text">
                              {selectedHB?.gender || "N/A"}&nbsp;
                              {selectedHB.age
                                ? `${selectedHB.age} years`
                                : "N/A"}
                              <br />
                              {healthBType[selectedHB.healthBuddyType]}
                              <br />
                              Experience:{" "}
                              {selectedHB.experience
                                ? `${selectedHB.experience} years`
                                : "N/A"}
                              <br />
                            </p>
                          </div>
                          <div className="col-md-7">
                            <p className="card-text">
                              Education: {selectedHB.education || "N/A"} <br />
                              {selectedHB.languagesKnown?.join(", ") || "N/A"}
                              <br />
                              12 hour price is{" "}
                              <b>₹{selectedHB.price12Hr || 200}</b> and 24 hours
                              price is <b>₹{selectedHB.price24Hr || 400}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}

          {data && data.servicePayments && data.servicePayments.length > 0 && (
            <>
              {userRole && userRole === USER_TYPE.ADMIN && (
                <PaymentHistory data={data.servicePayments}></PaymentHistory>
              )}
            </>
          )}
        </Row>
      </div>
      <div className="modal-footer">
        {userRole &&
          userRole === USER_TYPE.ADMIN &&
          data &&
          data.serviceStatus === "CREATED" && (
            <>
              <button
                type="button"
                className="btn btn-primary px-4"
                onClick={() => {
                  onAssignClick()
                }}
                disabled={helpBuddyId !== null ? false : true}
              >
                {t("Assign")}
              </button>
            </>
          )}

        {userRole &&
          userRole === USER_TYPE.SENIOR_CITIZEN &&
          data &&
          isSelected && (
            <>
              <button
                type="button"
                className="btn btn-primary px-4"
                onClick={() => {
                  onSelectAndPay()
                }}
              >
                {t("Pay")}
              </button>
            </>
          )}
        {data?.bookingStatus === "HelpBuddyAssigned" &&
          !isHealthCareBooking && (
            <>
              <button
                type="button"
                className="btn btn-danger px-4"
                onClick={e => {
                  // onSelectAndPay()
                  updateAvailability(e, selectedHB)
                }}
              >
                {t("REJECT")}
              </button>
              <button
                type="button"
                className="btn btn-primary px-4"
                onClick={() => {
                  onSelect()
                }}
              >
                {t("ACCEPT")}
              </button>
            </>
          )}
        {userRole && userRole === USER_TYPE.SERVICE_PROVIDER && (
          <>
            {data.serviceStatus === "CREATED" && (
              <>
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  onClick={() => {
                    onAcceptClick()
                  }}
                >
                  {t("Accept")}
                </button>
              </>
            )}
            {data.serviceStatus === "ASSIGNED" && (
              <button
                type="button"
                className="btn btn-primary px-4"
                onClick={() => {
                  onStartClick()
                }}
              >
                {t("Start")}
              </button>
            )}
            {data.serviceStatus === "STARTED" && (
              <button
                type="button"
                className="btn btn-primary px-4"
                onClick={() => {
                  onCompleteClick()
                }}
              >
                {t("Complete")}
              </button>
            )}
          </>
        )}
      </div>
    </Modal>
  )
}

AssignmentDetail.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.bool,
  userRole: PropTypes.any,
  data: PropTypes.any,
  closeModal: PropTypes.func,
  onCancelClick: PropTypes.func,
  isHealthCareBooking: PropTypes.bool,
  isSelected: PropTypes.bool,
}

export default withTranslation()(AssignmentDetail)

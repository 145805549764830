import { USER_TYPE } from "constants/common";
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
import { getAllUser } from "store/users/actions";
import AllAssignments from "./Assignment/AllAssignments";

const ServiceAssignmentMgmt = () => {


    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <div className="mt-4">
                                        {/* <CardTitle className="h4">Flush</CardTitle>
                            <p className="card-title-desc">
                               Manage all your bookings from here
                            </p> */}
                                        <AllAssignments userRole={USER_TYPE.ADMIN} />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ServiceAssignmentMgmt;
import React from 'react';
import GoogleMapReact from 'google-map-react';
import { GoogleComponent } from 'react-google-location';
import PropTypes from 'prop-types';
import getDistance from './distance'

const inputStyle = {
  position: "absolute",
  top: "124px",
  zIndex: "9",
  right: "100px",
  width: "300px",
  boxShadow: "4px 5px 15px grey"
}

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const GoogleMaps = ({ lat, lng, callback }) => {
  const [mapInstance, setMapInstance] = React.useState({});
  const [mapApi, setMapApi] = React.useState({});
  const [markerArr, setMarkerArr] = React.useState([]);
  const [selectedCoordinate, setSelectedCoordinate] = React.useState({
    lat: lat || 12.77,
    lng: lng || 77.98
  });
  const defaultCenter = {
    lat: lat || 12.77,
    lng: lng || 77.98
  }

  const apiHasLoaded = (instance, mapApi) => {//map, maps
    setMapApi(mapApi);
    setMapInstance(instance);

    const marker = renderMarker(instance, mapApi, selectedCoordinate);
    setMarkerArr([marker]);
  }

  const onPlaceSearch = (e) => {
    if (Object.keys(e.coordinates).length > 0)
      mapInstance?.setCenter(e.coordinates)
  }

  const renderMarker = (map, maps, myLatLng) => {
    let marker = new maps.Marker({
      position: myLatLng,
      map,
      title: 'Selected Coordinate'
    });

    return marker;
  }

  const onMapSelected = (prop) => {
    setTimeout(() => {
      clearMarker();
    }, 50)
    const { lat, lng } = prop;
    const coordinate = { lat, lng }
    setSelectedCoordinate({ lat, lng });
    //passing the selected lat and lng
    console.log("Selected Lat and Lng ===>", lat, lng);
    getDistance(mapApi).then(el => { console.log(el); });
    try {
      callback({ lat, lng });
    } catch (e) {
      console.warn("callback have not been passed to this component");
    }

    const marker = renderMarker(mapInstance, mapApi, coordinate);
    setMarkerArr([marker]);
  }

  const clearMarker = () => {
    for (let i = 0; i < markerArr.length; i++) {
      markerArr[i].setMap(null);
    }
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100vh', width: '100%' }}>
      <div id="autocomplete" style={inputStyle}>
        <GoogleComponent

          apiKey={GOOGLE_MAPS_API_KEY}
          language={'en'}
          country={'country:in|country:us'}
          coordinates={true}
          locationBoxStyle={'custom-style'}
          locationListStyle={'custom-style-list'}
          onChange={(e) => { onPlaceSearch(e) }} />
      </div>

      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY/* YOUR KEY HERE */ }}
        defaultCenter={defaultCenter}
        defaultZoom={12}
        onClick={(props) => { onMapSelected(props); }}
        onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
      >
      </GoogleMapReact>
    </div >
  );
}

GoogleMaps.propTypes = {
  lat: PropTypes.string,
  lng: PropTypes.string,
  callback: PropTypes.func
}

export default GoogleMaps;

import React, { useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useSelector } from "react-redux";


const ApprovedHelpBuddies = () => {
    const approvedHelpBuddyDetail = useSelector(state => state.Profile.approvedHelpBuddy);
    return (
        <>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <div className="table-responsive">
                                <h4 className="card-title">Approved Help Buddies</h4>

                                <div className="table-responsive">
                                    <Table className="table table-striped">
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th>Name</Th>
                                                <Th>Contact Number</Th>
                                                <Th>Service Completed</Th>
                                                <Th>Revenue Generated</Th>
                                                <Th>Rating</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {approvedHelpBuddyDetail.map((el, index) => (
                                                <Tr key={el.name} className={`table-${el.color}`}>
                                                    <Th scope="row">{index + 1}</Th>
                                                    <Td>{el.user.firstName} {el.user.lastName}</Td>
                                                    <Td type="call">{el.user.mobileNumber}</Td>
                                                    <Td>{el && el.serviceCompleted ? el.serviceCompleted : Math.floor(Math.random() * 80 + 15)}</Td>
                                                    <Td>₹{el && el.revenueGenerated ? el && el.revenueGenerated : Math.floor(Math.random() * 10000 + 4000)}</Td>
                                                    <Td>{el && el.rating ? el && el.rating : parseFloat(Math.random() * 5).toFixed(1)}/5</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </>
    )
}

export default ApprovedHelpBuddies;

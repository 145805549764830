import { call, put, takeEvery } from "redux-saga/effects"
import {
  getEmotionalQuotient,
  getSocialBehaviour,
  saveEmotionalQuotiet,
  saveSocialBehaviour,
  uploadUserDocument,
} from "../../utils/Apiv2"
import {
  behaviouralAnalysisByUserId,
  emotionalQuotient,
  emotionalQuotientByUserId,
  socialBehaviour,
} from "./actions"
import {
  GET_BEHAVIOURAL_ANALYSIS_BY_USER_ID,
  GET_EMOTIAL_QUOTIENTS,
  GET_EMOTIONAL_QUOTIENT_BY_USER_ID,
  GET_SOCIAL_BEHAVIOUR,
  SAVE_EMOTIONAL_QUOTIENT,
  SAVE_SOCIAL_BEHAVIOUR,
  UPLOAD_KYC_DOC,
} from "./actionTypes"

function* uploadKYCDocumentSaga(data) {
  try {
    yield call(uploadUserDocument, data.payload)
  } catch (error) {
    console.error(error)
  }
}

function* getEmotionalQuotientSaga() {
  try {
    const resp = yield call(getEmotionalQuotient, null)
    if (resp) {
      yield put(emotionalQuotient(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* saveEmotilaQuotientSaga(data) {
  try {
    yield call(saveEmotionalQuotiet, data.payload)
  } catch (error) {
    console.error(error)
  }
}

function* getSocialBehaviourSaga() {
  try {
    const resp = yield call(getSocialBehaviour, null)
    if (resp) {
      yield put(socialBehaviour(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* getBehaviouralAnalysisByUserIdSaga(data) {
  try {
    const resp = yield call(getSocialBehaviour, data.payload)
    if (resp) {
      yield put(behaviouralAnalysisByUserId(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* saveSocialBehaviourSaga(data) {
  try {
    yield call(saveSocialBehaviour, data.payload)
  } catch (error) {
    console.error(error)
  }
}

function* getEmotionalQuotientByUserIdSaga(data) {
  try {
    const resp = yield call(getEmotionalQuotient, data.payload)
    if (resp) {
      yield put(emotionalQuotientByUserId(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* OnboardingSaga() {
  yield takeEvery(UPLOAD_KYC_DOC, uploadKYCDocumentSaga)
  yield takeEvery(GET_EMOTIAL_QUOTIENTS, getEmotionalQuotientSaga)
  yield takeEvery(SAVE_EMOTIONAL_QUOTIENT, saveEmotilaQuotientSaga)
  yield takeEvery(GET_SOCIAL_BEHAVIOUR, getSocialBehaviourSaga)
  yield takeEvery(SAVE_SOCIAL_BEHAVIOUR, saveSocialBehaviourSaga)
  yield takeEvery(
    GET_BEHAVIOURAL_ANALYSIS_BY_USER_ID,
    getBehaviouralAnalysisByUserIdSaga
  )
  yield takeEvery(
    GET_EMOTIONAL_QUOTIENT_BY_USER_ID,
    getEmotionalQuotientByUserIdSaga
  )
}

export default OnboardingSaga

import { all, call, put, takeEvery } from "redux-saga/effects"

import { CREATE_TRIP, GET_ALL_TRIP_LIST, UPDATE_TRIP } from "./actionTypes"
import { allTripList, stopLoading } from "./actions"

import { changeTripStatus, createTripStatus, getTripList } from "utils/Apiv2"

function* getAllTripListSaga(data) {
  try {
    console.log("Debug ::> data.payload", data.payload)
    const resp = yield call(getTripList, data.payload)
    if (resp) {
      yield put(allTripList(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* updateTripStatus(data) {
  try {
    const resp = yield call(changeTripStatus, data.payload)
    if (resp) {
      const pageData = {
        page: 1,
        perPage: 10,
      }
      const resp = yield call(getTripList, pageData)
      if (resp) {
        yield put(allTripList(resp))
      } else {
        yield put(stopLoading())
      }
    }
  } catch (error) {
    yield put(stopLoading())
    console.error(error)
  }
}
function* createTripStatusSaga(data) {
  try {
    const resp = yield call(createTripStatus, data.payload)
    // if (resp) {
    //   const pageData = {
    //     page: 1,
    //     perPage: 10,
    //   }
    //   const resp = yield call(getTripList, pageData)
    //   if (resp) {
    //     yield put(allTripList(resp))
    //   }
    // }
  } catch (error) {
    console.error(error)
  }
}

function* Users() {
  yield takeEvery(GET_ALL_TRIP_LIST, getAllTripListSaga)
  yield takeEvery(UPDATE_TRIP, updateTripStatus)
  yield takeEvery(CREATE_TRIP, createTripStatusSaga)
}

export default Users

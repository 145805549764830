import { call, put, takeEvery, all } from "redux-saga/effects";
import { ACCEPT_SERVICE, COMPLETE_SERVICE, GET_ALL_ASSIGNMENTS, GET_ASSIGNED_ASSIGNMENTS, GET_COMPLETED_ASSIGNMENT, GET_INCOMPLETE_ASSIGNMENT, GET_ONGOING_ASSIGNMENTS, REJECT_SERVICE, START_SERVICE } from "./actionTypes";
import { acceptService, completeService, startService, rejectService, getAssignedAssignments, getOngoingAssignments, getCompletedAssignments, getAllAssignmentsApi, getIncompletedAssignments, assignHealthBuddy, getAllHealthCareServices, acceptHCService } from '../../utils/Apiv2';
import { allAssignmentsResp, completedAssignmentResp, ongoingAssignmentsResp, allAssignedAssignmentResp, getAllAssignments, getAllAssignedAssignemnts, getAllCompletedAssignments, startServiceResponse, completeServiceResponse, acceptServiceResponse, incompeletedAssignments, savePaymentDetail } from './actions';

function* getallAssignmentsSaga(data) {
    try {
        let resp
        if (data?.payload?.type === 'HealthCare') {
            resp = yield call(getAllHealthCareServices, null);
        } else {
            resp = yield call(getAllAssignmentsApi, null);
        }
        if (resp) {
            yield put(allAssignmentsResp(resp));
        }
        
    } catch (error) {
        console.error(error)
    }
}

function* getAssignedAssignmentsSaga(data) {
    try {
        const resp = yield call(getAssignedAssignments, data.payload);
        if (resp) {
            yield put(allAssignedAssignmentResp(resp));
        }    
    } catch (error) {
        console.error(error)
    }    
}

function* getOngoingAssignmentsSaga(data) {
    try {
        const resp = yield call(getOngoingAssignments, data.payload);
        if (resp) {
            yield put(ongoingAssignmentsResp(resp))
        }
    } catch (error) {
        console.error(error)
    }
}

function* getCompletedAssignmentsSaga(data) {
    try {
        const resp = yield call(getCompletedAssignments, data.payload);
        if (resp) {
            yield put(completedAssignmentResp(resp))
        }   
    } catch (error) {
        console.error(error)
    }
}

function* getIncompletedAssignmentsSaga() {
    try {
        const resp = yield call(getIncompletedAssignments, null);
        if (resp) {
            yield put(incompeletedAssignments(resp))
        }   
    } catch (error) {
        console.error(error)
    }    
}

function* acceptServiceSaga(data) {
    try {
        if (data.payload.type && data.payload.type === 'HealthBuddySelected') {
            delete data.payload.type;
            const paymentDetail = yield call(assignHealthBuddy, data.payload.reqData);
            if (data.payload.reqData.selected) {
                yield put(savePaymentDetail(paymentDetail))
            }
        } else if (data.payload.type && data.payload.type === 'HealthBuddy') {
            delete data.payload.type;
            const paymentDetail = yield call(acceptHCService, data.payload.reqData);
            // if (data.payload.reqData.selected) {
            //     yield put(savePaymentDetail(paymentDetail))
            // }
        } else {
            const resp = yield call(acceptService, data.payload);
            if (resp) {
                yield all([
                    put(acceptServiceResponse(resp)),
                    put(getAllAssignedAssignemnts()),
                    put(getAllAssignments()),
                ])
            }   
        }
    } catch (error) {
        console.error(error)
    }    
}

function* rejectServiceSaga(data) {
    try {
        const resp = yield call(rejectService, data.payload);
        if (resp) {
            yield all([
                put(getAllAssignments()),
                put(getAllAssignedAssignemnts()),
                put(getOngoingAssignments()),
                put(getAllCompletedAssignments())
            ])
        }   
    } catch (error) {
        console.error(error)
    }    
}

function* startServiceSaga(data) {
    try {
        const resp = yield call(startService, data.payload);
        if (resp) {
            yield all([
                put(startServiceResponse(resp)),
                put(getOngoingAssignments()),
                put(getAllAssignments()),
            ])
        }   
    } catch (error) {
        console.error(error)
    }
}

function* completeServiceSaga(data) {
    try {
        const resp = yield call(completeService, data.payload);
        if (resp) {
            yield all([
                put(completeServiceResponse(resp)),
                put(getAllCompletedAssignments()),
            ])
        }   
    } catch (error) {
        console.error(error)
    }
}

function* AssignmentSaga() {
    yield takeEvery(GET_ALL_ASSIGNMENTS, getallAssignmentsSaga);
    yield takeEvery(GET_ASSIGNED_ASSIGNMENTS, getAssignedAssignmentsSaga);
    yield takeEvery(GET_ONGOING_ASSIGNMENTS, getOngoingAssignmentsSaga);
    yield takeEvery(GET_COMPLETED_ASSIGNMENT, getCompletedAssignmentsSaga);
    yield takeEvery(GET_INCOMPLETE_ASSIGNMENT, getIncompletedAssignmentsSaga);

    yield takeEvery(ACCEPT_SERVICE, acceptServiceSaga);
    yield takeEvery(REJECT_SERVICE, rejectServiceSaga);
    yield takeEvery(START_SERVICE, startServiceSaga);
    yield takeEvery(COMPLETE_SERVICE, completeServiceSaga);
}

export default AssignmentSaga;
import React, { useMemo } from "react"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import {
  Row,
  Col,
  FormGroup
} from "reactstrap"

// availity-reactstrap-validation
import { AvField } from "availity-reactstrap-validation"

const SeniorCitizenFields = ({
  t,
  data,
  updateData
}) => {
  const {
    age, gender
  } = useMemo(() => data, [data])

  return (
    <Row>
      <Col xs={12} md={6} xl={6} className="mb-4">
        <FormGroup>
          <AvField
            id="age"
            name="age"
            label={t("Age")}
            className="form-control"
            type="number"
            required
            placeholder={t("Enter Age")}
            value={age}
            onChange={(e) => {
              updateData("age", e.target.value)
            }}
            min={0}
          />
        </FormGroup>
      </Col>

      <Col xs={12} md={6} xl={6} className="mb-4">
        <label className="form-label">{t("Gender")}</label>
        <select
          className="form-control"
          value={gender}
          onChange={(e) => {
            updateData("gender", e.target.value)
          }}
        >
          <option value={"MALE"}>{t("Male")}</option>
          <option value={"FEMALE"} >{t("Female")}</option>
          <option value={"OTHERS"} >{t("Others")}</option>
        </select>
      </Col>
    </Row>
  )
}

SeniorCitizenFields.propTypes = {
  t: PropTypes.any,
  data: PropTypes.instanceOf(Object),
  updateData: PropTypes.func
}

export default withTranslation()(SeniorCitizenFields)

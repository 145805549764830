import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState, useCallback } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  Input,
  Button,
  InputGroupAddon,
  InputGroup,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvInput,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation"

import countryCode from "../../utils/countryCode"

// Social Media Imports
// import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import {
  loginUser,
  // apiError,
  // socialLogin,
  // testLogin,
  verifyOtp,
  generateOtp,
  verifyMobileNumber,
  mobileNumberVerificationStatus,
} from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import happy60plus from "assets/images/happy60plus/logo.png"
// import logo from "assets/images/logo.svg"

//Import config
// import { facebook, google } from "../../config"
import { mobileAndTabletCheck, getPWADisplayMode } from "utils/Common"
import SenagerClubModal from "./SenagerClubModal"

const Login = props => {
  const dispatch = useDispatch()
  let loginMessage = useSelector(state => state.Login.loginFailMessage)
  let optStatus = useSelector(state => state.Login.optVerificationStatus)
  // let isValidatePhone = useSelector(
  //   state => state.Login.mobileNumberVerificationStatus
  // )
console.log("DEBUG: ::> ", loginMessage)
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const [loginViaOtp, setLoginViaOtp] = useState(false)
  const [userName, setUserName] = useState()
  const [otp, setOtp] = useState()
  const [selectedCountry, setCountry] = useState("+91")
  const [modal, setModal] = useState(false)
  // const [isValidatePhone, setIsValidatePhone] = useState(null)
  let isValidatePhone = useSelector(
    state => state.Login.mobileNumberVerificationStatus
  )
  const toggle = () => setModal(!modal)

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    setUserName(values.mobileNumber)
    // let mobileNumber = selectedCountry + values.mobileNumber
    // mobileNumber = mobileNumber.slice(1)
    dispatch(
      verifyMobileNumber({
        countryCode: selectedCountry,
        mobileNumber: values.mobileNumber,
      })
    )
    // setIsValidatePhone(true)
  }

  const handlePasswordSubmit = (event, values) => {
    loginMessage = null
    // let mobileNumber = selectedCountry + userName
    // mobileNumber = mobileNumber.slice(1)
    const data = {
      username: userName,
      ...values,
    }
    if (!loginViaOtp) {
      dispatch(loginUser(data, props.history))
    } else {
      dispatch(verifyOtp(userName, otp, props.history))
    }
  }

  const generateOTP = e => {
    dispatch(generateOtp(userName))
  }

  const back = () => {
    dispatch(mobileNumberVerificationStatus(null))
  }

  const resetError = () => {
    loginMessage = null
    optStatus = null
  }

  useEffect(() => {
    const divInstall = document.getElementById("install-container")

    window.addEventListener("beforeinstallprompt", event => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault()
      console.log("👍", "beforeinstallprompt", event)
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event

      // Remove the 'hidden' class from the install button container to show install option
      if (mobileAndTabletCheck()) {
        const displayMode = getPWADisplayMode()

        if (displayMode === "browser" && divInstall) {
          divInstall.classList.toggle("hidden", false)
        }
      }
    })

    window.addEventListener("appinstalled", event => {
      console.log("👍", "appinstalled", event)
      // Hide the install button.
      if (divInstall) {
        divInstall.classList.toggle("hidden", true)
      }

      // Clear the deferredPrompt so it can be garbage collected
      window.deferredPrompt = null
    })
  }, [])

  const installClick = useCallback(async () => {
    const divInstall = document.getElementById("install-container")
    // Hide the install option
    if (divInstall) {
      divInstall.classList.toggle("hidden", true)
    }

    console.log("👍", "install-button")
    const promptEvent = window.deferredPrompt
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return
    }
    // Show the install prompt.
    promptEvent.prompt()
    // Log the result
    const result = await promptEvent.userChoice
    console.log("👍", "userChoice", result)

    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Happy60Plus</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Welcome to Happy60Plus!
                        </h5>
                        <p>Sign in to continue to Happy60plus.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={happy60plus}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {loginMessage ? (
                      <Alert color="danger">{loginMessage}</Alert>
                    ) : null}
                    {error ? (
                      <Alert color="danger">
                        Something went wrong, Please Try again!
                      </Alert>
                    ) : null}
                    {optStatus ? (
                      <Alert color="danger">{optStatus}</Alert>
                    ) : null}

                    {isValidatePhone === null && (
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        <div className="mb-3">
                          <AvGroup>
                            <Label for="mobileNumber">Mobile Number</Label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <select
                                  id="dialCode"
                                  name="dialCode"
                                  className={`form-control form-select`}
                                  style={{
                                    textAlignLast: "center",
                                    width: 80,
                                    fontSize: "1.5rem",
                                  }}
                                  defaultValue={selectedCountry}
                                  onChange={e => {
                                    setCountry(e.target.value)
                                    // updateData("countryCode", e.target.value)
                                  }}
                                >
                                  {countryCode &&
                                    countryCode.map(country => (
                                      <option
                                        key={
                                          country.dialCode +
                                          country.name.replace(" ", "")
                                        }
                                        value={country.dialCode}
                                      >
                                        {country.dialCode === selectedCountry
                                          ? country.dialCode
                                          : `${country.name} ${country.dialCode}`}
                                      </option>
                                    ))}
                                </select>
                              </InputGroupAddon>
                              <AvInput
                                name="mobileNumber"
                                className="mobileNumberL"
                                placeholder="Enter Mobile Number"
                                type="number"
                                required
                              />
                              <AvFeedback style={{ fontSize: "1rem" }}>
                                This field is required.
                              </AvFeedback>
                            </InputGroup>
                          </AvGroup>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            style={{ fontSize: "1.5rem" }}
                          >
                            NEXT
                          </button>
                        </div>
                      </AvForm>
                    )}
                    {isValidatePhone?.status === true  && (
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handlePasswordSubmit(e, v)
                        }}
                      >
                        {!loginViaOtp && (
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Password"
                              value=""
                              type="password"
                              required
                              className="mobileNumberL"
                              placeholder="Enter Password"
                              onChange={() => {
                                resetError()
                              }}
                            />
                          </div>
                        )}

                        {loginViaOtp && (
                          <>
                            <div className="input-group mb-3">
                              <Input
                                label="OTP"
                                name="otp"
                                type="text"
                                placeholder="12345"
                                className="form-control mobileNumberL"
                                onChange={e => {
                                  setOtp(e.target.value)
                                  resetError()
                                }}
                              />
                              <button
                                className="btn btn-primary mobileNumberL"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                          </>
                        )}

                        <Row>
                          {!loginViaOtp && (
                            <Col xs={12}>
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block mobileNumberL"
                                  type="button"
                                  onClick={e => {
                                    setLoginViaOtp(true)
                                    generateOTP(e)
                                  }}
                                >
                                  Login via OTP
                                </button>
                              </div>
                            </Col>
                          )}

                          {loginViaOtp && (
                            <Col xs={12}>
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block mobileNumberL"
                                  type="button"
                                  onClick={e => {
                                    setLoginViaOtp(false)
                                  }}
                                >
                                  Login via Password
                                </button>
                              </div>
                            </Col>
                          )}
                          {!loginViaOtp && (
                            <Col xs={12}>
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block mobileNumberL"
                                  type="submit"
                                >
                                  Log In
                                </button>
                              </div>
                            </Col>
                          )}
                          {loginViaOtp && (
                            <Col xs={12}>
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block mobileNumberL"
                                  type="button"
                                  onClick={e => {
                                    generateOTP(e)
                                  }}
                                >
                                  Resend OTP
                                </button>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </AvForm>
                    )}
                    {isValidatePhone?.status === false && (
                      <>
                        <div className="mt-2 text-center">
                          <Row>
                            <Col xs={12}>
                              <p>Don&#39;t have an account ?</p>
                            </Col>
                            <Col xs={12} className="mb-4">
                              <Link
                                to="/registerSC"
                                className="fw-medium text-primary"
                                style={{ fontSize: "15px" }}
                              >
                                <button
                                  className="btn btn-primary btn-block w-100"
                                  style={{ maxWidth: 300, fontSize: "1.5rem" }}
                                  type="button"
                                >
                                  Sign up as Senior Citizen
                                </button>
                              </Link>
                            </Col>
                            <Col xs={12} className="mb-4">
                              <Link
                                to="/registerSP"
                                className="fw-medium text-primary"
                                style={{ fontSize: "15px" }}
                              >
                                <button
                                  className="btn btn-primary btn-block w-100"
                                  style={{ maxWidth: 300, fontSize: "1.5rem" }}
                                  type="button"
                                >
                                  Sign up as Service Provider
                                </button>
                              </Link>
                            </Col>
                            <Col xs={12} className="mb-4">
                              <Button color="secondary" onClick={back}>
                                Back
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                    {!loginViaOtp && isValidatePhone?.status === true && (
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    )}
                    <div className="mt-2 d-grid">
                      <Link to="/gold-card" className="w-100">
                        <button
                          className="w-100 btn btn-gradient services-button enquiry-button text-uppercase"
                          type="button"
                          style={{ fontSize: "1.3rem" }}
                          // onClick={toggle}
                        >
                          SUBSCRIBE GOLD CLUB
                        </button>
                      </Link>
                      {/* <SenagerClubModal modal={modal} toggle={toggle} /> */}
                    </div>
                    <div id="install-container" className="mt-5 hidden">
                      <Button
                        size="sm"
                        color="primary"
                        id="install-button"
                        onClick={installClick}
                      >
                        Install App
                      </Button>

                      <span className="ms-1">
                        for better user experience in small devices.
                      </span>
                    </div>

                    <div className="mt-1 text-center">
                      <p>© {new Date().getFullYear()} Happy60Plus</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}

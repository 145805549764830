import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { withTranslation } from "react-i18next"
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Col,
  Row,
  FormGroup,
  Label,
} from "reactstrap"
import FullScreenLoader from "components/Common/FullScreenLoader"

import { bookWebinar, clearWebinarBookingStatus } from "store/actions"

const WebinarServiceDetail = ({ t, data, onSuccessBooking }) => {
  const dispatch = useDispatch()
  const webinarBookingStatus = useSelector(
    state => state.ServiceReducer.webinarBookingStatus
  )

  const { id, title, time, date, image, type } = useMemo(() => data, [data])
  const [fullScreenLoader, setFullScreenLoader] = useState(false)

  const submitBooking = () => {
    setFullScreenLoader(true)
    dispatch(bookWebinar({ id, type }))
  }

  useEffect(() => {
    const { id: statusId, status } = webinarBookingStatus || {}
    if (statusId === data.id) {
      if (status) {
        setFullScreenLoader(false)
        onSuccessBooking()
      } else if (status === false) {
        setFullScreenLoader(false)
        dispatch(clearWebinarBookingStatus())
      }
    }
  }, [webinarBookingStatus])

  return (
    <Col lg={4} sm={6} xs={12} className="mb-3" key={id}>
      <FullScreenLoader isOpen={fullScreenLoader}></FullScreenLoader>

      <Card
        key={id}
        outline
        className="border height-adusted-card"
        color="primary"
      >
        <CardHeader className="bg-transparent">
          <h5 className="my-0 text-primary">
            <i className="mdi mdi-bullseye-arrow me-3" />
            {title}
          </h5>
        </CardHeader>

        <CardBody className="pt-0 d-flex flex-column justify-content-between">
          <CardText>
            <Row>
              <Col xs={12} sm={12} className="mb-1">
                <img src={image} className="border rounded w-100" />
              </Col>
              <Col xs={12} sm={12}>
                {t("Description")}: {time} on {date}
              </Col>
            </Row>
          </CardText>

          <Row>
            <Col>
              <p className="mb-1">{t("Click on Book Now to register")}</p>
              <div className="btn-group btn-group-example" role="group">
                <button
                  type="button"
                  className="btn btn-outline-primary w-sm"
                  onClick={submitBooking}
                  style={{ cursor: "pointer" }}
                >
                  {t("Book Now")}
                </button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

WebinarServiceDetail.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  onSuccessBooking: PropTypes.any,
}

export default withTranslation()(WebinarServiceDetail)

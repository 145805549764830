import React from "react"
import { Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import ServiceType from "../../components/Happy60/ServiceType"
// import ServiceOffer from "../../components/Happy60/ServiceOffer"

const MobileServicesPage = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Services | Happy60Plus</title>
        </MetaTags>
        <Container fluid>
          <ServiceType />
          {/* <ServiceOffer /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MobileServicesPage

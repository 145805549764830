import React from 'react';
import PropTypes from "prop-types"
import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { Link } from "react-router-dom"

const ApplicationProgress = ({
    t,
    isOpen,
    onClose
}) => {
    return (
        <Modal
            isOpen={isOpen}
            centered={true}>
            <div className='modal-body'>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-5">Application Progress</CardTitle>
                        <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle font-size-18" />
                                </div>
                                <Media>
                                    <Media body>
                                        <div>Application Submitted </div>
                                    </Media>
                                </Media>
                            </li>

                            <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle font-size-18" />
                                </div>
                                <Media>
                                    <Media body>
                                        <div id="activitytext">
                                            Onboarding process submitted
                                        </div>
                                    </Media>
                                </Media>
                            </li>
                            <li className="event-list active">
                                <div className="event-timeline-dot">
                                    <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
                                </div>
                                <Media>
                                    <Media body>
                                        <div>Reviewing and validating your application. Meanwhile please go through T&C</div>
                                    </Media>
                                </Media>
                                <ul>
                                    <li>
                                        Please make sure you have updated mobile number tied to Whatsapp. We will update you via whatsapp message.
                                    </li>
                                </ul>
                            </li>
                            <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle font-size-18" />
                                </div>
                                <Media>
                                    <Media body>
                                        <div>Verification completed</div>
                                    </Media>
                                </Media>
                            </li>
                        </ul>
                    </CardBody>
                </Card>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        onClose();
                    }}
                    className="btn btn-primary px-4"
                    data-dismiss="modal"
                >
                    {t("Close")}
                </button>
            </div>
        </Modal>
    )
}
ApplicationProgress.propTypes = {
    t: PropTypes.any,
    isOpen: PropTypes.bool,
    onClose: PropTypes.any
}

export default withTranslation()(ApplicationProgress);
import React, { useState } from "react"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch, useSelector } from "react-redux"
import DataTable from "react-data-table-component"
import UserDetailModal from "./userDetailModal"

import { Edit, ToggleLeft, ToggleRight } from "react-feather"
import HealthBuddyModal from "./healthBuddyModal"
import { getHealthBuddy, updateHealthBuddy } from "store/actions"

const HealthBuddyList = () => {
  const dispatch = useDispatch()
  const [selectedUser, setSelectedUser] = useState(null)
  const [userDetailModal, setUserDetailModal] = useState(false)
  const [updateHBuddy, setUpdateHealthBuddy] = useState(false)

  let healthBuddyList = useSelector(state => state.Users.healthBuddy)
  const userDetail = useSelector(state => state.Profile.userProfile)

  const updateAvailability = (event, values, availability) => {
    event.preventDefault()
    const healthBuddyData = {
      ...values,
      available: availability,
    }
    dispatch(updateHealthBuddy(healthBuddyData))
    setTimeout(() => {
      dispatch(getHealthBuddy(userDetail.id))
    }, 1000)
  }

  const columns = [
    {
      id: 1,
      name: "Name",
      selector: row => row.firstName,
      sortable: true,
      reorder: true,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <span>
            {row.firstName} {row.lastName}
          </span>
          &nbsp;
          <i
            className="bx bx-info-circle text-primary"
            onClick={() => {
              setSelectedUser(row)
              setUserDetailModal(true)
            }}
          ></i>
        </div>
      ),
    },
    {
      id: 2,
      name: "Contact No.",
      selector: row => row.mobileNumber,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Email",
      selector: row => row.email,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Availability",
      button: true,
      cell: item => (
        <div>
          <ul className="list-inline mb-0 font-size-20">
            <li className="list-inline-item">
              <span className="badge badge-pill badge-light">
                {item?.available && (
                  <ToggleRight
                    onClick={e => {
                      updateAvailability(e, item, true)
                    }}
                    size={25}
                    color="#34c38f"
                    fill="#34c38f"
                  />
                )}
                {!item?.available && (
                  <ToggleLeft
                    onClick={e => {
                      updateAvailability(e, item, false)
                    }}
                    size={25}
                    color="#f46a6a"
                    fill="#f46a6a"
                  />
                )}
              </span>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 5,
      name: "Update",
      button: true,
      cell: item => (
        <div>
          <ul className="list-inline mb-0 font-size-20">
            <li className="list-inline-item">
              <span
                className="badge badge-pill badge-light"
                onClick={() => {
                  setSelectedUser(item)
                  setUpdateHealthBuddy(true)
                  // dispatch(getWalletBalanceByUserId(item.id))
                }}
              >
                <Edit size={20} color="black" />
              </span>
            </li>
          </ul>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      <DataTable
        noHeader
        // title="Senior Citizen"
        columns={columns}
        data={healthBuddyList}
        defaultSortFieldId={1}
        // sortIcon={<SortIcon />}
        pagination
        highlightOnHover
        // selectableRows
      />
      {selectedUser && (
        <UserDetailModal
          isOpen={userDetailModal}
          data={selectedUser}
          closeModal={() => {
            setUserDetailModal(false)
          }}
        ></UserDetailModal>
      )}
      {updateHBuddy && (
        <HealthBuddyModal
          data={selectedUser}
          isOpen={updateHBuddy}
          closeModal={() => {
            setUpdateHealthBuddy(false)
          }}
        ></HealthBuddyModal>
      )}
    </React.Fragment>
  )
}

export default HealthBuddyList

import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
  LOGIN_FAILURE,
  OTP_VERIFICATION_STATUS,
  MOBILE_NUMBER_VERIFICATION_STATUS,
} from "./actionTypes"

const initialState = {
  error: "",
  userDetail: null,
  loginFailMessage: null,
  loading: false,
  optVerificationStatus: null,
  mobileNumberVerificationStatus: null,
  mobileNumber: "",
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        loginFailMessage: null,
        optVerificationStatus: null,
      }
      break
    case LOGIN_FAILURE:
      console.log("DEBUG ::> LOGIN FAIL", action.payload)
      state = {
        ...state,
        loginFailMessage: action.payload,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case OTP_VERIFICATION_STATUS:
      state = { ...state, optVerificationStatus: action.payload }
    case MOBILE_NUMBER_VERIFICATION_STATUS:
      state = {
        ...state,
        mobileNumberVerificationStatus: action.payload.resp,
        mobileNumber: action.payload.data,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default login

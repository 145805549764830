import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import StackedColumnChart from "../../components/Charts/StackedColumnChart"
import { useDispatch, useSelector } from "react-redux"
import { GetTotalServiceBookingData } from "../../store/actions"
import { USER_TYPE } from "constants/common"

const ServiceBooked = () => {
  const dispatch = useDispatch()
  const userDetail = useSelector(state => state.Profile.userProfile)

  const [periodType, setPeriodType] = useState("monthly")

  const { periodData } = useSelector(state => ({
    periodData: state.Dashboard.totalServiceBookingData || {},
  }))

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    if (userDetail?.type === USER_TYPE.PARTNER) {
      dispatch(
        GetTotalServiceBookingData({ period: pType, id: userDetail?.id })
      )
    } else {
      dispatch(GetTotalServiceBookingData({ period: pType }))
    }
  }
  return (
    <Card>
      <CardBody>
        <div className="d-sm-flex flex-wrap">
          <h4 className="card-title mb-4">Services Booked</h4>
          <div className="ms-auto">
            <ul className="nav nav-pills">
              {/* <li className="nav-item">
                                <Link
                                    to="#"
                                    className={classNames(
                                        { active: periodType === "weekly" },
                                        "nav-link disabled"
                                    )}
                                    onClick={() => {
                                        onChangeChartPeriod("weekly")
                                    }}
                                    id="one_month"
                                >
                                    Week
                                </Link>{" "}
                            </li> */}
              <li className="nav-item">
                <Link
                  to="#"
                  className={classNames(
                    { active: periodType === "monthly" },
                    "nav-link"
                  )}
                  onClick={() => {
                    onChangeChartPeriod("monthly")
                  }}
                  id="one_month"
                >
                  Month
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="#"
                  className={classNames(
                    { active: periodType === "yearly" },
                    "nav-link"
                  )}
                  onClick={() => {
                    onChangeChartPeriod("yearly")
                  }}
                  id="one_month"
                >
                  Year
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="clearfix"></div> */}
        <StackedColumnChart periodData={periodData} />
        {/* <BasicColumnChart periodData={periodData} /> */}
      </CardBody>
    </Card>
  )
}

export default withRouter(ServiceBooked)

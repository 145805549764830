import React, { useState } from "react"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch, useSelector } from "react-redux"
import DataTable from "react-data-table-component"
import UserDetailModal from "./userDetailModal"
import { getImageForUser } from "utils/Common"
import default_avatar from "../../../assets/images/users/default_avatar.png"
import { USER_TYPE } from "constants/common"
import { getAllUser } from "store/actions"

const HelpbuddyList = () => {
  const dispatch = useDispatch()

  let helpBuddyList = useSelector(state => state.Users.helpBuddy.users)
  let page = useSelector(state => state.Users.helpBuddy.page)

  const [selectedUser, setSelectedUser] = useState(null)
  const [userDetailModal, setUserDetailModal] = useState(false)
  const [perPage, setPerPage] = useState(10)

  const handlePageChange = page => {
    dispatch(
      getAllUser({
        type: USER_TYPE.SERVICE_PROVIDER,
        page,
        size: perPage,
      })
    )
  }

  const handlePerRowsChange = (newPerPage, page) => {
    dispatch(
      getAllUser({
        type: USER_TYPE.SERVICE_PROVIDER,
        page,
        size: newPerPage,
      })
    )
    setPerPage(newPerPage)
  }

  const columns = [
    {
      id: 1,
      name: "Photo",
      selector: row => row,
      sortable: false,
      reorder: true,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <img
            className="rounded-circle header-profile-user"
            src={
              row
                ? getImageForUser(row.id, row.profileImageURL)
                : default_avatar
            }
            alt="Header Avatar"
          ></img>
        </div>
      ),
    },
    {
      id: 2,
      name: "Name",
      selector: row => row.firstName,
      sortable: true,
      reorder: true,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <span>
            {row.firstName} {row.lastName}
          </span>
          &nbsp;
          <i
            className="bx bx-info-circle text-primary"
            onClick={() => {
              setSelectedUser(row)
              setUserDetailModal(true)
            }}
          ></i>
        </div>
      ),
    },
    {
      id: 3,
      name: "Contact No.",
      selector: row => row.mobileNumber,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Email",
      selector: row => row.email,
      sortable: true,
      // right: true,
      reorder: true,
    },
  ]

  return (
    <React.Fragment>
      <DataTable
        noHeader
        columns={columns}
        data={helpBuddyList}
        defaultSortFieldId={1}
        pagination
        highlightOnHover
        paginationServer
        paginationTotalRows={page?.totalElements || 10}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
      {selectedUser && (
        <UserDetailModal
          isOpen={userDetailModal}
          data={selectedUser}
          closeModal={() => {
            setUserDetailModal(false)
          }}
        ></UserDetailModal>
      )}
    </React.Fragment>
  )
}

export default HelpbuddyList

// minTime, maxTime in HH:mm 24 hour format
// step in minutes
const getMinMaxTimeList = (minTime, maxTime, step, id = new Date().getTime().toString()) => {
  const listValues = []
  if (minTime && maxTime && step) {
    switch (minTime < maxTime) {
      case true: {
        let value = minTime
        do {
          listValues.push(value)
          let [hours, minutes] = value.split(":").map((item) => parseInt(item))
          minutes += step
          if (minutes >= 60) {
            hours += 1
            minutes = minutes % 60
          }
          if (hours >= 24) {
            hours = hours % 24
          }
          hours = hours.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
          minutes = minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
          value = `${hours}:${minutes}`
        } while (value <= maxTime)
        break;
      }
      case false: {
        let value = minTime
        do {
          listValues.push(value)
          let [hours, minutes] = value.split(":").map((item) => parseInt(item))
          minutes += step
          if (minutes >= 60) {
            hours += 1
            minutes = minutes % 60
          }
          if (hours >= 24) {
            hours = hours % 24
          }
          hours = hours.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
          minutes = minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
          value = `${hours}:${minutes}`
        } while (value >= minTime || value <= maxTime)
        break;
      }
      default:
        break;
    }
  }
  return listValues
}

const getTimeStringFromMinutes = (minutesNumber) => {
  const hours = Math.floor(minutesNumber / 60)
  const minutes = minutesNumber % 60

  let timeString = `${hours} ${hours <= 1 ? 'hour' : "hours"}`
  if (minutes > 0) {
    timeString += ` ${minutes} ${minutes <= 1 ? 'minutes' : "minutes"}`
  }

  return timeString
}

// Duration and step, both in minutes
const getMinMaxDurationList = (minDuration = 0, maxDuration = 1440, step = 30) => {
  const listValues = []
  let minValue = minDuration, maxValue = maxDuration
  if (minDuration > maxDuration) {
    minValue = maxDuration
    maxValue = minDuration
  }

  let value = minValue
  do {
    listValues.push(value)
    value += step
  } while (value <= maxValue)

  return listValues
}

export {
  getMinMaxTimeList,
  getMinMaxDurationList,
  getTimeStringFromMinutes
}

import React, { useState, useEffect } from "react"
import cloneDeep from "lodash/cloneDeep"
import { Row, Col, Container, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import ServiceBookingModal from "./services/serviceBookingModal"
import ServiceDetail from "./services/serviceDetail"
import ServiceInfoModal from "./services/serviceInfoModal"
import { WEBINAR_SERVICES, USER_TYPE } from "../../constants/common"
import {
  checkBookActivityServices,
  deleteServiceType,
  getServiceType,
} from "store/actions"
import ConfirmModal from "components/Common/ConfirmModal"
import { getUserDetail } from "utils/userUtil"

import WebinarInfoModal from "./services/WebinarInfoModal"
import WebinarServiceDetail from "./services/WebinarServiceDetail"

const DEFAULT_WEBINAR_MODAL_DETAILS = {
  show: false,
  data: {},
}

const ServiceType = () => {
  const dispatch = useDispatch()
  const [showServiceBookingModal, setServiceBookingModal] = useState(false)
  const [serviceInfoModal, setServiceInfoModal] = useState(false)
  const [selectedServiceType, setSelectedServiceType] = useState(null)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const [webinarModalDetails, setWebinarModalDetails] = useState(
    cloneDeep(DEFAULT_WEBINAR_MODAL_DETAILS)
  )

  let serviceType = useSelector(state => state.ServiceReducer.serviceType)
  let userDetail = JSON.parse(getUserDetail())

  if (!userDetail?.id) {
    userDetail = useSelector(state => state.Profile.userProfile)
  }

  useEffect(() => {
    if (!serviceType?.length) {
      dispatch(getServiceType())
    }

    return () => {
      setWebinarModalDetails(cloneDeep(DEFAULT_WEBINAR_MODAL_DETAILS))
    }
  }, [])

  useEffect(() => {
    if (
      serviceType?.length &&
      window.location.href.includes("/services/book/daily-activity")
    ) {
      const serviceData = serviceType.find(
        x =>
          x.id === "a1e835d0-d3b7-4105-b3fb-564ff9f1957a" ||
          x.id === "2db41585-e913-43a0-bca2-1a1c41c72d15"
      )
      setSelectedServiceType(serviceData)
      setServiceInfoModal(true)
    }
    if (serviceType?.length) {
      const serviceData = serviceType.find(
        x =>
          x.id === "a1e835d0-d3b7-4105-b3fb-564ff9f1957a" ||
          x.id === "2db41585-e913-43a0-bca2-1a1c41c72d15"
      )
      if (serviceData) {
        const data = {
          userId: userDetail.id,
          serviceID: serviceData.id,
        }
        dispatch(checkBookActivityServices(data))
      }
    }
  }, [serviceType])

  const openWebinarModal = data => {
    setWebinarModalDetails({
      show: true,
      data: data || {},
    })
  }

  const closeWebinarModal = () => {
    setWebinarModalDetails(cloneDeep(DEFAULT_WEBINAR_MODAL_DETAILS))
  }

  return (
    <React.Fragment>
      {userDetail && userDetail.type === USER_TYPE.ADMIN && (
        <Row>
          <Col xs={4} sm={4} className="mb-4">
            <Button
              color="primary"
              type="button"
              onClick={() => {
                setSelectedServiceType(null), setServiceInfoModal(true)
              }}
            >
              Create new Service
            </Button>
          </Col>
        </Row>
      )}

      <Row className="justify-content-center">
        {WEBINAR_SERVICES.map(data => (
          <WebinarServiceDetail
            data={data}
            key={data.id}
            onSuccessBooking={() => {
              openWebinarModal(data)
            }}
          />
        ))}

        {serviceType.map(item => {
          return (
            <ServiceDetail
              data={item}
              key={item.id}
              userDetail={userDetail}
              bookService={serviceData => {
                setSelectedServiceType(serviceData)
                setServiceBookingModal(true)
              }}
              knowMore={serviceData => {
                setSelectedServiceType(serviceData)
                setServiceInfoModal(true)
              }}
              editServiceDetail={serviceData => {
                setSelectedServiceType(serviceData)
                setServiceInfoModal(true)
              }}
              deleteService={serviceData => {
                setSelectedServiceType(serviceData)
                setShowConfirmModal(true)
              }}
            />
          )
        })}
      </Row>

      {selectedServiceType && (
        <React.Fragment>
          <ServiceBookingModal
            isOpen={showServiceBookingModal}
            selectedService={selectedServiceType}
            data={{}}
            closeModal={() => {
              setServiceBookingModal(false)
            }}
            onSubmitClick={() => {
              setServiceBookingModal(false)
            }}
            onCancelClick={() => {
              setServiceBookingModal(false)
            }}
          />
        </React.Fragment>
      )}

      <ServiceInfoModal
        isOpen={serviceInfoModal}
        data={selectedServiceType || {}}
        userDetail={userDetail}
        closeModal={() => {
          setServiceInfoModal(false)
        }}
      />

      <ConfirmModal
        isOpen={showConfirmModal}
        titleText="Service delete"
        bodyText="Are you sure you want to delete service"
        onYesClick={() => {
          dispatch(deleteServiceType({ ...selectedServiceType, active: false }))
          setShowConfirmModal(false)
        }}
        onNoClick={() => {
          setShowConfirmModal(false)
        }}
      />

      <WebinarInfoModal
        webinarModalDetails={webinarModalDetails}
        closeModal={() => {
          closeWebinarModal()
        }}
      />
    </React.Fragment>
  )
}

export default ServiceType

import {
    BOOKING_DETAIL, CANCEL_SERVICE_RESPONSE, CLEAR_SERVICE_BOOKING_RESPOSE, UPDATE_SERVICE_RESPONSE, SERVICE_BOOKING_STATUS, SERVICE_TYPE, CREATE_SERVICE_RESPONSE, DELETE_SERVICE_TYPE_RESPONSE, PRICING_DETAIL,
    WEBINAR_BOOKING_STATUS, CLEAR_WEBINAR_BOOKING_STATUS, SET_PROMO_CODE_VALIDITY_STATUS, GET_ALL_HEALTH_CARE_BOOKINGS_RESPONSE, UPDATE_HEALTH_CARE_ASSIGNED, ACTIVITY_SERVICE_STATUS, BOOK_ANNUAL_SUBSCRIPTION_RESP
} from "./actionTypes"

const inititalState = {
    serviceBookingStatus: null,
    serviceType: [],
    ongoingServices: [],
    upcomingServices: [],
    completedServices: [],
    pricingDetail: null,
    webinarBookingStatus: null,
    pricingDetail: null,
    validPromoCode: null,
    createdHealthCareService: [],
    healthBuddyAssignedHealthCareService: [],
    selectedHealthBuddyHealthCareService: [],
    onlineActivityServiceStatus: {},
    annualSubscriptionData: null
}
const ServiceReducer = (state = inititalState, action) => {
    switch (action.type) {
        case SERVICE_TYPE:
            return {
                ...state,
                serviceType: action.payload.filter(item => item.active)
            }
        case UPDATE_SERVICE_RESPONSE:
            return {
                ...state,
                serviceType: [...state.serviceType.map(item => {
                    if (item.id === action.payload.id) {
                        item = action.payload;
                    }
                    return item;
                })]
            }
        case DELETE_SERVICE_TYPE_RESPONSE:
            return {
                ...state,
                serviceType: [...state.serviceType.filter(item => item.id !== action.payload.id)]
            }
        case CREATE_SERVICE_RESPONSE:
            return {
                ...state,
                serviceType: [...state.serviceType, action.payload]
            }
        case SERVICE_BOOKING_STATUS:
            return {
                ...state,
                serviceBookingStatus: action.payload
            }
        case BOOK_ANNUAL_SUBSCRIPTION_RESP:
            return {
                ...state,
                annualSubscriptionData: action.payload
            }
        case PRICING_DETAIL:
            return {
                ...state,
                pricingDetail: action.payload
            }
        case GET_ALL_HEALTH_CARE_BOOKINGS_RESPONSE:
            return {
                ...state,
                createdHealthCareService: action.payload.createdHealthCareService,
                healthBuddyAssignedHealthCareService: action.payload.healthBuddyAssignedHealthCareService,
                selectedHealthBuddyHealthCareService: action.payload.selectedHealthBuddyHealthCareService
            }
        case UPDATE_HEALTH_CARE_ASSIGNED: 
            return {
                ...state,
                createdHealthCareService: action.payload.createdHealthCareService,
                healthBuddyAssignedHealthCareService: action.payload.healthBuddyAssignedHealthCareService,
            }
        case BOOKING_DETAIL:
            return {
                ...state,
                ongoingServices: action.payload.ongoing,
                upcomingServices: action.payload.upcoming,
                completedServices: action.payload.completed,
            }
        case CLEAR_SERVICE_BOOKING_RESPOSE:
            return {
                ...state,
                serviceBookingStatus: null
            }
        case CANCEL_SERVICE_RESPONSE:
            return {
                ...state,
                upcomingServices: state.upcomingServices.filter(item => item.id !== action.payload.id)
            }
        case WEBINAR_BOOKING_STATUS:
            return {
                ...state,
                webinarBookingStatus: action.payload
            }
        case CLEAR_WEBINAR_BOOKING_STATUS:
            return {
                ...state,
                webinarBookingStatus: null
            }
        case SET_PROMO_CODE_VALIDITY_STATUS:
            return {
                ...state,
                validPromoCode: action.payload
            }
        case ACTIVITY_SERVICE_STATUS:
            return {
                ...state,
                onlineActivityServiceStatus: action.payload
            }
        default:
            return state
    }
}


export default ServiceReducer;

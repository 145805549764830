import React from "react"
import MetaTags from "react-meta-tags"
import { Link } from 'react-router-dom';
import RegistrationUserDetail from "./registrationUserDetail";
import { USER_TYPE } from '../../constants/common';

const RegisterServiceProvider = props => {

    return (
        <React.Fragment>
            <MetaTags>
                <title>Register</title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <RegistrationUserDetail
                    userType={USER_TYPE.SERVICE_PROVIDER}
                />
            </div>
        </React.Fragment>
    )
}

export default RegisterServiceProvider;

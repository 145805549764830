import { call, put, takeEvery } from "@redux-saga/core/effects"
import {
  downloadUserDocumentResp,
  uploadUserDocumentResp,
  valueHelps,
} from "./actions"
import {
  DOWNLOAD_USER_DOCUMENT,
  GET_VALUE_HELPS,
  LOAD_SCRIPT,
  SEND_WHATAPP_MESSAGE,
  UPLOAD_USER_DOCUMENT,
} from "./actionTypes"
import {
  downloadUserDocument,
  fetchValueHelps,
  loadScript,
  sendWhatsappMessage,
  uploadUserDocument,
} from "../../utils/Apiv2"

function* loadScriptSaga(data) {
  try {
    yield call(loadScript, data.payload)
  } catch (error) {
    console.error(error)
  }
}

function* getValue() {
  try {
    const response = yield call(fetchValueHelps, {})
    yield put(valueHelps(response))
  } catch (error) {
    console.error(error)
  }
}

function* sendWhatsappMessageSaga(data) {
  try {
    const resp = yield call(sendWhatsappMessage, data.payload)
  } catch (error) {
    console.error(error)
  }
}

function* uploadUserDocumentSaga(data) {
  try {
    const resp = yield call(uploadUserDocument, data.payload)
    if (resp) {
      yield put(uploadUserDocumentResp(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* downloadUserDocumentSaga(data) {
  try {
    const resp = yield call(downloadUserDocument, data.payload)
    if (resp) {
      yield put(downloadUserDocumentResp(resp))
    }
  } catch (error) {
    console.error(error)
  }
}

function* CommonSaga() {
  yield takeEvery(GET_VALUE_HELPS, getValue)
  yield takeEvery(LOAD_SCRIPT, loadScriptSaga)
  yield takeEvery(SEND_WHATAPP_MESSAGE, sendWhatsappMessageSaga)
  yield takeEvery(UPLOAD_USER_DOCUMENT, uploadUserDocumentSaga)
  yield takeEvery(DOWNLOAD_USER_DOCUMENT, downloadUserDocumentSaga)
}

export default CommonSaga

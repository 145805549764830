import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
    GET_ADMIN_PACKAGES, GET_USER_PACKAGES, GET_PACKAGES_BY_USER_ID,
    ADD_PACKAGE, UPDATE_PACKAGE, DELETE_PACKAGE,
    BOOK_PACKAGE, BOOK_SERVICE_FROM_PACKAGE,
    GET_PACKAGE_BOOKINGS, GET_PACKAGE_BOOKINGS_BY_USER_ID
} from "./actionTypes";

import {
    setAdminPackages, setUserPackages, setPackagesByUserId,
    addPackageResponse, updatePackageResponse, deletePackageResponse,
    bookPackageResponse, bookServiceFromPackageResponse,
    setPackageBookings, setPackageBookingsByUserId
} from './actions';

import {
    getAdminPackagesApi, getUserPackagesApi, getPackagesByUserIdApi,
    addPackageApi, updatePackageApi, deletePackageApi,
    bookPackageApi, bookPackageServiceApi,
    getPackageBookingsApi, getPackageBookingsByUserIdApi
} from '../../utils/Apiv2';

function* getAdminPackages() {
    try {
        const response = yield call(getAdminPackagesApi, {});
        if (response) {
            yield put(setAdminPackages(response.data['service-packages']));
        }
    } catch (e) {
        console.log("PackagesSaga getAdminPackages error", e)
    }
}
function* getUserPackages() {
    try {
        const response = yield call(getUserPackagesApi, {});
        if (response) {
            yield put(setUserPackages(response.data['service-packages']));
        }
    } catch (e) {
        console.log("PackagesSaga getUserPackages error", e)
    }
}
function* getPackagesByUserId() {
    try {
        const response = yield call(getPackagesByUserIdApi, {});
        if (response) {
            yield put(setPackagesByUserId(response.data['service-packages']));
        }
    } catch (e) {
        console.log("PackagesSaga getPackagesByUserId error", e)
    }
}

function* addPackage(data) {
    try {
        const response = yield call(addPackageApi, data.payload);
        if (response) {
            yield put(addPackageResponse(response));
        }
    } catch (e) {
        console.log("PackagesSaga addPackage error", e)
    }
}
function* updatePackage(data) {
    try {
        const response = yield call(updatePackageApi, data.payload);
        if (response) {
            yield put(updatePackageResponse(response));
        }
    } catch (e) {
        console.log("PackagesSaga updatePackage error", e)
    }
}
function* deletePackage(data) {
    try {
        const response = yield call(deletePackageApi, data.payload);
        if (response) {
            yield put(deletePackageResponse(data.payload));
        }
    } catch (e) {
        console.log("PackagesSaga deletePackage error", e)
    }
}

function* bookPackage(data) {
    try {
        const response = yield call(bookPackageApi, data.payload);
        if (response) {
            yield put(bookPackageResponse(response))
        }
    } catch (e) {
        console.log("PackagesSaga bookPackage error", e)
    }
}
function* bookPackageService(data) {
    try {
        const response = yield call(bookPackageServiceApi, data.payload);
        if (response) {
            yield put(bookServiceFromPackageResponse(response))
        }
    } catch (e) {
        console.log("PackagesSaga bookPackageService error", e)
        yield put(bookServiceFromPackageResponse(false));
    }
}

function* getPackageBookings() {
    try {
        const response = yield call(getPackageBookingsApi, {});
        if (response) {
            yield put(setPackageBookings(response.data['package-bookings']));
        }
    } catch (e) {
        console.log("PackagesSaga getPackageBookings error", e)
    }
}
function* getPackageBookingsByUserId() {
    try {
        const response = yield call(getPackageBookingsByUserIdApi, {});
        if (response) {
            yield put(setPackageBookingsByUserId(response.data['package-bookings']));
        }
    } catch (e) {
        console.log("PackagesSaga getPackageBookingsByUserId error", e)
    }
}

function* PackagesSaga() {
    yield takeEvery(GET_ADMIN_PACKAGES, getAdminPackages)
    yield takeEvery(GET_USER_PACKAGES, getUserPackages)
    yield takeEvery(GET_PACKAGES_BY_USER_ID, getPackagesByUserId)

    yield takeEvery(ADD_PACKAGE, addPackage)
    yield takeEvery(UPDATE_PACKAGE, updatePackage)
    yield takeEvery(DELETE_PACKAGE, deletePackage)

    yield takeEvery(BOOK_PACKAGE, bookPackage)
    yield takeEvery(BOOK_SERVICE_FROM_PACKAGE, bookPackageService)

    yield takeEvery(GET_PACKAGE_BOOKINGS, getPackageBookings)
    yield takeEvery(GET_PACKAGE_BOOKINGS_BY_USER_ID, getPackageBookingsByUserId)
}

export default PackagesSaga;

import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { RESET_PASSWORD, FORGET_PASSWORD } from "./actionTypes"
import { resetPasswordResponse } from "./actions"

import { resetPassword } from "../../../utils/Apiv2"

function* resetPasswordSaga(data) {
  try {
    const resp = yield call(resetPassword, data.payload);
    if (resp) {
      yield put(resetPasswordResponse(resp))
    }  
  } catch (error) {
    console.error(error)
  }  
}

export function* watchUserPasswordForget() {
  try {
    yield takeEvery(RESET_PASSWORD, resetPasswordSaga)    
  } catch (error) {
    console.error(error)
  }
}

function* forgetPasswordSaga() {
  try {
    yield all([fork(watchUserPasswordForget)])    
  } catch (error) {
    console.error(error)
  }
}

export default forgetPasswordSaga

import { SAVE_ONBOARD_STATUS, UPLOAD_KYC_DOC, GET_EMOTIAL_QUOTIENTS, EMOTIONAL_QUOTIENTS, GET_SOCIAL_BEHAVIOUR, SAVE_EMOTIONAL_QUOTIENT, SOCIAL_BEHAVIOUR, SAVE_SOCIAL_BEHAVIOUR, UPDATE_SOCIAL_BEHAVIOUR, UPDATE_EMOTIONAL_QUOTIENT, GET_BEHAVIOURAL_ANALYSIS_BY_USER_ID, BEHAVIOURAL_ANALYSIS_BY_USER_ID, GET_EMOTIONAL_QUOTIENT_BY_USER_ID, EMOTIONAL_QUOTIENT_BY_USER_ID } from "./actionTypes"


export const uploadKYCDoc = (kycDoc) => {
    return {
        type: UPLOAD_KYC_DOC,
        payload: kycDoc
    }
}

export const saveOnboardStatus = (onboardStatus) => {
    return {
        type: SAVE_ONBOARD_STATUS,
        payload: onboardStatus
    }
}

export const getEmotionalQuotient = () => {
    return {
        type: GET_EMOTIAL_QUOTIENTS,
        payload: null
    }
}

export const emotionalQuotient = (data) => {
    return {
        type: EMOTIONAL_QUOTIENTS,
        payload: data
    }
}

export const updateEmotionalQuotient = (data) => {
    return {
        type: UPDATE_EMOTIONAL_QUOTIENT,
        payload: data
    }
}

export const saveEmotionalQuotient = (data) => {
    return {
        type: SAVE_EMOTIONAL_QUOTIENT,
        payload: data
    }
}

export const getSocialBehaviour = () => {
    return {
        type: GET_SOCIAL_BEHAVIOUR,
        payload: null
    }
}

export const socialBehaviour = (data) => {
    return {
        type: SOCIAL_BEHAVIOUR,
        payload: data
    }
}

export const updateSocialBehaviour = (data) => {
    return {
        type: UPDATE_SOCIAL_BEHAVIOUR,
        payload: data
    }
}

export const saveSocialBehaviour = (data) => {
    return {
        type: SAVE_SOCIAL_BEHAVIOUR,
        payload: data
    }
}

export const getBehaviouralAnalysisByUserId = (userId) => {
    return {
        type: GET_BEHAVIOURAL_ANALYSIS_BY_USER_ID,
        payload: userId
    }
}

export const behaviouralAnalysisByUserId = (data) => {
    return {
        type: BEHAVIOURAL_ANALYSIS_BY_USER_ID,
        payload: data
    }
}

export const getEmotionalQuotientByUserId = (userId) => {
    return {
        type: GET_EMOTIONAL_QUOTIENT_BY_USER_ID,
        payload: userId
    }
}

export const emotionalQuotientByUserId = (data) => {
    return {
        type: EMOTIONAL_QUOTIENT_BY_USER_ID,
        payload: data
    }
}
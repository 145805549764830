import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Collapse, Button } from "reactstrap"
import classnames from "classnames"
import UpcomingService from "../../components/Happy60/services/UpcomingService"
import CompletedServices from "../../components/Happy60/services/CompletedServices"
import OngoingService from "../../components/Happy60/services/OngoingService"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getAllServiceBookingDetail } from "store/actions"
import { getUserDetail } from "utils/userUtil"

const Booking = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const selectedUser = useSelector(state => state.Profile.selectedUser)
  let userDetail = JSON.parse(getUserDetail())

  useEffect(() => {
    if (selectedUser?.id || userDetail?.id) {
      dispatch(getAllServiceBookingDetail(selectedUser?.id || userDetail?.id))
    }
  },[selectedUser, userDetail])

  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
  }

  const t_col2 = () => {
    setcol1(false)
    setcol2(!col2)
    setcol3(false)
  }

  const t_col3 = () => {
    setcol1(false)
    setcol3(!col3)
    setcol2(false)
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {selectedUser?.id && <Button
                style={{
                  position: "absolute",
                  top: 0,
                  right: 20,
                }}
                onClick={() => {
                  history.push(`/services/${selectedUser.id}`)
                }}
              >
                Book New Service
              </Button>}

              <Row>
                <Col lg={12}>
                  <div className="mt-4">
                    {/* <CardTitle className="h4">Flush</CardTitle>
                            <p className="card-title-desc">
                               Manage all your bookings from here
                            </p> */}

                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col1 }
                            )}
                            type="button"
                            onClick={t_col1}
                            style={{ cursor: "pointer" }}
                          >
                            Ongoing
                          </button>
                        </h2>

                        <Collapse isOpen={col1} className="accordion-collapse">
                          <div className="accordion-body">
                            <OngoingService />
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushTwo">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col2 }
                            )}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: "pointer" }}
                          >
                            Upcoming
                          </button>
                        </h2>

                        <Collapse isOpen={col2} className="accordion-collapse">
                          <div
                            className="accordion-body"
                            style={{ overflow: "auto" }}
                          >
                            <UpcomingService />
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushThree">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col3 }
                            )}
                            type="button"
                            onClick={t_col3}
                            style={{ cursor: "pointer" }}
                          >
                            Completed
                          </button>
                        </h2>

                        <Collapse isOpen={col3} className="accordion-collapse">
                          <div className="accordion-body">
                            <CompletedServices />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Booking

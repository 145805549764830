// Service Type
export const GET_SERVICE_TYPE = "GET_SERVICE_TYPE"
export const SERVICE_TYPE = "SERVICE_TYPE"
export const CREATE_SERVICE_TYPE = "CREATE_SERVICE_TYPE"
export const CREATE_SERVICE_RESPONSE = "CREATE_SERVICE_RESPONSE"
export const UPDATE_SERVICE_TYPE = "UPDATE_SERVICE_TYPE"
export const UPDATE_SERVICE_RESPONSE = "UPDATE_SERVICE_RESPONSE"
export const DELETE_SERVICE_TYPE = "DELETE_SERVICE_TYPE"
export const DELETE_SERVICE_TYPE_RESPONSE = "DELETE_SERVICE_TYPE_RESPONSE"

// Services Booking
export const BOOK_SERVICE = "BOOK_SERVICE"
export const SERVICE_BOOKING_STATUS = "SERVICE_BOOKING_STATUS"
export const GET_ALL_SERVICE_BOOKINGS = "GET_ALL_SERVICE_BOOKINGS"
export const CLEAR_SERVICE_BOOKING_RESPOSE = "CLEAR_SERVICE_BOOKING_RESPOSE";
export const BOOKING_DETAIL = "BOOKING_DETAIL"
export const CANCEL_SERIVCE_BOOKING = "CANCEL_SERIVCE_BOOKING"
export const CANCEL_SERVICE_RESPONSE = "CANCEL_SERVICE_RESPONSE"

// pricing
export const GET_PRICING_DETAIL = "GET_PRICING_DETAIL"
export const PRICING_DETAIL = "PRICING_DETAIL"

// rating
export const RATE_SERVICE = "RATE_SERVICE";

// webinar booking
export const BOOK_WEBINAR = "BOOK_WEBINAR"
export const WEBINAR_BOOKING_STATUS = "WEBINAR_BOOKING_STATUS"
export const CLEAR_WEBINAR_BOOKING_STATUS = "CLEAR_WEBINAR_BOOKING_STATUS";

// Service offers
export const VALIDATE_PROMO_CODE = "VALIDATE_PROMO_CODE"
export const SET_PROMO_CODE_VALIDITY_STATUS = "SET_PROMO_CODE_VALIDITY_STATUS"


export const GET_ALL_HEALTH_CARE_BOOKINGS = "GET_ALL_HEALTH_CARE_BOOKINGS"
export const GET_ALL_HEALTH_CARE_BOOKINGS_RESPONSE = "GET_ALL_HEALTH_CARE_BOOKINGS_RESPONSE"
export const UPDATE_HEALTH_CARE_ASSIGNED = "UPDATE_HEALTH_CARE_ASSIGNED"

// Daily Activity
export const BOOK_ACTIVITY_SERVICE = "BOOK_ACTIVITY_SERVICE"
export const CHECK_BOOK_ACTIVITY_SERVICE = "CHECK_BOOK_ACTIVITY_SERVICE"
export const ACTIVITY_SERVICE_STATUS = "ACTIVITY_SERVICE_STATUS"

export const BOOK_ANNUAL_SUBSCRIPTION = "BOOK_ANNUAL_SUBSCRIPTION"
export const BOOK_ANNUAL_SUBSCRIPTION_RESP = "BOOK_ANNUAL_SUBSCRIPTION_RESP"


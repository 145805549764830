import React, { useState, useEffect } from "react"

import { Row } from "reactstrap"

import { getAllTripsAndToursData } from "utils/Apiv2"
import TripAndToursCard from "./TripAndToursCard"
import TripAndToursKnowMore from "./TripAndToursKnowMore"
import TripAndToursEnquiryModal from "../Happy60/TripAndToursEnquiryModal"

const options = {
  loop: false,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  autoplayTimeout: 5000,
  autoHeight: true,
  items: 1,
  animateOut: "fadeOut",
}

const TripSC2 = () => {
  const [tripAndToursDetails, setTripAndToursDetails] = useState([])

  const [showKnowMore, setShowKnowMore] = useState(false)
  const [knowMoreData, setKnowMoreData] = useState({})

  const [showEnquiryModal, setShowEnquiryModal] = useState(false)
  const [enquiryData, setEnquiryData] = useState({})

  useEffect(() => {
    getAllTripsAndToursData().then(response => {
      const sortedData = response.data
        .map(detail => {
          return {
            ...detail,
            tripStartDate: detail.startDate,
          }
        })
        .sort((b, a) => b.tripStartDate - a.tripStartDate)

      setTripAndToursDetails(sortedData)
    })
  }, [])

  const openKnowMore = pdfUrl => {
    setShowKnowMore(true)
    setKnowMoreData(pdfUrl || {})
  }

  const closeKnowMore = () => {
    setShowKnowMore(false)
    setKnowMoreData({})
  }

  const openEnquiryModal = data => {
    setShowEnquiryModal(true)
    setEnquiryData(data || {})
  }

  const closeEnquiryModal = () => {
    setShowEnquiryModal(false)
    setEnquiryData({})
  }

  return (
    <>
      <React.Fragment>
        <Row className="justify-content-center">
          {tripAndToursDetails.map(detailItem => (
            <TripAndToursCard
              {...detailItem}
              key={detailItem.id}
              openKnowMore={openKnowMore}
              openEnquiryModal={openEnquiryModal}
            />
          ))}
        </Row>
      </React.Fragment>

      <TripAndToursKnowMore
        knowMoreData={knowMoreData}
        isOpen={showKnowMore}
        closeKnowMore={closeKnowMore}
      />

      <TripAndToursEnquiryModal
        enquiryData={enquiryData}
        isOpen={showEnquiryModal}
        toggleModal={closeEnquiryModal}
      />
    </>
  )
}

export default TripSC2

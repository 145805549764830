import React from "react";
import { withTranslation } from 'react-i18next';
import {
    Table,
    Card,
    CardBody,
    CardTitle,
    Label,
    CardSubtitle
} from "reactstrap"
import PropTypes from 'prop-types';
import { PAYMENT_MODE } from "constants/common";

const PaymentHistory = ({
    t,
    data
}) => {
    return (
        <>
            {data && data.length > 0 && (
                <div>
                    <Card>
                        <CardBody>
                            <CardSubtitle>Payment History</CardSubtitle>
                            <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Amount(₹)</th>
                                            <th>Mode</th>
                                            <th>Status</th>
                                            <th>Payment ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.length > 0 && data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{parseFloat(item.amount / 100).toFixed(2)} {item.isRefund ? 'Cr.' : 'Dr.'}</td>
                                                    <td>{item.paymentMode === PAYMENT_MODE.ONLINE ? 'Online' : item.paymentMode === PAYMENT_MODE.OFFLINE ? 'Cash' : item.paymentMode === PAYMENT_MODE.WALLET ? 'Wallet' : '-'}</td>
                                                    <td>{item.paymentStatus}</td>
                                                    <td>{item.id}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="disclosure" style={{ "fontSize": "10px" }}>
                        <ul style={{ "listStyleType": "none" }}>
                            <li>Captuted: Amount transfered successfully</li>
                            <li>Created: Amount might be deducted from account but not received by payment gateway or Happy60Plus</li>
                            <li>Failed: Transaction failed on client side.</li>
                        </ul>
                    </div>
                </div>
            )}
            {data && data.length === 0 && (
                <Label>No Payment history found</Label>
            )}
        </>

    )
}

PaymentHistory.propTypes = {
    t: PropTypes.any,
    data: PropTypes.any,
}

export default withTranslation()(PaymentHistory);
import React from "react"
import { getServerDetail } from "./serverDetailUtil"

function getRoutes() {
  return {
    ROUTE: {
      COLLECTIONS: "collections",
      DETAILS: "details",
    },
  }
}

function getUrl() {
  // const BASE = 'http://ec2-35-176-120-96.eu-west-2.compute.amazonaws.com:5000';
  try {
    var BASE_APPLICATION = getServerDetail().url
    console.log("url:", BASE_APPLICATION)

    const CHAT_BASE_URL = "http://20.77.88.153:3007"

    {
      /*BASE URL SEGREGATION BASED ON ENVIRONMENT STARTS HERE*/
    }

    return {
      URL: {
        LOGIN: {
          POST: BASE_APPLICATION + "/data-service/api/accounts/login",
          LOGIN_WITH_TOKEN: BASE_APPLICATION + "/data-service/api/user/details",
          KEY: "login",
          GENERATE_OTP:
            BASE_APPLICATION + "/data-service/api/accounts/login/generate-otp",
          VERIFY_OTP:
            BASE_APPLICATION + "/data-service/api/accounts/login/verify-otp",
          VERIFY_MOBILE_NUMBER:
            BASE_APPLICATION +
            "/data-service/api/accounts/is-user-existed?mobileNumber=",
          GET_REFRESH_TOKEN:
            BASE_APPLICATION + "/data-service/api/accounts/refresh-token",
        },
        PASSWORD: {
          RESET_PASSWORD:
            BASE_APPLICATION + "/data-service/api/accounts/forgot-password",
          CHANGE_PASSWORD:
            BASE_APPLICATION + "/data-service/api/accounts/change-password",
        },
        USERS: {
          GET: BASE_APPLICATION + "/data-service/api/user/",
          KEY: "users",
          POST: BASE_APPLICATION + "/data-service/api/user",
          SEARCH: BASE_APPLICATION + "/data-service/api/user/search",
          VALIDATE_REFERRAL:
            BASE_APPLICATION +
            "/data-service/api/users/validate-referral?referralCode=",
        },
        HELP_BUDDY: {
          GET: BASE_APPLICATION + "/data-service/api/help-buddy/",
          KEY: "help_buddy",
          POST: BASE_APPLICATION + "/data-service/api/help-buddy/",
          GET_FILTERED_HELP_BUDDY:
            BASE_APPLICATION +
            "/data-service/api/users/help-buddy/verified?status=$status&verified=$verified",
        },
        HEALTH_BUDDY: {
          GET_BY_PARTNER:
            BASE_APPLICATION +
            "/data-service/api/health-buddy/search/partner-organisation?id=",
          GET_HEALTH_BUDDY_LIST:
            BASE_APPLICATION +
            "/data-service/api/health-buddy/search/available-health-buddy?isAvailable=true",
          KEY: "health_buddy",
          POST: BASE_APPLICATION + "/data-service/api/health-buddy",
          PUT: BASE_APPLICATION + "/data-service/api/health-buddy/$pid",
        },
        SENIOR_CITIZEN: {
          GET: BASE_APPLICATION + "/data-service/api/senior-citizen/",
          POST: BASE_APPLICATION + "/data-service/api/senior-citizen/",
          // DELETE: BASE_APPLICATION + '/api/collections/$cid/documents/$did',
          KEY: "senior_citizen",
        },
        CONTACT_DETAILS: {
          // GET: BASE_APPLICATION + '/data-service/api/profile/contact-details',
          GET: BASE_APPLICATION + "/data-service/api/contact-details/",
          POST: BASE_APPLICATION + "/data-service/api/contact-details/",
          KEY: "contact_details",
        },
        ADDRESS: {
          // GET: BASE_APPLICATION + 'data-service/api/profile/addresses',
          GET: BASE_APPLICATION + "/data-service/api/address/user/",
          // PUT: BASE_APPLICATION + '/api/collections/$cid/documents/$did/entities',
          KEY: "address",
          POST: BASE_APPLICATION + "/data-service/api/address/",
        },
        VEHICLE_DETAILS: {
          // GET: BASE_APPLICATION + '/data-service/api/profile/vehicle-details',
          GET: BASE_APPLICATION + "/data-service/api/vehicle-details/",
          KEY: "vehicle_details",
          POST: BASE_APPLICATION + "/data-service/api/vehicle-details/",
        },
        BASE_URL: BASE_APPLICATION,
        // LOGIN: {
        //     POST: BASE_APPLICATION + '/api/user/Login',
        //     KEY: 'login'
        // },

        SERVICE_TYPE: {
          GET: "/data-service/api/service/list",
          POST: "/data-service/api/service/",
          PUT: "/data-service/api/service/$service_id",
          KEY: "service_type",
        },
        SERVICE_OFFER: {
          CHECK_PROMO_CODE:
            "/data-service/api/service/$service_id/check-promoCode",
        },
        ENQUIRY: {
          GET_ALL: "/data-service/api/enquiry/",
          UPDATE_ENQUIRY: "/data-service/api/enquiry/$enquiryId",
          SEARCH_ENQUIRY: "/data-service/api/enquiry/enquiry-type/",
        },
        HOME_PAGE_SERVICES: {
          GET_ALL: "/data-service/api/home-page-services/findAll",
          CREATE: "/data-service/api/home-page-services",
          UPDATE: "/data-service/api/home-page-services/$homePageServicesId",
        },
        TRIP: {
          GET_ALL: "/data-service/api/enquiry/",
          UPDATE_TRIP: "data-service/api/enquiry/$enquiryId",
        },
        TRIPS_AND_TOURS: {
          GET_ACTIVE: "/data-service/api/trip-details/list?isActive=true",
          GET_BY_ID: "/data-service/api/trip-details/list/$tripDetailsId",
        },
        BOOK_SERVICE: {
          GET: "",
          POST: BASE_APPLICATION + "/data-service/api/help-buddy-booking",
          TRANSPORTATION_BOOKING:
            BASE_APPLICATION + "/data-service/api/transportation-booking",
          PAYMENT: BASE_APPLICATION + "/data-service/api/service-payment/",
          PRICING_DETAIL:
            BASE_APPLICATION + "/data-service/api/service-payment/applyOffer",
          PRICING_DETAIL_MULTIPLE:
            BASE_APPLICATION + "/data-service/api/booking/applyOffer",
          WEBINAR:
            BASE_APPLICATION +
            "/data-service/api/webinar-booking/user/$user_id",
          CREATE_HEALTH_CARE_SERVICE:
            BASE_APPLICATION + "/data-service/api/healthcare-booking",
          GET_HEALTH_CARE_SERVICE:
            BASE_APPLICATION +
            "/data-service/api/users/healthcare-booking/user/$uid?bookingStatus=$bookingStatus",
          GET_HEALTH_CARE_SERVICE_PARTNER:
            BASE_APPLICATION +
            "/data-service/api/users/healthcare-booking/partner/$uid?isSelected=$isSelected",
          GET_HEALTH_CARE_BOOKING:
            BASE_APPLICATION +
            "/data-service/api/healthcare-booking/search/partnerOrganisation?bookingStatus=helpbuddyselected",
          GET_HEALTH_CARE_HEALTH_BUDDIES:
            BASE_APPLICATION +
            "/data-service/api/users/healthcare-booking/$pid",
          GET_COMMON_BOOKING_DETAIL:
            BASE_APPLICATION + "/data-service/api/bookings/$pid",
          CREATE_ACTIVITY:
            BASE_APPLICATION +
            "/data-service/api/user/$userID/monthly-planBooking?serviceID=$serviceID&isMorning=$isMorning",
          CHECK_ACTIVITY:
            BASE_APPLICATION +
            "/data-service/api/user/$userID/isCurrentMonthBooking?serviceID=$serviceID",
          DOWNLOAD_CSV:
            BASE_APPLICATION +
            "/data-service/api/getUser-detail-for-monthly-plan-booking?serviceID=",
          ACTIVITY_SCHEDULE:
            BASE_APPLICATION +
            "/data-service/api/getUser-detail-for-monthly-plan-booking?serviceID=",
          MORNING_ACTIVITY_URL:
            BASE_APPLICATION +
            "/data-service/api/user/1ffef86d-de57-4b49-b739-91dffb0dc14e/files/monthly-plan-morning-schedule",
          EVENING_ACTIVITY_URL:
            BASE_APPLICATION +
            "/data-service/api/user/1ffef86d-de57-4b49-b739-91dffb0dc14e/files/monthly-plan-evening-schedule",
          ANNUAL_SUB_NOTIFICATION:
            BASE_APPLICATION +
            "/data-service/api/gold-membership/$PID/send-notification?isInAppPurchase=false",
          ANNUAL_SUB_PAYMENT_DETAIL:
            BASE_APPLICATION + "/data-service/api/service-payment/create",
          VERIFY_SUBSCRIPTION:
            BASE_APPLICATION +
            "/data-service/api/gold-membership/is-valid-user?userID=$uid",
          BOOK_ANNUAL_SUB:
            BASE_APPLICATION +
            "/data-service/api/gold-membership/?isTrial=$isTrial",
        },
        RATING: {
          SERVICE_RATING:
            BASE_APPLICATION + "/data-service/api/service-ratings",
        },
        ASSIGNMENTS: {
          ALL_ASSIGNMENT:
            BASE_APPLICATION +
            '/data-service/api/users/service-bookings/?bid=""&status=created',
          // ASSIGNED_ASSIGNMENTS: BASE_APPLICATION + '/data-service/api/users/service-bookings?bid=$user_id&status=ASSIGNED',
          // ONGOING_ASSIGNMENTS: BASE_APPLICATION + '/data-service/api/users/service-bookings?bid=$user_id&status=STARTED',
          // COMPLETED_ASSIGNMENTS: BASE_APPLICATION + '/data-service/api/users/service-bookings?bid=$user_id&status=COMPLETED',
          // INCOMPLETE_ASSIGNMENTS: BASE_APPLICATION + '/data-service/api/users/service-bookings/inactive',

          ASSIGNED_ASSIGNMENTS:
            BASE_APPLICATION +
            "/data-service/api/service-bookings/search/bookingList?serviceStatus=ASSIGNED&sort=created_on&bid=$user_id",
          ONGOING_ASSIGNMENTS:
            BASE_APPLICATION +
            "/data-service/api/service-bookings/search/bookingList?serviceStatus=STARTED&sort=created_on&bid=$user_id",
          COMPLETED_ASSIGNMENTS:
            BASE_APPLICATION +
            "/data-service/api/service-bookings/search/bookingList?serviceStatus=COMPLETED&sort=created_on&bid=$user_id",
          INCOMPLETE_ASSIGNMENTS:
            BASE_APPLICATION +
            "/data-service/api/users/service-bookings/inactive",

          ASSING_ASSIGNMENT:
            BASE_APPLICATION +
            "/data-service/api/users/$user_id/service-bookings/$service_id",
          ASSIGN_HEALTH_BUDDY:
            BASE_APPLICATION + "/data-service/api/users/assigned-health-buddy",
          ACCEPT_HEALTH_CARE_SERVICE:
            BASE_APPLICATION +
            "/data-service/api/users/healthcare-booking/$hcid/health-buddy/$hbid/approved",
          GET_HEALTH_CARE_BOOKING_ESTIMATION:
            BASE_APPLICATION +
            "/data-service/api/users/healthcare-booking/$healthcareBookingId/service-payment?paymentMode=ONLINE",
        },
        VALUE_HELPS: {
          GET: "/data-service/api/values",
          KEY: "value_helps",
        },
        PAYMENT_GATEWAY: {
          ORDER: BASE_APPLICATION + "/data-service/api/service-payment",
          LOG_PAYMENT: BASE_APPLICATION + "/data-service/api/service-payment",
          GET_PAYMENT_INFO_BY_SERVICEBOOKING_ID:
            BASE_APPLICATION +
            "/data-service/api/service-payments/search/services?id=",
          GENERATE_INVOICE:
            BASE_APPLICATION + "/data-service/api/zoho/create-invoice",
        },
        WALLET: {
          BALANCE: "/data-service/api/hsp-wallets/search/users?id=$user_id",
          ALL_WALLET_BALANCE:
            "/data-service/api/hsp-wallets/search/users?id=$user_id",
          UPDATE_BALANCE: "/data-service/api/hsp-wallets",
        },
        DASHBOARD: {
          REGISTERED_USER: "/data-service/api/dashboard/users/$period",
          TOTAL_SERVICE_BOOKING: "/data-service/api/dashboard/bookings/$period",
          REVENUE: "/data-service/api/dashboard/revenue/$period",
          REVENUE_BY_TYPE: "/data-service/api/dashboard/revenue",
          TOTAL_SERVICE_BOOKING_BY_TYPE:
            "/data-service/api/dashboard/bookings-by-type",
          REGISTERED_USER_BY_TYPE: "/data-service/api/dashboard/users-by-type",
          BOOKING_BY_SERVICE_TYPE:
            "/data-service/api/dashboard/user-bookings-by-type",
          GENERAL_STATS: "/data-service/api/dashboard/bookings-info",
        },
        ONBOARD_STATUS: {
          EMOTIONAL_QUOTIENT: {
            GET:
              BASE_APPLICATION +
              "/data-service/api/help-buddy/$user_id/emotional-quotients",
            POST:
              BASE_APPLICATION +
              "/data-service/api/help-buddy/$user_id/emotional-quotients",
          },
          SOCIAL_BEHAVIOUR: {
            GET: "/data-service/api/help-buddy/$user_id/behavioral-verifications",
            POST: "/data-service/api/help-buddy/$user_id/behavioral-verifications",
          },
          KYC_DOC_UPLOAD: "/data-service/api/user/$user_id/files",
        },
        UPLOAD: {
          USER_DOC_UPLOAD: "/data-service/api/user/$user_id/files",
          USER_DOC_DOWNLOAD: "/data-service/api/user/$user_id/files/$file_id",
        },
        COMMON: {
          SEND_WHATSAPP_MESSAGE:
            BASE_APPLICATION + "/data-service/api/user/help-buddy/notify?id=",
        },
        CHAT_WIDGET: {
          KEY: "chat-widget",
          BASE_URL: CHAT_BASE_URL,
          MESSAGE_INITIALISE:
            CHAT_BASE_URL + "/conversations/$USER_ID/trigger_intent",
          MESSAGE_WEBHOOK: CHAT_BASE_URL + "/webhooks/rest/webhook",
        },
        PACKAGES: {
          GET_ADMIN_PACKAGES:
            BASE_APPLICATION +
            "/data-service/api/service-package/search/details?isAdmin=true",
          GET_USER_PACKAGES:
            BASE_APPLICATION +
            "/data-service/api/service-package/search/details?isAdmin=false",
          GET_PACKAGES_BY_USER_ID:
            BASE_APPLICATION +
            "/data-service/api/service-package/user/$user_id",
          ADD_PACKAGE: BASE_APPLICATION + "/data-service/api/service-package",
          UPDATE_PACKAGE:
            BASE_APPLICATION + "/data-service/api/service-package/$package_id",
          DELETE_PACKAGE:
            BASE_APPLICATION + "/data-service/api/service-package/$package_id",
          BOOK_PACKAGE: BASE_APPLICATION + "/data-service/api/package-bookings",
          BOOK_PACKAGE_SERVICE:
            BASE_APPLICATION +
            "/data-service/api/users/$user_id/service-bookings",
          GET_SERVICE_PACKAGE_BOOKINGS:
            BASE_APPLICATION + "/data-service/api/package-bookings",
          GET_SERVICE_PACKAGE_BOOKINGS_BY_USER_ID:
            BASE_APPLICATION +
            "/data-service/api/package-bookings/search/booking-details?id=$user_id",
        },
      },
    }
  } catch (e) {
    console.warn(e)
  }
}

export { getRoutes, getUrl, getServerDetail }

import {
  GET_ALL_HELP_BUDDY_LIST,
  ALL_HELP_BUDDY_LIST,
  GET_ALL_USER,
  ALL_USER,
  GET_ALL_SENIOR_CITIZEN,
  ALL_SENIOR_CITIZEN,
  GET_ALL_PARTNER_LIST,
  ALL_PARTNER_LIST,
  CREATE_PARTNER,
  CREATE_PARTNER_RESPONSE,
  CREATE_HEALTH_BUDDY_LIST_RESPONSE,
  UPDATE_HEALTH_BUDDY_LIST_RESPONSE,
  UPDATE_HEALTH_BUDDY_LIST,
  GET_HEALTH_BUDDY_LIST,
  GET_HEALTH_BUDDY_LIST_RESPONSE,
  SEARCH_USER,
  CREATE_PARTNER_ERROR,
} from "./actionTypes"

export const getAllUser = (data) => {
  return {
    type: GET_ALL_USER,
    payload: data
  }
}

export const searchUser = (data, searchType) => {
  return {
    type: SEARCH_USER,
    payload: {data, searchType}
  }
}

export const allUser = (allUserList) => {
  return {
    type: ALL_USER,
    payload: allUserList
  }
}

export const getAllSeniorCitizenList = () => {
  return {
    type: GET_ALL_SENIOR_CITIZEN,
    payload: null
  }
}

export const allSeniorCitizen = (data) => {
  return {
    type: ALL_SENIOR_CITIZEN,
    payload: data
  }
}

export const getAllHelpBuddyList = () => {
  return {
    type: GET_ALL_HELP_BUDDY_LIST,
    payload: null
  }
}

export const allHelpBuddyList = (data) => {
  return {
    type: ALL_HELP_BUDDY_LIST,
    payload: data
  }
}

export const allPartnerList = (data) => {
  return {
    type: ALL_PARTNER_LIST,
    payload: data
  }
}

export const createPartner = (data, partnerId = null) => {
  return {
    type: CREATE_PARTNER,
    payload: {
      data,
      partnerId,
    },
  }
}

export const createPartnerResponse = data => {
  return {
    type: CREATE_PARTNER_RESPONSE,
    payload: data,
  }
}

export const createPartnerError = data => {
  return {
    type: CREATE_PARTNER_ERROR,
    payload: data,
  }
}

export const createHealthBuddyResponse = data => {
  return {
    type: CREATE_HEALTH_BUDDY_LIST_RESPONSE,
    payload: data,
  }
}

export const getHealthBuddy = (id = null) => {
  return {
    type: GET_HEALTH_BUDDY_LIST,
    payload: id,
  }
}

export const getHealthBuddyResponse = data => {
  return {
    type: GET_HEALTH_BUDDY_LIST_RESPONSE,
    payload: data,
  }
}

export const updateHealthBuddy = data => {
  return {
    type: UPDATE_HEALTH_BUDDY_LIST,
    payload: data,
  }
}

export const updateHealthBuddyResponse = data => {
  return {
    type: UPDATE_HEALTH_BUDDY_LIST_RESPONSE,
    payload: data,
  }
}
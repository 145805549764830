import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Label, Card, CardBody } from "reactstrap";
import { AddressFormatter } from "components/Happy60/shared/addressFormatter"
import moment from 'moment';
import { getAllAssignments } from "store/actions"

const LatestBookings = props => {

    const dispatch = useDispatch();

    const allAssignmentList = useSelector(state => state.AssignmentReducer.allAssignments);

    useEffect(() => {
        dispatch(getAllAssignments())
    }, [dispatch]);

    let serviceType = useSelector(state => state.ServiceReducer.serviceType);

    const getServiceName = (serviceId) => {
        let serviceObj = serviceType.find(item => item.id === serviceId);
        return serviceObj && serviceObj.serviceType ? serviceObj.serviceType : "-";
    }
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {allAssignmentList.length > 0 && (<Table
                        id="tech-companies-1"
                        className="table"
                    >
                        <Thead>
                            <Tr>
                                <Th>Service</Th>
                                <Th data-priority="1">User</Th>
                                <Th data-priority="2">Date</Th>
                                <Th data-priority="3">Duration</Th>
                                <Th data-priority="4">Address</Th>
                                <Th data-priority="6">Description</Th>
                                <Th data-priority="7">Amount</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {allAssignmentList.map(item => {
                                return (
                                    <Tr key={item.id}>
                                        <Td>
                                            <span className="co-name">{getServiceName(item.serviceID)}.</span>
                                        </Td>
                                        <Td>{item.user.firstName} {item.user.lastName}</Td>
                                        <Td>{moment(item.dateTimeFrom).isValid() ? moment.utc(item.dateTimeFrom).local().format("DD-MM-YYYY hh:mm A") : '-'}</Td>
                                        <Td>{item.duration}</Td>
                                        <Td><AddressFormatter data={item.address} /></Td>
                                        <Td>{item.description}</Td>
                                        <Td>{item.servicePayments && item.servicePayments.length ? '₹' + parseFloat(item.servicePayments[0].amount / 100).toFixed(2) : '-'}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>)
                    }
                    {
                        allAssignmentList.length === 0 && (
                            <Label>No Assignments found</Label>
                        )
                    }
                </CardBody>
            </Card>

        </React.Fragment >
    )
}

LatestBookings.propTypes = {
    orders: PropTypes.array,
    onGetOrders: PropTypes.func,
}

export default withRouter(LatestBookings)

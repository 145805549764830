import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_REVENUE_DATA, GET_SERVICE_BOOKING_BY_TYPE_DATA, GET_USER_REGISTRATION_DATA, GET_TOTAL_SERVICE_BOOKING_DATA, GET_GENERAL_STATS, GET_TOTAL_REGISTERED_USER } from "./actionTypes";
import { getTotalBookedService, getTotalRegisteredUser, getTotalRegisteredUserByServiceType, getRevenueByType, getGeneralStats, getServiceBookingByType } from '../../utils/Apiv2';
import { UserRegistrationData, RevenueData, TotalServiceBookingData, ServiceBookingByTypeData, GeneralStats, TotalRegisteredUser } from "./actions";
import { PERIOD } from 'constants/common';

function* getTotalServiceBookingDataSaga(data) {
    try {
        const resp = yield call(getTotalBookedService, data.payload);
        yield put(TotalServiceBookingData(resp));
    } catch (error) {
        console.log('Debug ::> error', error)
    }
}

function* getUserRegistrationDataSaga({ payload: periodType }) {
    try {
        const resp = yield call(getTotalRegisteredUserByServiceType, periodType);
        yield put(UserRegistrationData(resp));
    } catch (error) {
        console.log('Debug :::> error', error)
    }
}

function* getRevenueDataSaga(data) {
    try {
        const resp = yield call(getRevenueByType, data.payload);
        yield put(RevenueData(resp));
    } catch (error) {
    }
}

function* getTotalRegisteredUserSaga() {
    try {
        const resp = yield call(getTotalRegisteredUser, PERIOD.YEARLY);
        if (resp) {
            yield put(TotalRegisteredUser(resp.totalNumber));
        }
    }
    catch (err) {

    }
}

function* getServiceBookingByTypeSaga() {
    try {
        const resp = yield call(getServiceBookingByType, null);
        yield put(ServiceBookingByTypeData(resp));
    } catch (error) {
        
    }
}

function* getGeneralStatsSaga(id) {
    try {
        const resp = yield call(getGeneralStats, id);
        yield put(GeneralStats(resp));
    } catch (error) {
    }
}


function* dashboardSaga() {
    // yield all([fork(watchGetChartsData)]);
    yield takeEvery(GET_TOTAL_SERVICE_BOOKING_DATA, getTotalServiceBookingDataSaga);
    yield takeEvery(GET_USER_REGISTRATION_DATA, getUserRegistrationDataSaga);
    yield takeEvery(GET_TOTAL_REGISTERED_USER, getTotalRegisteredUserSaga);
    yield takeEvery(GET_REVENUE_DATA, getRevenueDataSaga);
    yield takeEvery(GET_SERVICE_BOOKING_BY_TYPE_DATA, getServiceBookingByTypeSaga);
    yield takeEvery(GET_GENERAL_STATS, getGeneralStatsSaga);
}

export default dashboardSaga;

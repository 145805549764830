import {
  GET_HOME_PAGE_SERVICES,
  CREATE_HOME_PAGE_SERVICES,
  UPDATE_HOME_PAGE_SERVICES,
  DELETE_HOME_PAGE_SERVICES,
  UPDATE_LIST_HOME_PAGE_SERVICE,
  LOADING_HOME_PAGE_SERVICE,
} from "./actionTypes"

export const allHomePageServiceList = () => {
  return {
    type: GET_HOME_PAGE_SERVICES,
  }
}

export const updateListHomePageService = data => {
  return {
    type: UPDATE_LIST_HOME_PAGE_SERVICE,
    payload: data,
  }
}

export const createHomePageService = data => {
  return {
    type: CREATE_HOME_PAGE_SERVICES,
    payload: data,
  }
}

export const updateHomePageService = data => {
  return {
    type: UPDATE_HOME_PAGE_SERVICES,
    payload: data,
  }
}

export const deleteHomePageService = data => {
  return {
    type: DELETE_HOME_PAGE_SERVICES,
    payload: data,
  }
}

export const setHomePageLoading = data => {
  return {
    type: LOADING_HOME_PAGE_SERVICE,
    payload: data,
  }
}

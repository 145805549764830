import React, { useEffect } from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const SimplePieChart = ({ periodData }) => {
    let series = periodData.data || [];
    let options = {
        chart: {
            // width: 580,
            // innerHeight:500,
            // innerWidth:500,
            type: 'pie',
        },
        labels: periodData.category || [],
        legend: {
            position: 'bottom',
            horizontalAlign: 'left',
            floating: false
        },
        colors: ['#008ffb', '#00e396', '#775dd0', "#ff4560", "#DEA057", "#417D7A", "#FF7700"],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="pie"
                // width={400}
                innerWidth={400}
                height={380}
                className="apex-charts"
            />
        </React.Fragment>
    );
}

SimplePieChart.propTypes = {
    periodData: PropTypes.any
}
export default SimplePieChart;

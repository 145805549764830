import React, { useState } from "react";
import {
    Container
} from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ServiceAssignmentMgmt from '../../components/Happy60/serviceAssignment'

const ServiceAssignmentPage = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Service Assignments | Happy60Plus</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Home" breadcrumbItem="Assignments" />
                    <ServiceAssignmentMgmt></ServiceAssignmentMgmt>
                </Container>
            </div >
        </React.Fragment >
    );
}

export default ServiceAssignmentPage;
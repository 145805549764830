export const GET_ALL_ASSIGNMENTS = "GET_ALL_ASSIGNMENTS";
export const ALL_ASSIGNMENTS = "ALL_ASSIGNMENTS";

export const GET_ONGOING_ASSIGNMENTS = "GET_ONGOING_ASSIGNMENTS";
export const ONGOING_ASSIGNMENTS_RESP = "ONGOING_ASSIGNMENTS_RESP";

export const GET_ASSIGNED_ASSIGNMENTS = "GET_ASSIGNED_ASSIGNMENTS";
export const ASSIGNED_ASSIGNMENT_RESP = "ASSIGNED_ASSIGNMENT_RESP";

export const GET_COMPLETED_ASSIGNMENT = "GET_COMPLETED_ASSIGNMENT";
export const COMPLETED_ASSIGNMENT_RESP = "COMPLETED_ASSIGNMENT_RESP";

export const GET_INCOMPLETE_ASSIGNMENT = "GET_INCOMPLETE_ASSIGNMENT";
export const INCOMPLETE_ASSIGNMENT_RESP = "INCOMPLETE_ASSIGNMENT_RESP";

export const ASSIGN_SERVICE = "ASSIGN_SERVICE";
export const ACCEPT_SERVICE = "ACCEPT_SERVICE";
export const ACCEPT_SERVICE_RESPONSE = "ACCEPT_SERVICE_RESPONSE";
export const START_SERVICE = "START_SERVICE";
export const START_SERVICE_RESPONSE = "START_SERVICE_RESPONSE";
export const COMPLETE_SERVICE = "COMPLETE_SERVICE";
export const COMPLETE_SERVICE_RESPONSE = "COMPLETE_SERVICE_RESPONSE";
export const REJECT_SERVICE = "REJECT_SERVICE";

export const HEALTH_CARE_PAYMENT_DETAIL = "HEALTH_CARE_PAYMENT_DETAIL";
import React from "react"
import { withTranslation } from "react-i18next"
import { FormGroup, Modal, Row, Col, Label } from "reactstrap"
import PropTypes from "prop-types"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch } from "react-redux"
import { generateOrder } from "store/actions"
import PaymentHistory from "./paymentHistory"

const RefundModal = ({ t, isOpen, data, closeModal }) => {
  const dispatch = useDispatch()
  const handleValidSubmit = (e, v) => {
    dispatch(
      generateOrder({
        amount: v.serviceCharge * 100,
        serviceBookingId: data.id,
        paymentMode: "OFFLINE",
      })
    )
    closeModal()
  }

  const dueAmount = () => {
    let paidAmount = 0
    if (data && data.servicePayments && data.servicePayments.length > 0) {
      data.servicePayments.forEach(element => {
        paidAmount += element.amount
      })
    }
    return parseFloat((data.serviceEstimates - paidAmount) / 100).toFixed(2)
  }
  return (
    <Modal isOpen={isOpen} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t("Service Payment")}</h5>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <AvForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v)
        }}
      >
        <div className="modal-body">
          <Row>
            <Col xs={12} sm={12} className="mb-4">
              <Label>
                Estimated Service Charge: ₹{" "}
                {parseFloat(data.serviceEstimates / 100).toFixed(2)}
              </Label>
            </Col>
            <Col xs={12} sm={12} className="mb-4">
              <Label>
                Actual Service Charge: ₹{" "}
                {parseFloat(data.serviceActuals / 100).toFixed(2)}
              </Label>
            </Col>
            <Col xs={12} sm={12} className="mb-4">
              <PaymentHistory data={data.servicePayments}></PaymentHistory>
            </Col>
            <Col xs={12} sm={12} className="mb-4">
              <Label style={{ fontSize: "larger", fontWeight: "bold" }}>
                Amount Due: ₹ {dueAmount()}
              </Label>
            </Col>
            <Col xs={12} sm={12} className="mb-4">
              <FormGroup>
                <AvField
                  id="serviceCharge"
                  name="serviceCharge"
                  label={t("Amount received")}
                  placeholder={t("Provide amount received in ₹")}
                  type="number"
                  value={dueAmount()}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              onCancelClick()
            }}
          >
            {t("Cancel")}
          </button>
          <button type="submit" className="btn btn-primary">
            {t("Submit")}
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

RefundModal.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.any,
  data: PropTypes.any,
  closeModal: PropTypes.any,
}

export default withTranslation()(RefundModal)

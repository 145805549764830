import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { connect } from "react-redux";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link, useParams } from 'react-router-dom';

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import happy60plus from 'assets/images/happy60plus/logo.png'
import { history } from '../../history';

const Register = props => {
  const dispatch = useDispatch()
  // dispatch(getValueHelps());
  // const user = null;
  // const loading = null;
  // const registrationError = null;
  const { user, registrationError, loading } = useSelector(state => ({
    user: state && state.RegisterReducer && state.RegisterReducer.user ? state.RegisterReducer.user : null,
    registrationError: state && state.RegisterReducer && state.RegisterReducer.registrationError ? state.RegisterReducer.registrationError : null,
    loading: state && state.RegisterReducer && state.RegisterReducer.loading ? state.RegisterReducer.loading : null,
  }));

  let validationError = null;

  const [userType, setuserType] = React.useState('SUPER_ADMIN');
  const [formData, setFormData] = React.useState({});

  const changeUserType = (e) => {
    setuserType(e.target.value);
  }

  const validateForm = (formData) => {
    let valid = true;
    if (formData.password !== formData.confirmPassword) {
      valid = false;
      validationError += 'Password and Confirm password should match';
    }
    if (valid) {
      return true;
    }
    return false;
  }

  const signupSubmit = (e, data) => {
    validationError = '';
    e.preventDefault();
    let formData = { ...data, type: userType };
    if (validateForm(formData)) {
      delete formData.confirmPassword;
      dispatch(registerUser(formData));
    }
    // addToast('Alert', { appearance: 'error' });
    // submitUserDetail(formData)
    //   .then(
    //     (resp) => {
    //       console.log(resp);
    //     }
    //   )
    //   .catch(err => console.log(err));


  }

  const handleUserResponse = (user) => {
    if (user && user !== null) {
      history.push('/login');
      window.location.reload();
    }
  }

  useEffect(() => {
    dispatch(apiError(""))
    handleUserResponse(user)
  }, [user])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={happy60plus}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        signupSubmit(e, v)
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          User registered successfully. Redirecting to login page
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}
                      {validationError && validationError ? (
                        <Alert color="danger">{validationError}</Alert>
                      ) : null}
                      <div className="mb-3">
                        <label className="form-label">User Type</label>
                        <select className="form-control" onChange={changeUserType}>
                          <option value={"SUPER_ADMIN"}>Super Admin</option>
                          <option value={"ADMIN"} >Admin</option>
                          <option value={"SENIOR_CITIZEN"} >Senior Citizen</option>
                          <option value={"SERVICE_PROVIDER"} >Service Provier</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <AvField
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          className="form-control"
                          placeholder="First Name"
                          type="text"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          id="lastName"
                          name="lastName"
                          label="last Name"
                          className="form-control"
                          placeholder="Last Name"
                          type="text"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          id="confirmPassword"
                          name="confirmPassword"
                          label="Confirm Password"
                          type="password"
                          required
                          placeholder="ReEnterPassword"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          id="mobileNumber"
                          name="mobileNumber"
                          label="Mobile Number"
                          className="form-control"
                          placeholder="9898989898"
                          type="number"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                        />
                      </div>

                      <button type="submit" className="btn btn-primary">Register</button>
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the {" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Happy60Plus
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// const mapStateToProps = (state) => {
//   return {
//     registerReducer: state.RegisterUser
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     registerUser
//   };
// };

export default Register;

// export default Register

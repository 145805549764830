import React, { useState } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import { Row, Col } from "reactstrap"
import PropTypes from 'prop-types';
import { USER_TYPE } from "constants/common"
import { getImageForUser } from "utils/Common"
import default_avatar from '../../../../assets/images/users/default_avatar.png';
import { getUserDetail } from "utils/userUtil";

const UserDetail = ({ profile = null }) => {
  let userDetail = JSON.parse(getUserDetail())
  if (profile) {
    userDetail = profile
  }
  const userTypeMap = {
    [USER_TYPE.SENIOR_CITIZEN]: "Senior Citizen",
    [USER_TYPE.SERVICE_PROVIDER]: "Help Buddy",
    [USER_TYPE.PARTNER]: "Partner",
    [USER_TYPE.ADMIN]: "Admin",
    [USER_TYPE.HEALTH_BUDDY]: "Health Buddy",
  }
  
  return (
    <>
      <React.Fragment>
        <Row>
          <Col xs={12} md={6} xl={6} className="mb-4">
            <ul style={{ listStyleType: "none" }}>
              <br></br>
              <li>
                <span> User Type: </span>
                <span>{userTypeMap[userDetail.type]}</span>
              </li>
              <br></br>
              <li>
                <span> User Id: </span>
                <span>{userDetail.id}</span>
              </li>
              <br></br>
              <li>
                <span> Name: </span>
                <span>
                  {userDetail.firstName} {userDetail.lastName}
                </span>
              </li>
              <br></br>
              <li>
                <span> Contact No: </span>
                <span>{userDetail.mobileNumber}</span>
              </li>
              <br></br>
              <li>
                <span> Email id: </span>
                <span>{userDetail.email}</span>
              </li>
              <br></br>
              <li>
                <span> Joining Date: </span>
                <span>
                  {moment(userDetail.createdDate)
                    .local()
                    .format("DD/MM/YYYY hh:mm A")}
                </span>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={6} xl={6} className="mb-4">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                className="rounded-circle avatar-xl"
                src={userDetail && userDetail.profileImageURL ? getImageForUser(userDetail.id, userDetail.profileImageURL) : default_avatar}
                alt=""
              />
            </div>
          </Col>
        </Row>
      </React.Fragment>
    </>
  )
}

UserDetail.propTypes = {
  profile: PropTypes.any,
}

export default UserDetail

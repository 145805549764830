import React, { useEffect, useState, useMemo } from "react"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch, useSelector } from "react-redux"
import DataTable from "react-data-table-component"
import { getServiceType, searchEnquiry } from "store/actions"
import UserDetailModal from "./EnquiryStatusModel"
import EnquiryTooltipItem from "./EnquiryTooltipItem"

import moment from "moment"
import { CardBody, Row, Col, Card } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

const Enquiry = () => {
  const dispatch = useDispatch()

  let partnerList = useSelector(state => state.Enquiry.enquires)
  let loading = useSelector(state => state.Enquiry.loader)
  let page = useSelector(state => state.Enquiry.page)
  let services = useSelector(state => state.ServiceReducer.serviceType)

  const [perPage, setPerPage] = useState(10)
  const [selectedEnquiry, setSelectedEnquiry] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const [searchType, setSearchType] = useState(null)

  const totalRows = useMemo(() => {
    return partnerList ? partnerList.length : 0
  }, [partnerList])

  const handleSubmit = (event, values) => {
    event.preventDefault()
    dispatch(searchEnquiry(searchType))
  }

  useEffect(() => {
    if (!services?.length) {
      dispatch(getServiceType())
    }
  }, [services])

  useEffect(() => {
    if ((services && services.length) || isOpen === false) {
      handlePageChange(1)
    }
  }, [services, isOpen])

  const handlePageChange = page => {
    dispatch(searchEnquiry(searchType))
  }

  const handlePerRowsChange = (newPerPage, page) => {
    dispatch(searchEnquiry(searchType))
    setPerPage(newPerPage)
  }

  const enquiryStatus = {
    RAISED: "RAISED",
    UNDER_PROCESS: "UNDER_PROCESS",
    COMPLETED: "COMPLETED",
    UNSERVICEABLE: "UNSERVICEABLE",
    NOT_FULFILLED: "NOT_FULFILLED",
  }

  const columns = [
    {
      id: 1,
      name: "Name",
      selector: row => row.name,
      sortable: true,
      reorder: true,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <span>{row.name}</span>
        </div>
      ),
    },
    {
      id: 2,
      name: "Contact No.",
      selector: row => row.mobileNumber,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Email",
      selector: row => row.email || "N/A",
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Service",
      selector: row => {
        const service = services.find(service => service.id === row.serviceID)
        if (service) {
          return service.serviceType
        }
        return "N/A"
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Created Date",
      selector: item => item.createdDate,
      format: item =>
        item.createdDate
          ? moment(item.createdDate).format("DD-MM-YYYY hh:mm A")
          : "N/A",
      // format: item =>
      //   item.createdDate
      //     ? moment(item.createdDate).isValid()
      //       ? moment.utc(item.createdDate).local().format("DD-MM-YYYY hh:mm A")
      //       : moment(Number(item.createdDate)).isValid()
      //       ? moment(Number(item.createdDate))
      //           .local()
      //           .format("DD-MM-YYYY hh:mm A")
      //       : "-"
      //     : "-",
      sortable: true,
      sortFunction: (a, b) => {
        if (a.createdDate && b.createdDate) {
          return a.createdDate - b.createdDate
        }
        return 0
      },
      reorder: true,
    },
    {
      id: 6,
      name: "Status",
      selector: row => enquiryStatus[row.status] || row.status,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <span>{enquiryStatus[row.status] || row.status}</span>
          <EnquiryTooltipItem key={row.id} id={row.id} item={row} />
        </div>
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Action",
      selector: row => row,
      sortable: true,
      reorder: true,
      cell: row => (
        <div style={{ cursor: "pointer" }}>
          <span>
            <button
              type="button"
              className={`btn btn-primary p-2`}
              onClick={() => {
                setSelectedEnquiry(row)
                setIsOpen(true)
              }}
            >
              Change Status
            </button>
          </span>
        </div>
      ),
    },
  ]

  if (loading) {
    return <>Loading....</>
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v)
                    }}
                  >
                    <Row>
                      {/* <Col lg={4}>
                        <AvField
                          name="search"
                          className="form-control"
                          placeholder="Search User"
                          type="text"
                        />
                      </Col> */}
                      <Col lg={3}>
                        <select
                          className="form-control"
                          onChange={e => {
                            setSearchType(e.target.value)
                          }}
                        >
                          <option value="ALL" selected>
                            ALL
                          </option>
                          <option value="USER_ENQUIRY">USER ENQUIRY</option>
                          <option value="TOUR_AND_TRIP_ENQUIRY">
                            TOUR AND TRIP ENQUIRY
                          </option>
                          <option value="WEBINAR">WEBINAR</option>
                          <option value="YOGA">YOGA</option>
                        </select>
                      </Col>
                      <Col>
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Search
                        </button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <DataTable
        noHeader
        columns={columns}
        data={partnerList}
        defaultSortFieldId={5}
        defaultSortAsc={false}
        highlightOnHover
        pagination
        paginationServer={false}
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[10]}
      />

      {isOpen && (
        <UserDetailModal
          isOpen={isOpen}
          data={selectedEnquiry}
          closeModal={() => {
            setIsOpen(false)
            setSelectedEnquiry(null)
          }}
        />
      )}
    </React.Fragment>
  )
}

export default Enquiry

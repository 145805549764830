import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import cloneDeep from "lodash/cloneDeep"

import { Row, Col, Card, CardTitle, CardBody, Table, Button } from "reactstrap"

import { USER_TYPE } from "../../constants/common"
import { PAYMENT_STATUS } from "../../constants/common"
import { converTimestampIntoDateString } from "utils/Common"

import {
  getAdminPackages,
  getUserPackages,
  getPackagesByUserId,
  deletePackage,
  bookPackage,
  bookPackageResponse as setBookPackageResponse,
  getPackageBookingsByUserId,
} from "store/packages/actions"

import { makePayment, paymentStatus as setPaymentStatus } from "store/actions"

import PackageDetails from "./Packages/PackageDetails"
import PackageDetailsModal from "./Packages/PackageDetailsModal"
import ConfirmModal from "components/Common/ConfirmModal"
import alertCall from "components/Common/CustomAlert"
import PackageServiceBookingModal from "./Packages/PackageServiceBookingModal"

const USER_TYPE_ADMIN = USER_TYPE.ADMIN
const USER_TYPE_SENIOR_CITIZEN = USER_TYPE.SENIOR_CITIZEN

const EMPTY_PACKAGE_DETAILS = {
  id: null,
  name: "",
  description: "",
  validity: "-1",
  packageDetails: [],
  totalPackagePrice: 0,
  additionalBenefits: [],
}

const EMPTY_PACKAGE_SERVICE_DETAILS = {
  serviceType: "",
  isTransportationType: false,
  discount: 0,
  price: 0,
  availableHours: 0,
  minDurationHours: 1,
  duration: "-1",
  userID: "",
  serviceAddressID: "-1",
  description: "",
  serviceID: "",
  serviceStatus: "CREATED",
  vehicleType: "None",
  serviceMode: "",
  serviceEstimates: 0,
  vehicleServiceEstimates: 0,
  dateTimeFrom: "",
  paymentMode: "ONLINE",
  packageId: "",
  packageBookingId: "",
}

const getDefaultDiscount = isAdminUser => {
  return isAdminUser ? 0 : 10
}

const getEmptyPackageServiceDetails = userType => {
  return cloneDeep({
    ...EMPTY_PACKAGE_SERVICE_DETAILS,
    discount: getDefaultDiscount(userType === USER_TYPE_ADMIN),
  })
}

const Packages = () => {
  const dispatch = useDispatch()

  const userDetail = useSelector(state => state.Profile.userProfile)
  const { type: userType, id: userId } = useMemo(
    () => userDetail || {},
    [userDetail]
  )

  // packages lists
  const adminPackages = useSelector(
    state => state.PackagesReducer.adminPackages
  )
  const userPackages = useSelector(state => state.PackagesReducer.userPackages)
  const packagesByUserId = useSelector(
    state => state.PackagesReducer.packagesByUserId
  )
  const packageBookingStatus = useSelector(
    state => state.PackagesReducer.packageBookingStatus
  )
  const packageServiceBookingStatus = useSelector(
    state => state.PackagesReducer.packageServiceBookingStatus
  )
  const paymentStatus = useSelector(state => state.PaymentReducer.paymentStatus)

  // package bookings
  const packageBookingsByUserid = useSelector(
    state => state.PackagesReducer.packageBookingsByUserid
  )

  // services
  const allServices = useSelector(state => state.ServiceReducer.serviceType)

  // package details modal
  const [showPackageDetails, setShowPackageDetails] = useState(false)
  const [readOnlyDetails, setReadOnlyDetails] = useState(false)
  const [packageDetails, setPackageDetails] = useState(EMPTY_PACKAGE_DETAILS)

  // package service booking modal
  const [showPackageServiceBooking, setShowPackageServiceBooking] =
    useState(false)
  const [packageServiceDetails, setPackageServiceDetails] = useState(
    getEmptyPackageServiceDetails(userType)
  )

  // delete modal
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState(null)

  const closePackageDetailsModal = useCallback(() => {
    setShowPackageDetails(false)
    setTimeout(() => {
      setReadOnlyDetails(false)
      setPackageDetails(EMPTY_PACKAGE_DETAILS)
    }, 100)
  }, [])

  const addPackage = useCallback(() => {
    setShowPackageDetails(true)
    setPackageDetails(EMPTY_PACKAGE_DETAILS)
  }, [])

  const editPackage = useCallback(data => {
    setShowPackageDetails(true)
    setPackageDetails(data)
  }, [])

  const deletePackageClick = useCallback(data => {
    setSelectedPackage(data)
    setShowConfirmModal(true)
  }, [])

  const bookPackageClick = useCallback(
    data => {
      const { id, totalPackagePrice, userId: packageUserId } = data
      const bookPackageData = {
        packageId: id,
        userId: userType === USER_TYPE_ADMIN ? packageUserId : userId,
        // need to send price to razorpay in paise
        amount: totalPackagePrice * 100,
        paymentStatus: PAYMENT_STATUS.PENDING,
      }

      dispatch(bookPackage(bookPackageData))
    },
    [userId, bookPackage]
  )

  const knowMore = useCallback(data => {
    setReadOnlyDetails(true)
    setShowPackageDetails(true)
    setPackageDetails(data)
  }, [])

  const openPackageServiceBooking = useCallback(
    data => {
      setShowPackageServiceBooking(true)
      setPackageServiceDetails(data)
    },
    [setShowPackageServiceBooking, setPackageServiceDetails]
  )

  const closePackageServiceBooking = useCallback(() => {
    setShowPackageServiceBooking(false)
    setPackageServiceDetails(getEmptyPackageServiceDetails(userType))
  }, [setShowPackageServiceBooking, setPackageServiceDetails])

  useEffect(() => {
    if (userType) {
      dispatch(getAdminPackages())

      if (userType === USER_TYPE_ADMIN) {
        dispatch(getUserPackages())
      } else {
        dispatch(getPackagesByUserId())
        dispatch(getPackageBookingsByUserId())
      }
    }
  }, [userType])

  useEffect(() => {
    if (packageBookingStatus) {
      dispatch(
        makePayment({
          ...packageBookingStatus.paymentInfo,
          amount: packageBookingStatus.paymentInfo.amount,
        })
      )
      dispatch(setBookPackageResponse(null))
    }
  }, [packageBookingStatus])

  useEffect(() => {
    if (paymentStatus) {
      if (paymentStatus.error) {
        alertCall(
          paymentStatus.error || "Package booked successfully!",
          "danger"
        )
      } else {
        alertCall("Package booked successfully!", "success")

        if (userType) {
          if (userType === USER_TYPE_ADMIN) {
            dispatch(setPaymentStatus(null))
          } else {
            dispatch(setPaymentStatus(null))
            dispatch(getPackageBookingsByUserId())
          }
        }
      }
    }
  }, [paymentStatus, userType])

  useEffect(() => {
    dispatch(getPackageBookingsByUserId())
  }, [packageServiceBookingStatus])

  return (
    <React.Fragment>
      {userType === USER_TYPE_ADMIN && (
        <Row>
          <Col xs={4} sm={4} className="mb-4">
            <Button
              color="primary"
              type="button"
              onClick={() => {
                addPackage()
              }}
            >
              Create New Package
            </Button>
          </Col>

          <Col xs={12} className="mb-4">
            <h5 className="text-primary">Plans</h5>

            <Row>
              {adminPackages?.length ? (
                adminPackages.map(packageData => {
                  const { id } = packageData

                  return (
                    <PackageDetails
                      key={id}
                      data={packageData}
                      userDetail={userDetail}
                      editPackage={editPackage}
                      deletePackage={() => {
                        deletePackageClick(packageData)
                      }}
                    />
                  )
                })
              ) : (
                <Col>No Plans Yet!</Col>
              )}
            </Row>
          </Col>

          <Col xs={12}>
            <h5 className="text-primary">User Customized Plans</h5>

            <Row>
              {userPackages?.length ? (
                userPackages.map(packageData => {
                  const { id } = packageData

                  return (
                    <PackageDetails
                      key={id}
                      data={packageData}
                      userDetail={userDetail}
                      knowMore={knowMore}
                    />
                  )
                })
              ) : (
                <Col>No User Customized Plans Yet!</Col>
              )}
            </Row>
          </Col>
        </Row>
      )}

      {userType === USER_TYPE_SENIOR_CITIZEN && (
        <Row>
          <Col xs={4} sm={4} className="mb-4">
            <Button
              color="primary"
              type="button"
              onClick={() => {
                addPackage()
              }}
            >
              Customize your own Package
            </Button>
          </Col>

          <Col xs={12} className="mb-4">
            <h5 className="text-primary">Plans</h5>

            <Row>
              {adminPackages.concat(packagesByUserId)?.length ? (
                adminPackages.concat(packagesByUserId).map(packageData => {
                  const { id } = packageData

                  return (
                    <PackageDetails
                      key={id}
                      data={packageData}
                      userDetail={userDetail}
                      bookPackage={() => {
                        bookPackageClick(packageData)
                      }}
                      knowMore={knowMore}
                    />
                  )
                })
              ) : (
                <Col>No Plans Yet!</Col>
              )}
            </Row>
          </Col>

          <Col xs={12}>
            <Card>
              <CardBody>
                <CardTitle>My Packages</CardTitle>

                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>Plan Name</th>
                      <th>Service</th>
                      <th>Status</th>
                      <th>Valid Till</th>
                      <th>Total Hours</th>
                      <th>Used Hours</th>
                      <th>Available Hours</th>
                    </tr>
                  </thead>

                  <tbody>
                    {packageBookingsByUserid?.length ? (
                      packageBookingsByUserid.map(packageBooking => {
                        const {
                          packageId,
                          packageBookingDetails,
                          id: packageBookingId,
                        } = packageBooking
                        const {
                          name: packageName,
                          packageDetails: packageServices,
                        } =
                          adminPackages
                            .concat(packagesByUserId)
                            .find(({ id }) => id === packageId) || {}

                        const packageBookingDetailsLength =
                          packageBookingDetails?.length

                        return packageBookingDetails?.map(
                          (packageService, index) => {
                            const {
                              serviceId,
                              status,
                              totalHours: availableHours,
                              usedHours,
                              endDate,
                            } = packageService
                            const {
                              serviceType,
                              isTransportationType,
                              serviceMode,
                              minDuration,
                            } =
                              allServices.find(({ id }) => id === serviceId) ||
                              {}
                            const { price, discount } =
                              packageServices?.find(
                                ({ serviceId: packageServicesId }) =>
                                  packageServicesId === serviceId
                              ) || {}

                            const totalHours = availableHours + usedHours

                            return (
                              <tr key={`${packageId}-${index}-${serviceId}`}>
                                {index === 0 ? (
                                  <td rowSpan={packageBookingDetailsLength}>
                                    {packageName}
                                  </td>
                                ) : null}
                                <td>{serviceType}</td>
                                <td>{status}</td>
                                <td>
                                  {converTimestampIntoDateString(endDate)}
                                </td>
                                <td>{totalHours}</td>
                                <td>{usedHours}</td>
                                <td>{availableHours}</td>
                                <td>
                                  <button
                                    onClick={() => {
                                      const minDurationHours = minDuration / 60
                                      const total = price * minDurationHours
                                      const packagePrice =
                                        total - total * ((discount || 0) / 100)

                                      openPackageServiceBooking({
                                        ...getEmptyPackageServiceDetails(
                                          userType
                                        ),
                                        serviceType,
                                        isTransportationType,
                                        discount,
                                        price,
                                        availableHours,
                                        minDurationHours,
                                        duration: minDurationHours,
                                        userID: userId,
                                        serviceID: serviceId,
                                        serviceMode,
                                        serviceEstimates: packagePrice,
                                        packageId,
                                        packageBookingId,
                                      })
                                    }}
                                    className="btn btn-primary px-4"
                                    disabled={
                                      !availableHours ||
                                      availableHours === 0 ||
                                      status === "EXPIRED"
                                    }
                                  >
                                    Book Now
                                  </button>
                                </td>
                              </tr>
                            )
                          }
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={7} className="text-center">
                          No Package Booked Yet!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <PackageDetailsModal
        isOpen={showPackageDetails}
        data={packageDetails}
        readOnlyDetails={readOnlyDetails}
        userDetail={userDetail}
        isAdminUser={userType === USER_TYPE_ADMIN}
        closeModal={closePackageDetailsModal}
        emptyPackageDetails={EMPTY_PACKAGE_DETAILS}
        getDefaultDiscount={getDefaultDiscount}
      />

      <ConfirmModal
        isOpen={showConfirmModal}
        titleText="Package delete"
        bodyText="Are you sure you want to delete package"
        onYesClick={() => {
          dispatch(deletePackage({ ...selectedPackage, active: false }))
          setShowConfirmModal(false)
        }}
        onNoClick={() => {
          setShowConfirmModal(false)
        }}
      />

      <PackageServiceBookingModal
        isOpen={showPackageServiceBooking}
        data={packageServiceDetails}
        userId={userId}
        closeModal={closePackageServiceBooking}
        emptyPackageServiceDetails={getEmptyPackageServiceDetails(userType)}
      />
    </React.Fragment>
  )
}

export default Packages

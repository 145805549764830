import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
    Card,
    Col,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap"

const Blogs = () => {
    return (
        <Col sn={6}>
            <Card className="p-1 border shadow-none">
                <div className="p-3">
                    <h5>
                        <Link to="blog-details" className="text-dark">
                            Beautiful Day with Friends
                        </Link>
                    </h5>
                    <p className="text-muted mb-0">10 Apr, 2020</p>
                </div>

                <div className="position-relative">
                    <img
                        src={img1}
                        alt=""
                        className="img-thumbnail"
                    />
                </div>

                <div className="p-3">
                    <ul className="list-inline">
                        <li className="list-inline-item me-3">
                            <Link to="#" className="text-muted">
                                <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                Project
                            </Link>
                        </li>
                        <li className="list-inline-item me-3">
                            <Link to="#" className="text-muted">
                                <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                12 Comments
                            </Link>
                        </li>
                    </ul>
                    <p>
                        Neque porro quisquam est, qui dolorem ipsum quia
                        dolor sit amet
                    </p>

                    <div>
                        <Link to="#" className="text-primary">
                            Read more{" "}
                            <i className="mdi mdi-arrow-right"></i>
                        </Link>
                    </div>
                </div>
            </Card>
        </Col>
    )
}

export default Blogs;
import React, { useState } from "react";
import {
    Container
} from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Approvals from "../../components/Happy60/Approvals";

const ApprovalPage = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Approvals | Happy60Plus</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Home" breadcrumbItem="Approvals" />
                        
                    <Approvals></Approvals>

                </Container>
            </div >
        </React.Fragment >
    );
}

export default ApprovalPage;
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Container,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

// availity-reactstrap-validation
import { AvForm, AvInput } from "availity-reactstrap-validation"

import PersonalDetails from "./PersonalDetails/PersonalDetails"

import {
  // getProfileDetail,
  updateProfileDetail,
  getAddressDetail,
  getVehicleDetail,
  getContactDetail,
  getSeniorCitizenDetail,
  updateSeniorCitizenDetail,
  getHelpBuddyDetail,
  updateHelpBuddyDetail,
  updateProfileResponseStatus,
} from "../../store/actions"
import SeniorCitizenFields from "./PersonalDetails/SeniorCitizenFields"
import ServiceProviderFields from "./PersonalDetails/ServiceProviderFields"
import ChangePasswordModal from "./PersonalDetails/changePasswordModal"
import { USER_TYPE } from "../../constants/common"
import { getImageForUser } from "utils/Common"
import alertCall from "components/Common/CustomAlert"

const ProfileMgmt = () => {
  const dispatch = useDispatch()
  const [userData, setUserData] = useState({})

  let userDetailFromState = useSelector(state => state.Profile.userProfile)
  console.log("Debug ::> userDetailFromState", userDetailFromState)
  // senior citizen detail
  let seniorCitizenDetailFromState = useSelector(
    state => state.Profile.seniorCitizenDetail
  )

  // help buddy detail
  let helpBuddyDetailFromState = useSelector(
    state => state.Profile.helpBuddyDetail
  )

  const updateProfileStatus = useSelector(
    state => state.Profile?.updateProfileStatus
  )

  useEffect(() => {
    setUserData({
      ...userDetailFromState,
    })
  }, [])

  useEffect(() => {
    if (userDetailFromState?.id) {
      console.log("Debug ::> 112", 112)
      dispatch(getAddressDetail(userDetailFromState.id))
      dispatch(getContactDetail(userDetailFromState.id))
      if (userDetailFromState.type === USER_TYPE.SENIOR_CITIZEN) {
        dispatch(getSeniorCitizenDetail(userDetailFromState.id))
      } else if (userDetailFromState.type === USER_TYPE.SERVICE_PROVIDER) {
        dispatch(getVehicleDetail(userDetailFromState.id))
        dispatch(getHelpBuddyDetail(userDetailFromState.id))
      } else {
        setUserData({
          ...userDetailFromState
        })
      }
    }
  }, [userDetailFromState])

  useEffect(() => {
    if (seniorCitizenDetailFromState && seniorCitizenDetailFromState !== null) {
      setUserData({
        ...userDetailFromState,
        age: seniorCitizenDetailFromState.age,
        gender: seniorCitizenDetailFromState.gender,
        membershipID: seniorCitizenDetailFromState.membershipID,
      })
    }
  }, [seniorCitizenDetailFromState])

  useEffect(() => {
    if (helpBuddyDetailFromState && helpBuddyDetailFromState !== null) {
      setUserData({
        ...userDetailFromState,
        age: helpBuddyDetailFromState.age,
        gender: helpBuddyDetailFromState.gender,
        drivingLicenseNumber: helpBuddyDetailFromState.drivingLicenseNumber,
        drivingLicenseImageURL: helpBuddyDetailFromState.drivingLicenseImageURL,
        adhaarNumber: helpBuddyDetailFromState.adhaarNumber,
        adhaarImageURL: helpBuddyDetailFromState.adhaarImageURL,
        allowedServices: helpBuddyDetailFromState.allowedServices,
        languagesKnown: helpBuddyDetailFromState.languagesKnown,
        hobbies: helpBuddyDetailFromState.hobbies,
        shortNotes: helpBuddyDetailFromState.shortNotes,
        latestQualification: helpBuddyDetailFromState.latestQualification,
        verifictionStatus: helpBuddyDetailFromState.verifictionStatus,
      })
    }
  }, [helpBuddyDetailFromState])

  useEffect(() => {
    if (updateProfileStatus !== null) {
      if (updateProfileStatus === true) {
        alertCall("Profile updated successfully!", "success")

        if (userData.type === USER_TYPE.SERVICE_PROVIDER) {
          setTimeout(() => {
            alertCall(
              "Please complete onboarding process if not already!",
              "info"
            )
          }, 1000)
        }
      } else if (updateProfileStatus === false) {
        alertCall("Profile not updated, please try again later!", "error")
      }

      dispatch(updateProfileResponseStatus(null))
    }
  }, [updateProfileStatus])

  const updateUserData = (key, value) => {
    setUserData(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }
  const handleValidSubmit = (e, v) => {
    setUserData({ ...userData, ...v })
    submitDetail()
  }

  const submitDetail = () => {
    dispatch(
      updateProfileDetail({
        ...userData,
        countryCode: userData.countryCode,
        mobileNumber: userData.mobileNumber,
        id: userData.id,
      })
    )
    if (userData.type === USER_TYPE.SENIOR_CITIZEN) {
      dispatch(
        updateSeniorCitizenDetail({
          id: userData.id,
          age: userData.age,
          gender: userData.gender ? userData.gender : "MALE",
        })
      )
    } else if (userData.type === USER_TYPE.SERVICE_PROVIDER) {
      dispatch(
        updateHelpBuddyDetail({
          id: userData.id,
          age: userData.age,
          gender: userData.gender,
          drivingLicenseNumber: userData.drivingLicenseNumber,
          drivingLicenseImageURL: userData.drivingLicenseImageURL,
          adhaarNumber: userData.adhaarNumber,
          adhaarImageURL: userData.adhaarImageURL,
          allowedServices: userData.allowedServices,
          languagesKnown: userData.languagesKnown,
          hobbies: userData.hobbies,
          shortNotes: userData.shortNotes,
          latestQualification: userData.latestQualification,
          verifictionStatus: userData.verifictionStatus,
          _links: userData._links,
        })
      )
    }
  }

  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const walletBalance = useSelector(state => state.PaymentReducer.walletBalance)

  // const saveMembershipCard = () => {
  //     domtoimage
  //         .toJpeg(document.getElementById("MembershipCard"), {
  //             quality: 2,
  //         })
  //         .then(function (dataUrl) {
  //             let link = document.createElement("a")
  //             link.download = "membership-card.jpeg"
  //             link.href = dataUrl
  //             link.click()
  //             link.remove()
  //         })
  // }

  const [copied, setCopied] = useState(false)

  console.log("Debug ::> userData:::> LAST", userData)
  return (
    <React.Fragment>
      <Container>
        <Row className="justify-content-center">
          <Col md={12} lg={12} xl={12} xs={12}>
            <h5 className="text-primary">Referred Code</h5>
            <hr />
            <Row>
              <Col md={6} lg={6} xl={6} xs={6}>
                <InputGroup>
                  <Input
                    name="referralCode"
                    id="referralCode"
                    type="text"
                    value={userData?.userMetaData?.referralCode}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(userData?.userMetaData?.referralCode)
                        setCopied(true)
                        setTimeout(() => {
                          setCopied(false)
                        }, 2000)
                      }}
                    >
                      Copy
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                {copied && (
                  <div
                    style={{
                      position: "absolute",
                      right: 15,
                      color: "#34c38f",
                    }}
                  >
                    Copied!
                  </div>
                )}
              </Col>
            </Row>
            <br />
          </Col>

          <Col md={12} lg={12} xl={12} xs={12}>
            <h5 className="text-primary">Manage Profile</h5>
            <hr />
            <div className="form">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                {userData && (
                  <PersonalDetails
                    data={userData}
                    updateData={updateUserData}
                  />
                )}
                {userData && userData.type === USER_TYPE.SENIOR_CITIZEN && (
                  <SeniorCitizenFields
                    data={userData}
                    updateData={updateUserData}
                  />
                )}
                {userData && userData.type === USER_TYPE.SERVICE_PROVIDER && (
                  <ServiceProviderFields
                    data={userData}
                    updateData={updateUserData}
                  />
                )}
                <Row>
                  <Col xs={12} md={6} xl={6} className="mb-4">
                    <Button
                      color="primary"
                      onClick={() => {
                        setChangePasswordModal(true)
                      }}
                    >
                      Change password
                    </Button>
                  </Col>
                </Row>

                <ChangePasswordModal
                  isOpen={changePasswordModal}
                  onCancelClick={() => {
                    setChangePasswordModal(false)
                  }}
                ></ChangePasswordModal>
                {/* <button type="submit" className="btn btn-primary">Save</button> */}
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    xl={12}
                    className="mb-4 d-flex align-items-end"
                  >
                    <Button
                      type="submit"
                      color="primary"
                      className="w-100"
                      // onClick={() => {
                      //     submitDetail()
                      // }}
                    >
                      {"Submit"}
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ProfileMgmt

import React, { useCallback } from "react";
import PropTypes from 'prop-types'

import TextResponse from "./ResponseTypes/TextResponse"
import ImageResponse from "./ResponseTypes/ImageResponse"
import ButtonsResponse from "./ResponseTypes/ButtonsResponse"
import PdfResponse from "./ResponseTypes/PdfResponse"
import DatetimeResponse from "./ResponseTypes/DatetimeResponse"
import LinkResponse from "./ResponseTypes/LinkResponse"
import DropdownResponse from "./ResponseTypes/DropdownResponse";

import { getMinMaxDurationList, getTimeStringFromMinutes } from "../../utils/validations"

const BotResponse = ({
    messages,
    onUserMessageSubmit,
    buttonsResponseRef,
    datetimePickersRef,
    durationSelectorsRef,
    handleFileUploadChange,
    handleFileUploadSubmit,
    fileToBeUploaded,
    updateUserMessage,
    setUserInputBtnDisabled,
    widgetStyle
}) => {
    const getCustomPayloadUI = useCallback((custom) => {
        const customPayload = custom?.payload

        switch (customPayload) {
            case "pdf_attachment":
                return (
                    <PdfResponse
                        data={custom?.data}
                        widgetStyle={widgetStyle}
                    />
                )
            case "link":
                return (
                    <LinkResponse
                        data={custom?.data}
                        widgetStyle={widgetStyle}
                    />
                )
            case "date":
            case "time":
            case "datetime":
                return (
                    <DatetimeResponse
                        datetimePickersRef={datetimePickersRef}
                        type={customPayload}
                        title={custom?.title}
                        updateUserMessage={updateUserMessage}
                        setUserInputBtnDisabled={setUserInputBtnDisabled}
                        widgetStyle={widgetStyle}
                    />
                )
            case "duration":
                const { min, max, step } = custom
                return (
                    <DropdownResponse
                        durationSelectorsRef={durationSelectorsRef}
                        type={customPayload}
                        title={custom?.title}
                        options={getMinMaxDurationList(min, max, step).map((value) => {
                            const stringValue = getTimeStringFromMinutes(value)
                            return ({ value: stringValue, label: stringValue })
                        })}
                        updateUserMessage={updateUserMessage}
                        setUserInputBtnDisabled={setUserInputBtnDisabled}
                        widgetStyle={widgetStyle}
                    />
                )
            case "dropdown":
                return (
                    <DropdownResponse
                        durationSelectorsRef={durationSelectorsRef}
                        type={customPayload}
                        title={custom?.title}
                        options={custom?.options || []}
                        updateUserMessage={updateUserMessage}
                        setUserInputBtnDisabled={setUserInputBtnDisabled}
                        widgetStyle={widgetStyle}
                    />
                )
            default:
                return null
        }
    }, [])

    return messages.map((message, index) => {
        const { text, image, buttons, custom } = message

        return (
            <div key={`sub-${index}`}>
                {text ? <TextResponse text={text} widgetStyle={widgetStyle} /> : null}

                {image ? <ImageResponse image={image} /> : null}

                {buttons ? (
                    <ButtonsResponse
                        buttons={buttons}
                        onUserMessageSubmit={onUserMessageSubmit}
                        buttonsResponseRef={buttonsResponseRef}
                        handleFileUploadChange={handleFileUploadChange}
                        handleFileUploadSubmit={handleFileUploadSubmit}
                        fileToBeUploaded={fileToBeUploaded}
                        widgetStyle={widgetStyle}
                    />
                ) : null}

                {custom ? getCustomPayloadUI(custom) : null}
            </div>
        )
    })
}

BotResponse.propTypes = {
    messages: PropTypes.any,
    onUserMessageSubmit: PropTypes.func,
    buttonsResponseRef: PropTypes.any,
    datetimePickersRef: PropTypes.any,
    handleFileUploadChange: PropTypes.func,
    handleFileUploadSubmit: PropTypes.func,
    fileToBeUploaded: PropTypes.func,
    updateUserMessage: PropTypes.func,
    setUserInputBtnDisabled: PropTypes.func,
    widgetStyle: PropTypes.any,
}

export default BotResponse

import React, { useMemo } from "react"
import PropTypes from 'prop-types'

const TextResponse = ({ text, widgetStyle }) => {
  const {
    primaryBGC,
    primaryTextColor,
    botIcon
  } = useMemo(() => widgetStyle, [widgetStyle])

  return (
    <div className="bot-response">
      <div>
        <img src={botIcon} className="bot-profile-photo" alt="elie" />
      </div>

      <div className="bot-msg-div">
        <div className="bot-msg" style={{ background: primaryBGC, color: primaryTextColor }}>
          {/* <p style={{ color: primaryTextColor }}>
            {text}
          </p> */}

          <div dangerouslySetInnerHTML={{ __html: text }} style={{ color: primaryTextColor }}>
            {/* {text} */}
          </div>
        </div>
      </div>
    </div>
  )
}

TextResponse.propTypes = {
  text: PropTypes.string,
  widgetStyle: PropTypes.any
}

export default TextResponse

import {
  LOADING_HOME_PAGE_SERVICE,
  UPDATE_LIST_HOME_PAGE_SERVICE,
} from "./actionTypes"

const INIT_STATE = {
  homePageServices: [],
  loader: false,
}

const HomePageServices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_LIST_HOME_PAGE_SERVICE:
      return {
        ...state,
        homePageServices: action.payload.data,
        loader: false,
      }
    case LOADING_HOME_PAGE_SERVICE:
      return {
        ...state,
        loader: action.payload.data,
      }

    default:
      return state
  }
}

export default HomePageServices

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { FormGroup, Modal, Row, Col, Label, Button, Input } from "reactstrap"
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { USER_TYPE } from "../../../constants/common"
import { useDispatch, useSelector } from "react-redux"
import {
  bookActivityServices,
  createServiceType,
  updateServiceType,
} from "store/actions"
import { getUrl } from "utils/Config"

const ServiceInfoModal = ({ t, isOpen, data, userDetail, closeModal }) => {
  const dispatch = useDispatch()
  const [error, setError] = useState()
  const [serviceDetail, setServiceDetail] = useState({})
  const [selectedServiceMode, SetSelectedServiceMode] = useState(null)
  const serviceAddOns = useSelector(state => state.CommonReducer.serviceAddOn)
  const serviceModes = useSelector(state => state.CommonReducer.serviceMode)
  const onlineActivityServiceStatus = useSelector(
    state => state.ServiceReducer.onlineActivityServiceStatus
  )

  const handleValidSubmit = (e, v) => {
    e.preventDefault()
    let httpObj = { ...data, ...serviceDetail, ...v }
    console.log(httpObj)
    if (httpObj.id) {
      dispatch(
        updateServiceType({
          ...httpObj,
          fieldOrder: parseInt(httpObj.fieldOrder),
          minimumDuration: parseInt(httpObj.minimumDuration),
        })
      )
    } else {
      dispatch(createServiceType(httpObj))
    }
    closeModal()
  }
  useEffect(() => {
    if (data.id) {
      setServiceDetail({ ...data })
    } else {
      setServiceDetail({})
    }
  }, [data])

  const dummy = e => {
    if (e.target.value === "BOTH" && e.target.checked) {
    }
  }
  const isActivityService =
    data.id === "a1e835d0-d3b7-4105-b3fb-564ff9f1957a" ||
    data.id === "2db41585-e913-43a0-bca2-1a1c41c72d15"
  const activityServiceId = data.id
  const [activityServiceTime, setActivityServiceTime] = useState("0")
  const bookActivityService = (e, v) => {
    const data = {
      isMorning: activityServiceTime === "0",
      userId: userDetail.id,
      serviceID: activityServiceId,
    }
    dispatch(bookActivityServices(data))
    closeModal()
  }

  let CONFIG
  let MORNING_ACTIVITY_URL
  let EVENING_ACTIVITY_URL

  if (isActivityService) {
    CONFIG = getUrl()
    MORNING_ACTIVITY_URL = CONFIG.URL.BOOK_SERVICE.MORNING_ACTIVITY_URL
    EVENING_ACTIVITY_URL = CONFIG.URL.BOOK_SERVICE.EVENING_ACTIVITY_URL
  }

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      className={isActivityService && "service-modal"}
    >
      <div className="modal-body">
        {!isActivityService &&
          userDetail &&
          userDetail.type !== USER_TYPE.ADMIN && (
            <>
              <div className="modal-header">
                <h5 className="modal-title mt-0">{t(serviceDetail.name)}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="description">
                  <span>Description: {serviceDetail.description}</span>
                </div>
                <br></br>
                <div className="minimum-duration">
                  <span>
                    {" "}
                    Minimum Duration for service:{" "}
                    {serviceDetail.minimumDuration
                      ? serviceDetail.minimumDuration
                      : 0}{" "}
                    minutes
                  </span>
                </div>
                <br></br>
                {!!(
                  serviceDetail.serviceMode === "ONLINE" ||
                  serviceDetail.serviceMode === "BOTH"
                ) && (
                  <div className="chanrges">
                    <label>Online</label>
                    <br />
                    <div style={{ paddingLeft: "10%" }}>
                      <span>
                        Base Charge:{" "}
                        <label>
                          ₹{serviceDetail.servicePrice?.onlineBasePrice}*
                        </label>
                      </span>
                      <br></br>
                      <span>
                        Hourly Charge:{" "}
                        <label>
                          ₹{serviceDetail.servicePrice?.onlinePrice}*
                        </label>
                      </span>
                    </div>
                  </div>
                )}
                {!!(
                  serviceDetail.serviceMode === "OFFLINE" ||
                  serviceDetail.serviceMode === "BOTH"
                ) && (
                  <div className="chanrges">
                    <label>Offline</label>
                    <br />
                    <div style={{ paddingLeft: "10%" }}>
                      <span>
                        Base Charge:{" "}
                        <label>
                          ₹{serviceDetail.servicePrice?.offlineBasePrice}*
                        </label>
                      </span>
                      <br></br>
                      <span>
                        Hourly Charge:{" "}
                        <label>
                          ₹{serviceDetail.servicePrice?.offlinePrice}*
                        </label>
                      </span>
                    </div>
                  </div>
                )}
                <br></br>
                <div className="service mode">
                  <span>
                    {" "}
                    Service Mode:{" "}
                    {serviceDetail.serviceMode === "ONLINE"
                      ? "Online"
                      : serviceDetail.serviceMode === "OFFLINE"
                      ? "Offline"
                      : "Online & Offline"}
                  </span>
                </div>
                <br></br>
                {serviceDetail.termCondition && (
                  <div className="terms-condition" style={{ fontSize: "8px" }}>
                    <span>*T&amp;C</span>:{" "}
                    <span>{serviceDetail.termCondition}</span>
                  </div>
                )}
              </div>
            </>
          )}
        {isActivityService &&
          userDetail &&
          userDetail.type !== USER_TYPE.ADMIN && (
            <>
              <div className="modal-header">
                <h5 className="modal-title mt-0">{t(serviceDetail.name)}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="description">
                  <AvForm
                    onValidSubmit={(e, v) => {
                      bookActivityService(e, v)
                    }}
                  >
                    <Row>
                      <Col xs={12} sm={12} className="p-4">
                        <FormGroup>
                          <Label for="healthBuddyType">Select Time</Label>
                          <select
                            id="healthBuddyType"
                            name="healthBuddyType"
                            className="form-control"
                            onChange={e => {
                              setActivityServiceTime(e.target.value)
                            }}
                            required
                          >
                            <option value="0" selected>
                              Morning
                            </option>
                            <option value="1">Evening</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <img
                      src={
                        activityServiceTime === "0"
                          ? MORNING_ACTIVITY_URL
                          : EVENING_ACTIVITY_URL
                      }
                      className="w-100 rounded"
                      alt="Online Activity"
                    />
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={closeModal}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={
                          isActivityService &&
                          onlineActivityServiceStatus?.isCurrentMonthBooking
                        }
                      >
                        {t("Book Service")}
                      </button>
                    </div>
                    {isActivityService &&
                      onlineActivityServiceStatus?.isCurrentMonthBooking && (
                        <span>
                          You are already subscribed, your subscription is valid
                          till{" "}
                          <b>
                            {moment(
                              new Date(
                                onlineActivityServiceStatus?.monthLastDate
                              )
                            ).format("Do MMMM YYYY")}
                          </b>
                        </span>
                      )}
                  </AvForm>
                </div>
              </div>
            </>
          )}
        {userDetail && userDetail.type === USER_TYPE.ADMIN && (
          <>
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              <div className="modal-header">
                {serviceDetail.name && (
                  <h5 className="modal-title mt-0">{t(serviceDetail.name)}</h5>
                )}
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <AvField
                        id="serviceType"
                        name="serviceType"
                        label={t("Service Name")}
                        className={`form-control ${
                          error && error["serviceType"] && "is-invalid"
                        }`}
                        placeholder={t("Service Type")}
                        type="text"
                        value={serviceDetail.name}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <AvField
                        id="description"
                        name="description"
                        label={t("Service Description")}
                        className={`form-control ${
                          error && error["descripton"] && "is-invalid"
                        }`}
                        placeholder={t("Service Description")}
                        type="text"
                        value={serviceDetail.description}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <AvField
                        type="select"
                        id="serviceMode"
                        name="serviceMode"
                        label="Service Mode"
                        value={data.serviceMode}
                        className={`form-control ${
                          error && error["serviceMode"] && "is-required"
                        }`}
                        errorMessage="Pick one!"
                        required
                        onChange={e => {
                          SetSelectedServiceMode(e.target.value)
                        }}
                      >
                        <option value={-1} key="service_mode_default">
                          --Select--
                        </option>
                        {serviceModes &&
                          serviceModes.map(item => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            )
                          })}
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <Label>Transportation: </Label>&nbsp;
                      <Input
                        type="checkbox"
                        id="isTransportationType"
                        checked={serviceDetail.isTransportationType}
                        onChange={e =>
                          setServiceDetail({
                            ...serviceDetail,
                            isTransportationType: e.target.checked,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  {((selectedServiceMode &&
                    (selectedServiceMode === "ONLINE" ||
                      selectedServiceMode === "BOTH")) ||
                    serviceDetail.serviceMode === "ONLINE" ||
                    serviceDetail.serviceMode === "BOTH") && (
                    <>
                      <Col xs={12} xl={6} sm={6} className="mb-4">
                        <FormGroup>
                          <AvField
                            id="onlineBasePrice"
                            name="onlineBasePrice"
                            label={t("Online Base Charge(in ₹)")}
                            className={`form-control ${
                              error && error["onlineBasePrice"] && "is-invalid"
                            }`}
                            placeholder={t("Base Charge")}
                            type="number"
                            value={serviceDetail.servicePrice?.onlineBasePrice}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} xl={6} sm={6} className="mb-4">
                        <FormGroup>
                          <AvField
                            id="onlinePrice"
                            name="onlinePrice"
                            label={t("Online Hourly Charge(in ₹)")}
                            className={`form-control ${
                              error && error["onlinePrice"] && "is-invalid"
                            }`}
                            placeholder={t("Hourly Charge")}
                            type="number"
                            value={serviceDetail.servicePrice?.onlinePrice}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                  {((selectedServiceMode &&
                    (selectedServiceMode === "OFFLINE" ||
                      selectedServiceMode === "BOTH")) ||
                    serviceDetail.serviceMode === "OFFLINE" ||
                    serviceDetail.serviceMode === "BOTH") && (
                    <>
                      <Col xs={12} xl={6} sm={6} className="mb-4">
                        <FormGroup>
                          <AvField
                            id="offlineBasePrice"
                            name="offlineBasePrice"
                            label={t("Offline Base Charge(in ₹)")}
                            className={`form-control ${
                              error && error["offlineBasePrice"] && "is-invalid"
                            }`}
                            placeholder={t("Base Charge")}
                            type="number"
                            value={serviceDetail.servicePrice?.offlineBasePrice}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} xl={6} sm={6} className="mb-4">
                        <FormGroup>
                          <AvField
                            id="offlinePrice"
                            name="offlinePrice"
                            label={t("Offline Hourly Charge(in ₹)")}
                            className={`form-control ${
                              error && error["offlinePrice"] && "is-invalid"
                            }`}
                            placeholder={t("Hourly Charge")}
                            type="number"
                            value={serviceDetail.servicePrice?.offlinePrice}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                  <Col xs={12} lg={6} sm={6} className="mb-4">
                    <FormGroup>
                      <AvField
                        id="minimumDuration"
                        name="minimumDuration"
                        label={t("Minimum Duration(in minutes)")}
                        placeholder={t("e.g. 120")}
                        type="number"
                        value={serviceDetail.minimumDuration}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6} sm={6} className="mb-4">
                    <FormGroup>
                      <AvField
                        id="fieldOrder"
                        name="fieldOrder"
                        label={t("Service Display order")}
                        placeholder={t("e.g. 10")}
                        type="number"
                        value={serviceDetail.fieldOrder}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <AvField
                        type="select"
                        name="serviceAddOns"
                        label="Service Add-ons"
                        value={serviceDetail.serviceAddOns}
                        errorMessage="Pick one!"
                      >
                        <option value={-1} key="service_add_ons_default">
                          --Select--
                        </option>
                        {serviceAddOns &&
                          serviceAddOns.map(item => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.label}
                              </option>
                            )
                          })}
                      </AvField>
                    </FormGroup>
                  </Col>

                  <Col xs={12} sm={12} className="mb-4">
                    <FormGroup>
                      <AvField
                        id="termCondition"
                        name="termCondition"
                        label={t("Terns & Conditions")}
                        placeholder={t("Terms and conditions")}
                        type="text"
                        value={serviceDetail.termCondition}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary px-4">
                  {serviceDetail.id ? t("Update") : t("Create")}
                </button>
              </div>
            </AvForm>
          </>
        )}
      </div>
    </Modal>
  )
}

ServiceInfoModal.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.any,
  data: PropTypes.any,
  userDetail: PropTypes.any,
  closeModal: PropTypes.any,
}

export default withTranslation()(ServiceInfoModal)

import React from 'react'
import ReactDOM from 'react-dom'

const defaultErrorMessage = "Something went wrong, please try again later!"

function alertCall(message, type) {
    ReactDOM.render(
        <div className={`alert alert-${type || 'primary'}`}>
            {message || defaultErrorMessage}
        </div>, document.getElementById('custom-alert'));

    setTimeout(() => {
        ReactDOM.render(<></>, document.getElementById('custom-alert'));
    }, 3000);
}

export default alertCall;

import { RZP_SCRIPT, WHATSAPP_PROD, WHATSAPP_TEST, ENV } from "constants/common";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentDetailByServiceBookingId,  makePayment, clearPaymentStatus, clearServiceBookingStatus, logPaymentDetail } from '../../store/actions';
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap"
import { getServerDetail } from "utils/serverDetailUtil";

const ExternalPayment = () => {
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const orderInfo = useSelector(state => state.PaymentReducer.paymentDetailByServiceBookingId);
    let paymentDetail = useSelector(state => state.PaymentReducer.paymentStatus);
    const logPaymentDetailResp = useSelector(state => state.PaymentReducer.logPaymentDetailResp);

    useEffect(() => {
        if (window.location && window.location.search && window.location.search.substr(1)) {
            const qParam = window.location.search.substr(1).split("=");
            if (qParam.length > 0 && qParam[0] === 'sid' && qParam[1] !== "" && qParam[1] !== null) {
                dispatch(fetchPaymentDetailByServiceBookingId(qParam[1]))
            }
            else {
                setError('This link is invalid.');
            }
        }
    }, [dispatch])

    useEffect(() => {
        if (orderInfo && orderInfo !== null) {
            if (!Object.keys(orderInfo).length) {
                setError('This link is invalid.');
            }
            else if (!orderInfo.razorPayPaymentId) {
                dispatch(makePayment({ ...orderInfo, amount: orderInfo.amount * 100 }));
            }
            else if (orderInfo.razorPayPaymentId && orderInfo.razorPayPaymentId !== null) {
                setError('You have already paid for this service.')
            }
        }

        // else {
        //     setError('This link in invalid');
        // }
    }, [orderInfo]);

    useEffect(() => {
        if (paymentDetail) {
            if (paymentDetail.error) {
                setError(paymentDetail.error.description + ' Please refresh the page and try again in some time.');
            }
            else {
                dispatch(logPaymentDetail({
                    ...orderInfo,
                    razorPayPaymentId: paymentDetail.razorpay_payment_id,
                    paymentStatus: 'captured'
                }))
            }
            //     dispatch(clearServiceBookingStatus());
            //     dispatch(clearPaymentStatus());
            // }
        }
    }, [paymentDetail]);

    useEffect(() => {
        if (logPaymentDetailResp) {
            if (window.location.href.indexOf('payment') !== -1) {
                setError('Your payment is successful, this window will be closed and redirecting you to Whatsapp.');
                setTimeout(() => {
                    console.log('opening whatsapp')
                    let whatsappLink = "https://wa.me/";
                    if (getServerDetail().env === ENV.PROD) {
                        whatsappLink += WHATSAPP_PROD;
                    }
                    else {
                        whatsappLink += WHATSAPP_TEST;
                    }
                    window.open(whatsappLink);
                    // window.open('', '_self').close()
                }, 1000)
            }

        }
    }, [logPaymentDetailResp])

    return (
        <>
            {error && (
                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                {error}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default ExternalPayment;
import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Collapse } from "reactstrap"
import classnames from "classnames"
import SeniorCitizenList from "./Users/seniorCitizenList"
import HelpbuddyList from "./Users/helpBuddyList"
import PartnerList from "./Users/partnerList"
import { useDispatch, useSelector } from "react-redux"
import { getAllUser, getHealthBuddy, searchUser } from "store/users/actions"
import CreateUserModal from "./Users/createUserModal"
import { USER_TYPE } from "constants/common"
import HealthBuddyList from "./Users/healthBuddyList"
import { AvField, AvForm } from "availity-reactstrap-validation"

const UserMgmt = () => {
  const dispatch = useDispatch()
  const [createUserModal, setCreateUserModal] = useState(false)
  const [userType, setUserType] = useState(USER_TYPE.PARTNER)
  const [searchType, setSearchType] = useState(null)
  const userDetail = useSelector(state => state.Profile.userProfile)

  useEffect(() => {
    if (userDetail && userDetail.type === USER_TYPE.PARTNER) {
      dispatch(getHealthBuddy(userDetail.id))
    } else {
      dispatch(getAllUser({
        type: USER_TYPE.PARTNER,
        page: 1,
        size: 10
      }))
      dispatch(getAllUser({
        type: USER_TYPE.SENIOR_CITIZEN,
        page: 1,
        size: 10
      }))
      dispatch(getAllUser(
        {
          type: USER_TYPE.SERVICE_PROVIDER,
          page: 1,
          size: 10
        }
      ))
    }    
  }, [userDetail])

  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
  }

  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol3(false)
  }

  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
  }

  const handleSubmit = (event, values) => {
    event.preventDefault();
    dispatch(searchUser(values, searchType));
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          {/* load the accordian */}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col lg={4}>
                        <AvField
                          name="search"
                          className="form-control"
                          placeholder="Search User"
                          type="text"
                        />
                      </Col>
                      <Col lg={2}>
                      <select
                        className="form-control"
                        onChange={e => {
                          setSearchType(e.target.value)
                        }}
                      >
                        <option value="" selected disabled>
                          Select One
                        </option>
                        <option value="firstName">First Name</option>
                        <option value="lastName">Last Name</option>
                        <option value="mobileNumber">Mobile Number</option>
                      </select>
                      </Col>
                      <Col>
                        <button className="btn btn-primary btn-block" type="submit">
                          Search
                        </button>
                      </Col>
                    </Row>
                  </AvForm>
                  <Row>
                    <Col lg={12}>
                      <div className="mt-4">
                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          {userDetail && userDetail.type !== USER_TYPE.PARTNER && (
                            <>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingFlushOne"
                                >
                                  <button
                                    className={classnames(
                                      "accordion-button",
                                      "fw-medium",
                                      { collapsed: !col1 }
                                    )}
                                    type="button"
                                    onClick={t_col1}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Senior Citizen
                                  </button>
                                </h2>

                                <Collapse
                                  isOpen={col1}
                                  className="accordion-collapse"
                                >
                                  <div className="accordion-body">
                                    <SeniorCitizenList></SeniorCitizenList>
                                  </div>
                                </Collapse>
                              </div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingFlushTwo"
                                >
                                  <button
                                    className={classnames(
                                      "accordion-button",
                                      "fw-medium",
                                      { collapsed: !col2 }
                                    )}
                                    type="button"
                                    onClick={t_col2}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Help Buddy
                                  </button>
                                </h2>

                                <Collapse
                                  isOpen={col2}
                                  className="accordion-collapse"
                                >
                                  <div className="accordion-body">
                                    <HelpbuddyList></HelpbuddyList>
                                  </div>
                                </Collapse>
                              </div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingFlushThree"
                                >
                                  <div
                                    className={classnames(
                                      "accordion-button",
                                      "fw-medium",
                                      { collapsed: !col3 }
                                    )}
                                    onClick={t_col3}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: "95%",
                                      }}
                                    >
                                      <span>Partners</span>
                                      <button
                                        type="button"
                                        className="btn btn-outline-primary w-sm"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setUserType(USER_TYPE.PARTNER)
                                          setCreateUserModal(true)
                                        }}
                                      >
                                        Add Partners
                                      </button>
                                    </div>
                                  </div>
                                </h2>

                                <Collapse
                                  isOpen={col3}
                                  className="accordion-collapse"
                                >
                                  <div className="accordion-body">
                                    <PartnerList></PartnerList>
                                  </div>
                                </Collapse>
                              </div>
                            </>
                          )}
                          {userDetail && userDetail.type === USER_TYPE.PARTNER && (
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingFlushThree"
                              >
                                <div
                                  className={classnames(
                                    "accordion-button",
                                    "fw-medium",
                                    { collapsed: !col1 }
                                  )}
                                  onClick={t_col1}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      width: "95%",
                                    }}
                                  >
                                    <span>Health Buddy</span>
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary w-sm"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setUserType(USER_TYPE.HEALTH_BUDDY)
                                        setCreateUserModal(true)
                                      }}
                                    >
                                      Add Health Buddy
                                    </button>
                                  </div>
                                </div>
                              </h2>

                              <Collapse
                                isOpen={col1}
                                className="accordion-collapse"
                              >
                                <div className="accordion-body">
                                  <HealthBuddyList></HealthBuddyList>
                                </div>
                              </Collapse>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <CreateUserModal
        isOpen={createUserModal}
        userType={userType}
        closeModal={() => {
          setCreateUserModal(false)
        }}
      />
    </React.Fragment>
  )
}

export default UserMgmt

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import MultipleColumnChart from "../../components/Charts/MultipleColumnChart"
import { useDispatch, useSelector } from "react-redux"
import { GetUserRegistrationData } from "../../store/actions"
import { PERIOD } from "constants/common"

const UserRegistrationWidget = () => {
  const dispatch = useDispatch()
  const [periodType, setPeriodType] = useState(PERIOD.MONTHLY)
  const [chartData, setChartData] = useState(null)

  const { periodData } = useSelector(state => ({
    periodData: state.Dashboard.UserRegistrationData || [],
  }))

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(GetUserRegistrationData(pType))
  }

  useEffect(() => {
    const periodDataMap = []
    periodData?.data?.forEach(period => {
      periodDataMap.push({
        name: "User Count",
        type: "column",
        data: period.data.count,
      })
      periodDataMap.push({
        name: "Service Booked",
        type: "column",
        data: period.data.serviceBooked,
      })
    })

    const data = {
      category: periodData?.category,
      periodDataMap,
    }
    setChartData(data)
  }, [periodData])

  return (
    <Card>
      <CardBody>
        <div className="d-sm-flex flex-wrap">
          <h4 className="card-title mb-4">User Registered</h4>
          <div className="ms-auto">
            <ul className="nav nav-pills">
              {/* <li className="nav-item">
                                <Link
                                    to="#"
                                    className={classNames(
                                        { active: periodType === "weekly" },
                                        "nav-link disabled"
                                    )}
                                    onClick={() => {
                                        onChangeChartPeriod("weekly")
                                    }}
                                    id="one_month"
                                >
                                    Week
                                </Link>{" "}
                            </li> */}
              <li className="nav-item">
                <Link
                  to="#"
                  className={classNames(
                    { active: periodType === "monthly" },
                    "nav-link"
                  )}
                  onClick={() => {
                    onChangeChartPeriod("monthly")
                  }}
                  id="one_month"
                >
                  Month
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="#"
                  className={classNames(
                    { active: periodType === "yearly" },
                    "nav-link"
                  )}
                  onClick={() => {
                    onChangeChartPeriod("yearly")
                  }}
                  id="one_month"
                >
                  Year
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="clearfix"></div> */}
        <MultipleColumnChart periodData={chartData} />
      </CardBody>
    </Card>
  )
}

export default withRouter(UserRegistrationWidget)

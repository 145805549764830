import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Label } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch, useSelector } from "react-redux"
import ConfirmModal from "components/Common/ConfirmModal"
import moment from "moment"
import AssignmentDetail from "./assignmentDetailModal"
import PaymentModal from "../Payment/paymentModal"
import { AddressFormatter } from "../shared/addressFormatter"
import { getAllAssignedAssignemnts, getServiceType } from "store/actions"
import { USER_TYPE, PAYMENT_STATUS } from "constants/common"
import { getUserDetail } from "utils/userUtil"
import DataTable from "react-data-table-component"

const UpcomingAssignment = ({ userRole }) => {
  const dispatch = useDispatch()

  const [assignmentDetailModal, setAssignmentDetailModal] = useState(false)
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [paymentModal, setPaymentModal] = useState(false)
  const [perPage, setPerPage] = useState(10)

  const upcomingAssignmentList = useSelector(
    state => state.AssignmentReducer.upcomingAssignments.data
  )
  const page = useSelector(
    state => state.AssignmentReducer.upcomingAssignments.page
  )
  let serviceType = useSelector(state => state.ServiceReducer.serviceType)
  let addressList = useSelector(state => state.Profile.address)
  let modeList = useSelector(state => state.CommonReducer.serviceMode)

  useEffect(() => {
    if (!serviceType?.length) {
      dispatch(getServiceType())
    }
  }, [serviceType])

  useEffect(() => {
    handlePageChange(1)
  }, [dispatch])

  const handlePageChange = page => {
    dispatch(getAllAssignedAssignemnts(null, page, perPage))
  }

  const handlePerRowsChange = (newPerPage, page) => {
    dispatch(getAllAssignedAssignemnts(null, page, newPerPage))
    setPerPage(newPerPage)
  }

  const getServiceName = serviceId => {
    let serviceObj = serviceType.find(item => item.id === serviceId)
    return serviceObj && serviceObj.serviceType ? serviceObj.serviceType : "-"
  }
  const getAddressTag = addressId => {
    let addressObj = addressList.find(item => item.id === addressId)
    return addressObj && addressObj.tags ? addressObj.tags : "-"
  }

  const getMode = modeId => {
    let mode = modeList.filter(item => item.id === modeId)
    if (mode && mode.length) {
      return mode[0].description
    } else {
      return "NA"
    }
  }

  const cancelAssignment = serviceInfo => {
    dispatch(
      cancelAssignment({
        ...serviceInfo,
        serviceStatus: "CANCELLED",
      })
    )
  }

  let columns = [
    {
      id: 1,
      name: "Service",
      selector: item => <span>{getServiceName(item.serviceID)}</span>,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Date",
      selector: (item) => item.dateTimeFrom,
      format: (item) => (moment(item.dateTimeFrom).isValid() ? moment.utc(item.dateTimeFrom).local().format("DD-MM-YYYY hh:mm A") : '-'),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Duration",
      selector: row => row.duration,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Address",
      selector: item => <AddressFormatter data={item.address} />,
      sortable: true,
      // right: true,
      reorder: true,
    },
  ]
  if (userRole && userRole === USER_TYPE.ADMIN) {
    columns = [
      ...columns,
      {
        id: 5,
        name: "UserName",
        selector: item => item,
        format: item => item.user?.firstName + " " + item.user?.lastName,
        sortable: true,
        // right: true,
        reorder: true,
      },
      {
        id: 6,
        name: "Contact No",
        selector: item => item.user?.mobileNumber,
        sortable: true,
        // right: true,
        reorder: true,
      },
      {
        id: 7,
        name: "Booked Date",
        selector: item => item.createdOn,
        format: item =>
          item.createdOn
            ? moment(item.createdOn).isValid()
              ? moment.utc(item.createdOn).local().format("DD-MM-YYYY hh:mm A")
              : moment(Number(item.createdOn)).isValid()
              ? moment(Number(item.createdOn))
                  .local()
                  .format("DD-MM-YYYY hh:mm A")
              : "-"
            : "-",
        sortable: true,
        // right: true,
        reorder: true,
      },
    ]
  }
  columns = [
    ...columns,
    {
      id: 8,
      name: "Service Mode",
      selector: item => <span>{getMode(item.serviceMode)}</span>,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Description",
      selector: row => row.description,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 10,
      name: "Action",
      selector: item => (
        <ul className="list-inline mb-0 font-size-20">
          {userRole && userRole === USER_TYPE.SERVICE_PROVIDER && (
            <>
              <li className="list-inline-item">
                <i
                  className="bx bxs-right-arrow text-success"
                  onClick={() => {
                    setSelectedAssignment(item)
                    setAssignmentDetailModal(true)
                  }}
                />
              </li>
            </>
          )}
          {userRole && userRole === USER_TYPE.ADMIN && (
            <>
              <li className="list-inline-item">
                <i
                  className="bx bx-info-circle text-primary"
                  onClick={() => {
                    setSelectedAssignment(item)
                    setAssignmentDetailModal(true)
                  }}
                />
              </li>
              {item.paymentStatus &&
                item.paymentStatus !== PAYMENT_STATUS.PAID &&
                item.paymentStatus !== PAYMENT_STATUS.REFUND_PENDING && (
                  <li className="list-inline-item">
                    <i
                      className="bx bx-money text-success"
                      onClick={() => {
                        setSelectedAssignment(item)
                        setPaymentModal(true)
                      }}
                    ></i>
                  </li>
                )}
            </>
          )}
        </ul>
      ),
      sortable: true,
      // right: true,
      reorder: true,
    },
  ]

  return (
    <React.Fragment>
      {upcomingAssignmentList?.length > 0 && (
        <DataTable
          noHeader
          // title="Senior Citizen"
          columns={columns}
          data={upcomingAssignmentList}
          defaultSortFieldId={2}
          // sortIcon={<SortIcon />}
          pagination
          highlightOnHover
          // selectableRows

          paginationServer
          paginationTotalRows={page.totalElements || 10}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
        />
      )}
      {upcomingAssignmentList?.length === 0 && (
        <Label>No Assignments found</Label>
      )}
      <ConfirmModal
        isOpen={showConfirmModal}
        titleText="Cancel Assignment"
        bodyText="Are you sure you want to cancel assignment"
        onYesClick={() => {
          cancelAssignment(selectedAssignment)
          setShowConfirmModal(false)
        }}
        onNoClick={() => {
          setShowConfirmModal(false)
        }}
      />
      {selectedAssignment && (
        <>
          <AssignmentDetail
            isOpen={assignmentDetailModal}
            data={selectedAssignment}
            userRole={getUserDetail("type")}
            closeModal={() => {
              setAssignmentDetailModal(false)
            }}
            onCancelClick={() => {
              setAssignmentDetailModal(false)
            }}
          ></AssignmentDetail>
          <PaymentModal
            isOpen={paymentModal}
            data={selectedAssignment}
            onCancelClick={() => {
              setPaymentModal(false)
            }}
            closeModal={() => {
              setPaymentModal(false)
            }}
          ></PaymentModal>
        </>
      )}
    </React.Fragment>
  )
}

UpcomingAssignment.propTypes = {
  userRole: PropTypes.any,
}

export default UpcomingAssignment

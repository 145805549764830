import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const MultipleColumnChart = ({ periodData }) => {
  if (!periodData?.category) {
    return <>Loading...</>
  }
  const options = {
    colors: [
      "#008FFB",
      "#3F51B5",
      "#33B2DF",
      "#4ECDC4",
      "#2B908F",
      "#449DD1",
      "#5653FE",
      "#662E9B",
    ],
    chart: {
      stacked: false,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "50%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: periodData.category,
      labels: {
        show: true,
      },
    },
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }
  
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={periodData.periodDataMap}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

MultipleColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default MultipleColumnChart

import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import RegisterReducer from "./auth/register/reducer"
import Profile from "./auth/profile/reducer"

// assignment
import AssignmentReducer from "./assignments/reducer"

// services
import ServiceReducer from "./services/reducer"

// common
import CommonReducer from "./common/reducer"

// payment
import PaymentReducer from "./payment/reducer"

// onboardReducer
import OnboardReducer from "./onboarding/reducer"

// users
import Users from "./users/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

// packages
import PackagesReducer from "./packages/reducer"

// Enquiry
import Enquiry from "./enquiry/reducer"

// HomePageServices
import HomePageServices from "./home-page-service/reducer"

// Trip
import Trip from "./trip/reducer"

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  RegisterReducer,
  AssignmentReducer,
  ServiceReducer,
  CommonReducer,
  PaymentReducer,
  OnboardReducer,
  Users,
  Dashboard,
  PackagesReducer,
  Enquiry,
  Trip,
  HomePageServices,
})

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer

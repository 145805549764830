import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from "prop-types"
import classnames from 'classnames'

import {
    Table,
    Input, Button,
} from 'reactstrap';
import { ChevronRight, ChevronLeft } from "react-feather";
import { Tooltip } from 'react-tippy';

import { sortListAscending } from 'utils/sortUtil';
import { GENERAL_SERVICE_DURATIONS, TRANSPORTATION_SERVICE_DURATIONS } from 'constants/common';

import "./SelectionList.scss"

const MIN_PACKAGE_SERVICE_DURATION = 60 * 4 // 4 hours

const SelectionList = ({
    allData,
    alreadySelectedFields,
    updateSelectedFields,
    updatePackageService,
    totalPackagePrice,
    isAdminUser,
    getDefaultDiscount
}) => {
    const [selectedLeftFields, setSelectedLeftFields] = useState([])
    const [selectedRightFields, setSelectedRightFields] = useState([])

    const [availableFields, selectedFields] = useMemo(() => {
        const selectedFieldIds = alreadySelectedFields.map(({ serviceId }) => serviceId)

        const availableData = allData.filter(({ id }) => !selectedFieldIds.includes(id)).map((item) => {
            const {
                id, serviceType, minDuration, isTransportationType,
                onlinePrice, onlineBasePrice, offlinePrice, offlineBasePrice
            } = item

            let price;
            if (!onlineBasePrice && !onlinePrice) {
                price = (offlineBasePrice || 0) + (offlinePrice || 0);
            } else {
                price = (onlineBasePrice || 0) + (onlinePrice || 0);
            }

            const defaultDiscount = getDefaultDiscount(isAdminUser)
            const total = price * (MIN_PACKAGE_SERVICE_DURATION / 60)
            const packagePrice = total - (total * ((defaultDiscount || 0) / 100))

            return {
                serviceId: id,
                serviceName: serviceType,
                price,
                minDuration: minDuration,
                hours: MIN_PACKAGE_SERVICE_DURATION / 60,
                total,
                discount: defaultDiscount,
                packagePrice,
                isTransportationType: isTransportationType
            }
        })

        const selectedData = allData.filter(({ id }) => selectedFieldIds.includes(id)).map((item) => {
            const { id, serviceType, minDuration, isTransportationType,
                onlinePrice, onlineBasePrice, offlinePrice, offlineBasePrice, price: itemPrice
            } = item
            const serviceData = alreadySelectedFields.find(({ serviceId }) => serviceId === id) || {}

            let price = itemPrice
            if (!price) {
                if (!onlineBasePrice && !onlinePrice) {
                    price = (offlineBasePrice || 0) + (offlinePrice || 0);
                } else {
                    price = (onlineBasePrice || 0) + (onlinePrice || 0);
                }
            }

            return {
                ...serviceData,
                serviceName: serviceType,
                price,
                minDuration: minDuration,
                isTransportationType: isTransportationType
            }
        })

        return [sortListAscending(availableData, "serviceName"), sortListAscending(selectedData, "serviceName")]
    }, [allData, alreadySelectedFields])

    const updateSelectedLeftFields = (newIds) => {
        setSelectedLeftFields(newIds)
    }

    const updateSelectedRightFields = (targetServiceId) => {
        if (selectedRightFields.includes(targetServiceId)) {
            setSelectedRightFields(selectedRightFields.filter((serviceId) => serviceId !== targetServiceId))
        } else {
            setSelectedRightFields(selectedRightFields.concat(targetServiceId))
        }
    }

    const addField = () => {
        if (selectedLeftFields?.length) {
            updateSelectedFields(alreadySelectedFields.concat(availableFields.filter(({ serviceId }) => selectedLeftFields.includes(serviceId))))
            setSelectedLeftFields([])
        }
    }

    const removeField = () => {
        if (selectedRightFields?.length) {
            updateSelectedFields(alreadySelectedFields.filter(({ serviceId }) => !selectedRightFields.includes(serviceId)))
            setSelectedRightFields([])
        }
    }

    const getDurationOptions = useCallback((isTransportationType, minDuration = 0) => {
        if (isTransportationType) {
            return TRANSPORTATION_SERVICE_DURATIONS.filter(({ value }) => value >= MIN_PACKAGE_SERVICE_DURATION && (!minDuration || value % minDuration === 0)).map(({ label, value }) => ({ label, value: value / 60 }))
        }
        return GENERAL_SERVICE_DURATIONS.filter(({ value }) => value >= MIN_PACKAGE_SERVICE_DURATION && (!minDuration || value % minDuration === 0)).map(({ label, value }) => ({ label, value: value / 60 }))
    }, [])

    return (
        <div id="service-selection-widget">
            <div className="field-selector align-self-baseline" id="field-selector-left">
                <Table>
                    <thead>
                        <tr>
                            <th>Available Services</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>
                                <Input
                                    type="select"
                                    id="service-selection-available"
                                    multiple
                                    value={selectedLeftFields}
                                    onChange={(e) => {
                                        e.preventDefault()

                                        const selectedIds = Array.from(e.target.options).filter(({ selected }) => selected === true).map(({ value }) => value)
                                        updateSelectedLeftFields(selectedIds)
                                    }}
                                >
                                    {availableFields.map((field) => {
                                        const { serviceId, serviceName } = field
                                        return (
                                            <option
                                                key={`left-group-${serviceId}`}
                                                // onClick={() => { updateSelectedLeftFields(field) }}
                                                value={serviceId}
                                            >
                                                {serviceName}
                                            </option>
                                        )
                                    })}
                                </Input>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <div className="action-btn">
                <div>
                    <Button outline color="primary" size="sm" onClick={addField}><ChevronRight /></Button>
                </div>
                <div>
                    <Button outline color="primary" size="sm" onClick={removeField}><ChevronLeft /></Button>
                </div>
            </div>

            <div className="field-selector align-self-baseline table-responsive" id="field-selector-right">
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Hours</th>
                            <th>Total</th>
                            <th>Discount %</th>
                            <th>Package Price</th>
                        </tr>
                    </thead>

                    <tbody>
                        {selectedFields.map((field) => {
                            const {
                                serviceId, serviceName, isTransportationType, minDuration,
                                price, hours, total, discount, packagePrice
                            } = field
                            return (
                                <tr
                                    key={serviceId}
                                    className={classnames("", {
                                        "bg-primary": selectedRightFields.includes(serviceId)
                                    })}
                                    onClick={() => { updateSelectedRightFields(serviceId) }}
                                >
                                    <td>
                                        <Tooltip title={serviceName}>
                                            <Input
                                                type="text"
                                                value={serviceName}
                                                disabled
                                            />
                                        </Tooltip>
                                    </td>

                                    <td>
                                        <Input
                                            type="text"
                                            value={price}
                                            disabled
                                        />
                                    </td>

                                    <td>
                                        <Input
                                            id="hours"
                                            name="hours"
                                            type="select"
                                            value={hours}
                                            onChange={(e) => { updatePackageService(serviceId, "hours", e.target.value) }}
                                            onClick={(e) => { e.stopPropagation() }}
                                        >
                                            {getDurationOptions(isTransportationType, minDuration).map(({ label, value }) => {
                                                return (
                                                    <option value={value} key={value}>{label}</option>
                                                )
                                            })}
                                        </Input>
                                    </td>

                                    <td>
                                        <Input
                                            type="text"
                                            value={total}
                                            disabled
                                        />
                                    </td>

                                    <td>
                                        <Input
                                            type="number"
                                            min="0"
                                            max="100"
                                            value={discount}
                                            onChange={(e) => { updatePackageService(serviceId, "discount", e.target.value) }}
                                            onClick={(e) => { e.stopPropagation() }}
                                            disabled={isAdminUser ? null : true}
                                        />
                                    </td>

                                    <td>
                                        <Input
                                            type="text"
                                            value={packagePrice}
                                            disabled
                                        />
                                    </td>
                                </tr>
                            )
                        })}

                        {
                            selectedFields?.length ? (
                                <tr key="total">
                                    <td>
                                        <Input
                                            type="text"
                                            value="Total"
                                            disabled
                                        />
                                    </td>

                                    <td>
                                        <Input
                                            type="text"
                                            value="-"
                                            disabled
                                        />
                                    </td>

                                    <td>
                                        <Input
                                            type="text"
                                            value="-"
                                            disabled
                                        />
                                    </td>

                                    <td>
                                        <Input
                                            type="text"
                                            value="-"
                                            disabled
                                        />
                                    </td>

                                    <td>
                                        <Input
                                            type="text"
                                            value="-"
                                            disabled
                                        />
                                    </td>

                                    <td>
                                        <Input
                                            type="number"
                                            value={totalPackagePrice}
                                            disabled
                                        />
                                    </td>
                                </tr>
                            ) : (
                                <tr><td colSpan={6} className="text-center">Please add service(s)</td></tr>
                            )
                        }

                    </tbody>
                </Table>
            </div>
        </div>
    )
}

SelectionList.propTypes = {
    allData: PropTypes.any,
    alreadySelectedFields: PropTypes.any,
    updateSelectedFields: PropTypes.func,
    updatePackageService: PropTypes.func,
    totalPackagePrice: PropTypes.number,
    isAdminUser: PropTypes.bool,
    getDefaultDiscount: PropTypes.func
}

export default SelectionList;

import React from "react";
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const AddressFormatter = ({ data, props }) => {
    return (
        <p {...props}>{data?.address}, {data?.landmark}, {data?.city}</p>
    )
}

AddressFormatter.propTypes = {
    t: PropTypes.any,
    data: PropTypes.any,
    props: PropTypes.any
}

export default withTranslation()(AddressFormatter);

import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { FormGroup, Modal, Row, Col, Label, Button, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { changePassword } from "store/actions"

const ChangePasswordModal = ({ t, isOpen, onCancelClick }) => {
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const changePasswordResp = useSelector(
    state => state.Profile.changePasswordResponse
  )
  useEffect(() => {
    setError(changePasswordResp)
  }, [changePasswordResp])
  const handleValidSubmit = (e, v) => {
    e.preventDefault()
    console.log(v)
    if (v.newPassword !== v.confirmpassword) {
      setError("new password and confirm password should be same")
    } else {
      dispatch(changePassword({ ...v }))
    }
  }
  return (
    <>
      <Modal isOpen={isOpen} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">{"Change Password"}</h5>
        </div>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <div className="modal-body">
            {error ? (
              <Alert color={!error.status ? "danger" : "success"}>
                {error.message}
              </Alert>
            ) : null}
            <Row>
              <Col xs={12} sm={12} className="mb-4">
                <FormGroup>
                  <AvField
                    id="oldPassword"
                    name="oldPassword"
                    label={t("Old Password")}
                    className={`form-control ${
                      error && error["oldPassword"] && "is-invalid"
                    }`}
                    placeholder={t("Old Password")}
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} className="mb-4">
                <FormGroup>
                  <AvField
                    id="newPassword"
                    name="newPassword"
                    label={t("New Password")}
                    className={`form-control ${
                      error && error["newPassword"] && "is-invalid"
                    }`}
                    placeholder={t("New Password")}
                    type="password"
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} className="mb-4">
                <FormGroup>
                  <AvField
                    id="confirmpassword"
                    name="confirmpassword"
                    label={t("Confirm Password")}
                    className={`form-control ${
                      error && error["confirmpassword"] && "is-invalid"
                    }`}
                    placeholder={t("Confirm Password")}
                    type="password"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                onCancelClick()
              }}
            >
              {t("Close")}
            </button>
            <button type="submit" className="btn btn-primary">
              {"Change Password"}
            </button>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}

ChangePasswordModal.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.any,
  onCancelClick: PropTypes.any,
}

export default withTranslation()(ChangePasswordModal)

import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import { Label } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { AddressFormatter } from "../shared/addressFormatter";
import { getAllIncompletedAssignments, getServiceType } from "store/actions";
import AssignmentDetail from "./assignmentDetailModal";
import { getUserDetail } from "utils/userUtil";
import PaymentModal from "../Payment/paymentModal";
import { PAYMENT_STATUS, USER_TYPE } from "constants/common";
import DataTable from "react-data-table-component";

const IncompleteAssignments = ({
    userRole
}) => {
    const dispatch = useDispatch();

    const [assignmentDetailModal, setAssignmentDetailModal] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [paymentModal, setPaymentModal] = useState(false);
    useEffect(() => {
        dispatch(getAllIncompletedAssignments());
    }, [dispatch]);

    const incompletedAssignmentList = useSelector(state => state.AssignmentReducer.incompleteAssignments);
    let serviceType = useSelector(state => state.ServiceReducer.serviceType);
    let addressList = useSelector(state => state.Profile.address);
    let modeList = useSelector(state => state.CommonReducer.serviceMode);

    useEffect(() => {
        if (!serviceType?.length) {
          dispatch(getServiceType())
        }
      }, [serviceType])

    const getServiceName = (serviceId) => {
        let serviceObj = serviceType.find(item => item.id === serviceId);
        return serviceObj && serviceObj.serviceType ? serviceObj.serviceType : "-";
    }
    const getAddressTag = (addressId) => {
        let addressObj = addressList.find(item => item.id === addressId);
        return addressObj && addressObj.tags ? addressObj.tags : "-";
    }
    const getMode = (modeId) => {
        let mode = modeList.filter(item => item.id === modeId)
        if (mode && mode.length) {
            return mode[0].description;
        }
        else {
            return "NA"
        }
    }

    let columns = [
        {
            id: 1,
            name: "Service",
            selector: (item) => (
                <span>{getServiceName(item.serviceID)}</span>
            ),
            sortable: true,
            reorder: true
        },
        {
            id: 2,
            name: "Date",
            selector: (item) => item.dateTimeFrom,
            format: (item) => (moment(item.dateTimeFrom).isValid() ? moment.utc(item.dateTimeFrom).local().format("DD-MM-YYYY hh:mm A") : '-'),
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "Duration",
            selector: (row) => row.duration,
            sortable: true,
            // right: true,
            reorder: true
        },
        {
            id: 4,
            name: "Address",
            selector: (item) => (
                <AddressFormatter data={item.address} />
            ),
            sortable: true,
            // right: true,
            reorder: true
        },
    ];

    if (userRole && userRole === USER_TYPE.ADMIN) {
        columns = [
            ...columns,
            {
                id: 5,
                name: "UserName",
                selector: (item) => item,
                format: (item) => (item.user.firstName + " " + item.user.lastName),
                sortable: true,
                // right: true,
                reorder: true
            }, {
                id: 6,
                name: "Contact No",
                selector: (item) => item.user.mobileNumber,
                sortable: true,
                // right: true,
                reorder: true
            }, {
                id: 7,
                name: "Booked Date",
                selector: (item) => item.createdOn,
                format: (item) => (item.createdOn
                    ? moment(item.createdOn).isValid()
                        ? moment.utc(item.createdOn).local().format("DD-MM-YYYY hh:mm A")
                        : moment(Number(item.createdOn)).isValid()
                            ? moment(Number(item.createdOn)).local().format("DD-MM-YYYY hh:mm A")
                            : '-'
                    : '-'),
                sortable: true,
                // right: true,
                reorder: true
            }]
    }
    columns = [...columns, {
        id: 8,
        name: "Service Mode",
        selector: (item) => (
            <span>{getMode(item.serviceMode)}</span>
        ),
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 9,
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 10,
        name: "Action",
        selector: (item) => (
            <div>
                <ul className="list-inline mb-0 font-size-20">
                    <li className="list-inline-item">
                        <i className="bx bx-info-circle text-primary" onClick={() => {
                            setSelectedAssignment(item)
                            setAssignmentDetailModal(true)
                        }} ></i>
                    </li>
                    {item.paymentStatus && item.paymentStatus !== PAYMENT_STATUS.PAID && item.paymentStatus !== PAYMENT_STATUS.REFUND_PENDING && (
                        <li className="list-inline-item">
                            <i className="bx bx-money text-success" onClick={() => {
                                setSelectedAssignment(item)
                                setPaymentModal(true)
                            }} ></i>
                        </li>
                    )}
                </ul>
            </div>
        ),
        sortable: true,
        // right: true,
        reorder: true
    }]

    return (
        <React.Fragment>
            {incompletedAssignmentList.length > 0 && (
                <DataTable
                    noHeader
                    // title="Senior Citizen"
                    columns={columns}
                    data={incompletedAssignmentList}
                    defaultSortFieldId={2}
                    defaultSortAsc={false}
                    // sortIcon={<SortIcon />}
                    pagination
                    highlightOnHover
                // selectableRows
                />
            )}
            {incompletedAssignmentList.length === 0 && (
                <Label>No Assignments found</Label>
            )}
            {selectedAssignment && (
                <>
                    <AssignmentDetail
                        isOpen={assignmentDetailModal}
                        data={selectedAssignment}
                        userRole={getUserDetail('type')}
                        closeModal={() => {
                            setAssignmentDetailModal(false)
                        }}
                        onCancelClick={() => {
                            setAssignmentDetailModal(false)
                        }}
                    >
                    </AssignmentDetail>
                    <PaymentModal
                        isOpen={paymentModal}
                        data={selectedAssignment}
                        onCancelClick={() => {
                            setPaymentModal(false)
                        }}
                        closeModal={() => {
                            setPaymentModal(false)
                        }}>

                    </PaymentModal>
                </>

            )}
        </React.Fragment>
    )
}

IncompleteAssignments.propTypes = {
    userRole: PropTypes.any
}

export default IncompleteAssignments

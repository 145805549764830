import React, { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { FormGroup, Modal, Row, Col, Label } from "reactstrap"
import PropTypes from "prop-types"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector } from "react-redux"
import { PAYMENT_MODE } from "constants/common"

const PricingDetailModal = ({
  t,
  isOpen,
  data,
  closeModal,
  makePaymentAndBookService,
  onCancelClick,
}) => {
  let pricingDetail = useSelector(state => state.ServiceReducer.pricingDetail)
  const walletBalance = useSelector(state => state.PaymentReducer.walletBalance)
  console.log("Debug :::> pricingDetail", pricingDetail)
  const { servicePrice } = data
  const handleValidSubmit = (e, v) => {
    e.preventDefault()
    console.log("Debug :::> pricingDetail", pricingDetail)
    makePaymentAndBookService(data, pricingDetail)
  }

  const formatAmount = amount => {
    return parseFloat(amount / 100).toFixed(2)
  }

  return (
    <Modal isOpen={isOpen} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t("Service Price Estimates")}</h5>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <AvForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v)
        }}
      >
        <div className="modal-body">
          <Row>
            <Col
              xs={6}
              sm={6}
              className="mb-4"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Label> Service Estimates:</Label>
            </Col>
            <Col xs={6} sm={6} className="mb-4">
              <Label>
                {" "}
                ₹{" "}
                {servicePrice && formatAmount(pricingDetail?.serviceCost || 0)}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col
              xs={6}
              sm={6}
              className="mb-4"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Label> Discounts:</Label>
            </Col>
            <Col xs={6} sm={6} className="mb-4">
              <Label>
                - ₹{" "}
                {(servicePrice &&
                  formatAmount(pricingDetail?.totalDiscount || 0)) ||
                  0}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col
              xs={6}
              sm={6}
              className="mb-4"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Label> Total Amount:</Label>
            </Col>
            <Col xs={6} sm={6} className="mb-4">
              <Label>
                {" "}
                ₹{" "}
                {servicePrice && formatAmount(pricingDetail?.finalAmount || 0)}
              </Label>
            </Col>
          </Row>
          {walletBalance && walletBalance !== null && (
            <Row>
              <Col
                xs={6}
                sm={6}
                className="mb-4"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Label> Wallet Balance:</Label>
              </Col>
              <Col xs={6} sm={6} className="mb-4">
                <Label> ₹ {walletBalance.amount / 100}</Label>
              </Col>
            </Row>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              onCancelClick()
            }}
          >
            {t("Close")}
          </button>
          <button type="submit" className="btn btn-primary">
            {data &&
            data.paymentMode &&
            data.paymentMode === PAYMENT_MODE.ONLINE
              ? t("Make Payment & Book Service")
              : t("Book Service")}
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

PricingDetailModal.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.any,
  data: PropTypes.any,
  closeModal: PropTypes.any,
  makePaymentAndBookService: PropTypes.any,
  onCancelClick: PropTypes.any,
}

export default withTranslation()(PricingDetailModal)

import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import {
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  Button,
} from "reactstrap"

const Address = ({
  t,
  data,
  key,
  addressSelected,
  deleteAddressDetail,
  fullWidth,
}) => {
  let { address, landmark, city, state, pinCode, tags } = useMemo(
    () => data || {},
    [data]
  )

  return (
    <Col lg={fullWidth ? 12 : 4} sm={fullWidth ? 12 : 6} xs={12} key={key}>
      <Card outline color="primary" className="border">
        <CardHeader className="bg-transparent">
          <h5 className="my-0 text-primary">
            <i className="mdi mdi-bullseye-arrow me-3" />
            {tags}
          </h5>
        </CardHeader>
        <CardBody>
          <CardTitle className="mt-0">{address}</CardTitle>
          <CardText>
            {landmark},
            <br />
            {city}, - {pinCode},
            <br />
            {state}
          </CardText>
          <div className="btn-group btn-group-example mb-3" role="group">
            <button
              type="button"
              className="btn btn-outline-primary w-sm"
              onClick={() => {
                addressSelected(data)
              }}
            >
              Edit
            </button>
            <button
              type="button"
              className="btn btn-outline-danger w-sm"
              onClick={() => {
                deleteAddressDetail(data)
              }}
            >
              Delete
            </button>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

Address.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  key: PropTypes.any,
  addressSelected: PropTypes.func,
  deleteAddressDetail: PropTypes.func,
  fullWidth: PropTypes.bool,
}

export default withTranslation()(Address)

import React, { useEffect } from "react";

import {
    Col,
    Form,
    Input,
    Label,
    Row
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { propTypes } from "react-bootstrap-editable";
import { updateEmotionalQuotient } from "store/actions";

const RadioBtn = (props) => {
    function radioChnaged(e) {
        props.change;
    }
    return (
        <div className={`form-check form-radio-${props.color} mb-3`}>
            <input
                type="radio"
                value={props.value}
                name={props.name}
                className="form-check-input"
                onChange={props.change}
                checked={props.checked}
            />
        </div>
    );
}
RadioBtn.propTypes = {
    color: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    change: PropTypes.any,
    checked: PropTypes.bool
}

const EQTest = ({
    data
}) => {
    const dispatch = useDispatch();

    const eqQuesions = useSelector(state => state.CommonReducer.eqQuestions);

    function getCheckedValue(id, option) {
        let eq = data.filter(item => item.eqId === id);
        if (eq && eq.length > 0 && eq[0].value === option) {
            return true;
        }
        else {
            if (eq.length === 0 && option === 5) {
                return true;
            }
            return false;
        }
    }

    function testChange(e) {
        dispatch(updateEmotionalQuotient({ field: e.target.name, value: parseInt(e.target.value) }))
    }
    return (<>
        <div className="table-rep-plugin">
            <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
            >
                <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                >
                    <Thead>
                        <Tr>
                            <Th>Question</Th>
                            <Th data-priority="1">Not at All</Th>
                            <Th data-priority="3">Rarely</Th>
                            <Th data-priority="1">Often</Th>
                            <Th data-priority="3">Sometimes</Th>
                            <Th data-priority="3">Very Often</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {eqQuesions.map((el, index) => (
                            <Tr key={index} onChange={(e) => { testChange(e) }}>
                                <Th>
                                    {el.description}
                                </Th>
                                <Td className="center">
                                    <RadioBtn color="danger" name={el.id} value={1} checked={getCheckedValue(el.id, 1)} />
                                </Td>
                                <Td><RadioBtn color="warning" name={el.id} value={2} checked={getCheckedValue(el.id, 2)} /></Td>
                                <Td><RadioBtn color="info" name={el.id} value={3} checked={getCheckedValue(el.id, 3)} /></Td>
                                <Td><RadioBtn color="primary" name={el.id} value={4} checked={getCheckedValue(el.id, 4)} /></Td>
                                <Td><RadioBtn color="success" name={el.id} value={5} checked={getCheckedValue(el.id, 5)} /></Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </div>
        </div>
    </>)
}

EQTest.propTypes = {
    data: PropTypes.any
}

export default EQTest;
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from 'react-i18next';
import { FormGroup, Modal, Row, Col, Label, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useDispatch } from "react-redux";
import { rateService } from "store/actions";

const RateServiceModal = ({
    t,
    isOpen,
    data,
    onCancelClick
}) => {

    const dispatch = useDispatch()
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState(null);

    const flipStar = (e) => {
        setRating(e.target.id - 1);
        let i = 0;
        while (i < e.target.id) {
            document.getElementsByClassName('rating-star')[i].classList.remove('bx-star');
            document.getElementsByClassName('rating-star')[i].classList.add('bxs-star');
            i++;
        }
        while (i < 5) {
            document.getElementsByClassName('rating-star')[i].classList.remove('bxs-star');
            document.getElementsByClassName('rating-star')[i].classList.add('bx-star');
            i++;
        }
    }

    const handleValidSubmit = (e, v) => {
        e.preventDefault();
        dispatch(rateService({ rating: rating.toString(), serviceComment: comment, serviceId: data.id }));
        onCancelClick()
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                        {t('Rate Service')}
                    </h5>
                </div>
                <AvForm
                    onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                    }}>
                    <div className="modal-body">
                        <Row>
                            <Col xs={12} sm={12} className="mb-4" onClick={(e) => { flipStar(e) }}>
                                <Label>Rating:</Label>&nbsp;
                                <br />
                                <i className="bx bx-star me-2 rating-star" id="1"></i>
                                <i className="bx bx-star me-2 rating-star" id="2"></i>
                                <i className="bx bx-star me-2 rating-star" id="3"></i>
                                <i className="bx bx-star me-2 rating-star" id="4"></i>
                                <i className="bx bx-star me-2 rating-star" id="5"></i>
                            </Col>
                            <Col xs={12} sm={12} className="mb-4">
                                <AvForm>
                                    <FormGroup>
                                        <AvField
                                            id="serviceComment"
                                            name="serviceComment"
                                            label={t("Comment")}
                                            placeholder={t("Comment about service")}
                                            type="text"
                                            onChange={(e) => { setComment(e.target.value) }}
                                        />
                                    </FormGroup>
                                </AvForm>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => { onCancelClick() }}>
                            {t("Cancel")}
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            {t("Submit")}
                        </button>
                    </div>
                </AvForm>
            </Modal>
        </>

    )
}

RateServiceModal.propTypes = {
    t: PropTypes.any,
    isOpen: PropTypes.bool,
    data: PropTypes.any,
    onCancelClick: PropTypes.any
}

export default withTranslation()(RateServiceModal);
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    Container,
    Row,
    CardTitle,
    Nav,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
} from "reactstrap";
import classnames from "classnames"
import MetaTags from "react-meta-tags";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ServiceType from '../../components/Happy60/ServiceType'
import ServiceOffer from '../../components/Happy60/ServiceOffer'


const ServiceOfferPage = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Offers | Happy60Plus</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Home" breadcrumbItem="Service Offers" />
                    <ServiceOffer />

                </Container>
            </div >
        </React.Fragment >
    );
}

export default ServiceOfferPage;
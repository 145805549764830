import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getProfileDetail, getWalletBalanceByUserId } from '../store/actions'
import { getHelpBuddyDetail, getSeniorCitizenDetail, profileDetail } from "store/auth/profile/actions"
import { USER_TYPE } from "constants/common"
import { getUserDetail } from "utils/userUtil"
import { extractUrlParam } from 'utils/urlExtractor';
import VersionCacheControl from "../VersionCacheControl"

const Authmiddleware = ({
    component: Component,
    layout: Layout,
    isAuthProtected,
    ...rest
}) => {
    const dispatch = useDispatch();

    // let userDetail = useSelector(state => state.Profile.userProfile);
    // const queryParam = extractUrlParam();

    // useEffect(() => {
    //   console.log(userDetail);
    //   if (!userDetail) {
    //     const urlParam = extractUrlParam();
    //     dispatch(getProfileDetail(urlParam.uid));
    //   } else {
    //     console.log(window.location);
    //   }
    // }, [userDetail])

    return (
        <Route
            {...rest}
            render={props => {
                if (isAuthProtected && !getUserDetail()) {
                    if (extractUrlParam().uid) {
                        dispatch(getProfileDetail(extractUrlParam().uid));
                        setTimeout(() => {
                            return (
                                <Redirect
                                    to={{ pathname: props.location.pathname, state: { from: props.location } }}
                                />
                            )
                        }, 1500)

                    }
                    else {
                        return (
                            <Redirect
                                to={{ pathname: "/login", state: { from: props.location } }}
                            />
                        )
                    }
                }
                else {
                    if (props.location.pathname.indexOf('logout') === -1) {
                        if (getUserDetail() && JSON.parse(getUserDetail()).id) {
                            dispatch(profileDetail(JSON.parse(getUserDetail())))
                            // dispatch(getProfileDetail(JSON.parse(getUserDetail()).id))
                            if (JSON.parse(getUserDetail()).type === USER_TYPE.SERVICE_PROVIDER) {
                                dispatch(getHelpBuddyDetail(JSON.parse(getUserDetail()).id));
                            }
                            else if (JSON.parse(getUserDetail()).type === USER_TYPE.SENIOR_CITIZEN) {
                                dispatch(getSeniorCitizenDetail(JSON.parse(getUserDetail()).id));
                                dispatch(getWalletBalanceByUserId());
                            }
                            <Redirect
                                to={{ pathname: "/onboard", state: { from: props.location } }}
                            />
                        }
                    }
                }
                return (
                    <Layout>
                        <VersionCacheControl>
                            {({ fetchMetaLoading, isLatestVersion, refreshCacheAndReload }) => {
                                if (fetchMetaLoading) return null;
                                if (!fetchMetaLoading && !isLatestVersion) {
                                    refreshCacheAndReload();
                                }

                                return <Component {...props} />
                            }}
                        </VersionCacheControl>
                    </Layout>
                )
            }}
        />
    )
}

Authmiddleware.propTypes = {
    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any,
}

export default Authmiddleware;

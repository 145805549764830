import {
  DOWNLOAD_USER_DOCUMENT_RESP,
  UPLOAD_USER_DOCUMENT_RESP,
  VALUE_HELPS,
} from "./actionTypes"

const inititalState = {
  serviceAddOn: ["TRANSPORTATION", "NONE"],
  serviceType: [
    {
      id: "60001",
      label: "Accompany and Assist",
      description: "Accompany and Assist",
    },
    {
      id: "60002",
      label: "Delivery and Daily Errands",
      description: "Delivery and Daily Errands",
    },
    {
      id: "60003",
      label: "Transportation Asisstance with Buddy",
      description: "Transportation Asisstance with Buddy",
    },
    {
      id: "60004",
      label: "Technology Enablement",
      description: "Technology Enablement",
    },
    {
      id: "60005",
      label: "Companionship",
      description: "Companionship",
    },
    {
      id: "60006",
      label: "Medicine Delivery",
      description: "Medicine Delivery",
    },
  ],
  gender: [
    {
      id: "20001",
      label: "Male",
      description: "Male",
    },
    {
      id: "20002",
      label: "Female",
      description: "Female",
    },
    {
      id: "20003",
      label: "Prefer not to answer",
      description: "Prefer not to answer",
    },
  ],
  user: [
    {
      id: "10001",
      label: "Administrator",
      description: "Administrator",
    },
    {
      id: "10002",
      label: "Help Buddy",
      description: "Help Buddy",
    },
    {
      id: "10003",
      label: "Senior Citizen",
      description: "Senior Citizen",
    },
    {
      id: "10004",
      label: "Partner Administrator",
      description: "Partner Administrator",
    },
    {
      id: "10005",
      label: "Health Buddy",
      description: "Health Buddy",
    },
  ],
  serviceMode: [
    {
      id: "ONLINE",
      label: "SERVICE_MODE",
      description: "Online",
    },
    {
      id: "OFFLINE",
      label: "SERVICE_MODE",
      description: "Offline",
    },
    {
      id: "BOTH",
      label: "SERVICE_MODE",
      description: "Both",
    },
  ],
  vehicleType: [
    {
      id: "30001",
      label: "Two Wheeler",
      description: "Two Wheeler",
    },
    {
      id: "30002",
      label: "Four Wheeler",
      description: "Four Wheeler",
    },
    {
      id: "30003",
      label: "None",
      description: "None",
    },
  ],
  verificationStatus: [
    "PENDING",
    "APPROVED",
    "AADHAR_VERIFICATION_PENDING",
    "DL_VERIFICATION_PENDING",
    "COMPLETED",
  ],
  serviceStatus: [
    {
      id: "50001",
      label: "Created",
      description: "Created",
    },
    {
      id: "50002",
      label: "Partner Assigned",
      description: "Partner Assigned",
    },
    {
      id: "50003",
      label: "Assigned",
      description: "Assigned",
    },
    {
      id: "50004",
      label: "Started",
      description: "Started",
    },
    {
      id: "50005",
      label: "Completed",
      description: "Completed",
    },
    {
      id: "50008",
      label: "Cancelled",
      description: "Cancelled",
    },
    {
      id: "50009",
      label: "Incomplete",
      description: "Incomplete",
    },
  ],
  eqQuestions: [
    {
      id: "80001",
      label: "EQ_QUESTION",
      description: "I can recognize my emotions as i experience them.",
    },
    {
      id: "80002",
      label: "EQ_QUESTION",
      description: "I lose my temper when i feel frustrated.",
    },
    {
      id: "80003",
      label: "EQ_QUESTION",
      description: "People have told me that i'm a good listener.",
    },
    {
      id: "80004",
      label: "EQ_QUESTION",
      description:
        "I know how to calm myself down when i feel anxious or upset.",
    },
    {
      id: "80005",
      label: "EQ_QUESTION",
      description: "I enjoy organizing groups.",
    },
    {
      id: "80006",
      label: "EQ_QUESTION",
      description: "I find it hard to focus on something over the long term.",
    },
    {
      id: "80007",
      label: "EQ_QUESTION",
      description:
        "I find it difficult to move on when i feel frustrated or unhappy.",
    },
    {
      id: "80008",
      label: "EQ_QUESTION",
      description: "I know my strengths and weaknesses.",
    },
    {
      id: "80009",
      label: "EQ_QUESTION",
      description: "I avoid conflict and negotiations.",
    },
    {
      id: "80010",
      label: "EQ_QUESTION",
      description: "I feel that i don't enjoy my work.",
    },
    {
      id: "80011",
      label: "EQ_QUESTION",
      description: "I set long-term goals, and review my progress regularly.",
    },
    {
      id: "80012",
      label: "EQ_QUESTION",
      description: "I find it difficult to read other people's emotions.",
    },
    {
      id: "80013",
      label: "EQ_QUESTION",
      description: "I struggle to build rapport with others.",
    },
    {
      id: "80014",
      label: "EQ_QUESTION",
      description: "I use active listening skill when people speak to me.",
    },
  ],
  languages: ["ENGLISH", "HINDI", "KANNADA", "TAMIL", "TELUGU", "MALAYALAM"],
  uploadDocResp: null,
  downloadDocResp: null,
}

const CommonReducer = (state = inititalState, action) => {
  switch (action.type) {
    case VALUE_HELPS:
      state = {
        ...state,
        serviceAddOn: action.payload.SERVICE_ADD_ON,
        serviceType: action.payload.SERVICE_TYPE,
        gender: action.payload.GENDER,
        user: action.payload.USER,
        vehicleType: action.payload.VEHILCE_TYPE,
        verificationStatus: action.payload.VERITIFCATION_STATUS,
        serviceStatus: action.payload.SERVICE_STATUS,
        eqQuestions: action.payload.EQ_TYPE,
        serviceMode: action.payload.SERVICE_MODE,
      }
      break
    case UPLOAD_USER_DOCUMENT_RESP:
      state = {
        ...state,
        uploadDocResp: action.payload,
      }
      break
    case DOWNLOAD_USER_DOCUMENT_RESP:
      state = {
        ...state,
        downloadDocResp: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default CommonReducer

import React, { useState } from "react";
import {
    Container
} from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumb from "../../components/Common/Breadcrumb";
import UserMgmt from "components/Happy60/UserMgmt";

const UserPage = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Users | Happy60Plus</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Home" breadcrumbItem="Users" />
                    <UserMgmt></UserMgmt>
                </Container>
            </div >
        </React.Fragment >
    );
}

export default UserPage;
import React, { useState } from "react";
import {
    Container
} from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Booking from '../../components/Happy60/bookings'
import HealthCareBooking from "../../components/Happy60/healthCareBooking";

const BookingPage = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Bookings | Happy60Plus</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Home" breadcrumbItem="Bookings" />

                    <Booking></Booking>
                    <HealthCareBooking />

                </Container>
            </div >
        </React.Fragment >
    );
}

export default BookingPage;
import { ALL_TRIP_LIST, DONE_PROCESS, UPDATE_TRIP } from "./actionTypes"

const INIT_STATE = {
  trips: [],
  page: {},
  loader: false,
}

const Trip = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_TRIP_LIST:
      return {
        ...state,
        trips: action.payload.data,
        page: action.payload.page,
        loader: false,
      }
    case UPDATE_TRIP:
      return {
        ...state,
        loader: true,
      }
    case DONE_PROCESS:
      return {
        ...state,
        loader: false,
      }

    default:
      return state
  }
}

export default Trip

/** Get Dashboard Chart data */

export const GET_TOTAL_SERVICE_BOOKING_DATA = "GET_TOTAL_SERVICE_BOOKING_DATA";
export const TOTAL_SERVICE_BOOKING_DATA = "TOTAL_SERVICE_BOOKING_DATA";

export const GET_TOTAL_REGISTERED_USER = "GET_TOTAL_REGISTERED_USER";
export const TOTAL_REGISTERED_USER = "TOTAL_REGISTERED_USER";

export const GET_USER_REGISTRATION_DATA = "GET_USER_REGISTRATION_DATA";
export const USER_REGISTRATION_DATA = "USER_REGISTRATION_DATA";

export const GET_REVENUE_DATA = "GET_REVENUE_DATA";
export const REVENUE_DATA = "REVENUE_DATA";

export const GET_SERVICE_BOOKING_BY_TYPE_DATA = "GET_SERVICE_BOOKING_BY_TYPE_DATA";
export const SERVICE_BOOKING_BY_TYPE_DATA = "SERVICE_BOOKING_BY_TYPE_DATA";

export const GET_GENERAL_STATS = "GET_GENERAL_STATS";
export const GENERAL_STATS = "GENERAL_STATS";
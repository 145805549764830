import React, { useEffect, useState } from "react"
import { Row, Col, Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import Address from "./Address/Address"
import AddEditAddress from "./Address/AddEditAddress"
import { getAddressDetail, updateAddressDetail } from "../../store/actions"
import ConfirmModal from "../Common/ConfirmModal"
import { deleteAddresses } from "../../store/actions"

const AddressMgmt = ({ userProfile = null }) => {
  const [showAddressModal, setAddressModelVisibility] = useState(false)
  const [currentAddressObject, setCurrentAddressObject] = useState()
  const [showConfirmModel, setShowConfirmModel] = useState(false)

  const dispatch = useDispatch()
  let addressObjectfromState
  let userDetailfromState

  if (userProfile) {
    addressObjectfromState = userProfile.addresses
    userDetailfromState = userProfile
  } else {
    addressObjectfromState = useSelector(state => state.Profile.address)
    userDetailfromState = useSelector(state => state.Profile.userProfile)
  }
  const addUpdateAddressDetail = data => {
    dispatch(updateAddressDetail({ ...data, userID: userDetailfromState.id }))
    setAddressModelVisibility(false)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!addressObjectfromState?.length) {
        dispatch(getAddressDetail(userDetailfromState.id))
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <React.Fragment>
      <Container>
        <Row className="justify-content-center">
          <Col md={12} lg={12} xl={12}>
            {userProfile && (
              <>
                <h5 className="text-primary">Manage Addresses</h5>
                <hr />
              </>
            )}
            <Row>
              {addressObjectfromState?.map((item, index) => {
                return (
                  <Address
                    data={item}
                    key={"address_detail_" + index + item.userID}
                    addressSelected={addressDetail => {
                      setCurrentAddressObject(addressDetail)
                      setAddressModelVisibility(true)
                    }}
                    deleteAddressDetail={addressDetail => {
                      setCurrentAddressObject(addressDetail)
                      setShowConfirmModel(true)
                    }}
                    fullWidth={userProfile !== null}
                  />
                )
              })}
            </Row>
            <br />
            <AddEditAddress
              isOpen={showAddressModal}
              currentAddressObject={currentAddressObject}
              updateData={setCurrentAddressObject}
              onCancelClick={() => {
                setAddressModelVisibility(false)
              }}
              onSubmitClick={addressDetail => {
                addUpdateAddressDetail(addressDetail)
              }}
              handleSubmit={userProfile !== null}
            />
            <ConfirmModal
              isOpen={showConfirmModel}
              titleText={"Are you sure you want to delete this address detail"}
              onYesClick={() => {
                dispatch(deleteAddresses(currentAddressObject))
                setShowConfirmModel(false)
              }}
              onNoClick={() => {
                setShowConfirmModel(false)
              }}
            />
            {userProfile && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setAddressModelVisibility(true)
                  setCurrentAddressObject()
                }}
              >
                Add New
              </button>
            )}
            {!userProfile && (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  setAddressModelVisibility(true)
                  setCurrentAddressObject()
                }}
              >
                Add New
              </button>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

AddressMgmt.propTypes = {
  userProfile: PropTypes.any,
}

export default AddressMgmt

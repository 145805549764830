import axios from "axios"
// import cloneDeep from 'lodash/cloneDeep';

// import uuid from 'react-uuid';

import { getUrl } from "./Config"
import { getUserDetail } from "./userUtil"
import { getServerDetail } from "./serverDetailUtil"
import {
  ENV,
  PAYMENT_MODE,
  PERIOD,
  RZP_API_KEY_PROD,
  RZP_API_KEY_TEST,
} from "constants/common"
import happy60plus from "assets/images/happy60plus/logo.png"
// import { setup } from 'axios-cache-adapter'
// import moment from 'moment'
// import happy60plus from 'assets/images/happy60plus/logo.png'
// import { USER_TYPE, RZP_API_KEY_TEST, RZP_API_KEY_PROD, ENV, PAYMENT_MODE, PERIOD } from 'constants/common';
// import { getUserDetail } from './userUtil';
// import { sortServicesByDate } from './sortUtil';

// import alertCall from 'components/Common/CustomAlert';
import store from "../store/index"
import { paymentStatus } from "store/actions"
const dispatch = store.dispatch

import {
  requestInterceptor,
  successInterceptor,
  errorInterceptor,
} from "./axiosInterceptors"

// const dispatch = store.dispatch

const CONFIG = getUrl()
const CORS_WORKAROUND = "" //'https://cors-anywhere.herokuapp.com/';

const axiosInstance = axios.create({
  baseURL: CONFIG?.URL?.BASE_URL,
  timeout: 10 * 60 * 1000,
  maxBodyLength: Infinity,
})
axiosInstance.interceptors.request.use(requestInterceptor)
axiosInstance.interceptors.response.use(successInterceptor, errorInterceptor)

///////////////////////////////////
const getHeaderData = (customOptionsObj = {}) => {
  const customOptions = customOptionsObj || {}
  const defaultHeader = { "Access-Control-Allow-Origin": "*" }
  const token = localStorage.getItem("token")
  if (token) {
    defaultHeader["Authorization"] = `Bearer ${token}`
  }

  return {
    ...customOptions,
    headers: {
      ...defaultHeader,
      ...(customOptions.headers || {}),
    },
  }
}

export const get = (url, customOptionsObj) => {
  const config = getHeaderData(customOptionsObj)
  if (!url) {
    console.error("URL is a mandatory field to make an API call")
    return
  }
  return new Promise((resolve, reject) => {
    axiosInstance.get(url, config).then(
      data => {
        resolve(data)
      },
      e => {
        reject(e)
      }
    )
  })
}

export const post = (url, param, customOptionsObj) => {
  const config = getHeaderData(customOptionsObj)
  if (!url) {
    console.error("URL is a mandatory field to make an API call")
    return
  }

  return new Promise((resolve, reject) => {
    axiosInstance.post(url, param, config).then(
      resp => {
        resolve(resp)
      },
      err => {
        console.log(err)
        reject(err)
      }
    )
  })
}

export const put = (url, param, customOptionsObj) => {
  const config = getHeaderData(customOptionsObj)

  if (!url) {
    console.error("URL is a mandatory field to make an API call")
    return
  }

  return new Promise((resolve, reject) => {
    axiosInstance.put(url, param, config).then(
      resp => {
        resolve(resp)
      },
      e => {
        console.error(e)
        reject(e)
      }
    )
  })
}

export const deleteApi = (url, param, customOptionsObj) => {
  const config = getHeaderData(customOptionsObj)

  if (!url) {
    console.error("URL is a mandatory field to make an API call")
    return
  }

  return new Promise((resolve, reject) => {
    axiosInstance.delete(url, { ...config, data: param }).then(
      resp => {
        resolve(resp)
      },
      e => {
        console.error(e)
        reject(e)
      }
    )
  })
}
///////////////////////////////////

/** USER */
export const submitUserDetail = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.USERS.POST + "?isWhatsapp=false"
  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        if (resp.data.status) {
          console.log(
            "APP-LOG: File: Apiv2.js Line: #154::> resp.data.data",
            resp.data.data
          )
          const token = resp.data.jsonData
          localStorage.setItem("token", token)

          res({
            status: "success",
            data: { ...resp.data.data, token },
          })
        } else if (resp.data.errorMessage) {
          rej(resp.data.errorMessage)
        }
      },
      e => {
        console.log(e)
        rej("Something want wrong, Please try again after sometime.")
      }
    )
  })
}

export const userLogin = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.LOGIN.POST
  return new Promise((res, rej) => {
    post(url, data.user).then(
      async resp => {
        if (resp.data.status) {
          const token = resp.data.jsonData
          localStorage.setItem("token", token)

          res({
            status: "success",
            data: resp.data.data,
          })
        } else {
          res({
            status: "failure",
            data: resp.data.errorMessage,
          })
        }
      },
      e => {
        if (e.response?.data?.errorMessage) {
          console.log("DEBUG::>", e.response?.data?.errorMessage)
          res({
            status: "failure",
            data: e.response?.data?.errorMessage,
          })
        } else {
          rej(e)
        }
      }
    )
  })
}
export const loginWithToken = data => {
  console.log("Debug :::> data", data)
  localStorage.setItem("token", data)

  let url = CORS_WORKAROUND + CONFIG.URL.LOGIN.LOGIN_WITH_TOKEN
  return new Promise((res, rej) => {
    get(url, { headers: { Authorization: `Bearer ${data}` } }).then(
      async resp => {
        if (resp.data.status) {
          console.log("Debug :::> resp.data>>", resp.data)

          res({
            status: "success",
            data: { ...resp.data.data, token: data },
          })
        } else {
          res({
            status: "failure",
            data: resp.data.errorMessage,
          })
        }
      },
      e => {
        if (e.response?.data?.errorMessage) {
          console.log("DEBUG::>", e.response?.data?.errorMessage)
          res({
            status: "failure",
            data: e.response?.data?.errorMessage,
          })
        } else {
          rej(e)
        }
      }
    )
  })
}

const searchUserByMobile = data => {
  let urlSearch = CORS_WORKAROUND + CONFIG.URL.USERS.SEARCH
  return new Promise((res, rej) => {
    post(urlSearch, data).then(
      resp => {
        const token = localStorage.getItem("token")

        if (resp.data.status) {
          res({
            status: "success",
            data: resp.data.data.length === 1 ? resp.data.data[0] : {},
          })
        } else {
          res({
            status: "failure",
            data: resp.data.errorMessage,
          })
        }
      },
      e => {
        console.log("Debug ::> err", e)
        res({
          status: "failure",
          data: e,
        })
      }
    )
  })
}

export const getUser = userId => {
  let url = CORS_WORKAROUND + CONFIG.URL.USERS.GET + userId
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        res(resp.data)
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getServiceType = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.SERVICE_TYPE.GET
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        console.log("Debug ::> resp.data SERVICE", resp.data)
        if (resp.data.status) {
          res(
            resp.data.data
              .map(item => {
                return {
                  ...item,
                  fieldOrder: item.fieldOrder ? item.fieldOrder : 100,
                }
              })
              .sort((a, b) => a.fieldOrder - b.fieldOrder)
          )
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const generateOtp = ({ userName }) => {
  let url =
    CORS_WORKAROUND + CONFIG.URL.LOGIN.GENERATE_OTP + "?username=" + userName
  return new Promise((res, rej) => {
    get(url).then(resp => {
      res(resp)
    }),
      e => {
        rej(e)
      }
  })
}

export const verifyMobileNumber = ({ countryCode, mobileNumber: mNumber }) => {
  let mobileNumber = mNumber
  let url =
    CORS_WORKAROUND + CONFIG.URL.LOGIN.VERIFY_MOBILE_NUMBER + mobileNumber
  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp.data.status) {
        res(resp.data)
      } else {
        rej(resp.data)
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const verifyOtp = ({ username, otp }) => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.LOGIN.VERIFY_OTP +
    "?username=" +
    username +
    "&otp=" +
    otp
  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp.data.status) {
        if (resp.data.status) {
          const token = resp.data.jsonData
          localStorage.setItem("token", token)

          res({
            status: "success",
            data: resp.data.data,
          })
        } else {
          res({
            status: "failure",
            data: resp.data.errorMessage,
          })
        }
      } else {
        res({
          status: "failure",
          data: resp.data,
        })
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const resetPassword = ({ username, password }) => {
  let url = CORS_WORKAROUND + CONFIG.URL.PASSWORD.RESET_PASSWORD
  return new Promise((res, rej) => {
    put(url, {
      oldPassword: password,
      newPassword: password,
      username: username,
    }).then(
      resp => {
        if (resp && resp.data && resp.data.status) {
          res({
            status: "success",
            message:
              "Your password has been changed successfully. You can login with your new credentials",
          })
        } else {
          res({
            status: "failure",
            message: "Some issue occured while resetting your password.",
          })
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const changePassword = password => {
  let url = CORS_WORKAROUND + CONFIG.URL.PASSWORD.CHANGE_PASSWORD
  console.log(
    "APP-LOG: File: Apiv2.js Line: #420::> ",
    JSON.parse(getUserDetail()).mobileNumber
  )
  return new Promise((res, rej) => {
    put(url, {
      ...password,
      username: JSON.parse(getUserDetail()).mobileNumber,
    }).then(
      resp => {
        res({
          status: resp.data.status,
          message: resp.data.status
            ? resp.data.jsonData
            : resp.data.errorMessage,
        })
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getAllUser = data => {
  // let url = CORS_WORKAROUND + CONFIG.URL.USERS.GET + `search/userList?sort=firstName&type=${data.type}&page=${data.page - 1}&size=${data.size}`;
  let url = CORS_WORKAROUND + CONFIG.URL.USERS.GET + `/user-type/${data.type}`
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp && resp.data && resp.data.status) {
          res({
            users: resp.data.data,
            page: resp.data.page || {},
          })
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const searchUser = search => {
  let url = CORS_WORKAROUND + CONFIG.URL.USERS.SEARCH
  const searchQuery = {
    [search.searchType]: search.data.search,
  }
  return new Promise((res, rej) => {
    post(url, searchQuery).then(
      resp => {
        if (resp && resp.data.status) {
          res(resp.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const searchEnquiry = search => {
  let url
  if (search.searchType == null || search.searchType == "ALL") {
    url = CORS_WORKAROUND + CONFIG.URL.ENQUIRY.GET_ALL
  } else {
    url =
      CORS_WORKAROUND +
      CONFIG.URL.ENQUIRY.SEARCH_ENQUIRY +
      `${search.searchType}`
  }

  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (res && resp.data && resp.data.status) {
          res({ data: resp.data.data, page: resp.data.page || {} })
        } else {
          res({ data: undefined, page: 1 || {} })
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getHomePageServices = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.HOME_PAGE_SERVICES.GET_ALL
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (res && resp.data && resp.data.status) res({ data: resp.data.data })
        else {
          rej(resp.data.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const deleteHomePageServices = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.HOME_PAGE_SERVICES.UPDATE
  url = url.replace("$homePageServicesId", data)
  return new Promise((res, rej) => {
    deleteApi(url).then(
      resp => {
        if (res && resp.data && resp.data.status) res({ data: resp.data.data })
        else {
          rej(resp.data.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const updateHomePageService = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.HOME_PAGE_SERVICES.UPDATE
  url = url.replace("$homePageServicesId", data.id)
  return new Promise((res, rej) => {
    put(url, data).then(
      resp => {
        if (res && resp.data && resp.data.status) res({ data: resp.data.data })
        else {
          rej(resp.data.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const createHomePageService = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.HOME_PAGE_SERVICES.CREATE
  return new Promise((res, rej) => {
    delete data.id
    post(url, data).then(
      resp => {
        if (res && resp.data && resp.data.status) res({ data: resp.data.data })
        else {
          rej(resp.data.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getEnquiryList = pageDetail => {
  // let url =
  //   CORS_WORKAROUND +
  //   CONFIG.URL.ENQUIRY.GET_ALL +
  //   `&page=${pageDetail.page - 1}&size=${pageDetail.perPage}`
  let url = CORS_WORKAROUND + CONFIG.URL.ENQUIRY.GET_ALL
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (res && resp.data && resp.data.status)
          res({ data: resp.data.data, page: resp.data.page || {} })
        else {
          rej(resp.data.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const changeEnquiryStatus = enquiry => {
  let url = CORS_WORKAROUND + CONFIG.URL.ENQUIRY.UPDATE_ENQUIRY
  url = url.replace("$enquiryId", enquiry.id)
  return new Promise((res, rej) => {
    put(url, enquiry).then(
      resp => {
        if (res && resp.data && resp.data.status)
          res({
            data: resp.data.data,
            page: resp.data.page || {},
          })
        else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getTripList = pageDetail => {
  // let url =
  //   CORS_WORKAROUND +
  //   CONFIG.URL.TRIP.GET_ALL +
  //   `?page=${pageDetail.page - 1}&size=${pageDetail.perPage}`
  let url = CORS_WORKAROUND + CONFIG.URL.TRIP.GET_ALL
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (res && resp.data && resp.data.status)
          res({
            data: resp.data.data,
            page: resp.data.page || {},
          })
        else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getAllTripsAndToursData = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.TRIPS_AND_TOURS.GET_ACTIVE
  return new Promise((res, rej) => {
    get(url).then(
      async resp => {
        console.log(resp)
        const { status, data, errorMessage } = resp.data || {}
        if (status) {
          res({
            status: "success",
            data: data,
          })
        } else {
          res({
            status: "failure",
            data: errorMessage,
          })
        }
      },
      e => {
        if (e.response?.data?.errorMessage) {
          console.log("DEBUG::>", e.response?.data?.errorMessage)
          res({
            status: "failure",
            data: e.response?.data?.errorMessage,
          })
        } else {
          rej(e)
        }
      }
    )
  })
}

export const getTripsAndToursDataById = id => {
  let url = CORS_WORKAROUND + CONFIG.URL.TRIPS_AND_TOURS.GET_BY_ID
  url = url.replace("$tripDetailsId", id)
  return new Promise((res, rej) => {
    get(url).then(
      async resp => {
        console.log(resp)
        res(
          resp.data || {
            status: false,
            errorMessage: "Something Went wrong, Please Try again!",
          }
        )
      },
      e => {
        if (e.response?.data?.errorMessage) {
          console.log("DEBUG::>", e.response?.data?.errorMessage)
          res({
            status: "failure",
            errorMessage:
              e.response?.data?.errorMessage ||
              "Something Went wrong, Please Try again!",
          })
        } else {
          rej(e || "Something Went wrong, Please Try again!")
        }
      }
    )
  })
}

export const changeTripStatus = enquiry => {
  let url = CORS_WORKAROUND + CONFIG.URL.TRIP.UPDATE_TRIP
  url = url.replace("$enquiryId", enquiry.id)
  return new Promise((res, rej) => {
    put(url, enquiry).then(
      resp => {
        if (res && resp.data.status) res(resp.data)
        else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const createTripStatus = enquiry => {
  let url = CORS_WORKAROUND + CONFIG.URL.TRIP.UPDATE_TRIP
  return new Promise((res, rej) => {
    post(url, enquiry).then(
      resp => {
        if (res && resp.data.status) res(resp.data)
        else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getAllHelpBuddyList = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.USERS.GET + `/user-type/HelpBuddy`
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (res && resp.data && resp.data.status) res(resp.data.data)
        else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getHelpBuddy = userId => {
  let url = CORS_WORKAROUND + CONFIG.URL.USERS.GET + userId
  return new Promise((res, rej) => {
    get(url, userId).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getFilteredHelpBuddy = ({ status, verified }) => {
  let url = CORS_WORKAROUND + CONFIG.URL.HELP_BUDDY.GET_FILTERED_HELP_BUDDY
  url = url.replace("$status", status)
  url = url.replace("$verified", verified)
  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp.data.status) {
        res(resp.data.data)
      } else {
        rej(resp.data)
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const getSeniorCitizen = userId => {
  let url = CORS_WORKAROUND + CONFIG.URL.USERS.GET + userId
  return new Promise((res, rej) => {
    get(url, userId).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getContactDetails = userId => {
  let url = CORS_WORKAROUND + CONFIG.URL.CONTACT_DETAILS.GET + userId
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const updateProfileDetails = data => {
  console.log("Debug :::> datauser", data)
  if (data && data.type === USER_TYPE.SERVICE_PROVIDER) {
    let url = CORS_WORKAROUND + CONFIG.URL.HELP_BUDDY.POST
    return new Promise((res, rej) => {
      post(url, data).then(
        resp => {
          if (resp.data.status) {
            res(resp.data.data)
          } else {
            rej(resp.data)
          }
        },
        e => {
          console.log(e)
          rej(e)
        }
      )
    })
  } else {
    let url = CORS_WORKAROUND + CONFIG.URL.USERS.POST
    if (data && data.id) {
      url += "/" + data.id
      return new Promise((res, rej) => {
        put(url, data).then(
          resp => {
            if (resp.data.status) {
              res(resp.data.data)
            } else {
              rej(resp.data)
            }
          },
          e => {
            console.log(e)
            rej(e)
          }
        )
      })
    } else {
      return new Promise((res, rej) => {
        post(url, data).then(
          resp => {
            if (resp.data.status) {
              res(resp.data.data)
            } else {
              rej(resp.data)
            }
          },
          e => {
            console.log(e)
            rej(e)
          }
        )
      })
    }
  }
}

export const updateSeniorCitiznDetails = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.USERS.POST
  return new Promise((res, rej) => {
    put(url, data).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const updateHelpBuddyDetails = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.HELP_BUDDY.POST
  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const addUpdateContactDetail = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.CONTACT_DETAILS.POST + data.userID
  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const getAddressDetails = userId => {
  let url = CORS_WORKAROUND + CONFIG.URL.ADDRESS.GET + userId
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getVehicleDetails = userId => {
  let url = CORS_WORKAROUND + CONFIG.URL.VEHICLE_DETAILS.GET + userId
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const checkReferralCodeValidity = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.USERS.VALIDATE_REFERRAL + data
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log("Debug ::> ERR", e)
        rej("Referral code is invalid!")
      }
    )
  })
}

export const updateAddress = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.ADDRESS.POST
  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const deleteAddress = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.ADDRESS.POST + "/" + data.id
  return new Promise((res, rej) => {
    deleteApi(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const updateVehicleDetails = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.VEHICLE_DETAILS.POST
  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const deleteVehicle = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.VEHICLE_DETAILS.POST + "/" + data.id
  return new Promise((res, rej) => {
    deleteApi(url, data).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const deleteContact = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.CONTACT_DETAILS.POST + data.id
  return new Promise((res, rej) => {
    deleteApi(url, data).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const createServiceType = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.SERVICE_TYPE.POST
  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const updateServiceType = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.SERVICE_TYPE.PUT
  url = url.replace("$service_id", data.id)
  return new Promise((res, rej) => {
    put(url, data).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const fetchValueHelps = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.VALUE_HELPS.GET
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const logOut = () => {
  store.dispatch({ type: "USER_LOGOUT" })
  sessionStorage.clear()
  localStorage.clear()

  window.location.href = "/login"
}

export const loadScript = src => {
  return new Promise(resolve => {
    const script = document.createElement("script")
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

export const getAnnualSubPricingDetail = id => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.BOOK_SERVICE.ANNUAL_SUB_PAYMENT_DETAIL +
    "?serviceBookingID=" +
    id +
    "&paymentBookingStatus=GOLD_MEMBERSHIP&paymentMode=ONLINE&key=h60p-72d0d3eb-9399-4220-9b5c-371b366410c2"

  return new Promise((res, rej) => {
    post(url).then(
      resp => {
        console.log(
          "APP-LOG: File: Apiv2.js Line: #1015::> resp?.data",
          resp?.data
        )
        if (resp?.data?.status) {
          res({
            paymentId: resp?.data?.data.id,
            razorPayOrderId: resp?.data?.jsonData,
          })
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log("Debug ::> e", e)
        rej(e)
      }
    )
  })
}

export const verifySubscription = id => {
  let url = CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.VERIFY_SUBSCRIPTION
  url = url.replace("$uid", id)
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log("Debug ::> e", e)
        rej(e)
      }
    )
  })
}

export const bookAnnualSubscription = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.BOOK_ANNUAL_SUB
  url = url.replace("$isTrial", data.isTrialSelected)
  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        if (resp.data.status) {
          console.log("Debug :::> resp.data.data", resp.data.data)
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log("Debug ::> e", e)
        rej(e)
      }
    )
  })
}

export const generateOrder = orderDetail => {
  let url = CORS_WORKAROUND + CONFIG.URL.PAYMENT_GATEWAY.ORDER
  return new Promise((res, rej) => {
    post(url, {
      ...orderDetail,
      userId: JSON.parse(getUserDetail()).id,
      dateTime: new Date().getTime(),
    }).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getPaymentInfoByServiceBookingId = serviceBookingId => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.PAYMENT_GATEWAY.GET_PAYMENT_INFO_BY_SERVICEBOOKING_ID +
    serviceBookingId
  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp.data.status) {
        res(resp.data.data)
      } else {
        rej(resp.data)
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const logPaymentDetail = data => {
  let paymentBookingStatus = "HELP_BUDDY_BOOKING"
  const isAnnual = data.isGoldMembership
  if (data.isTransportationService) {
    paymentBookingStatus = "TRANSPORTATION_BOOKING"
  } else if (isAnnual) {
    paymentBookingStatus = "GOLD_MEMBERSHIP"
  }

  let url =
    CORS_WORKAROUND + CONFIG.URL.PAYMENT_GATEWAY.LOG_PAYMENT + "/update?"

  url = url + "paymentBookingStatus=" + paymentBookingStatus + "&"
  url = url + "key=h60p-72d0d3eb-9399-4220-9b5c-371b366410c2"

  let config = getHeaderData()

  delete data.isTransportationService
  delete data.isGoldMembership

  return new Promise((res, rej) => {
    put(
      url,
      {
        ...data,
      },
      config
    ).then(
      resp => {
        if (isAnnual) {
          let notificationUrl =
            CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.ANNUAL_SUB_NOTIFICATION
          notificationUrl = notificationUrl.replace("$PID", data.bookingID)
          get(notificationUrl).then(data => {
            if (resp.data.status) {
              res(resp.data.data)
            } else {
              rej(resp.data)
            }
          }),
            e => {
              rej(e)
            }
        }
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const generateInvoice = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.PAYMENT_GATEWAY.GENERATE_INVOICE
  if (data.serviceID) {
    url =
      url +
      `?userID=${data.userID}&serviceID=${data.serviceID}&razorPayPaymentId=${data.razorPayPaymentId}&amount=${data.amount}&isSuccessful=true&bookingDays=1&manualInvoice=false`
  } else {
    url =
      url +
      `?userID=${data.userID}&serviceType=GOLD_MEMBERSHIP&razorPayPaymentId=${data.razorPayPaymentId}&amount=${data.amount}&isSuccessful=true&bookingDays=1&manualInvoice=false`
  }
  return new Promise((res, rej) => {
    post(url).then(resp => {
      if (resp.data.status) {
        res(resp.data.data)
      } else {
        rej(resp.data)
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const getOnboardStatus = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.ONBOARD_STATUS.STATUS
  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp.data.status) {
        res(resp.data.data)
      } else {
        rej(resp.data)
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const getEqQuestions = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.ONBOARD_STATUS.EQ_QUESTION
  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp.data.status) {
        res(resp.data.data)
      } else {
        rej(resp.data)
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const uploadUserDocument = docs => {
  let url = CORS_WORKAROUND + CONFIG.URL.UPLOAD.USER_DOC_UPLOAD
  url = url.replace("$user_id", JSON.parse(getUserDetail()).id)
  let formData = new FormData()
  formData.append("file", docs.file)
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }
  return new Promise((resolve, reject) => {
    post(url, formData, config).then(resp => {
      if (resp) {
        resolve({
          key: docs.key,
          data: resp.data,
        })
      }
    }),
      e => {
        reject(e)
      }
  })
}

export const downloadUserDocument = docsId => {
  let url = CORS_WORKAROUND + CONFIG.URL.UPLOAD.USER_DOC_DOWNLOAD
  url = url.replace("$user_id", JSON.parse(getUserDetail()).id)
  url = url.replace("$file_id", docsId)
  window.open(url, "_blank")
}

export const getEmotionalQuotient = (userId = null) => {
  let url = CORS_WORKAROUND + CONFIG.URL.ONBOARD_STATUS.EMOTIONAL_QUOTIENT.GET
  url = url.replace(
    "$user_id",
    userId && userId !== null ? userId : JSON.parse(getUserDetail()).id
  )
  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp.data.status) {
        res(resp.data.data)
      } else {
        rej(resp.data)
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const saveEmotionalQuotiet = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.ONBOARD_STATUS.EMOTIONAL_QUOTIENT.POST
  url = url.replace("$user_id", JSON.parse(getUserDetail()).id)
  return new Promise((resolve, reject) => {
    post(
      url,
      data.map(item => {
        item["userID"] = JSON.parse(getUserDetail()).id
        return item
      })
    ).then(resp => {
      resolve(resp)
    }),
      e => {
        reject(e)
      }
  })
}

export const getSocialBehaviour = (userId = null) => {
  let url = CORS_WORKAROUND + CONFIG.URL.ONBOARD_STATUS.SOCIAL_BEHAVIOUR.GET
  url = url.replace(
    "$user_id",
    userId && userId !== null ? userId : JSON.parse(getUserDetail()).id
  )
  return new Promise((resolve, reject) => {
    get(url).then(resp => {
      if (resp.data.status) {
        resolve(resp.data.data)
      } else {
        resolve(resp.data)
      }
    }),
      e => {
        reject(e)
      }
  })
}

export const saveSocialBehaviour = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.ONBOARD_STATUS.SOCIAL_BEHAVIOUR.POST
  url = url.replace("$user_id", JSON.parse(getUserDetail()).id)
  return new Promise((resolve, reject) => {
    post(
      url,
      data.map(item => {
        item["userID"] = JSON.parse(getUserDetail()).id
        return item
      })
    ).then(resp => {
      if (resp.data.status) {
        resolve(resp.data.data)
      } else {
        resolve(resp.data)
      }
    }),
      e => {
        reject(e)
      }
  })
}

export const sendWhatsappMessage = data => {
  const url = CORS_WORKAROUND + CONFIG.URL.COMMON.SEND_WHATSAPP_MESSAGE + data
  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp.data.status) {
        res(resp.data.data)
      } else {
        rej(resp.data)
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const bookWebinar = ({ type }) => {
  let url = CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.WEBINAR
  url = url.replace("$user_id", JSON.parse(getUserDetail()).id)
  url += `?webinarType=${type}`

  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        res(resp.data)
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getTotalRegisteredUser = period => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.DASHBOARD.REGISTERED_USER.replace("$period", period)
  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp?.data?.status) {
        const keys = Object.keys(resp.data.data)
        let totalNumber = 0
        keys.forEach((key, index) => {
          totalNumber += resp.data[key].reduce((a, b) => a + b.count, 0)
        })
        res({
          totalNumber,
        })
      }
    }),
      e => {
        rej(e)
      }
  })
}

// User Registered
export const getTotalRegisteredUserByServiceType = (
  period = PERIOD.MONTHLY
) => {
  console.log("Debug :::> 123", 123)
  let url = CORS_WORKAROUND
  url += CONFIG.URL.DASHBOARD.REGISTERED_USER
  url = url.replace("$period", period)

  return new Promise((res, rej) => {
    get(url).then(resp => {
      console.log("Debug :::> resp?.data", resp?.data)
      if (resp?.status) {
        let keys = Object.keys(resp.data)
        if (period !== PERIOD.YEARLY) {
          keys.reverse()
        }
        let total = []
        keys.forEach((key, index) => {
          const totalAmount = resp.data[key]
            .map(x => x.count)
            .reduce((partialSum, a) => partialSum + a, 0)
          total.push(totalAmount)
        })
        let revenueDataMap = {}
        keys.forEach((key, index) => {
          resp.data[key].forEach(data => {
            const count =
              revenueDataMap[data.userType]?.count?.length > 0
                ? [...revenueDataMap[data.userType]?.count, data.count]
                : [data.count]
            const serviceBookedData =
              data.serviceBooked === null ? 0 : data.serviceBooked
            const serviceBooked =
              revenueDataMap[data.userType]?.serviceBooked?.length > 0
                ? [
                    ...revenueDataMap[data.userType]?.serviceBooked,
                    serviceBookedData,
                  ]
                : [serviceBookedData]
            revenueDataMap = {
              ...revenueDataMap,
              [data.userType]: {
                count,
                serviceBooked,
              },
            }
          })
        })

        const data = []
        Object.keys(revenueDataMap).forEach((key, index) => {
          data.push({
            name: key,
            data: revenueDataMap[key],
          })
        })
        console.log("Debug::>DASHBOARD", {
          category: keys,
          data,
          total,
        })
        res({
          category: keys,
          data,
          total,
        })
      }
    }),
      e => {
        console.log("Debug :::> e Debug", e)
        rej(e)
      }
  })
}

// Revenue Stats
export const getRevenueByType = data => {
  const period = data.period || PERIOD.MONTHLY
  let url = CORS_WORKAROUND
  url += CONFIG.URL.DASHBOARD.REVENUE
  url = url.replace("$period", period)
  if (data.id) {
    url = url + `?partnerID=${data.id}`
  }

  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp?.status) {
        let keys = Object.keys(resp.data)
        if (period !== PERIOD.YEARLY) {
          keys.reverse()
        }
        let revenueDataMap = {}
        let total = []
        keys.forEach((key, index) => {
          const totalAmount = resp.data[key]
            .map(x => x.amount)
            .reduce((partialSum, a) => partialSum + a, 0)
          total.push((totalAmount / 100).toFixed(2))
        })
        keys.forEach((key, index) => {
          resp.data[key].forEach(data => {
            const fixedAmount = (data.amount / 100).toFixed(2)
            const amount =
              revenueDataMap[data.serviceType]?.length > 0
                ? [...revenueDataMap[data.serviceType], fixedAmount]
                : [fixedAmount]
            revenueDataMap = {
              ...revenueDataMap,
              [data.serviceType]: amount,
            }
          })
        })
        const data = []
        Object.keys(revenueDataMap).forEach((key, index) => {
          data.push({
            name: key,
            data: revenueDataMap[key],
          })
        })

        res({
          category: keys,
          data,
          total,
        })
      }
    }),
      e => {
        rej(e)
      }
  })
}
// Service Booking by Type
export const getServiceBookingByType = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.DASHBOARD.BOOKING_BY_SERVICE_TYPE
  url = url + `?userID=${JSON.parse(getUserDetail()).id}`
  return new Promise((res, rej) => {
    get(url).then(resp => {
      console.log("Debug :::> resp1112", resp)
      if (resp?.data) {
        const category = resp.data.map(data => data.serviceType)
        const data = resp.data.map(data => data.bookingCount)
        res({
          category: category,
          data: data,
        })
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const getGeneralStats = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.DASHBOARD.GENERAL_STATS
  if (data.payload) {
    url = url + `?partnerID=${data.payload}`
  }
  return new Promise((res, rej) => {
    get(url).then(resp => {
      console.log("Debug :::> resp general states", resp)
      if (resp.data) {
        res(resp.data)
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const initialiseChat = () => {
  const loggedInUserId = uuid()
  localStorage.setItem("loggedInUserId", loggedInUserId)

  const url =
    CORS_WORKAROUND +
    CONFIG.URL.CHAT_WIDGET.MESSAGE_INITIALISE.replace(
      "$USER_ID",
      loggedInUserId
    )
  // console.log("initialiseChat url", url)

  return new Promise((res, rej) => {
    post(url, { name: "start_session" }).then(
      resp => {
        const { data } = resp
        res(data)
      },
      e => {
        rej(e)
      }
    )
  })
}

export const sendUserMessage = ({ message }) => {
  const loggedInUserId = localStorage.getItem("loggedInUserId")
  const url = CORS_WORKAROUND + CONFIG.URL.CHAT_WIDGET.MESSAGE_WEBHOOK

  return new Promise((res, rej) => {
    post(url, {
      message,
      sender: loggedInUserId,
    }).then(
      resp => {
        const { data } = resp
        res(data)
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getActionDetails = actionId => {
  const url = `RASA_URL/action/get/${actionId}`

  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        const { data } = resp
        res(data)
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getSlotDetails = slotId => {
  const url = `RASA_URL/slot/get/${slotId}`

  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        const { data } = resp
        res(data)
      },
      e => {
        rej(e)
      }
    )
  })
}

export const checkPromoCodeValidity = serviceInfo => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.SERVICE_OFFER.CHECK_PROMO_CODE +
    "?promo=" +
    serviceInfo.promo
  url = url.replace("$service_id", serviceInfo.serviceID)
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          resolve(resp.data.data)
        } else {
          resolve(resp.data)
        }
      },
      e => {
        console.log(e)
        alertCall(e.message, "danger")
        rej(e)
      }
    )
  })
}

export const getAdminPackagesApi = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.PACKAGES.GET_PACKAGES_BY_USER_ID
  url = url.replace("$user_id", JSON.parse(getUserDetail()).id)

  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getUserPackagesApi = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.PACKAGES.GET_PACKAGES_BY_USER_ID
  url = url.replace("$user_id", JSON.parse(getUserDetail()).id)

  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getPackagesByUserIdApi = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.PACKAGES.GET_PACKAGES_BY_USER_ID
  url = url.replace("$user_id", JSON.parse(getUserDetail()).id)

  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const addPackageApi = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.PACKAGES.ADD_PACKAGE

  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        alertCall("Package added successfully!", "success")
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        alertCall(e.response?.data?.message || e.message, "danger")
        rej(e)
      }
    )
  })
}

export const updatePackageApi = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.PACKAGES.UPDATE_PACKAGE
  url = url.replace("$package_id", data.id)

  return new Promise((res, rej) => {
    put(url, data).then(
      resp => {
        alertCall("Package updated successfully!", "success")
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        alertCall(e.response?.data?.message || e.message, "danger")
        rej(e)
      }
    )
  })
}

export const deletePackageApi = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.PACKAGES.DELETE_PACKAGE
  url = url.replace("$package_id", data.id)

  return new Promise((res, rej) => {
    deleteApi(url).then(
      resp => {
        alertCall("Package deleted successfully!", "success")
        res(resp)
      },
      e => {
        console.log(e)
        alertCall(e.response?.data?.message || e.message, "danger")
        rej(e)
      }
    )
  })
}

export const bookPackageApi = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.PACKAGES.BOOK_PACKAGE

  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        if (resp && resp.data) {
          const paymentsData = cloneDeep(resp.data)
          delete paymentsData._links

          res({
            status: true,
            mode: PAYMENT_MODE.ONLINE,
            paymentInfo: paymentsData,
          })
        }
        res(false)
      },
      e => {
        console.log(e)
        alertCall(e.response?.data?.message || e.message, "danger")
        rej(e)
      }
    )
  })
}

export const bookPackageServiceApi = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.PACKAGES.BOOK_PACKAGE_SERVICE
  url = url.replace("$user_id", JSON.parse(getUserDetail()).id)

  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        console.log(e)
        alertCall(e.response?.data?.message || e.message, "danger")
        rej(e)
      }
    )
  })
}

export const getPackageBookingsApi = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.PACKAGES.GET_SERVICE_PACKAGE_BOOKINGS

  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getPackageBookingsByUserIdApi = () => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.PACKAGES.GET_SERVICE_PACKAGE_BOOKINGS_BY_USER_ID
  url = url.replace("$user_id", JSON.parse(getUserDetail()).id)

  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        if (resp.data.status) {
          res(resp.data.data)
        } else {
          rej(resp.data)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

// Service

export const getTotalBookedService = data => {
  const period = data.period || PERIOD.MONTHLY
  let url = CORS_WORKAROUND
  url += CONFIG.URL.DASHBOARD.TOTAL_SERVICE_BOOKING
  url = url.replace("$period", period)
  if (data.id) {
    url = url + `?partnerID=${data.id}`
  }
  // let url = CORS_WORKAROUND + CONFIG.URL.DASHBOARD.TOTAL_SERVICE_BOOKING;
  // url = url.replace('$period', period === PERIOD.MONTHLY ? 'MONTHLY' : period === PERIOD.YEARLY ? 'YEARLY' : 'WEEKLY')

  return new Promise((res, rej) => {
    get(url).then(resp => {
      if (resp) {
        let keys = Object.keys(resp.data)
        if (period !== PERIOD.YEARLY) {
          keys.reverse()
        }
        let total = []
        keys.forEach((key, index) => {
          const totalAmount = resp.data[key]
            .map(x => x.bookingCount || x.BookingCount || 0)
            .reduce((partialSum, a) => partialSum + a, 0)
          total.push(totalAmount)
        })
        let revenueDataMap = {}
        keys.forEach((key, index) => {
          resp.data[key].forEach(data => {
            const bookingCount =
              revenueDataMap[data.serviceType]?.length > 0
                ? [
                    ...revenueDataMap[data.serviceType],
                    data.bookingCount || data.BookingCount || 0,
                  ]
                : [data.bookingCount || data.BookingCount || 0]
            revenueDataMap = {
              ...revenueDataMap,
              [data.serviceType]: bookingCount,
            }
          })
        })
        const data = []
        Object.keys(revenueDataMap).forEach((key, index) => {
          data.push({
            name: key,
            data: revenueDataMap[key],
          })
        })

        res({
          category: keys,
          data,
          total,
        })
      }
    }),
      e => {
        rej(e)
      }
  })
}

export const makePaymentService = orderDetail => {
  return new Promise((res, rej) => {
    const options = {
      key:
        getServerDetail().env === ENV.PROD
          ? RZP_API_KEY_PROD
          : RZP_API_KEY_TEST,
      currency: "INR",
      amount: orderDetail.amount,
      name: "Happy60Plus",
      description: "Service booking",
      image: happy60plus,
      order_id: orderDetail.razorPayOrderId,
      handler: function (response) {
        console.log(response)
        dispatch(paymentStatus(response))
        res(response)
        return response
      },
      prefill: getUserDetail()
        ? {
            name:
              JSON.parse(getUserDetail()).firstName +
              " " +
              JSON.parse(getUserDetail()).lastName,
            email: JSON.parse(getUserDetail()).email,
            contact: JSON.parse(getUserDetail()).mobileNumber,
          }
        : {},
    }
    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
    paymentObject.on("payment.failed", function (response) {
      dispatch(paymentStatus(response))
      res(response)
      return response
    })
  })
}

export const updateWalletBalance = data => {
  // let url = CORS_WORKAROUND + CONFIG.URL.WALLET.UPDATE_BALANCE
  // if (data.id) {
  //   return new Promise((res, rej) => {
  //     put(url + "/" + data.id, data).then(
  //       resp => {
  //         if (resp && resp.data) {
  //           res(resp.data)
  //         }
  //       },
  //       e => {
  //         rej(e)
  //       }
  //     )
  //   })
  // } else {
  //   return new Promise((res, rej) => {
  //     post(url, data).then(
  //       resp => {
  //         if (resp && resp.data) {
  //           res(resp.data)
  //         }
  //       },
  //       e => {
  //         rej(e)
  //       }
  //     )
  //   })
  // }
}

export const getWalletBalance = userId => {
  // let url = CORS_WORKAROUND + CONFIG.URL.WALLET.BALANCE
  // url = url.replace(
  //   "$user_id",
  //   userId && userId !== null ? userId : JSON.parse(getUserDetail()).id
  // )
  // return new Promise((res, rej) => {
  //   get(url).then(
  //     resp => {
  //       if (
  //         resp &&
  //         resp.data &&
  //         resp.data.data &&
  //         resp.data.data["hsp-wallets"].length
  //       ) {
  //         res(resp.data.data["hsp-wallets"][0])
  //       } else {
  //         res({
  //           id: null,
  //           userId: userId,
  //           amount: 0,
  //           isActive: true,
  //         })
  //       }
  //     },
  //     e => {
  //       rej(e)
  //     }
  //   )
  // })
}

export const getAllWalletBalance = () => {
  // let url = CORS_WORKAROUND + CONFIG.URL.WALLET.ALL_WALLET_BALANCE
  // url = url.replace(
  //   "$user_id",
  //   userId && userId !== null ? userId : JSON.parse(getUserDetail()).id
  // )
  // return new Promise((res, rej) => {
  //   get(url).then(
  //     resp => {
  //       if (
  //         resp &&
  //         resp.data &&
  //         resp.data.data &&
  //         resp.data.data["hsp-wallets"].length
  //       ) {
  //         res(resp.data.data["hsp-wallets"])
  //       }
  //     },
  //     e => {
  //       rej(e)
  //     }
  //   )
  // })
}

export const getPrigincDetail = serviceInfo => {
  let url
  console.log(
    "APP-LOG: File: Apiv2.js Line: #2066::> isRoundTrip",
    serviceInfo.isRoundTrip
  )
  const isRoundTrip = serviceInfo.roundTrip || false
  url =
    CORS_WORKAROUND +
    CONFIG.URL.BOOK_SERVICE.PRICING_DETAIL +
    "?isRoundTrip=" +
    isRoundTrip

  const repsData = Object.assign({}, serviceInfo)
  delete repsData.paymentMode
  delete repsData.isRoundTrip

  return new Promise((res, rej) => {
    post(url, repsData).then(resp => {
      console.log("Debug :::> resp data after book", resp)
      if (resp && resp.data) {
        res(resp.data)
      }
    }),
      e => {
        console.log(e)
        rej(e)
      }
  })
}

export const bookServices = data => {
  if (data.id) {
    url += "/" + data.id
    return new Promise((res, rej) => {
      put(url, data).then(
        resp => {
          console.log("Debug :::> resp", resp)
          if (
            resp &&
            resp.data &&
            resp.data.data &&
            resp.data.data &&
            resp.data.data["service-payments"]
          ) {
            res(resp.data.data["service-payments"][0])
          } else {
            res(false)
          }
        },
        e => {
          console.log(e)
          rej(e)
        }
      )
    })
  } else {
    console.log("APP-LOG: File: Apiv2.js Line: #2122::> data", data)
    const repsData = Object.assign({}, data)
    const paymentMode = repsData.paymentMode
    delete repsData.paymentMode
    delete repsData.isRoundTrip

    let bookingType = "HELP_BUDDY_BOOKING"

    let url =
      CORS_WORKAROUND +
      CONFIG.URL.BOOK_SERVICE.POST +
      "?paymentMode=" +
      paymentMode
    if (data.isRoundTrip) {
      url =
        CORS_WORKAROUND +
        CONFIG.URL.BOOK_SERVICE.TRANSPORTATION_BOOKING +
        "?paymentMode=" +
        paymentMode
      bookingType = "TRANSPORTATION_BOOKING"
    }

    return new Promise((res, rej) => {
      post(url, repsData).then(
        resp => {
          console.log("Debug :::> resp service booked", resp)
          if (resp.data.status) {
            console.log("Debug :::> resp.jsonData", resp.data.jsonData)

            const paymentURL =
              CORS_WORKAROUND +
              `${CONFIG.URL.BOOK_SERVICE.PAYMENT}create?serviceBookingID=${resp.data.jsonData}&paymentBookingStatus=${bookingType}&paymentMode=${paymentMode}&key=h60p-72d0d3eb-9399-4220-9b5c-371b366410c2`
            console.log(
              "APP-LOG: File: Apiv2.js Line: #2138::> paymentURL",
              paymentURL
            )
            post(paymentURL).then(
              respPay => {
                console.log(
                  "APP-LOG: File: Apiv2.js Line: #2174::> resp.data",
                  respPay.data
                )
                res({
                  status: true,
                  mode: paymentMode,
                  paymentInfo: respPay.data.jsonData,
                  orderId: resp.data.jsonData,
                  id: respPay?.data?.data?.id,
                })
              },
              e => {
                console.log(e)
                rej(e)
              }
            )
          }
        },
        e => {
          console.log(e)
          rej(e)
        }
      )
    })
  }
}

export const bookActivityService = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.CREATE_ACTIVITY
  url = url.replace("$userID", data.userId)
  url = url.replace("$serviceID", data.serviceID)
  url = url.replace("$isMorning", data.isMorning)
  return new Promise((res, rej) => {
    post(url).then(
      resp => {
        const data = {
          isCurrentMonthBooking: true,
          monthLastDate: moment().endOf("month").format("YYYY-MM-DD"),
        }
        res(data)
      },
      e => {
        rej(e)
      }
    )
  })
}

export const checkBookActivityService = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.CHECK_ACTIVITY
  url = url.replace("$userID", data.userId)
  url = url.replace("$serviceID", data.serviceID)
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        res(resp.data)
      },
      e => {
        rej(e)
      }
    )
  })
}

export const bookMultiServices = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.POST + "booking"

  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        if (resp.data.paymentMode === "ONLINE") {
          res({
            status: true,
            mode: PAYMENT_MODE.ONLINE,
            paymentInfo: resp.data,
          })
        } else if (resp.data.paymentMode === "OFFLINE") {
          res({
            status: true,
            mode: PAYMENT_MODE.OFFLINE,
            paymentInfo: resp.data,
          })
        } else {
          res(false)
        }
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const getHealthCareServices = async () => {
  // let url = CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.GET_HEALTH_CARE_SERVICE;
  // url = url.replace('$bookingStatus','Created')
  // url = url.replace('$uid',JSON.parse(getUserDetail()).id)
  // const resp = await get(url)
  const user = JSON.parse(getUserDetail())
  let urlAssigned
  let helpBuddySelectedUrl

  if (USER_TYPE.PARTNER === user.type) {
    urlAssigned =
      CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.GET_HEALTH_CARE_SERVICE_PARTNER
    urlAssigned = urlAssigned.replace("$isSelected", false)
    urlAssigned = urlAssigned.replace("$uid", JSON.parse(getUserDetail()).id)

    helpBuddySelectedUrl =
      CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.GET_HEALTH_CARE_SERVICE_PARTNER
    helpBuddySelectedUrl = helpBuddySelectedUrl.replace("$isSelected", true)
    helpBuddySelectedUrl = helpBuddySelectedUrl.replace(
      "$uid",
      JSON.parse(getUserDetail()).id
    )
  } else {
    urlAssigned =
      CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.GET_HEALTH_CARE_SERVICE
    urlAssigned = urlAssigned.replace("$bookingStatus", "HelpBuddyAssigned")
    urlAssigned = urlAssigned.replace("$uid", JSON.parse(getUserDetail()).id)

    helpBuddySelectedUrl =
      CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.GET_HEALTH_CARE_SERVICE
    helpBuddySelectedUrl = helpBuddySelectedUrl.replace(
      "$bookingStatus",
      "HelpBuddySelected"
    )
    helpBuddySelectedUrl = helpBuddySelectedUrl.replace(
      "$uid",
      JSON.parse(getUserDetail()).id
    )
  }

  const respAssigned = await get(urlAssigned)
  const helpBuddySelected = await get(helpBuddySelectedUrl)
  if (respAssigned?.data && helpBuddySelected?.data) {
    const healthCareBookings = [
      // ...resp?.data?.data['healthCare-bookings'],
      ...respAssigned?.data,
      ...helpBuddySelected?.data,
    ]
    const healthBuddies = await Promise.all(
      healthCareBookings.map(async booking => {
        let assignHealthBuddyUrl =
          CORS_WORKAROUND +
          CONFIG.URL.BOOK_SERVICE.GET_HEALTH_CARE_HEALTH_BUDDIES
        assignHealthBuddyUrl = assignHealthBuddyUrl.replace("$pid", booking.id)
        let healthBuddiesList = {}
        try {
          healthBuddiesList = await get(assignHealthBuddyUrl)
        } catch (error) {}
        let bookingDetail = {}
        if (booking.commonBookingID) {
          let bookingDetailUrl =
            CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.GET_COMMON_BOOKING_DETAIL
          bookingDetailUrl = bookingDetailUrl.replace(
            "$pid",
            booking.commonBookingID
          )
          try {
            bookingDetail = await get(bookingDetailUrl)
          } catch (error) {}
        }
        let userUrl
        let userData
        if (USER_TYPE.PARTNER === user.type && booking.users) {
          userUrl =
            CORS_WORKAROUND + CONFIG.URL.CONTACT_DETAILS.GET + booking.users.id
          userData = await get(userUrl)
          booking.users = {
            ...booking.users,
            ...userData,
          }
        }

        return {
          ...booking,
          healthBuddies: healthBuddiesList?.data,
          bookingDetail: bookingDetail?.data,
        }
      })
    )
    return healthBuddies
  }
  // if (resp?.data?.data && resp?.data?.data['healthCare-bookings']) {
  //     const healthCareBookings = resp?.data?.data['healthCare-bookings']
  // }
}

export const createHealthCareServices = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.POST + "booking"
  let resData = {
    userID: data.userID,
    serviceID: data.serviceID,
    startDate: data.dateTimeFrom,
    endDate: data.dateTimeEnd,
    duration: data.duration,
    serviceAddressID: data.serviceAddressID,
  }
  if (data.commonBooking === "daily") {
    resData = {
      ...resData,
      isDaily: true,
    }
  } else if (data.commonBooking === "specificDay") {
    resData = {
      ...resData,
      isSpecificDaysOfWeek: true,
      daysOfWeek: data.daysOfWeek,
    }
  }
  return new Promise((res, rej) => {
    post(url, resData).then(
      resp => {
        if (resp.data) {
          const resDataForHealthCare = {
            weight: data.weight,
            diagnosis: data.diagnosis,
            languagePreference: data.selectedLanguages,
            bookingStatus: "Created",
            healthBuddyType: data.healthBuddyType,
            commonBookingID: resp.data.id,
          }
          let url =
            CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.CREATE_HEALTH_CARE_SERVICE
          post(url, resDataForHealthCare).then(
            async resp => {
              const urlHealthBuddy =
                CORS_WORKAROUND + CONFIG.URL.ASSIGNMENTS.ASSIGN_HEALTH_BUDDY
              const resSelectHB = {
                healthBuddyID: data.selectedHealthBuddy.id,
                partnerOrgID: data.selectedHealthBuddy.partnerOrganizationID,
                healthCareBookingID: resp.data.id || null,
              }
              await post(urlHealthBuddy, resSelectHB).then(
                res => {
                  res(resp.data)
                },
                err => {
                  console.log(err)
                  rej(err)
                }
              )
              res(resp.data)
            },
            e => {
              console.log(e)
              rej(e)
            }
          )
        } else {
          throw "error"
        }
        res(resp.data)
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const editBookedServices = data => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.BOOK_SERVICE.POST +
    data.userID +
    "/service-bookings"
  return new Promise((res, rej) => {
    put(url, data).then(
      resp => {
        res(resp.data)
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const cancelBookedService = data => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.BOOK_SERVICE.POST +
    data.userID +
    "/service-bookings/" +
    data.id
  return new Promise((res, rej) => {
    put(url, data).then(
      resp => {
        res(resp.data.data["service-bookings"][0])
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const getServiceBookings = userId => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.BOOK_SERVICE.POST +
    userId +
    "/service-bookings"
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        res(sortServicesByDate(resp.data.data["service-bookings"] || []))
      },
      e => {
        rej(e)
      }
    )
  })
}

export const rateService = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.RATING.SERVICE_RATING
  return new Promise((res, rej) => {
    post(url, { ...data, userType: JSON.parse(getUserDetail()).type }).then(
      resp => {
        res(resp)
      }
    ),
      e => {
        rej(e)
      }
  })
}

export const getAllAssignmentsApi = () => {
  const url = CORS_WORKAROUND + CONFIG.URL.ASSIGNMENTS.ALL_ASSIGNMENT
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        res(sortServicesByDate(resp.data.data["service-bookings"] || []))
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getAllHealthCareServices = async () => {
  const url = CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.GET_HEALTH_CARE_BOOKING
  const resp = await get(url)
  if (resp?.data?.data && resp?.data?.data["healthCare-bookings"]) {
    const healthCareBookings = resp?.data?.data["healthCare-bookings"]
    const healthBuddies = await Promise.all(
      healthCareBookings.map(async booking => {
        let bookingDetail = {}
        if (booking.commonBookingID) {
          let bookingDetailUrl =
            CORS_WORKAROUND + CONFIG.URL.BOOK_SERVICE.GET_COMMON_BOOKING_DETAIL
          bookingDetailUrl = bookingDetailUrl.replace(
            "$pid",
            booking.commonBookingID
          )
          bookingDetail = await get(bookingDetailUrl)
        }

        return {
          ...booking,
          bookingDetail: bookingDetail?.data,
        }
      })
    )
    return healthBuddies
  }
}

export const getAssignedAssignments = data => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.ASSIGNMENTS.ASSIGNED_ASSIGNMENTS +
    `&page=${data.page - 1}&size=${data.perPage}`
  const id = data.id
  url = url.replace(
    "$user_id",
    id !== null
      ? id
      : JSON.parse(getUserDetail()).type === USER_TYPE.ADMIN
      ? USER_TYPE.ADMIN
      : JSON.parse(getUserDetail()).id
  )
  return new Promise((res, rej) => {
    get(url).then(
      async resp => {
        const data = resp.data.data["service-bookings"]
        let url = CORS_WORKAROUND + CONFIG.URL.USERS.GET
        const resData = await Promise.all(
          data.map(async service => {
            const user = await get(url + service.userID)
            return {
              ...service,
              user: user.data,
            }
          })
        )
        res({ data: resData || [], page: resp.data.page })
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getOngoingAssignments = data => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.ASSIGNMENTS.ONGOING_ASSIGNMENTS +
    `&page=${data.page - 1}&size=${data.perPage}`
  const id = data.id
  url = url.replace(
    "$user_id",
    id !== null
      ? id
      : JSON.parse(getUserDetail()).type === USER_TYPE.ADMIN
      ? USER_TYPE.ADMIN
      : JSON.parse(getUserDetail()).id
  )
  return new Promise((res, rej) => {
    get(url).then(
      async resp => {
        const data = resp.data.data["service-bookings"]
        let url = CORS_WORKAROUND + CONFIG.URL.USERS.GET
        const resData = await Promise.all(
          data.map(async service => {
            const user = await get(url + service.userID)
            return {
              ...service,
              user: user.data,
            }
          })
        )
        res({ data: resData || [], page: resp.data.page })
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getCompletedAssignments = data => {
  let url =
    CORS_WORKAROUND +
    CONFIG.URL.ASSIGNMENTS.COMPLETED_ASSIGNMENTS +
    `&page=${data.page - 1}&size=${data.perPage}`
  const id = data.id
  url = url.replace(
    "$user_id",
    id !== null
      ? id
      : JSON.parse(getUserDetail()).type === USER_TYPE.ADMIN
      ? USER_TYPE.ADMIN
      : JSON.parse(getUserDetail()).id
  )
  return new Promise((res, rej) => {
    get(url).then(
      async resp => {
        const data = resp.data.data["service-bookings"]
        let url = CORS_WORKAROUND + CONFIG.URL.USERS.GET
        const resData = await Promise.all(
          data.map(async service => {
            const user = await get(url + service.userID)
            return {
              ...service,
              user: user.data,
            }
          })
        )
        res({ data: resData || [], page: resp.data.page })
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getIncompletedAssignments = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.ASSIGNMENTS.INCOMPLETE_ASSIGNMENTS
  return new Promise((res, rej) => {
    get(url).then(
      resp => {
        res(sortServicesByDate(resp.data.data["service-bookings"] || [], true))
      },
      e => {
        rej(e)
      }
    )
  })
}

export const acceptService = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.ASSIGNMENTS.ASSING_ASSIGNMENT
  url = url.replace("$user_id", data.userID)
  url = url.replace("$service_id", data.id)
  return new Promise((res, rej) => {
    put(url, {
      ...data,
      buddyID:
        data.buddyID !== null ? data.buddyID : JSON.parse(getUserDetail()).id,
    }).then(
      resp => {
        res(resp.data.data["service-bookings"][0])
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const acceptHCService = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.ASSIGNMENTS.ACCEPT_HEALTH_CARE_SERVICE
  url = url.replace("$hcid", data.healthCareBookingID)
  url = url.replace("$hbid", data.healthBuddyId)
  return new Promise((res, rej) => {
    get(url).t1hen(
      resp => {
        res(resp.data)
      },
      e => {
        console.log("Debug ::> e", e)
        rej(e)
      }
    )
  })
}

export const assignHealthBuddy = data => {
  const url = CORS_WORKAROUND + CONFIG.URL.ASSIGNMENTS.ASSIGN_HEALTH_BUDDY
  if (data.selected) {
    return new Promise((res, rej) => {
      put(url, data).then(
        resp => {
          let getPaymentDetail =
            CORS_WORKAROUND +
            CONFIG.URL.ASSIGNMENTS.GET_HEALTH_CARE_BOOKING_ESTIMATION
          getPaymentDetail = getPaymentDetail.replace(
            "$healthCareBookingId",
            data.healthCareBookingID
          )
          get(getPaymentDetail).then(
            resp => {
              res(resp.data)
            },
            e => {
              console.log("Debug ::> e", e)
              rej(e)
            }
          )
          // res(resp.data);
        },
        e => {
          console.log(e)
          rej(e)
        }
      )
    })
  }
  return new Promise((res, rej) => {
    post(url, data).then(
      resp => {
        res(resp.data)
      },
      e => {
        console.log("Debug ::> e", e)
        rej(e)
      }
    )
  })
}

export const rejectService = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.ASSIGNMENTS.ASSING_ASSIGNMENT
  url = url.replace("$user_id", data.userID)
  url = url.replace("$service_id", data.id)
  return new Promise((res, rej) => {
    put(url, data).then(
      resp => {
        res(resp.data)
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const startService = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.ASSIGNMENTS.ASSING_ASSIGNMENT
  url = url.replace("$user_id", data.userID)
  url = url.replace("$service_id", data.id)
  return new Promise((res, rej) => {
    put(url, data).then(
      resp => {
        res(resp.data.data["service-bookings"][0])
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const completeService = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.ASSIGNMENTS.ASSING_ASSIGNMENT
  url = url.replace("$user_id", data.userID)
  url = url.replace("$service_id", data.id)
  return new Promise((res, rej) => {
    put(url, data).then(
      resp => {
        res(resp.data.data["service-bookings"][0])
      },
      e => {
        console.log(e)
        rej(e)
      }
    )
  })
}

export const getAllHealthBuddy = async partnerId => {
  try {
    let url
    if (partnerId) {
      url = `${CORS_WORKAROUND}${CONFIG.URL.HEALTH_BUDDY.GET_BY_PARTNER}${partnerId}`
    } else {
      url = `${CORS_WORKAROUND}${CONFIG.URL.HEALTH_BUDDY.GET_HEALTH_BUDDY_LIST}`
    }
    const resp = await get(url)
    if (resp && resp.data && resp.data.data) {
      const healthBuddies = resp.data.data["health-buddy"]
      const result = await Promise.all(
        healthBuddies.map(async healthBuddy => {
          try {
            const userProfileUrl = `${CORS_WORKAROUND}${CONFIG.URL.USERS.GET}${healthBuddy.id}`
            const userResp = await get(userProfileUrl)
            if (userResp && userResp.data) {
              const addressUrl = `${CORS_WORKAROUND}${CONFIG.URL.ADDRESS.GET}${healthBuddy.id}`
              const addressResp = await get(addressUrl)
              let resData = {
                ...healthBuddy,
                ...userResp.data,
                addresses: null,
              }
              if (addressResp && addressResp.data) {
                resData = {
                  ...resData,
                  ...addressResp.data.data,
                }
              }
              return resData
            }
          } catch (error) {
            console.log(error)
          }
        })
      )
      const healthBuddiesList = result.filter(e => e)
      return healthBuddiesList
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createPartner = (userData, partnerId = null) => {
  let url = CORS_WORKAROUND + CONFIG.URL.USERS.POST
  return new Promise((res, rej) => {
    post(url, userData).then(
      resp => {
        if (resp && resp.data) {
          if (userData.type === USER_TYPE.PARTNER) {
            res(resp.data)
          } else if (userData.type === USER_TYPE.HEALTH_BUDDY) {
            let url = CORS_WORKAROUND + CONFIG.URL.USERS.POST
            const reqData = {
              id: resp.data.id,
              partnerOrganizationID: partnerId,
              servicePrice12Hr: userData.servicePrice12Hr,
              servicePrice24Hr: userData.servicePrice24Hr,
            }
            post(url, reqData).then(
              r => {
                if (r && r.data) {
                  res({
                    ...resp.data,
                    healthBuddyId: r.data.id,
                  })
                }
              },
              e => {
                rej(e)
              }
            )
          }
        } else {
          rej(e)
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const updateHealthBuddy = data => {
  let url = CORS_WORKAROUND + CONFIG.URL.HEALTH_BUDDY.PUT
  url = url.replace("$pid", data.id)
  const reqData = {
    id: data.id,
    age: data.age,
    gender: data.gender,
    languagesKnown: data.languagesKnown,
    backgroundVerification: data.backgroundVerification,
    experience: data.experience,
    aadharNumber: data.aadharNumber,
    education: data.education,
    partnerOrganizationID: data.partnerOrganizationID,
    servicePrice12Hr: data.servicePrice12Hr,
    servicePrice24Hr: data.servicePrice24Hr,
    healthBuddyType: data.type,
    available: data.available,
  }
  return new Promise((res, rej) => {
    put(url, reqData).then(
      resp => {
        if (resp && resp.data && resp.data) {
          let profileUrl = CORS_WORKAROUND + CONFIG.URL.USERS.POST
          if (data && data.id) {
            profileUrl += "/" + data.id
            return new Promise((res, rej) => {
              const reqData = {
                firstName: data.firstName,
                lastName: data.lastName,
                mobileNumber: data.mobileNumber,
                email: data.email,
                profileImageURL: data.profileImageURL,
              }
              put(profileUrl, reqData).then(
                user => {
                  res({
                    ...resp.data,
                    ...user.data,
                  })
                },
                e => {
                  console.log(e)
                  rej(e)
                }
              )
            })
          }
        }
      },
      e => {
        rej(e)
      }
    )
  })
}

export const getRefreshToken = () => {
  let url = CORS_WORKAROUND + CONFIG.URL.LOGIN.GET_REFRESH_TOKEN
  return new Promise((res, rej) => {
    get(url, { headers: { refreshToken: true } }).then(
      resp => {
        const { status, jsonData, errorMessage } = resp?.data || {}
        if (status) {
          res(jsonData)
        } else {
          rej(errorMessage)
        }
      },
      e => {
        rej(e.response?.data?.errorMessage || e)
      }
    )
  })
}

import { ALL_ENQUIRY_LIST, DONE_PROCESS, UPDATE_ENQUIRY } from "./actionTypes"

const INIT_STATE = {
  enquires: [],
  page: {},
  loader: false,
}

const Enquiry = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_ENQUIRY_LIST:
      return {
        ...state,
        enquires: action.payload.data,
        page: action.payload.page,
        loader: false,
      }
    case UPDATE_ENQUIRY:
      return {
        ...state,
        loader: true,
      }
    case DONE_PROCESS:
      return {
        ...state,
        loader: false,
      }

    default:
      return state
  }
}

export default Enquiry

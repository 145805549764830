import React, { useMemo } from "react"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import {
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Row
} from "reactstrap"

const Vehicle = ({
  t,
  data,
  key,
  vehicleSelected,
  deleteVehicleDetail
}) => {
  const { vehicleType, registrationNumber, frontImageURL, rearImageURL } = useMemo(() => data || {}, [data])
  return (
    <Col lg={4} sm={6} xs={12} key={key}>
      <Card outline color="primary" className="border">
        <CardBody>
          <CardTitle className="mt-0">{vehicleType}</CardTitle>
          <CardText>
            <b>Reg No.</b> {registrationNumber}<br />
            {/* <b>Front Img.</b> {frontImageURL}<br />
            <b>Rear Img.</b> {rearImageURL} */}
          </CardText>
          <div
            className="btn-group btn-group-example mb-3"
            role="group"
          >
            <button
              type="button"
              className="btn btn-outline-primary w-sm"
              onClick={() => { vehicleSelected(data) }}
            >
              Edit
            </button>
            <button
              type="button"
              className="btn btn-outline-danger w-sm"
              onClick={() => { deleteVehicleDetail(data) }}
            >
              Delete
            </button>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

Vehicle.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  key: PropTypes.any,
  vehicleSelected: PropTypes.func,
  deleteVehicleDetail: PropTypes.func
}

export default withTranslation()(Vehicle)

import React, { useEffect, useState } from "react"

import { Label } from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import DataTable from "react-data-table-component"
import { getServiceType } from "store/actions"
import AssignmentDetailModal from "components/Happy60/Assignment/assignmentDetailModal"
import { USER_TYPE } from "constants/common"

const SelectedHealthBuddy = () => {
  const dispatch = useDispatch()
  const userDetail = useSelector(state => state.Profile.userProfile)
  const healthCareService = useSelector(
    state => state.ServiceReducer.selectedHealthBuddyHealthCareService
  )
  let serviceType = useSelector(state => state.ServiceReducer.serviceType)
  let addressList = useSelector(state => state.Profile.address)

  const [assignmentDetailModal, setAssignmentDetailModal] = useState(false)
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [paymentModal, setPaymentModal] = useState(false)

  const getServiceName = serviceId => {
    let serviceObj = serviceType.find(item => item.id === serviceId)
    return serviceObj && serviceObj.serviceType ? serviceObj.serviceType : "-"
  }
  const getAddressTag = addressId => {
    let addressObj = addressList.find(item => item.id === addressId)
    return addressObj && addressObj.tags ? addressObj.tags : "-"
  }

  useEffect(() => {
    if (!serviceType?.length) {
      dispatch(getServiceType())
    }
  }, [serviceType])

  let columns = [
    {
      id: 1,
      name: "Service",
      selector: item => item.bookingDetail.serviceID,
      format: item => getServiceName(item.bookingDetail.serviceID),
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Start Date",
      selector: item => item.bookingDetail.startDate,
      format: item =>
        moment(item.bookingDetail.startDate).isValid()
          ? moment
              .utc(item.bookingDetail.startDate)
              .local()
              .format("DD/MM/YYYY")
          : "-",
      sortable: true,
      reorder: true,
    },
    {
      id: 20,
      name: "End Date",
      selector: item => item.bookingDetail.endDate,
      format: item =>
        moment(item.bookingDetail.endDate).isValid()
          ? moment.utc(item.bookingDetail.endDate).local().format("DD/MM/YYYY")
          : "-",
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Duration",
      selector: row => row.bookingDetail.duration,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Address",
      selector: item => item.bookingDetail.serviceAddressID,
      format: item => getAddressTag(item.bookingDetail.serviceAddressID),
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Diagnosis",
      selector: row => row.diagnosis,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Action",
      selector: item => (
        <div>
          <ul className="list-inline mb-0 font-size-20">
            <li className="list-inline-item">
              <i
                className="bx bxs-user-detail text-primary"
                onClick={() => {
                  setSelectedAssignment(item)
                  setAssignmentDetailModal(true)
                }}
              />
            </li>
          </ul>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      {healthCareService && healthCareService.length > 0 && (
        <DataTable
          noHeader
          // title="Senior Citizen"
          columns={columns}
          data={healthCareService}
          defaultSortFieldId={2}
          // sortIcon={<SortIcon />}
          pagination
          highlightOnHover
          // selectableRows
        />
      )}
      {healthCareService.length === 0 && <Label>No bookings found</Label>}
      {selectedAssignment && (
        <>
          <AssignmentDetailModal
            isOpen={assignmentDetailModal}
            data={selectedAssignment}
            closeModal={() => {
              setAssignmentDetailModal(false)
            }}
            isSelected={true}
            userRole={userDetail?.type}
            isHealthCareBooking={userDetail?.type === USER_TYPE.SENIOR_CITIZEN}
            onCancelClick={() => {
              setAssignmentDetailModal(false)
              setShowConfirmModal(true)
            }}
          ></AssignmentDetailModal>
        </>
      )}
    </React.Fragment>
  )
}

export default SelectedHealthBuddy

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import {
  Modal,
  Row,
  Col,
  FormGroup
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux";
import { uploadUserDocument } from "store/actions";
import { getImageForUser } from "utils/Common";

const AddEditVehicle = ({
  t,
  isOpen,
  currentVehicleObject,
  onCancelClick,
  onSubmitClick
}) => {

  const dispatch = useDispatch();
  let { id, vehicleType, registrationNumber, frontImageURL, rearImageURL } = useMemo(() => currentVehicleObject || {}, [currentVehicleObject])

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    onSubmitClick({ ...currentVehicleObject, ...v, frontImageURL: currentVehicleObject?.frontImageURL, rearImageURL: currentVehicleObject?.rearImageURL });
  }
  let uploadFileResponse = useSelector(state => state.CommonReducer.uploadDocResp)

  const uploadImage = (key, data) => {
    dispatch(uploadUserDocument(key, data));
  }

  const removeImage = (key) => {
    currentVehicleObject[key] = null;
    key === 'frontImageURL' ? frontImageURL = null : rearImageURL = null;
  }

  // useEffect(() => {
  //   if (uploadFileResponse) {
  //     console.log(uploadFileResponse);
  //     // uploadFileResponse.key === 'frontImageURL' ? frontImageURL = uploadFileResponse.data : rearImageURL = uploadFileResponse.data;
  //     // console.log(frontImageURL);
  //     // console.log(rearImageURL);
  //     currentVehicleObject = { ...currentVehicleObject, [uploadFileResponse.key]: uploadFileResponse.data };
  //   }
  // }, [uploadFileResponse])

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {id ? t("Update Vehicle") : t("Add Vehicle")}
        </h5>
      </div>
      <AvForm className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v)
        }}>
        <div className="modal-body">

          <Row>
            <Col xs={12} className="mb-4">
              <FormGroup>
                <AvField
                  name="vehicleType"
                  label={t("Vehicle Type")}
                  value={vehicleType}
                  className="form-control"
                  placeholder={t("Enter Vehicle Type")}
                  type="text"
                  required f
                />
              </FormGroup>
            </Col>

            <Col xs={12} className="mb-4">
              <FormGroup>
                <AvField
                  name="registrationNumber"
                  label={t("Registration Number")}
                  value={registrationNumber}
                  className="form-control"
                  placeholder={t("Enter Registration Number")}
                  type="text"
                  required
                />
              </FormGroup>
            </Col>
            {frontImageURL}
            {(!frontImageURL || frontImageURL === null) && (
              <Col xs={12} className="mb-4">
                <FormGroup>
                  <AvField
                    id="frontImageURL"
                    name="frontImageURL"
                    label={t("Front Image URL")}
                    // value={frontImageURL}
                    className="form-control"
                    placeholder={t("Enter Front Image URL")}
                    type="file"
                    onChange={(e) => {
                      uploadImage('frontImageURL', e.target.files[0])
                    }}
                  />
                </FormGroup>
              </Col>
            )}

            {frontImageURL && (
              <Col xs={12} md={6} xl={6} className="mb-4">
                <label>Front Image</label>
                <div className="mb-4">
                  <img className="rounded-circle avatar-xl" src={getImageForUser(id, frontImageURL)} alt="" />
                  {/* <i className="bx bxs-trash text-danger" style={{ "marginLeft": "20px", "cursor": "pointer", "fontSize": "16px" }} onClick={() => {
                    removeImage("frontImageURL");
                  }} id="deleteImage"></i> */}
                </div>
              </Col>
            )}

            {(!rearImageURL || rearImageURL === null) && (
              <Col xs={12}>
                <FormGroup>
                  <AvField
                    id="rearImageURL"
                    name="rearImageURL"
                    label={t("Rear Image URL")}
                    // value={rearImageURL}
                    className="form-control"
                    placeholder={t("Enter Rear Image URL")}
                    type="file"
                    onChange={(e) => {
                      uploadImage('rearImageURL', e.target.files[0])
                    }}
                  />
                </FormGroup>
              </Col>
            )}
            {rearImageURL && (
              <Col xs={12} md={6} xl={6} className="mb-4">
                <label>Rear Image</label>
                <div className="mb-4">
                  <img className="rounded-circle avatar-xl" src={getImageForUser(id, rearImageURL)} alt="" />
                  {/* <i className="bx bxs-trash text-danger" style={{ "marginLeft": "20px", "cursor": "pointer", "fontSize": "16px" }} onClick={() => {
                    removeImage("rearImageURL");
                  }} id="deleteImage"></i> */}
                </div>

              </Col>
            )}
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              onCancelClick();
            }}
            className="btn btn-secondary px-4"
            data-dismiss="modal"
          >
            {t("Cancel")}
          </button>

          <button
            type="submit"
            // onClick={() => {
            //   onSubmitClick(currentVehicleObject)
            // }}
            className="btn btn-primary px-4"
          >
            {currentVehicleObject && currentVehicleObject._links ? t("Update") : t("Add")}
          </button>
        </div>
      </AvForm>
    </Modal >
  )
}

AddEditVehicle.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  currentVehicleObject: PropTypes.any
}

export default withTranslation()(AddEditVehicle)

const { ALL_ASSIGNMENTS, USER_ASSIGNMENTS, COMPLETED_ASSIGNMENT_RESP, ASSIGNED_ASSIGNMENT_RESP, ONGOING_ASSIGNMENTS_RESP, START_SERVICE_RESPONSE, COMPLETE_SERVICE_RESPONSE, ACCEPT_SERVICE_RESPONSE, INCOMPLETE_ASSIGNMENT_RESP, HEALTH_CARE_PAYMENT_DETAIL } = require("./actionTypes")

const initialState = {
    allAssignments: [],
    ongoingAssignments: [],
    upcomingAssignments: [],
    completedAssignments: [],
    incompleteAssignments: [],
    healthCarePaymentDetail: {}
}

const AssignmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_ASSIGNMENTS:
            return {
                ...state,
                allAssignments: action.payload
            }
        case USER_ASSIGNMENTS:
            return {
                ...state,
                ongoingAssignments: action.payload.ongoing,
                upcomingAssignments: action.payload.upcoming,
                completedAssignments: action.payload.completed,
            }

        case ASSIGNED_ASSIGNMENT_RESP: {
            return {
                ...state,
                upcomingAssignments: action.payload
            }
        }
        case ONGOING_ASSIGNMENTS_RESP: {
            return {
                ...state,
                ongoingAssignments: action.payload
            }
        }
        case COMPLETED_ASSIGNMENT_RESP: {
            return {
                ...state,
                completedAssignments: action.payload
            }
        }
        case INCOMPLETE_ASSIGNMENT_RESP: {
            return {
                ...state,
                incompleteAssignments: action.payload
            }
        }
        case ACCEPT_SERVICE_RESPONSE:
            return {
                ...state,
                upcomingAssignments: action.payload
            }
        case START_SERVICE_RESPONSE:
            return {
                ...state,
                ongoingAssignments: action.payload
            }
        case COMPLETE_SERVICE_RESPONSE:
            return {
                ...state,
                completedAssignments: action.payload
            }
        case HEALTH_CARE_PAYMENT_DETAIL: 
            return {
                ...state,
                healthCarePaymentDetail: action.payload
            }
        default:
            return state;
    }
}

export default AssignmentReducer;
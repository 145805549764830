import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const StackedColumnChart = ({ periodData }) => {
  if (!periodData.category) {
    return <>Loading...</>
  }
  const options = {
    colors: ['#008FFB', '#3F51B5', '#33B2DF', '#4ECDC4', '#2B908F', '#449DD1', '#5653FE', '#662E9B'],
    chart: {
      stacked: !0,
      toolbar: {
        show: 1
      },
      zoom: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "50%"
        // endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: !1
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        const name = w.globals.initialSeries[seriesIndex].name;
        const total = periodData.total[dataPointIndex];
        return `
        <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
          ${periodData.category[dataPointIndex]}
          <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Total:
                    </span><span class="apexcharts-tooltip-text-y-value">${total}</span></div>
        </div>
        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><span
                class="apexcharts-tooltip-marker" style="background-color: rgb(63, 81, 181);"></span>
            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${name}:
                    </span><span class="apexcharts-tooltip-text-y-value">${data}</span></div>
            </div>
        </div>
        `;
      }
    },
    xaxis: {
      show: true,
      categories: periodData.category,
      labels: {
        show: true
      }
    },
    legend: {
      position: "bottom"
    },
    fill: {
      opacity: 1
    }
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={periodData.data}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  );
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any
}
export default StackedColumnChart;

import Booking from "components/Happy60/bookings"
import React, { useState } from "react"

const ServiceBooking = () => {
    return (
        <>
            <Booking />
        </>
    )
}

export default ServiceBooking
import {
  GET_ALL_ENQUIRY_LIST,
  ALL_ENQUIRY_LIST,
  UPDATE_ENQUIRY,
  DONE_PROCESS,
  SEARCH_ENQUIRY,
} from "./actionTypes"

export const getAllEnquiryList = (page, perPage) => {
  return {
    type: GET_ALL_ENQUIRY_LIST,
    payload: { page, perPage },
  }
}

export const searchEnquiry = searchType => {
  return {
    type: SEARCH_ENQUIRY,
    payload: { searchType },
  }
}

export const allEnquiryList = data => {
  return {
    type: ALL_ENQUIRY_LIST,
    payload: data,
  }
}

export const updateEnquiryStatus = data => {
  return {
    type: UPDATE_ENQUIRY,
    payload: data,
  }
}
export const stopLoading = () => {
  return {
    type: DONE_PROCESS,
  }
}

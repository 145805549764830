import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from "reactstrap"
import { updateContactDetail } from "store/actions";
import AddEditContact from "./Contact/AddEditContact";

import Contact from './Contact/Contact';
import ConfirmModal from '../Common/ConfirmModal'
import { deleteContacts } from '../../store/actions'

const ContactMgmt = () => {

    const [showContactModal, setContactModelVisibility] = useState(false);
    const [currentContactObject, setCurrentContactObject] = useState();
    const [showConfirmModel, setShowConfirmModel] = useState(false);
    const dispatch = useDispatch();

    let contactObjectfromState = useSelector(state => state.Profile.contactDetail);
    let userDetailfromState = useSelector(state => state.Profile.userProfile);
    const addUpdateContactDetail = (data) => {
        dispatch(updateContactDetail({ ...data, userID: userDetailfromState.id }));
        setContactModelVisibility(false);
    }

    return (
        <React.Fragment>
            <Container>
                <Row className="justify-content-center">
                    <Col md={12} lg={12} xl={12}>
                        <h5 className="text-primary">Manage Contacts</h5>
                        <hr />
                        <Row>
                            {
                                contactObjectfromState && contactObjectfromState.map((item, index) => {
                                    return (
                                        <Contact data={item} key={'contact_detail_' + index + item.userID}
                                            contactSelected={(contactDetail) => {
                                                setCurrentContactObject(contactDetail)
                                                setContactModelVisibility(true)
                                            }}
                                            deleteContactDetail={(contactDetail) => {
                                                setCurrentContactObject(contactDetail)
                                                setShowConfirmModel(true)
                                            }}
                                        />
                                    )
                                })
                            }
                        </Row>
                        <br />
                        <AddEditContact
                            isOpen={showContactModal}
                            currentContactObject={currentContactObject}
                            onCancelClick={() => { setContactModelVisibility(false) }}
                            onSubmitClick={(contactDetail) => { addUpdateContactDetail(contactDetail) }}
                        />
                        <ConfirmModal
                            isOpen={showConfirmModel}
                            titleText={'Are you sure you want to delete this contact detail'}
                            onYesClick={() => {
                                dispatch(deleteContacts(currentContactObject))
                                setShowConfirmModel(false)
                            }}
                            onNoClick={() => { setShowConfirmModel(false) }}
                        />
                        <button type="submit" className="btn btn-primary" onClick={() => {
                            setContactModelVisibility(true)
                            setCurrentContactObject()
                        }}>Add New</button>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default ContactMgmt;
import React from "react"
import MetaTags from "react-meta-tags"

import {
    Container
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import OnBoard from "components/Happy60/onboard";
import { getEmotionalQuotient, getSocialBehaviour } from "store/actions";
import { useDispatch } from "react-redux";
import ExternalPayment from "components/Happy60/externalPayment";


const PaymentPage = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>PaymentPage | Happy60Plus</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <ExternalPayment></ExternalPayment>
                </Container>
            </div >
        </React.Fragment >
    );
}


export default PaymentPage;
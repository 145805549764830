import React, { Fragment, useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap"

import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { AvForm } from "availity-reactstrap-validation"
import { updateEnquiryStatus } from "store/actions"

const UserDetailModal = ({ isOpen, data, closeModal }) => {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    ...data,
    // mobileNumber: `${data?.countryCode}${data?.mobileNumber}`,
    mobileNumber: `${data?.mobileNumber}`,
    enquiryStatus: data?.enquiryStatus || "RAISED",
    comment: data?.comment,
  })

  useEffect(() => {
    setFormData({
      ...data,
      mobileNumber: `${data?.mobileNumber}`,
      enquiryStatus: data?.enquiryStatus || "RAISED",
      comment: data?.comment,
    })
  }, [data])

  const handleValidSubmit = () => {
    dispatch(updateEnquiryStatus(formData))
    closeModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      className="modal-dialog-centered modal-xl"
    >
      <div className="modal-header" style={{ borderBottom: "none" }}>
        <h5 className="modal-title mt-0">Change Status</h5>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <Fragment>
        <div className="form">
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
          >
            <Row className="p-4">
              <Col xs={12} md={12} xl={12} className="mb-4">
                <FormGroup>
                  <Label for="enquiryStatus">Status</Label>
                  <Input
                    id="enquiryStatus"
                    name="enquiryStatus"
                    type="select"
                    value={formData.enquiryStatus}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        enquiryStatus: e.target.value,
                      })
                    }}
                  >
                    <option value="RAISED">RAISED</option>
                    <option value="UNDER_PROCESS">UNDER_PROCESS</option>
                    <option value="COMPLETED">COMPLETED</option>
                    <option value="UNSERVICEABLE">UNSERVICEABLE</option>
                    <option value="NOT_FULFILLED">NOT_FULFILLED</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={12} md={12} xl={12} className="mb-4">
                <FormGroup>
                  <Label for="comment">Comment</Label>
                  <Input
                    id="comment"
                    name="comment"
                    type="textarea"
                    value={formData.comment || ""}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        comment: e.target.value,
                      })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12} xl={12} className="mb-4">
                <FormGroup>
                  <Button type="submit" color="primary" className="w-100">
                    Submit
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </AvForm>
        </div>
      </Fragment>
    </Modal>
  )
}

UserDetailModal.propTypes = {
  isOpen: PropTypes.any,
  data: PropTypes.any,
  closeModal: PropTypes.any,
}

export default UserDetailModal

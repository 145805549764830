import React from "react";

import {
    Col,
    Form,
    Input,
    Label,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Progress
} from "reactstrap";
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';

const AvtarText = (props) => {
    return (
        <div className="avatar-sm">
            <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                {props.text}
            </span>
        </div>
    )
}
AvtarText.propTypes = {
    text: PropTypes.string
}

const Training = () => {
    const [watchedVideo, setWatchedVideo] = React.useState([]);
    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const _onEnd = (id) => {
        //check if the id exists in the watchvideo 
        const isAlreadyWatched = watchedVideo.filter(el => el.id == id);
        if (isAlreadyWatched.length == 0)
            setWatchedVideo([...watchedVideo, { id: id }])
    }
    return (<>
        <Progress
            value={watchedVideo.length * 25}
            color="primary"
            style={{ width: '100%' }}
            animated
        ></Progress>
        <Row>
            <Col lg={6}>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <AvtarText text="01" />
                            Customer Service Skills - Video Training Course
                        </CardTitle>
                        <CardSubtitle className="mb-3">
                            Are you planning to become a Customer Service Representative?
                            That’s great! So if you want to expand your customer service skills and techniques,
                            then Customer Service Skills – Video Training Course is totally perfect for you.
                        </CardSubtitle>

                        <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                            <YouTube videoId="YH1EJlHh7DU" opts={{
                                height: '100%',
                                width: '100%',
                            }}
                                onReady={_onReady}
                                onEnd={() => _onEnd(1)}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col lg={6}>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <AvtarText text="02" />
                            The Seven Secrets of Exceptional Customer Service</CardTitle>
                        <CardSubtitle className="mb-3">
                            Carrie Gendreau presentation at the 2011 Vermont Travel Industry Conference. Part of the VTIC Lecture Series.
                        </CardSubtitle>

                        <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                            <YouTube videoId="tb2rv77YO-w" opts={{
                                height: '100%',
                                width: '100%',
                            }}
                                onReady={_onReady}
                                onEnd={() => _onEnd(2)}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col lg={6}>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <AvtarText text="03" />
                            When the Phone Rings: Telephone Skills for Better Service</CardTitle>
                        <CardSubtitle className="mb-3">
                            Great telephone service doesnt have to be a thing of the past. Even with the advent of email and the Internet, your customers still want to be able to call sometimes and reach a live person. And how that call is handled still makes a huge difference to the success of your organization.
                        </CardSubtitle>

                        <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9">
                            <YouTube videoId="GKuxVm_jRoo" opts={{
                                height: '100%',
                                width: '100%',
                            }}
                                onReady={_onReady}
                                onEnd={() => _onEnd(3)}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <AvtarText text="04" />
                            The DO’s & DON’Ts of PHONE Etiquette</CardTitle>
                        <CardSubtitle className="mb-3">
                            5 TIPS FOR BETTER PHONE ETIQUETTE:
                            - Have account pulled up
                            - Prepare Websites
                            - Having Something to Take Notes
                            - Remember to Smile
                            - Fill the Silenc.
                        </CardSubtitle>

                        <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9">
                            <YouTube videoId="3rFR-LiFq_U" opts={{
                                height: '100%',
                                width: '100%',
                            }}
                                onReady={_onReady}
                                onEnd={() => _onEnd(4)}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Progress
            value={watchedVideo.length * 25}
            color="primary"
            style={{ width: '100%' }}
            animated
        ></Progress>
        <br />
        <br />
    </>)
}

export default Training;
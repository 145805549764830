import cloneDeep from 'lodash/cloneDeep';

import {
    ADMIN_PACKAGES, USER_PACKAGES, PACKAGES_BY_USER_ID,
    ADD_PACKAGE_RESPONSE, UPDATE_PACKAGE_RESPONSE, DELETE_PACKAGE_RESPONSE,
    BOOK_PACKAGE_RESPONSE, BOOK_SERVICE_FROM_PACKAGE_RESPONSE,
    PACKAGE_BOOKINGS, PACKAGE_BOOKINGS_BY_USER_ID
} from "./actionTypes"

import { sortListAscending } from 'utils/sortUtil';

const inititalState = {
    adminPackages: [],
    userPackages: [],
    packagesByUserId: [],
    addPackageStatus: false,
    updatePackageStatus: false,
    packageBookingStatus: null,
    packageServiceBookingStatus: null,
    packageBookings: [],
    packageBookingsByUserid: []
}
const PackagesReducer = (state = inititalState, action) => {
    const { type, payload } = action
    switch (type) {
        case ADMIN_PACKAGES:
            return {
                ...state,
                adminPackages: sortListAscending(payload, "name")
            }
        case USER_PACKAGES:
            return {
                ...state,
                userPackages: payload
            }
        case PACKAGES_BY_USER_ID:
            return {
                ...state,
                packagesByUserId: payload
            }

        case ADD_PACKAGE_RESPONSE: {
            if (payload) {
                const { createdByAdmin } = payload
                const { adminPackages, packagesByUserId } = state
                const newPackage = cloneDeep(payload)
                delete newPackage._links

                return {
                    ...state,
                    addPackageStatus: true,
                    adminPackages: createdByAdmin === true ? sortListAscending(adminPackages.concat(newPackage), "name") : adminPackages,
                    packagesByUserId: createdByAdmin === false ? sortListAscending(packagesByUserId.concat(newPackage), "name") : packagesByUserId
                }
            }
            return {
                ...state,
                addPackageStatus: false
            }
        }
        case UPDATE_PACKAGE_RESPONSE: {
            if (payload) {
                const { createdByAdmin, id } = payload
                const { adminPackages, packagesByUserId } = state

                return {
                    ...state,
                    updatePackageStatus: true,
                    adminPackages: createdByAdmin === true ? sortListAscending(adminPackages.map(item => {
                        if (item.id === id) {
                            return payload
                        }
                        return item;
                    }), "name") : adminPackages,
                    packagesByUserId: createdByAdmin === false ? sortListAscending(packagesByUserId.map(item => {
                        if (item.id === id) {
                            return payload
                        }
                        return item;
                    }), "name") : packagesByUserId
                }
            }
            return {
                ...state,
                updatePackageStatus: true
            }
        }
        case DELETE_PACKAGE_RESPONSE: {
            const { createdByAdmin, id } = payload
            const { adminPackages, packagesByUserId } = state

            return {
                ...state,
                adminPackages: createdByAdmin === true ? adminPackages.filter((item) => item.id !== id) : adminPackages,
                packagesByUserId: createdByAdmin === false ? packagesByUserId.filter((item) => item.id !== id) : packagesByUserId
            }
        }
        case BOOK_PACKAGE_RESPONSE:
            return {
                ...state,
                packageBookingStatus: payload
            }
        case BOOK_SERVICE_FROM_PACKAGE_RESPONSE:
            return {
                ...state,
                packageServiceBookingStatus: payload
            }

        case PACKAGE_BOOKINGS:
            return {
                ...state,
                packageBookings: payload
            }
        case PACKAGE_BOOKINGS_BY_USER_ID:
            return {
                ...state,
                packageBookingsByUserid: payload
            }

        default:
            return state
    }
}


export default PackagesReducer;

import {
    GET_USER_REGISTRATION_DATA,
    USER_REGISTRATION_DATA,
    GET_REVENUE_DATA,
    REVENUE_DATA,
    GET_SERVICE_BOOKING_BY_TYPE_DATA,
    SERVICE_BOOKING_BY_TYPE_DATA,
    GET_TOTAL_SERVICE_BOOKING_DATA,
    TOTAL_SERVICE_BOOKING_DATA,
    GET_GENERAL_STATS,
    GENERAL_STATS,
    GET_TOTAL_REGISTERED_USER,
    TOTAL_REGISTERED_USER
} from "./actionTypes";

export const GetUserRegistrationData = (periodType) => {
    return {
        type: GET_USER_REGISTRATION_DATA,
        payload: periodType
    }
}

export const UserRegistrationData = (data) => {
    return {
        type: USER_REGISTRATION_DATA,
        payload: data
    }
}

// revenue data
export const GetRevenueData = (data) => {
    return {
        type: GET_REVENUE_DATA,
        payload: data
    }
}

export const RevenueData = (data) => {
    return {
        type: REVENUE_DATA,
        payload: data
    }
}

// service booking by type
export const GetServiceBookingByTypeData = () => {
    return {
        type: GET_SERVICE_BOOKING_BY_TYPE_DATA,
        payload: null
    }
}

export const ServiceBookingByTypeData = (data) => {
    return {
        type: SERVICE_BOOKING_BY_TYPE_DATA,
        payload: data
    }
}

// total service booking
export const GetTotalServiceBookingData = (data) => {
    return {
        type: GET_TOTAL_SERVICE_BOOKING_DATA,
        payload: data
    }
}

export const TotalServiceBookingData = (data) => {
    return {
        type: TOTAL_SERVICE_BOOKING_DATA,
        payload: data
    }
}

// total user
export const GetTotalRegisteredUser = () => {
    return {
        type: GET_TOTAL_REGISTERED_USER,
        payload: null
    }
}

export const TotalRegisteredUser = (data) => {
    return {
        type: TOTAL_REGISTERED_USER,
        payload: data
    }
}

// genral stats
export const GetGeneralStats = (id) => {
    return {
        type: GET_GENERAL_STATS,
        payload: id
    }
}

export const GeneralStats = (data) => {
    return {
        type: GENERAL_STATS,
        payload: data
    }
}

import React from 'react';
import {
    Modal
} from "reactstrap";
import PropTypes from 'prop-types';

const GenericModal = (props) => {
    const [modal_center, setmodal_center] = React.useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    return (
        <>
            {/* responsible for rendering the page for approvals */}
            <button
                style={{ marginLeft: "5px" }}
                type="button"
                className="btn btn-default btn-xs m-left-2"
                onClick={() => {
                    tog_center();
                }}
                data-toggle="modal"
                data-target=".bs-example-modal-center"
            >
                {props.btn}
            </button>
            <Modal
                size="xl"
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">{props.title}</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_center(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
            </Modal>
        </>
    )
}

GenericModal.propTypes = {
    title: PropTypes.string,
    btn: PropTypes.string,
    children: PropTypes.any
}

export default GenericModal;
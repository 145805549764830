import React, { Fragment, useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap"

import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { AvForm } from "availity-reactstrap-validation"
import { createHomePageService, updateHomePageService } from "store/actions"

const HomePageServiceModel = ({ isOpen, data, closeModal }) => {
  const dispatch = useDispatch()
  const [times, setTimes] = useState(1)
  const [formData, setFormData] = useState({
    ...data,
    title: data.title,
    sub_title: data.sub_title,
    description: data.description,
    image_url: data.image_url,
    image_caption: data.image_caption,
    serviceDetails: data.serviceDetails || [],
  })

  useEffect(() => {
    setFormData({
      ...data,
      title: data.title,
      sub_title: data.sub_title,
      description: data.description,
      image_url: data.image_url,
      image_caption: data.image_caption,
      serviceDetails: data.serviceDetails || [],
    })
    setTimes(data?.serviceDetails?.length || 1)
  }, [data])

  const handleValidSubmit = () => {
    if (data.id) {
      dispatch(updateHomePageService(formData))
    } else {
      dispatch(createHomePageService(formData))
    }
    closeModal()
  }

  console.log("APP-LOG: File: HomePageServiceModel.js Line: #41::> data", data)

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      className="modal-dialog-centered modal-xl"
    >
      <div className="modal-header" style={{ borderBottom: "none" }}>
        <h5 className="modal-title mt-0">Update Service</h5>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <Fragment>
        <div className="form">
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
          >
            <Row className="p-4">
              <Col xs={12} md={12} xl={12} className="mb-4">
                <FormGroup>
                  <Label for="title">Title</Label>
                  <Input
                    id="title"
                    name="title"
                    type="text"
                    value={formData.title || ""}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        title: e.target.value,
                      })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12} xl={12} className="mb-4">
                <FormGroup>
                  <Label for="sub_title">Sub Title</Label>
                  <Input
                    id="sub_title"
                    name="sub_title"
                    type="textarea"
                    value={formData.sub_title || ""}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        sub_title: e.target.value,
                      })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12} xl={12} className="mb-4">
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    id="description"
                    name="description"
                    type="textarea"
                    value={formData.description || ""}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        description: e.target.value,
                      })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12} xl={12} className="mb-4">
                <Row className="mb-4">
                  <Col className="d-flex justify-content-start align-items-center">
                    <Label>Service Details</Label>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-block"
                      type="button"
                      disabled={
                        !(
                          formData?.serviceDetails?.length > 1 ||
                          !!(
                            formData?.serviceDetails[0]?.header ||
                            formData?.serviceDetails[0]?.description
                          )
                        )
                      }
                      onClick={() => {
                        setTimes(times + 1)
                      }}
                    >
                      Add New Detail
                    </button>
                  </Col>
                </Row>
                {Array(times)
                  .fill(0)
                  .map((value, index) => (
                    <Row key={index + value} className="mb-2">
                      <Col xs={4} md={4} xl={4}>
                        <FormGroup>
                          <Input
                            id="header"
                            name="description"
                            type="textarea"
                            placeholder="Header"
                            value={
                              formData?.serviceDetails[index]?.header || ""
                            }
                            onChange={e => {
                              const list = formData.serviceDetails
                              list[index] = {
                                ...list[index],
                                header: e.target.value,
                              }

                              setFormData({
                                ...formData,
                                serviceDetails: list,
                              })
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6} md={6} xl={6}>
                        <FormGroup>
                          <Input
                            id="header"
                            name="serviceDetailsDescription"
                            type="textarea"
                            placeholder="Description"
                            value={
                              formData.serviceDetails[index]?.description || ""
                            }
                            onChange={e => {
                              const list = formData.serviceDetails
                              list[index] = {
                                ...list[index],
                                description: e.target.value,
                              }

                              setFormData({
                                ...formData,
                                serviceDetails: list,
                              })
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={2}
                        md={2}
                        xl={2}
                        className="d-flex justify-content-end align-items-center"
                      >
                        <button
                          type="button"
                          className={`btn btn-primary p-2`}
                          disabled={times === 1}
                          style={{ minWidth: "100px", fontSize: 16 }}
                          onClick={() => {
                            const list = formData.serviceDetails.filter(
                              (x, indexX) => indexX !== index
                            )
                            setFormData({
                              ...formData,
                              serviceDetails: list,
                            })
                            setTimes(times - 1)
                          }}
                        >
                          Delete
                        </button>
                      </Col>
                    </Row>
                  ))}
              </Col>
              <Col xs={12} md={12} xl={12} className="mb-4">
                <FormGroup>
                  <Label for="image_url">Image Url</Label>
                  <Input
                    id="image_url"
                    name="image_url"
                    type="text"
                    value={formData.image_url || ""}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        image_url: e.target.value,
                      })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12} xl={12} className="mb-4">
                <FormGroup>
                  <Label for="image_caption">Image Caption</Label>
                  <Input
                    id="image_caption"
                    name="image_caption"
                    type="text"
                    value={formData.image_caption || ""}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        image_caption: e.target.value,
                      })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12} xl={12} className="mb-4">
                <FormGroup>
                  <Button type="submit" color="primary" className="w-100">
                    Submit
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </AvForm>
        </div>
      </Fragment>
    </Modal>
  )
}

HomePageServiceModel.propTypes = {
  isOpen: PropTypes.any,
  data: PropTypes.any,
  closeModal: PropTypes.any,
}

export default HomePageServiceModel

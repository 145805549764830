import React from "react"
import { Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import Breadcrumb from "../../components/Common/Breadcrumb"
import ServiceList from "components/Happy60/ServiceList"

const HomePageService = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Home Page Service | Happy60Plus</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Home" breadcrumbItem="Home Page Service" />
          <ServiceList></ServiceList>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HomePageService

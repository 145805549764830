import React, { useMemo, useState, useRef, useEffect } from "react"
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { MoreVertical, Send } from "react-feather"

import OverlaySpinner from "./OverlaySpinner";

import UserResponse from "./UserResponse"
import BotResponse from "./BotResponse"

import h60pIconImage from "../../assets/images/happy60plus/happy60plus_icon-square.png"

const ChatbotWidgetComponent = ({
    showWidget, setShowWidget,

    widgetStyleDefault,
    widgetOverlayloading,
    isUploadOrExtractionInProgress,
    handleAbortUploadSubmit,

    clearChatHistory,
    restartChatbot,
    botTyping,

    chatHistory,

    handleDateTimePickerSubmit,
    handleDurationSelectorsSubmit,
    onSubmit,

    handleFileUploadChange,
    handleFileUploadSubmit,
    fileToBeUploaded
}) => {
    const messagesEndRef = useRef(null)
    const buttonsResponseRef = useRef(null)
    const datetimePickersRef = useRef(null)
    const durationSelectorsRef = useRef(null)

    const [message, setMessage] = useState("")
    const [userInputBtnDisabled, setUserInputBtnDisabled] = useState(false)

    const {
        botColor,
        botIcon,
        botName,
        primaryBGC,
        secondaryBGC,
    } = useMemo(() => widgetStyleDefault, [])

    const [botFirstName, botLastName] = useMemo(() => {
        return [botName.slice(0, 3), botName.slice(3,)]
    }, [botName])

    useEffect(() => {
        setTimeout(() => {
            scrollToChatBottom()
        }, 100)
    }, [chatHistory])

    const scrollToChatBottom = () => {
        if (messagesEndRef?.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
        }
    }

    const onUserMessageSubmit = (userMessage, title = "") => {
        if (userMessage) {
            if (durationSelectorsRef?.current) {
                handleDurationSelectorsSubmit(userMessage)
                durationSelectorsRef.current.innerHTML = ""
                setUserInputBtnDisabled(false)
            } else if (datetimePickersRef?.current) {
                handleDateTimePickerSubmit(userMessage)
                datetimePickersRef.current.innerHTML = ""
                setUserInputBtnDisabled(false)
            } else {
                onSubmit({ message: userMessage, title })
                if (buttonsResponseRef?.current) {
                    buttonsResponseRef.current.innerHTML = ""
                }
            }
            setMessage("")
        }
    }

    return (
        <>
            {/* chatbot widget  */}
            <div
                className={classnames("widget", {
                    "d-none": !showWidget,
                    "d-block": showWidget
                })}
            >
                <div className="askelie-bot">
                    <OverlaySpinner
                        widgetOverlayloading={widgetOverlayloading}
                        showCancelButton={isUploadOrExtractionInProgress}
                        onCancel={handleAbortUploadSubmit}
                    />

                    <div className="row">
                        <div
                            className="col d-flex justify-content-between align-items-center chat-header"
                            style={{ background: botColor }}
                        >
                            <h6 className="m-0">
                                <span>{botFirstName}</span>
                                <span className="fw-bolder">{botLastName}</span>
                            </h6>

                            {/* <div className="dropdown">
                                <button className="btn px-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <MoreVertical
                                        size={14}
                                        color="white"
                                    />
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" onClick={clearChatHistory}>Clear</a></li>
                                    <li><a className="dropdown-item" onClick={restartChatbot}>Restart</a></li>
                                    <li><a className="dropdown-item" onClick={() => setShowWidget(false)}>Close</a></li>
                                </ul>
                            </div> */}

                            <UncontrolledButtonDropdown direction="down">
                                <DropdownToggle outline className="border-0 px-0">
                                    <MoreVertical
                                        size={14}
                                        color="white"
                                    />
                                </DropdownToggle>
                                <DropdownMenu className="p-0 widget-menu">
                                    <DropdownItem onClick={clearChatHistory}>
                                        Clear
                                    </DropdownItem>
                                    <DropdownItem onClick={restartChatbot}>
                                        Restart
                                    </DropdownItem>
                                    <DropdownItem onClick={() => setShowWidget(false)}>
                                        Close
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col chat-body" id="chat-body">
                            {
                                chatHistory.map((chatObject, index) => {
                                    const { sender, messages, title } = chatObject

                                    switch (sender) {
                                        case "user": {
                                            return (<UserResponse messages={title || messages} key={`user-response-${index}`} widgetStyle={widgetStyleDefault} />)
                                        }
                                        case "bot": {
                                            return (
                                                <BotResponse
                                                    key={`bot-response-${index}`}
                                                    messages={messages}
                                                    onUserMessageSubmit={onUserMessageSubmit}
                                                    buttonsResponseRef={buttonsResponseRef}
                                                    datetimePickersRef={datetimePickersRef}
                                                    durationSelectorsRef={durationSelectorsRef}
                                                    handleFileUploadChange={handleFileUploadChange}
                                                    handleFileUploadSubmit={handleFileUploadSubmit}
                                                    fileToBeUploaded={fileToBeUploaded}
                                                    updateUserMessage={setMessage}
                                                    setUserInputBtnDisabled={setUserInputBtnDisabled}
                                                    widgetStyle={widgetStyleDefault}
                                                />
                                            )
                                        }
                                        default:
                                            return null
                                    }
                                })
                            }

                            {
                                botTyping ? (
                                    <div>
                                        <div className="bot-response">
                                            <div>
                                                <img src={botIcon} className="bot-profile-photo bot-typing-icon" alt="elie" />
                                            </div>

                                            <div className="bot-typing-div">
                                                <div className="bot-msg" style={{ background: primaryBGC }}>
                                                    <div className="botTyping">
                                                        <div className="bounce1" style={{ backgroundColor: secondaryBGC }} />
                                                        <div className="bounce2" style={{ backgroundColor: secondaryBGC }} />
                                                        <div className="bounce3" style={{ backgroundColor: secondaryBGC }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }

                            <div ref={messagesEndRef} style={{ height: "2px", width: "100%" }} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    onUserMessageSubmit(message)
                                }}
                            >
                                <div className="text-right chat-footer">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Type a message..."
                                        onChange={(e) => {
                                            setMessage(e.target.value)
                                        }}
                                        value={message}
                                        disabled={userInputBtnDisabled}
                                    />

                                    <span
                                        onClick={(e) => {
                                            e.preventDefault()
                                            onUserMessageSubmit(message)
                                        }}
                                    >
                                        <Send
                                            className="send-btn"
                                            type="submit"
                                            size={20}
                                            style={{ color: botColor }}
                                        />
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>

            {/* bot profile */}
            <div
                className={classnames("profile_div", {
                    "d-none": showWidget,
                    "d-block": !showWidget
                })}
                id="profile_div"
                onClick={() => { setShowWidget(true) }}
            >
                <img
                    className="imgProfile"
                    src={h60pIconImage}
                    alt="imgProfile"
                />
            </div>

        </>
    );
}

ChatbotWidgetComponent.propTypes = {
    showWidget: PropTypes.bool,
    setShowWidget: PropTypes.func,

    widgetStyleDefault: PropTypes.any,
    widgetOverlayloading: PropTypes.bool,
    isUploadOrExtractionInProgress: PropTypes.bool,
    handleAbortUploadSubmit: PropTypes.func,

    clearChatHistory: PropTypes.func,
    restartChatbot: PropTypes.func,
    botTyping: PropTypes.bool,

    chatHistory: PropTypes.any,

    handleDateTimePickerSubmit: PropTypes.func,
    handleDurationSelectorsSubmit: PropTypes.func,
    onSubmit: PropTypes.func,

    handleFileUploadChange: PropTypes.func,
    handleFileUploadSubmit: PropTypes.func,
    fileToBeUploaded: PropTypes.any,
}

export default ChatbotWidgetComponent;
